import 'react-grid-layout/css/styles.css'

import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import {
  BookOutlined,
  BuildCircleOutlined,
  DateRangeRounded,
  Refresh,
  RestartAlt,
  SettingsRounded,
  WarningAmberRounded,
  ZoomOut,
} from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Popover,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material'
import Dialog from '@mui/material/Dialog'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Slide from '@mui/material/Slide'
import Typography from '@mui/material/Typography'

import { useTheme } from '@emotion/react'
import { titleize } from 'inflection'
import { DateTime, Interval } from 'luxon'
import GridLayout from 'react-grid-layout'

import { DateSelectionModal, DeviceAwsStatusIcon } from '@common/components'
import { MemoChartMinimap } from '@common/components/chart'
import { DynamicSelect, StaticSelect } from '@common/components/Selects'
import { AUTOMOTIVE_LAYOUT_KEY, DEFAULT_LAYOUT_KEY } from '@common/constants'
import { formatScenarioName, parseApiErrors, useLocalStorage } from '@common/utils'
import { days, hours } from '@common/utils/durations'
import processEventData, {
  CATEGORY_MODEL_SCORES,
} from '@common/utils/grafanaViewDataProcessor'
import ScenarioOverlapConfirmation from '@portal/pages/Devices/ScenarioOverlapConfirmation'
import propertyUrls from '@portal/pages/Properties/urls'
import ScenarioForm from '@portal/pages/Scenarios/ScenarioForm'
import { isDemoModeActive } from '@portal/pages/Units/unitUtils'
import unitUrls from '@portal/pages/Units/urls'
import ConfirmationDialog from '@portal/UI/components/ConfirmationDialog'

import ChartCard from './ChartCard'
import {
  ALARM_OPENED,
  ALARM_REJECTED,
  automotiveCategories,
  chartCategories,
  GRID_DRAGGING_MODE,
  GRID_IDLE_MODE,
  GRID_RESIZING_MODE,
  NOISE,
  RESERVATIONS,
  SCENARIOS,
} from './constants'
import {
  colorsPool,
  getEventTypesMeta,
  GRAFANA_DARK_BLUE,
  GRAFANA_DARK_GREEN,
  GRAFANA_GREEN,
  GRAFANA_RED,
} from './eventTypesMeta'
import HeaderItem from './HeaderItem'
import ResizeHandle from './ResizeHandle'
import SmokeModelsSelector from './SmokeModelsSelector'
import { humanizeCategory } from './utils'

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
))

const areaSwitchers = [ALARM_OPENED, ALARM_REJECTED, NOISE, RESERVATIONS, SCENARIOS]
const areaTooltipDateFormat = 'yyyy-MM-dd HH:mm:ss'
const refreshTimeInMillis = 30000
const gridColumns = 20
const gridItemHeight = 7
const gridMinH = 4
const gridMinW = 3

const generateDefaultCardsVisibility = ({ layout }) =>
  (layout === AUTOMOTIVE_LAYOUT_KEY ? automotiveCategories : chartCategories).reduce(
    (acc, category) => ({ ...acc, [category]: true }),
    {},
  )

const generateDefaultLayoutSettings = ({ extraCategories, layout }) => {
  let y = 0
  return [
    ...(layout === AUTOMOTIVE_LAYOUT_KEY ? automotiveCategories : chartCategories),
    ...(extraCategories?.filter((i) => i) ?? []),
  ].map((category, i) => {
    if (i % 2 === 0 && i !== 0) y += gridItemHeight
    return {
      i: category,
      x: i % 2 === 0 ? 0 : gridColumns / 2,
      y,
      w: gridColumns / 2,
      h: gridItemHeight,
      minH: gridMinH,
      minW: gridMinW,
      isBounded: true,
    }
  }, [])
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} [props.id]
 * @param {'event'|'device'} props.entity
 * @param {string} [props.unit]
 * @param {Object[]} [props.initialTimeRange]
 * @param {function} [props.onClose]
 */
export default function EventViewModal({
  id = null,
  entity,
  unit = undefined,
  initialTimeRange = undefined,
  onClose = () => {},
}) {
  const availableDateOptions = [
    { title: '1H', value: 1 * hours },
    { title: '3H', value: 3 * hours },
    { title: '6H', value: 6 * hours },
    { title: '12H', value: 12 * hours },
    { title: '1D', value: 1 * days },
    { title: '2D', value: 2 * days },
    { title: '3D', value: 3 * days },
    { title: '7D', value: 7 * days },
    { title: '30D', value: 30 * days },
    { title: 'Custom', value: 'custom' },
  ]

  const {
    isAtLeastDataScience,
    eventModalData,
    eventModalChartData,
    eventModalIsLoading,
    eventModalDataError,
    eventModalChartDataError,
    systemDataTypes,
    systemDeviceModels,
    systemSmokeProfiles,
    systemSmokeModelKeys,
    systemTimeZones,
    doFetchEventModalData,
    doFetchEventModalChartData,
    doFetchEventModalUnitDevices,
    doResetEventModalState,
    doScenarioCreateFromReadings,
    doShowSnackbar,
  } = useConnect(
    'selectIsAtLeastDataScience',
    'selectEventModalData',
    'selectEventModalChartData',
    'selectEventModalIsLoading',
    'selectEventModalDataError',
    'selectEventModalChartDataError',
    'selectSystemDataTypes',
    'selectSystemDeviceModels',
    'selectSystemSmokeProfiles',
    'selectSystemSmokeModelKeys',
    'selectSystemTimeZones',
    'doFetchEventModalData',
    'doFetchEventModalChartData',
    'doFetchEventModalUnitDevices',
    'doResetEventModalState',
    'doScenarioCreateFromReadings',
    'doShowSnackbar',
  )

  const defaultDateTimeData = { custom: null, option: 'custom', range: null }

  const theme = useTheme()

  const refreshInterval = useRef(null)

  const isDeviceAutomotive = useMemo(() => {
    const modelData = systemDeviceModels?.find(
      (model) => model.id === eventModalData?.device?.model,
    )
    return modelData?.label === 'ag0i'
  }, [eventModalData?.device])

  const currentLayoutKey = isDeviceAutomotive
    ? AUTOMOTIVE_LAYOUT_KEY
    : DEFAULT_LAYOUT_KEY

  const [refreshing, setRefreshing] = useState(false)
  const [modalCoreData, setModalCoreData] = useState(null)
  const [selectedData, setSelectedData] = useState({ id, entity })

  const [chartData, setChartData] = useState(null)
  const [currentData, setCurrentData] = useState(null)
  const [visibleSmokeModels, setVisibleSmokeModels] = useLocalStorage(
    'portalGrafanaSmokeModels',
    {},
  )
  const [selectedTimezone, setSelectedTimezone] = useState(null)
  const [selectedDateTimeData, setSelectedDateTimeData] = useState(defaultDateTimeData)
  const [scenarioFormData, setScenarioFormData] = useState(null)

  const [selectorId, setSelectorId] = useState(null)
  const [selectorStart, setSelectorStart] = useState(null)
  const [selectorEnd, setSelectorEnd] = useState(null)
  const [selectedArea, setSelectedArea] = useState(null)
  const selectedAreaHistory = useRef([])

  const [cursor, setCursor] = useState(null)

  const [autoRefresh, setAutoRefresh] = useLocalStorage('grafanaAutoRefresh', true)
  const [timeframePopoverAnchor, setTimeframePopoverAnchor] = useState(null)
  const [settingsPopoverAnchor, setSettingsPopoverAnchor] = useState(null)
  const [openDateSelectionModal, setOpenDateSelectionModal] = useState(false)
  const [scenarioOverlapFormOpen, setScenarioOverlapFormOpen] = useState(false)
  const [openResetLayoutConfirmation, setOpenResetLayoutConfirmation] = useState(false)
  const [typesPopoverData, setTypesPopoverData] = useState(null)
  const [typeVisibilitySettings, setTypeVisibilitySettings] = useLocalStorage(
    'eventsTypeVisibility',
    {},
  )
  const [visibleAreas, setVisibleAreas] = useLocalStorage(
    'eventsVisibleAreas',
    areaSwitchers.reduce((acc, key) => ({ ...acc, [key]: true }), {}),
  )
  const [cardsVisibility, setCardsVisibility] = useLocalStorage(
    'portalGrafanaCardsVisibility',
    {
      [DEFAULT_LAYOUT_KEY]: generateDefaultCardsVisibility({
        layout: DEFAULT_LAYOUT_KEY,
      }),
      [AUTOMOTIVE_LAYOUT_KEY]: generateDefaultCardsVisibility({
        layout: AUTOMOTIVE_LAYOUT_KEY,
      }),
    },
  )
  const [gridLayout, setGridLayout] = useLocalStorage('portalGrafanaLayout', {
    [DEFAULT_LAYOUT_KEY]: generateDefaultLayoutSettings({
      extraCategories: Object.keys(visibleSmokeModels),
      layout: DEFAULT_LAYOUT_KEY,
    }),
    [AUTOMOTIVE_LAYOUT_KEY]: generateDefaultLayoutSettings({
      extraCategories: Object.keys(visibleSmokeModels),
      layout: AUTOMOTIVE_LAYOUT_KEY,
    }),
  })
  const [gridLayoutMode, setGridLayoutMode] = useState(GRID_IDLE_MODE)

  useEffect(() => {
    const activeModels = systemSmokeModelKeys
      .filter((model) => model.active)
      .map((model) => model.key)

    setVisibleSmokeModels((prev) => {
      const updated = {}
      // cleanup selected models from items that are not used anymore
      Object.keys(prev).forEach((model) => {
        if (activeModels.includes(model)) {
          updated[model] = prev[model]
        }
      })
      activeModels.forEach((model) => {
        if (!(model in prev)) {
          updated[model] = false
        }
      })
      return updated
    })

    setGridLayout((prev) => {
      const updated = { ...prev }
      const availableCategories = [
        ...(isDeviceAutomotive ? automotiveCategories : chartCategories),
        ...activeModels,
      ]

      const processLayout = ({ currentLayout, categories }) => {
        const result = []
        // cleanup layout config from items that are not used anymore
        currentLayout.forEach((item) => {
          if (categories.includes(item.i)) {
            result.push(item)
          }
        })

        const categoriesToAdd = []
        const currentCategories = result.map((item) => item.i)
        categories.forEach((category) => {
          if (!currentCategories.includes(category)) {
            categoriesToAdd.push(category)
          }
        })

        const sortedResult = result.sort((a, b) => a.y - b.y)
        let y = 0
        categoriesToAdd.forEach((category) => {
          const lastItem = sortedResult[sortedResult.length - 1]
          if (lastItem) {
            y =
              (sortedResult.length - 1) % 3 === 0
                ? lastItem.y + gridItemHeight
                : lastItem.y
          }
          sortedResult.push({
            i: category,
            x: sortedResult.length % 2 === 0 ? 0 : gridColumns / 2,
            y,
            w: gridColumns / 2,
            h: gridItemHeight,
            minH: gridMinH,
            minW: gridMinW,
            isBounded: true,
          })
        })
        return sortedResult
      }

      const processedLayout = processLayout({
        currentLayout: prev[currentLayoutKey] ?? [],
        categories: availableCategories,
      })

      return { ...updated, [currentLayoutKey]: processedLayout }
    })
  }, [systemSmokeModelKeys, isDeviceAutomotive])

  const unitDemoModeData = useMemo(() => {
    if (modalCoreData?.unit) {
      const demoModeActive = isDemoModeActive(modalCoreData.unit)
      if (demoModeActive) {
        const { demoModeStart, demoModeEnd } = modalCoreData.unit
        const range = [
          DateTime.fromISO(demoModeStart, { zone: selectedTimezone }),
          DateTime.fromISO(demoModeEnd, { zone: selectedTimezone }),
        ]
        const format = 'LLL dd, yyyy hh:mma'
        const formattedStart = range[0].toFormat(format)
        const formattedEnd = range[1].toFormat(format)
        return {
          range,
          tooltip: [
            'Unit is in demo mode',
            `Start: ${formattedStart}`,
            `End: ${formattedEnd}`,
          ].join(`\n`),
        }
      }
      return null
    }
    return null
  }, [modalCoreData, selectedTimezone])

  const fetchData = useCallback(async (payload) => {
    try {
      await doFetchEventModalData(payload)
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [])

  const fetchChartData = useCallback(async (payload) => {
    try {
      await doFetchEventModalChartData(payload)
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [])

  const fetchUnitBasedData = useCallback(async () => {
    if (unit) {
      try {
        const unitDevices = await doFetchEventModalUnitDevices(unit)
        const firstDeviceId = unitDevices[0].id
        setSelectedData({ id: firstDeviceId, entity: 'device', unitDevices })
      } catch (err) {
        const parsedError = parseApiErrors(err?.response)
        doShowSnackbar(parsedError, 'error')
      }
    }
  }, [unit])

  useEffect(() => {
    if (autoRefresh && !eventModalIsLoading) {
      refreshInterval.current = setInterval(() => {
        const now = DateTime.now().setZone(selectedTimezone)
        const endTime = selectedDateTimeData?.range?.[1]

        if (endTime && now) {
          const diff = Interval.fromDateTimes(endTime, now)
          const diffMinutes = diff.length('minutes')

          if (diffMinutes <= 5) {
            setSelectedDateTimeData((prev) => ({
              ...prev,
              range: prev.range.map((date) =>
                date.plus({ seconds: refreshTimeInMillis / 1000 }),
              ),
            }))
          }
        }
      }, refreshTimeInMillis)
    }

    return () => clearInterval(refreshInterval.current)
  }, [eventModalIsLoading, autoRefresh])

  const handleZoomOut = () => {
    selectedAreaHistory.current.splice(-1)
    const latestHistoryArea = selectedAreaHistory.current.at(-1)
    setSelectedArea(
      latestHistoryArea ? { ...latestHistoryArea, updateBrush: true } : null,
    )
  }

  const handleZoomReset = () => {
    selectedAreaHistory.current.length = 0
    setSelectedArea(null)
  }

  const cleanUp = useCallback(() => {
    doResetEventModalState()
    handleZoomReset()
    setChartData(null)
    setSelectedDateTimeData(defaultDateTimeData)
    setSelectedData({})
    clearInterval(refreshInterval.current)
  }, [])

  useEffect(() => {
    setSelectedData({ id, entity })
  }, [id])

  useEffect(() => {
    fetchUnitBasedData()
  }, [unit])

  useEffect(() => {
    if (!id && !unit) {
      cleanUp()
    }
  }, [id, unit])

  useEffect(() => {
    if (selectedData.id) {
      fetchData({
        id: selectedData.id,
        entity: selectedData.entity,
      })
    }
  }, [selectedData.id])

  useEffect(() => {
    // make sure that newly added categories will be enabled by default
    setCardsVisibility((prev) => {
      const updateVisibility = ({ layout }) => {
        const result = { ...prev[layout] }
        const initialKeys = Object.keys(result)
        const availableKeys = Object.keys(generateDefaultCardsVisibility({ layout }))
        initialKeys.forEach((key) => {
          if (!availableKeys.includes(key)) delete result[key]
        })
        availableKeys.forEach((key) => {
          if (result[key] === undefined) result[key] = true
        })
        return result
      }

      return {
        ...prev,
        [currentLayoutKey]: updateVisibility({ layout: currentLayoutKey }),
      }
    })

    // make sure that newly added areas will be enabled by default
    setVisibleAreas((prev) => {
      const updated = { ...prev }
      areaSwitchers.forEach((key) => {
        if (updated[key] === undefined) {
          updated[key] = true
        }
      })
      return updated
    })

    // make all smoke profiles always selected by default
    setTypeVisibilitySettings((prev) => {
      const copy = { ...prev }
      systemSmokeProfiles.forEach((profile) => {
        Object.assign(copy, { [profile.id]: true })
      })
      return copy
    })
  }, [isDeviceAutomotive])

  useEffect(() => {
    if (eventModalData) {
      setModalCoreData(eventModalData)
    }
  }, [eventModalData])

  useEffect(() => {
    if (refreshing) {
      if (selectedDateTimeData.option !== 'custom') {
        setSelectedDateTimeData((prev) => {
          const now = DateTime.now().setZone(selectedTimezone)
          const range = [now.minus({ milliseconds: prev.option }), now]
          return { ...prev, range }
        })
      } else {
        fetchChartData({
          event: modalCoreData.event,
          device: modalCoreData.device,
          selectedArea,
          selectedTimezone,
          selectedDateTimeData,
          selectedSmokeModels: visibleSmokeModels,
        })
      }

      setRefreshing(false)
      return
    }

    const eventData = modalCoreData?.event
    const deviceData = modalCoreData?.device

    const timeNow = DateTime.now()
    const timezone = eventData?.timeZone ?? deviceData?.timezone

    let start = timeNow.minus({ days: 1 })
    let end = timeNow
    if (eventData) {
      start = DateTime.fromISO(eventData.createdOn)
      end = eventData.endTime ? DateTime.fromISO(eventData.endTime) : timeNow
    } else if (deviceData && initialTimeRange) {
      ;[start, end] = initialTimeRange
    }

    setSelectedTimezone(timezone)
    setSelectedDateTimeData(
      eventData || deviceData
        ? { custom: !eventData, option: 'custom', range: [start, end] }
        : defaultDateTimeData,
    )
  }, [modalCoreData])

  useEffect(() => {
    if (modalCoreData && selectedDateTimeData.range) {
      fetchChartData({
        event: modalCoreData.event,
        device: modalCoreData.device,
        selectedArea,
        selectedTimezone,
        selectedDateTimeData,
        selectedSmokeModels: visibleSmokeModels,
      })
    }
  }, [modalCoreData, selectedDateTimeData, selectedArea])

  useEffect(() => {
    setSelectedDateTimeData((prev) => {
      if (prev.range) {
        return {
          ...prev,
          range: prev.range.map((date) => date.setZone(selectedTimezone)),
        }
      }
      return prev
    })
  }, [selectedTimezone])

  useEffect(() => {
    if (eventModalChartData) {
      if (!selectedArea) {
        setChartData(eventModalChartData)
      }
      setCurrentData(eventModalChartData)
    }
  }, [eventModalChartData])

  const eventTypesMeta = getEventTypesMeta(
    systemDataTypes,
    systemSmokeProfiles,
    chartData?.data
      ?.filter((item) => item.modelChart)
      ?.map((item) => ({ type: item.key, category: item.category })),
    { layout: currentLayoutKey },
  )

  const deviceDataByCategory = useMemo(() => {
    const processData = (name, value) => {
      if (value.category === CATEGORY_MODEL_SCORES) {
        return {
          name,
          ...value,
          visible:
            typeVisibilitySettings[value.key] ??
            systemSmokeProfiles.map((profile) => profile.id).includes(name),
        }
      }
      return {
        ...value,
        name,
        visible: typeVisibilitySettings[value.key] ?? value.visible,
      }
    }

    return Object.entries(eventTypesMeta).reduce(
      (acc, [name, value]) => ({
        ...acc,
        [value.category]:
          value.category in acc
            ? [...acc[value.category], processData(name, value)]
            : [processData(name, value)],
      }),
      {},
    )
  }, [eventTypesMeta, systemSmokeProfiles])

  const handleDateTimeChange = (dateRange, option) => {
    const zonedDateRange = dateRange.map((date) =>
      date.setZone(selectedTimezone, { keepLocalTime: true }),
    )
    setSelectedDateTimeData({ custom: true, option, range: zonedDateRange })
    setOpenDateSelectionModal(false)
    setTimeframePopoverAnchor(null)
    handleZoomReset()
  }

  const handleTimeframeChange = (_, value) => {
    if (value === 'custom') {
      setOpenDateSelectionModal(true)
      return
    }

    const now = DateTime.now().setZone(selectedTimezone)
    const range = [now.minus({ milliseconds: value }), now]
    handleDateTimeChange(range, value)
  }

  const processedData = useMemo(() => {
    const activeModels = systemSmokeModelKeys
      .filter((model) => model.active)
      .map((model) => model.key)
    return [
      ...(isDeviceAutomotive ? automotiveCategories : chartCategories),
      ...activeModels,
    ].reduce(
      (acc, category) => ({
        ...acc,
        [category]: {
          current: processEventData(chartData?.data, category, selectedTimezone),
          zoomed: processEventData(currentData?.data, category, selectedTimezone),
          error: chartData?.data?.find((i) => category === i.category && i.error)
            ?.error,
        },
      }),
      {},
    )
  }, [currentData, isDeviceAutomotive])

  const minimapData = useMemo(() => {
    const data = Object.values(processedData).find(
      (dt) => dt.current?.length > 0 && dt.current[0].time,
    )
    if (data?.current?.length > 0) {
      return data.current
    }
    return null
  }, [processedData])

  const formatEventDateRange = () => {
    const format = 'LLL dd, yyyy hh:mma'
    const formattedStart = DateTime.fromISO(modalCoreData.event.createdOn, {
      zone: selectedTimezone,
    }).toFormat(format)
    const formattedEnd = modalCoreData.event.endTime
      ? DateTime.fromISO(modalCoreData.event.endTime, {
          zone: selectedTimezone,
        }).toFormat(format)
      : 'Ongoing'
    return `${formattedStart} - ${formattedEnd}`
  }

  const formatDateTooltip = () => {
    if (selectedDateTimeData.range) {
      const format = 'LLL dd, yyyy hh:mma'

      const [start, end] = selectedDateTimeData.range
      return `${start.toFormat(format)} - ${end.toFormat(format)}`
    }
    return ''
  }

  const getAreaStyle = useCallback(
    ({ color }) => ({
      dashedBackground: false,
      filledBackground: true,
      area: { color, opacity: 0.1 },
      handle: { color, dashArray: [4, 4], opacity: 0.6 },
    }),
    [],
  )

  const alertsAreas = useMemo(
    () =>
      currentData?.alarms
        .filter((alarm) => {
          const isRejected = alarm.rejections
          const showOpened = visibleAreas[ALARM_OPENED]
            ? !alarm.rejections
            : alarm.rejections
          const showRejected = visibleAreas[ALARM_REJECTED]
            ? alarm.rejections
            : !alarm.rejections
          const dataTypeVisible = !alarm.tag || typeVisibilitySettings[alarm.tag]

          return (
            dataTypeVisible &&
            ((isRejected && showRejected) || (!isRejected && showOpened))
          )
        })
        .map((alarm) => {
          const formattedLabel = `${alarm.rejections ? 'REJECTED' : ''} Alarm ${
            alarm.id
          }`
          const formattedStart = DateTime.fromISO(alarm.start)
            .setZone(selectedTimezone)
            .toFormat(areaTooltipDateFormat)
          const formattedEnd =
            alarm.end && !alarm.ongoing
              ? DateTime.fromISO(alarm.end)
                  .setZone(selectedTimezone)
                  .toFormat(areaTooltipDateFormat)
              : 'Ongoing'

          const profile = systemSmokeProfiles.find((item) => item.id === alarm.tag)

          return {
            id: alarm.id,
            start: alarm.start,
            end: alarm.end,
            payload: {
              label: formattedLabel,
              start: formattedStart,
              end: formattedEnd,
              modelTag: profile
                ? `${profile.modelKey.replace(/moneymaker/gi, 'mm')} - ${profile.name}`
                : null,
              rejections: alarm.rejections,
              metadata: JSON.stringify(alarm.metadata, null, 2),
            },
            style: getAreaStyle({
              color: alarm.rejections ? GRAFANA_RED : GRAFANA_GREEN,
            }),
            priority: alarm.rejections ? 20 : 30,
          }
        }),
    [
      currentData,
      visibleAreas[ALARM_OPENED],
      visibleAreas[ALARM_REJECTED],
      typeVisibilitySettings,
    ],
  )

  const noiseAreas = useMemo(
    () =>
      visibleAreas[NOISE]
        ? currentData?.noiseEvents.map((event) => {
            const formattedStart = event.createdOn
              .setZone(selectedTimezone)
              .toFormat(areaTooltipDateFormat)
            const formattedEnd =
              event.endTime && !event.ongoing
                ? event.endTime
                    .setZone(selectedTimezone)
                    .toFormat(areaTooltipDateFormat)
                : 'Ongoing'

            return {
              id: event.id,
              start: event.createdOn,
              end: event.endTime,
              payload: {
                label: 'Noise Event',
                start: formattedStart,
                end: formattedEnd,
              },
              style: getAreaStyle({ color: GRAFANA_DARK_GREEN }),
              priority: 20,
            }
          })
        : [],
    [currentData, visibleAreas[NOISE]],
  )

  const reservationsAreas = useMemo(
    () =>
      visibleAreas[RESERVATIONS]
        ? currentData?.reservations.map((reservation) => ({
            id: reservation.id,
            start: reservation.checkIn,
            end: reservation.checkOut,
            payload: {
              label: 'Reservation',
              checkIn: DateTime.fromISO(reservation.checkIn)
                .setZone(selectedTimezone)
                .toFormat(areaTooltipDateFormat),
              checkOut: DateTime.fromISO(reservation.checkOut)
                .setZone(selectedTimezone)
                .toFormat(areaTooltipDateFormat),
            },
            style: getAreaStyle({ color: GRAFANA_DARK_BLUE }),
            priority: 0,
          }))
        : [],
    [currentData, visibleAreas[RESERVATIONS]],
  )

  const scenariosAreas = useMemo(
    () =>
      visibleAreas[SCENARIOS]
        ? currentData?.scenarios.map((scenario) => ({
            id: scenario.id,
            start: DateTime.fromISO(scenario.readingStart),
            end: DateTime.fromISO(scenario.readingEnd),
            payload: {
              label: `Scenario - ${scenario.name}`,
              createdOn: DateTime.fromISO(scenario.createdOn)
                .setZone(selectedTimezone)
                .toFormat(areaTooltipDateFormat),
              start: DateTime.fromISO(scenario.readingStart)
                .setZone(selectedTimezone)
                .toFormat(areaTooltipDateFormat),
              end: DateTime.fromISO(scenario.readingEnd)
                .setZone(selectedTimezone)
                .toFormat(areaTooltipDateFormat),
              ...scenario.labels.reduce(
                (acc, label, index) => ({
                  ...acc,
                  [index !== 0 ? `label_${index}_start` : 'labelStart']:
                    DateTime.fromISO(label.start)
                      .setZone(selectedTimezone)
                      .toFormat(areaTooltipDateFormat),
                  [index !== 0 ? `label_${index}_end` : 'labelEnd']: DateTime.fromISO(
                    label.end,
                  )
                    .setZone(selectedTimezone)
                    .toFormat(areaTooltipDateFormat),
                }),
                {},
              ),
            },
            style: getAreaStyle({ color: '#5CBFB0' }),
            priority: 10,
          }))
        : [],
    [currentData, visibleAreas[SCENARIOS]],
  )

  const buildGraph = (category) => {
    const deviceData = deviceDataByCategory[category]

    const categoryError = processedData[category]?.error
    const categoryHasData = !!processedData[category]?.zoomed?.find(
      (d) => d.time !== null && d.time !== undefined,
    )

    // get rid of unused types and update colors only for available models
    const processedDataTypes =
      categoryHasData && category === CATEGORY_MODEL_SCORES
        ? deviceData
            ?.filter((type) => currentData.data.find((data) => data.tag === type.key))
            ?.map((type, i) => ({
              ...type,
              color: colorsPool[i % colorsPool.length],
            }))
        : deviceData

    return (
      <ChartCard
        category={category}
        gridMode={gridLayoutMode}
        loading={eventModalIsLoading}
        theme={theme}
        categoryHasData={categoryHasData}
        processedData={processedData}
        currentData={currentData}
        highlightedAreas={[
          ...alertsAreas,
          ...reservationsAreas,
          ...scenariosAreas,
          ...noiseAreas,
        ]}
        error={
          categoryError ? categoryError?.message || 'Oops. Something went wrong' : null
        }
        dataTypes={processedDataTypes ?? []}
        cursorState={[cursor, setCursor]}
        zoomState={{
          selectorIdState: [selectorId, setSelectorId],
          selectorStartState: [selectorStart, setSelectorStart],
          selectorEndState: [selectorEnd, setSelectorEnd],
          selectedAreaState: [selectedArea, setSelectedArea],
          historyRef: selectedAreaHistory,
        }}
        chartSettings={{
          showNotAvailableInTooltip: true,
          showCursor: !(category === CATEGORY_MODEL_SCORES && unitDemoModeData),
          tooltip: {
            notAvailableText: category !== CATEGORY_MODEL_SCORES ? 'N/A' : 'null',
          },
        }}
        onSettingsClick={setTypesPopoverData}
      />
    )
  }

  const deviceOption = useCallback(
    (option) => (
      <Box>
        <Typography variant="h6">{option.mainMac}</Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          textTransform="uppercase"
          fontSize="0.7rem"
        >
          {option.propertyName} - {option.unitName}
        </Typography>
      </Box>
    ),
    [],
  )

  const propertyLink = useMemo(() => {
    const propertyId =
      modalCoreData?.[selectedData.entity]?.property ??
      modalCoreData?.[selectedData.entity]?.propertyId
    return propertyId ? propertyUrls.entity.replace(':id', propertyId) : null
  }, [modalCoreData, selectedData])

  const unitLink = useMemo(() => {
    const unitId =
      modalCoreData?.[selectedData.entity]?.unit ??
      modalCoreData?.[selectedData.entity]?.unitId
    return unitId ? unitUrls.entity.replace(':id', unitId) : null
  }, [modalCoreData, selectedData])

  const visibleCategories = useMemo(
    () =>
      Object.entries({ ...cardsVisibility[currentLayoutKey], ...visibleSmokeModels })
        .filter((data) => data[1])
        .reduce((acc, [category]) => [...acc, category], []),
    [cardsVisibility, visibleSmokeModels, isDeviceAutomotive],
  )

  const handleResetLayout = () => {
    setCardsVisibility((prev) => ({
      ...prev,
      [currentLayoutKey]: generateDefaultCardsVisibility({ layout: currentLayoutKey }),
    }))
    setGridLayout((prev) => ({
      ...prev,
      [currentLayoutKey]: generateDefaultLayoutSettings({
        extraCategories: Object.keys(visibleSmokeModels),
        layout: currentLayoutKey,
      }),
    }))
    setVisibleSmokeModels(
      systemSmokeModelKeys
        .filter((model) => model.active)
        .reduce((acc, model) => ({ ...acc, [model.key]: false }), {}),
      {},
    )
  }

  const buildInitialScenarioFormData = () => ({
    name: formatScenarioName(
      modalCoreData[selectedData.entity]?.propertyName,
      modalCoreData[selectedData.entity]?.unitName,
      modalCoreData[selectedData.entity]?.createdOn ??
        selectedArea?.start ??
        selectedDateTimeData.range[0],
    ),
    sourceDevice: modalCoreData.device.id,
    deviceModel: modalCoreData.device.model,
    readingStart: selectedArea?.start ?? selectedDateTimeData.range[0],
    readingEnd: selectedArea?.end ?? selectedDateTimeData.range[1],
  })

  return (
    <>
      <DateSelectionModal
        open={openDateSelectionModal}
        onCancel={() => setOpenDateSelectionModal(false)}
        onSave={(range) => handleDateTimeChange(range, 'custom')}
      />
      <ScenarioOverlapConfirmation
        open={scenarioOverlapFormOpen}
        scenarios={currentData?.scenarios ?? []}
        timeRange={
          selectedArea
            ? [selectedArea.start, selectedArea.end]
            : selectedDateTimeData.range
        }
        onClose={() => setScenarioOverlapFormOpen(false)}
        onOverwrite={() => {
          fetchChartData({
            event: modalCoreData.event,
            device: modalCoreData.device,
            selectedArea,
            selectedTimezone,
            selectedDateTimeData,
            selectedSmokeModels: visibleSmokeModels,
          })
          setScenarioOverlapFormOpen(false)
        }}
        onContinue={() => {
          setScenarioFormData(buildInitialScenarioFormData())
          setScenarioOverlapFormOpen(false)
        }}
      />
      <ScenarioForm
        open={!!scenarioFormData}
        onClose={(result) => {
          setScenarioFormData(null)
          if (result === true) {
            fetchChartData({
              event: modalCoreData.event,
              device: modalCoreData.device,
              selectedArea,
              selectedTimezone,
              selectedDateTimeData,
              selectedSmokeModels: visibleSmokeModels,
            })
          }
        }}
        onSave={doScenarioCreateFromReadings}
        instance={scenarioFormData}
      />
      <ConfirmationDialog
        fullWidth={false}
        title="Reset Layout"
        message="Cards visibility, position and size will be set to default. Are you sure you want to proceed?"
        open={openResetLayoutConfirmation}
        onConfirm={() => {
          setOpenResetLayoutConfirmation(false)
          setSettingsPopoverAnchor(false)
          handleResetLayout()
        }}
        onCancel={() => setOpenResetLayoutConfirmation(false)}
      />
      <Popover
        open={!!timeframePopoverAnchor}
        onClose={() => setTimeframePopoverAnchor(null)}
        anchorEl={timeframePopoverAnchor}
        anchorOrigin={{ vertical: 50, horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Stack direction="row" px={2} py={2}>
          <ToggleButtonGroup
            exclusive
            onChange={handleTimeframeChange}
            size="small"
            variant="outlined"
          >
            {availableDateOptions.map((option) => {
              const selected = selectedDateTimeData.option === option.value
              const tooltipText = selected ? formatDateTooltip() : null
              return (
                <Tooltip key={option.value} title={tooltipText}>
                  <ToggleButton value={option.value} selected={selected}>
                    {option.title}
                  </ToggleButton>
                </Tooltip>
              )
            })}
          </ToggleButtonGroup>
        </Stack>
      </Popover>
      <Popover
        open={!!settingsPopoverAnchor}
        onClose={() => setSettingsPopoverAnchor(null)}
        anchorEl={settingsPopoverAnchor}
        anchorOrigin={{ vertical: 50, horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Stack>
          <Typography variant="h6" px={2} pt={1} sx={{ fontWeight: 'bold' }}>
            Filters
          </Typography>
          <Stack direction="row" gap={1} pl={2}>
            {areaSwitchers.map((key) => (
              <FormControlLabel
                key={key}
                label={titleize(key)}
                labelPlacement="end"
                slotProps={{ typography: { fontSize: 12 } }}
                disabled={eventModalIsLoading}
                control={
                  <Checkbox
                    checked={visibleAreas[key]}
                    size="small"
                    onChange={() =>
                      setVisibleAreas((areas) => ({
                        ...areas,
                        [key]: !areas[key],
                      }))
                    }
                  />
                }
              />
            ))}
          </Stack>
          <Typography variant="h6" px={2} sx={{ fontWeight: 'bold' }}>
            Settings
          </Typography>
          <FormControlLabel
            label="Auto-refresh"
            labelPlacement="end"
            slotProps={{ typography: { fontSize: 12 } }}
            disabled={eventModalIsLoading}
            control={
              <Checkbox
                checked={autoRefresh}
                size="small"
                onChange={() => setAutoRefresh((value) => !value)}
              />
            }
            sx={{ pl: 2, pb: 0.5 }}
          />
          <SmokeModelsSelector
            loading={eventModalIsLoading}
            visibleSmokeModels={visibleSmokeModels}
            onSave={(models) => {
              setVisibleSmokeModels(models)
              fetchChartData({
                event: modalCoreData.event,
                device: modalCoreData.device,
                selectedArea,
                selectedTimezone,
                selectedDateTimeData,
                selectedSmokeModels: models,
              })
            }}
          />
          <Typography variant="h6" px={2} sx={{ fontWeight: 'bold' }}>
            Visibility
          </Typography>
          <Stack direction="row" gap={1} pl={2}>
            {Object.entries(cardsVisibility[currentLayoutKey]).map(
              ([category, visible]) => (
                <FormControlLabel
                  key={category}
                  label={humanizeCategory(category)}
                  labelPlacement="end"
                  slotProps={{ typography: { fontSize: 12 } }}
                  disabled={eventModalIsLoading}
                  control={
                    <Checkbox
                      checked={visible}
                      size="small"
                      onChange={() =>
                        setCardsVisibility((prev) => ({
                          ...prev,
                          [currentLayoutKey]: {
                            ...prev[currentLayoutKey],
                            [category]: !prev[currentLayoutKey][category],
                          },
                        }))
                      }
                    />
                  }
                />
              ),
            )}
          </Stack>
          <Divider sx={{ mt: 1 }} />
          <Box display="flex" m={1} justifyContent="end">
            <Button
              color="error"
              disabled={eventModalIsLoading}
              onClick={() => {
                setOpenResetLayoutConfirmation(true)
              }}
            >
              Reset Layout
            </Button>
          </Box>
        </Stack>
      </Popover>
      <Popover
        open={!!typesPopoverData}
        onClose={() => setTypesPopoverData(null)}
        anchorEl={typesPopoverData?.anchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {typesPopoverData?.category &&
          deviceDataByCategory[typesPopoverData.category]?.map((dataType) => {
            if (typesPopoverData.category === CATEGORY_MODEL_SCORES) {
              const profileData = systemSmokeProfiles.find(
                (profile) => profile.id === dataType.key,
              )
              if (!profileData) {
                return null
              }
              if (!currentData.data.find((data) => data.tag === profileData.id)) {
                return null
              }
            }

            return (
              <Stack
                key={`${typesPopoverData.category}_${dataType.key}`}
                direction="column"
                gap={2}
                px={1.5}
              >
                <FormControlLabel
                  key={`${typesPopoverData.category}_${dataType.key}`}
                  label={dataType.tooltipLabel ?? dataType.key}
                  labelPlacement="end"
                  slotProps={{ typography: { fontSize: 14 } }}
                  control={
                    <Checkbox
                      checked={typeVisibilitySettings[dataType.key] ?? dataType.visible}
                      size="small"
                      onChange={() =>
                        setTypeVisibilitySettings((settings) => ({
                          ...settings,
                          [dataType.key]:
                            settings[dataType.key] !== undefined
                              ? !settings[dataType.key]
                              : !dataType.visible,
                        }))
                      }
                    />
                  }
                />
              </Stack>
            )
          })}
      </Popover>
      <Dialog
        fullScreen
        open={!!id || !!unit}
        onClose={onClose}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundImage: 'unset',
            boxShadow: 'none',
          },
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
          <Box>
            <Box display="flex" alignItems="center" gap={2}>
              {selectedData.entity && (
                <Typography
                  variant="body2"
                  fontSize={10}
                  sx={{ color: theme.palette.muted }}
                >{`${selectedData.entity} id: ${selectedData.id}`}</Typography>
              )}
              {modalCoreData?.device && (
                <DeviceAwsStatusIcon
                  type="chip"
                  status={modalCoreData.device.awsStatus}
                />
              )}
            </Box>

            {modalCoreData && selectedData.id && (
              <Stack direction="row" gap={2} mt={2} alignItems="center">
                <DynamicSelect
                  getFullEntity
                  disableClearable
                  useValueInRequest={false}
                  label="Device"
                  endpoint="devices"
                  value={modalCoreData.device}
                  onChange={(device) => {
                    setSelectedData({ id: device.id, entity: 'device' })
                  }}
                  disabled={eventModalIsLoading}
                  primaryTextAttr="mainMac"
                  optionRender={deviceOption}
                  variant="outlined"
                  size="small"
                  sx={{ minWidth: 206 }}
                />
                <HeaderItem
                  label="Property"
                  value={modalCoreData[selectedData.entity]?.propertyName ?? 'N/A'}
                  linkTo={propertyLink}
                />
                <HeaderItem
                  label="Unit"
                  value={modalCoreData[selectedData.entity]?.unitName ?? 'N/A'}
                  linkTo={unitLink}
                  HeaderIcon={unitDemoModeData ? BuildCircleOutlined : null}
                  headerIconHint={unitDemoModeData?.tooltip}
                  color={unitDemoModeData ? 'warning.main' : null}
                />
                {modalCoreData?.event && (
                  <HeaderItem label="Event Range" value={formatEventDateRange()} />
                )}
                {chartData && (
                  <Tooltip title="Refresh">
                    <IconButton
                      disabled={eventModalIsLoading}
                      onClick={() => {
                        setRefreshing(true)
                        fetchData({
                          id: selectedData.id,
                          entity: selectedData.entity,
                        })
                      }}
                    >
                      <Refresh />
                    </IconButton>
                  </Tooltip>
                )}
                {chartData && (
                  <Tooltip title={formatDateTooltip()}>
                    <IconButton
                      disabled={eventModalIsLoading}
                      onClick={(event) =>
                        setTimeframePopoverAnchor(event.currentTarget)
                      }
                    >
                      <DateRangeRounded />
                    </IconButton>
                  </Tooltip>
                )}
                {chartData && (
                  <Button
                    variant="outlined"
                    startIcon={<SettingsRounded />}
                    disabled={eventModalIsLoading}
                    onClick={(event) => setSettingsPopoverAnchor(event.currentTarget)}
                  >
                    Settings
                  </Button>
                )}
                {chartData && (
                  <StaticSelect
                    disableClearable
                    label="Timezone"
                    options={['UTC', ...systemTimeZones]}
                    value={selectedTimezone}
                    disabled={eventModalIsLoading}
                    onChange={(zone) => {
                      setSelectedTimezone(zone)
                      fetchChartData({
                        event: modalCoreData.event,
                        device: modalCoreData.device,
                        selectedArea,
                        selectedTimezone: zone,
                        selectedDateTimeData,
                        selectedSmokeModels: visibleSmokeModels,
                      })
                    }}
                    size="small"
                    sx={{ minWidth: 300 }}
                  />
                )}
                {chartData && currentData && isAtLeastDataScience && (
                  <Button
                    disabled={!modalCoreData.device || eventModalIsLoading}
                    variant="outlined"
                    startIcon={<BookOutlined />}
                    onClick={() => {
                      if (currentData.scenarios.length > 0) {
                        setScenarioOverlapFormOpen(true)
                      } else {
                        setScenarioFormData(buildInitialScenarioFormData())
                      }
                    }}
                  >
                    Save Scenario
                  </Button>
                )}
              </Stack>
            )}
          </Box>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        {!chartData && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100vh"
          >
            {eventModalIsLoading && <CircularProgress />}
            {!eventModalIsLoading &&
              (eventModalDataError || eventModalChartDataError) && (
                <Stack alignItems="center">
                  <WarningAmberRounded sx={{ fill: 'grey', fontSize: 48 }} />
                  <Typography variant="h4" color="grey">
                    Oops. Something went wrong
                  </Typography>
                </Stack>
              )}
          </Box>
        )}
        <Box display="flex" sx={{ alignItems: 'center', justifyContent: 'center' }}>
          {eventModalIsLoading && chartData && (
            <CircularProgress sx={{ position: 'absolute' }} />
          )}
          <Box
            sx={{
              width: '100%',
              transitionProperty: 'opacity',
              transitionDuration: '300ms',
              opacity: eventModalIsLoading ? 0.35 : 1,
              pointerEvents: eventModalIsLoading ? 'none' : 'initial',
            }}
          >
            <Box m={2}>
              <Box
                display="flex"
                gap={1}
                sx={{
                  transitionProperty: 'height, margin',
                  transitionDuration: '300ms',
                  overflow: 'hidden',
                  height: selectedArea ? 36.5 : 0,
                  mb: selectedArea ? 2 : 0,
                }}
              >
                <Button
                  startIcon={<ZoomOut />}
                  variant="outlined"
                  onClick={handleZoomOut}
                >
                  Zoom Out
                </Button>
                <Button
                  startIcon={<RestartAlt />}
                  variant="outlined"
                  onClick={handleZoomReset}
                >
                  Reset Zoom
                </Button>
              </Box>
              {chartData && minimapData && (
                <Box sx={{ mb: 1 }}>
                  <MemoChartMinimap
                    id="chart_minimap"
                    data={minimapData}
                    selectedArea={selectedArea}
                    onChange={(area) => {
                      setSelectedArea(area)
                      selectedAreaHistory.current.push(area)
                      setSelectorStart(null)
                      setSelectorEnd(null)
                    }}
                    onMove={(area) => {
                      setSelectorStart(area.start)
                      setSelectorEnd(area.end)
                    }}
                  />
                </Box>
              )}
              {chartData && (
                <GridLayout
                  className="layout"
                  layout={gridLayout[currentLayoutKey].filter((data) =>
                    visibleCategories.includes(data.i),
                  )}
                  cols={gridColumns}
                  rowHeight={30}
                  margin={[20, 20]}
                  resizeHandles={['se', 'sw']}
                  resizeHandle={ResizeHandle}
                  draggableHandle=".dragHandle"
                  width={globalThis.document.body.clientWidth - 15 - 16 * 2}
                  onLayoutChange={(l) => {
                    setGridLayout((prev) => ({
                      ...prev,
                      [currentLayoutKey]: prev[currentLayoutKey].map(
                        (item) => l.find((newItem) => newItem.i === item.i) || item,
                      ),
                    }))
                  }}
                  onResizeStart={() => setGridLayoutMode(GRID_RESIZING_MODE)}
                  onResizeStop={() => setGridLayoutMode(GRID_IDLE_MODE)}
                  onDragStart={() => setGridLayoutMode(GRID_DRAGGING_MODE)}
                  onDragStop={() => setGridLayoutMode(GRID_IDLE_MODE)}
                >
                  {visibleCategories.map((category) => (
                    <div key={category}>{buildGraph(category)}</div>
                  ))}
                </GridLayout>
              )}
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  )
}
