import { ascend, groupBy, sortWith } from 'ramda'

import { DateTime } from 'luxon'

const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

function formatCurrency(value, defaultValue = 'N/A') {
  return Number.isNaN(Number(value)) ? defaultValue : usdFormatter.format(value)
}

function formatMetricValue({ value, symbol, position, showSymbol = true }) {
  if (value === '--' || value === undefined) return value
  const val =
    symbol === '$'
      ? formatCurrency(value).replace('$', '')
      : value.toFixed(2).replace(/[.,]0+$/, '')
  return showSymbol
    ? `${position === 'left' ? symbol : ''}${val}${position === 'right' ? symbol : ''}`
    : val
}

function extractValueAndSymbol(inputString) {
  const regex = /^([^\d]*)([\d,.]+)(.*)$/
  const match = inputString.match(regex)
  if (!match) {
    throw new Error('Invalid input string')
  }
  return {
    value: parseFloat(match[2].replace(/,/g, '')),
    symbol: match[1] || match[3],
    symbolPosition: match[1] ? 'left' : 'right',
  }
}

function processWeeksMetrics(rawData, metricsToProject) {
  const { data, metric } = rawData

  const processedData = []

  data.forEach((item, index) => {
    const shouldBeProjected = metricsToProject.map((m) => m.apiValue).includes(metric)

    const parsedValue = extractValueAndSymbol(item.value)
    const { symbol, symbolPosition } = parsedValue

    if (data.length - 1 !== index) {
      processedData.push({
        date: DateTime.fromISO(item.date).minus({ days: 6 }).toFormat('yyyy-MM-dd'),
        bucketEndDate: item.date,
        displayData: null,
        rawValue: item.rawValue,
        value: formatMetricValue({
          value: item.rawValue,
          symbol,
          position: symbolPosition,
        }),
      })
    }

    if (data.length - 1 === index) {
      if (shouldBeProjected) {
        const getDate = () => {
          const previousData = processedData.at(-1)
          if (previousData) {
            return DateTime.fromISO(previousData.bucketEndDate)
              .plus({ days: 1 })
              .toFormat('yyyy-MM-dd')
          }
          return data[index].date
        }

        const date = getDate()

        const currentWeekday = DateTime.now().weekday
        const projectedValue = (item.rawValue / currentWeekday) * 7
        const bucketEndDate = DateTime.fromISO(date)
          .plus({ days: 6 - currentWeekday })
          .toFormat('yyyy-MM-dd')

        processedData.push({
          date,
          bucketEndDate,
          displayData: {
            start: DateTime.fromISO(bucketEndDate)
              .minus({ days: 6 - currentWeekday })
              .toFormat('yyyy-MM-dd'),
            end: item.date,
            showSuffix: true,
            isProjected: false,
          },
          rawValue: item.rawValue,
          value: formatMetricValue({
            value: item.rawValue,
            symbol,
            position: symbolPosition,
          }),
        })

        processedData.push({
          date: DateTime.fromISO(item.date)
            .minus({ days: currentWeekday })
            .toFormat('yyyy-MM-dd'),
          bucketEndDate: item.date,
          displayData: {
            start: DateTime.fromISO(bucketEndDate)
              .minus({ days: 6 - currentWeekday })
              .toFormat('yyyy-MM-dd'),
            end: item.date,
            showSuffix: true,
            isProjected: true,
          },
          rawValue: projectedValue,
          value: formatMetricValue({
            value: projectedValue,
            symbol,
            position: symbolPosition,
          }),
        })
      } else {
        processedData.push({
          date: DateTime.fromISO(item.date).minus({ days: 6 }).toFormat('yyyy-MM-dd'),
          bucketEndDate: item.date,
          displayData: null,
          rawValue: item.rawValue,
          value: formatMetricValue({
            value: item.rawValue,
            symbol,
            position: symbolPosition,
          }),
        })
      }
    }
  })

  return processedData
}

function processMonthsMetrics(rawData, metricsToProject) {
  const { data, metric } = rawData

  const processedData = []

  data.forEach((item, index) => {
    const shouldBeProjected = metricsToProject.map((m) => m.apiValue).includes(metric)

    const parsedItemDate = DateTime.fromISO(item.date)
    const parsedValue = extractValueAndSymbol(item.value)
    const { symbol, symbolPosition } = parsedValue

    if (data.length - 1 !== index) {
      processedData.push({
        date: parsedItemDate.startOf('month').toFormat('yyyy-MM-dd'),
        bucketEndDate: item.date,
        displayData: null,
        rawValue: item.rawValue,
        value: formatMetricValue({
          value: item.rawValue,
          symbol,
          position: symbolPosition,
        }),
      })
    }

    if (data.length - 1 === index) {
      const currentDate = DateTime.now()
      const sameYear = parsedItemDate.year === currentDate.year
      const sameMonth = sameYear && parsedItemDate.month === currentDate.month

      if (
        shouldBeProjected &&
        sameMonth &&
        parsedItemDate.startOf('day') > currentDate.startOf('day')
      ) {
        const date = parsedItemDate.startOf('month')

        const { daysInMonth } = parsedItemDate
        const currentDay = currentDate.day
        const projectedValue = (item.value / currentDay) * daysInMonth

        const bucketEndDate = date.endOf('month').toFormat('yyyy-MM-dd')

        processedData.push({
          date: date.toFormat('yyyy-MM-dd'),
          bucketEndDate: date.plus({ days: currentDay }).toFormat('yyyy-MM-dd'),
          displayData: {
            start: DateTime.fromISO(bucketEndDate)
              .startOf('month')
              .toFormat('yyyy-MM-dd'),
            end: item.date,
            showSuffix: true,
            isProjected: false,
          },
          rawValue: item.rawValue,
          value: formatMetricValue({
            value: item.rawValue,
            symbol,
            position: symbolPosition,
          }),
        })

        processedData.push({
          date: date.plus({ days: currentDay }).toFormat('yyyy-MM-dd'),
          bucketEndDate: item.date,
          displayData: {
            start: DateTime.fromISO(bucketEndDate)
              .startOf('month')
              .toFormat('yyyy-MM-dd'),
            end: item.date,
            showSuffix: true,
            isProjected: true,
          },
          rawValue: projectedValue,
          value: formatMetricValue({
            value: projectedValue,
            symbol,
            position: symbolPosition,
          }),
        })
      } else {
        processedData.push({
          date: parsedItemDate.startOf('month').toFormat('yyyy-MM-dd'),
          bucketEndDate: item.date,
          displayData: null,
          rawValue: item.rawValue,
          value: formatMetricValue({
            value: item.rawValue,
            symbol,
            position: symbolPosition,
          }),
        })
      }
    }
  })

  return processedData
}

function processRawData(
  rawData,
  intervalType,
  interval,
  chartColors,
  metricsToProject,
) {
  const result = {}

  rawData.forEach((data) => {
    let symbol = ''
    let symbolPosition = 'right'

    const aggregatedData = [...data.data]
    if (interval !== 1) {
      aggregatedData.length = 0
      aggregatedData.push(
        ...(intervalType === 'weeks'
          ? processWeeksMetrics(data, metricsToProject)
          : processMonthsMetrics(data, metricsToProject)),
      )
    }

    const parsedPoint = aggregatedData
      .map((item) => {
        const parsedValue = extractValueAndSymbol(item.value)
        symbol = parsedValue.symbol
        symbolPosition = parsedValue.symbolPosition
        return { ...item, value: parsedValue.value }
      })
      .sort((a, b) => (DateTime.fromISO(a.date) > DateTime.fromISO(b.date) ? 1 : -1))
    const entityColor =
      chartColors[(result[`${data.metric}`] ?? []).length % chartColors.length]
    const updatedData = {
      ...data,
      data: parsedPoint,
      symbol,
      symbolPosition,
      color: entityColor,
    }

    if (result[`${data.metric}`]) {
      result[`${data.metric}`].push(updatedData)
    } else {
      result[`${data.metric}`] = [updatedData]
    }
  })
  return result
}

function prepareMetricsCsvData({ metrics, headerFormatter } = {}) {
  const processedData = Object.values(metrics)
    .reduce((acc, value) => [...acc, ...value], [])
    .reduce((acc2, item) => {
      const accCopy = [...acc2]
      const processedItems = item.data.map((data) => ({
        name: item.name,
        date: data.bucketEndDate ?? data.date,
        [`${item.metric}`]: `${formatMetricValue({
          value: data.value,
          symbol: item.symbol,
          position: item.symbolPosition,
        })}`,
      }))

      processedItems.forEach((processedItem) => {
        const index = accCopy.findIndex(
          (obj) => obj.name === processedItem.name && obj.date === processedItem.date,
        )
        if (index !== -1) {
          accCopy[index] = { ...accCopy[index], ...processedItem }
        } else {
          accCopy.push(processedItem)
        }
      })

      const normalizeObjects = (objects) => {
        const props = ['name', 'date', ...Object.keys(metrics)]
        const normalizedObjects = objects.map((obj) => {
          const normalizedObj = {}
          props.forEach((prop) => {
            normalizedObj[prop] = Object.prototype.hasOwnProperty.call(obj, prop)
              ? obj[prop]
              : null
          })
          return normalizedObj
        })
        return normalizedObjects
      }

      return normalizeObjects(accCopy)
    }, [])

  const sortedData = sortWith(
    [ascend((item) => DateTime.fromISO(item.date))],
    processedData,
  )

  const csvData = sortedData.map((item, i) => {
    const processedString = Object.keys(item)
      .map((key) => {
        const currentValue = item[key]
        if (currentValue !== null) {
          return `"${currentValue.toString()}"`
        }

        const groupedByName = groupBy((j) => j.name)(sortedData)
        const currentGroup = groupedByName[item.name]
        const dataIndex = currentGroup.findIndex((data) => data.date === item.date)
        const nextValue = dataIndex !== -1 ? currentGroup.at(dataIndex + 1) : null

        return `"${nextValue?.[key]?.toString()}"`
      })
      .join(',')
    if (i === 0) {
      const headerString = Object.keys(item)
        .map((key) => `"${headerFormatter ? headerFormatter(key) : key}"`)
        .join(',')
      return `${headerString}\r\n${processedString}`
    }
    return processedString
  })

  return csvData.join('\r\n')
}

function synchronizeDatesAndFillZeros(data) {
  const result = [...data]

  const allUniqueDates = Array.from(
    new Set(result.flatMap((obj) => obj.data.map((entry) => entry.date))),
  )

  result.forEach((obj) => {
    if (obj.data.length === 0) {
      return
    }

    const existingDates = obj.data.map((entry) => entry.date)
    const missingDates = allUniqueDates.filter((date) => !existingDates.includes(date))
    const valueData = extractValueAndSymbol(obj.data.at(-1).value)
    const objMissingEntries = missingDates.map((date) => ({
      date,
      value: valueData
        ? `${valueData.symbolPosition === 'left' ? valueData.symbol : ''}0.0${
            valueData.symbolPosition === 'right' ? valueData.symbol : ''
          }`
        : '0.0',
      rawValue: 0,
    }))
    obj.data.push(...objMissingEntries)
    obj.data.sort((a, b) => a.date.localeCompare(b.date))
  })

  return result
}

// there is a case when metric responses filtered by propertyGroups and accounts
// could have different amounts of data. We need to cut off residual data/add missing data
// and make sure the amount of data is the same for all responses. Otherwise, the graph
// won't be rendered correctly
const normalizeMetricResponses = (...arrays) => {
  if (arrays.length === 0) {
    return []
  }

  const filteredArrays = arrays.filter((arr) => arr.length > 0)

  const allDates = new Set()
  filteredArrays.forEach((arr) => {
    arr.forEach((obj) => obj.data.forEach((item) => allDates.add(item.date)))
  })
  const allDatesArr = Array.from(allDates)

  const addMissingDates = (dataArray) =>
    allDatesArr.map((date) => {
      const value = dataArray.at(-1)?.value
      const valueData = value ? extractValueAndSymbol(value) : null
      const existingItem = dataArray.find((item) => item.date === date)
      return (
        existingItem || {
          date,
          value: valueData
            ? `${valueData.symbolPosition === 'left' ? valueData.symbol : ''}0.0${
                valueData.symbolPosition === 'right' ? valueData.symbol : ''
              }`
            : '0.0',
          rawValue: 0,
        }
      )
    })

  // Update all data arrays with missing dates
  filteredArrays.forEach((arr) => {
    arr.forEach((obj) => {
      const updatedObj = { ...obj }
      updatedObj.data = addMissingDates(obj.data)
      Object.assign(obj, updatedObj)
    })
  })

  return filteredArrays.map((arr) => synchronizeDatesAndFillZeros(arr))
}

const fetchAndProcessMetricsData = async ({
  apiFetch,
  payload,
  intervalType,
  interval,
  chartColors,
  metricsToProject,
}) => {
  const response = await apiFetch(`/reports/chart/`, payload, {
    useAccountHeader: false,
    cancelationPrefix: `metrics_summarize_${Boolean(payload.summarize)}`,
  })
  const [normalizedResponse = []] = normalizeMetricResponses(response)
  return processRawData(
    normalizedResponse,
    intervalType,
    interval,
    chartColors,
    metricsToProject,
  )
}

function getMetricsDateRange(pointDate, bucketStart, suffix) {
  if (!pointDate) {
    return null
  }

  const format = 'LLL dd, yyyy'
  const parsedPointDate = DateTime.fromISO(pointDate)
  const parsedBucketStart = bucketStart ? DateTime.fromISO(bucketStart) : null

  if (parsedBucketStart !== null) {
    let startDateFormat = format
    let endDateFormat = format

    const sameYear = parsedBucketStart.year === parsedPointDate.year
    const sameMonth = sameYear && parsedBucketStart.month === parsedPointDate.month
    if (sameYear && sameMonth) {
      startDateFormat = 'LLL dd'
      endDateFormat = 'dd, yyyy'
    } else if (sameYear) {
      startDateFormat = 'LLL dd'
    }

    const formattedStart = parsedBucketStart.toFormat(startDateFormat)
    const formattedEnd = parsedPointDate.toFormat(endDateFormat)

    const formattedRange = `${formattedStart}-${formattedEnd}`
    return suffix ? `${formattedRange} (${suffix})` : formattedRange
  }
  return parsedPointDate.toFormat(format)
}

export {
  fetchAndProcessMetricsData,
  formatMetricValue,
  getMetricsDateRange,
  prepareMetricsCsvData,
}
