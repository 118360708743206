import { pipe } from 'ramda'

import { pluralize, underscore } from 'inflection'

import {
  createListBundle,
  defaultInitialState,
  getEntityFromUrl,
  isAbortError,
} from '@common/utils'
import { filterValidEntities } from '@portal/pages/EventDashboard/utils'
import { viewableEventClasses } from '@portal/Utils/constants'

import eventUrls from './urls'

const entityName = 'events'

function getDataFromPromiseResult(result) {
  if (result.status === 'rejected') {
    throw result.reason
  }
  return result.status === 'fulfilled' ? result.value : null
}

const eventListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, store, params }) => {
    const { entityType, entityId } = getEntityFromUrl(store)

    return apiFetch(
      '/events/',
      {
        ...params,
        ...(entityType !== 'event'
          ? { [entityType]: entityId }
          : { eventClass: params.eventClass ?? viewableEventClasses.join(',') }),
      },
      { cancelationPrefix: entityName },
    )
  },
  initialState: {
    ...defaultInitialState,
    ordering: ['createdOn', 'desc'],
  },
  urlTest: (url, _, hash) => url === eventUrls.list || ['eventLog'].includes(hash),
})

export default {
  ...eventListBundle,
  doFetchChargeSummary:
    () =>
    async ({ apiFetch, store }) => {
      const apiParams = store.selectEventsListApiParams()

      const { localCreatedOnDateBefore, localCreatedOnDateAfter } = apiParams
      const incompleteDate = !localCreatedOnDateAfter || !localCreatedOnDateBefore

      const validEntities = filterValidEntities(apiParams)
      const getEntityFilter = () => {
        if (validEntities.property) return { property: validEntities.property }
        if (validEntities.account) return { account: validEntities.account }
        if (validEntities.organization)
          return { organization: validEntities.organization }
        if (validEntities.organizationGroup)
          return { organizationGroup: validEntities.organizationGroup }
        return {}
      }

      const entityFilter = getEntityFilter()

      if (incompleteDate) {
        return null
      }

      try {
        const response = await apiFetch(
          `/events/smoke_charge_summary/`,
          {
            localCreatedOnDateAfter,
            localCreatedOnDateBefore,
            eventClass: 'SMOKE',
            ...entityFilter,
          },
          { cancelationPrefix: entityName },
        )
        return response
      } catch (err) {
        if (!isAbortError(err)) throw err
        return null
      }
    },
  doDeleteEvent:
    (payload) =>
    async ({ apiFetch }) =>
      apiFetch('/events/', payload, {
        method: 'DELETE',
        cancelationPrefix: entityName,
      }),
  doFetchPreGoLiveEvents:
    ({ goLiveDate, propertyId }) =>
    async ({ apiFetch }) => {
      try {
        const response = await apiFetch('/events/', {
          pageSize: 500,
          createdOnBefore: goLiveDate,
          property: propertyId,
        })
        return response
      } catch (err) {
        if (!isAbortError(err)) throw err
        return null
      }
    },
  doFetchEventEntitiesFilterInitialData:
    (props) =>
    async ({ apiFetch }) => {
      const cancelationPrefix = `${entityName}_initial_filter`
      const endpointFromEntity = pipe(pluralize, underscore)
      const requestData = Object.entries(props).reduce(
        (acc, [entity, id]) => ({
          ...acc,
          [entity]: id
            ? apiFetch(`/${endpointFromEntity(entity)}/${id}/`, {
                cancelationPrefix,
              })
            : {},
        }),
        {},
      )

      try {
        const apiResults = await Promise.allSettled(Object.values(requestData))
        return apiResults.reduce((acc, result, index) => {
          const entity = Object.keys(requestData)[index]
          const parsedResult = getDataFromPromiseResult(result)
          return { ...acc, ...{ [entity]: parsedResult } }
        }, {})
      } catch (err) {
        if (!isAbortError(err)) throw err
        return null
      }
    },
}
