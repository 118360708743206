import { useCallback, useEffect, useState } from 'react'

import { isEmpty } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import {
  Circle as CircleIcon,
  CopyAll,
  Delete as DeleteIcon,
  Edit,
  LockReset as LockResetIcon,
  Refresh as RefreshIcon,
} from '@mui/icons-material'
import { Box, Button, Chip, Typography } from '@mui/material'

import { Breadcrumbs, Loading, TabComponent } from '@common/components'
import { parseApiErrors, useHashFilter, useSmallScreen } from '@common/utils'
import { homeUrls } from '@portal/pages/Home'
import ReactivateUserDialog from '@portal/pages/Users/UserReactivateDialog'
import AuditLogTab from '@portal/UI/components/AuditLogTab'
import DeleteModal from '@portal/UI/components/DeleteModal'
import ListPageTitle from '@portal/UI/components/ListPageTitle'
import ResetPasswordModal from '@portal/UI/components/ResetPasswordModal'

import UserTab from './Tabs/User'
import userUrls from './urls'
import UserForm from './UserForm'

export default function SingleUser() {
  const {
    isAtLeastAdmin,
    systemPortalRoles,
    routeParams: { id: userId },
    userFetch: user,
    userFetchStatus,
    hashObject,
    doUserFetch,
    doPasswordReset,
    doUserDelete,
    doUpdateUrl,
    doShowSnackbar,
    doUserListBulkReactivate,
  } = useConnect(
    'selectIsAtLeastAdmin',
    'selectSystemPortalRoles',
    'selectRouteParams',
    'selectUserFetch',
    'selectUserFetchStatus',
    'doUserFetch',
    'selectMe',
    'selectHashObject',
    'doPasswordReset',
    'doUserDelete',
    'doUpdateUrl',
    'doShowSnackbar',
    'doUserListBulkReactivate',
  )

  const [tabValue, setTabValue] = useState(0)
  const [isEditingUser, setIsEditingUser] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [reactivateModelOpen, setReactivateModelOpen] = useState(false)
  const [deleteError, setDeleteError] = useState()
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false)
  const [resetPasswordError, setResetPasswordError] = useState(null)

  const isSmallScreen = useSmallScreen()

  const fetchUser = useCallback(async (id) => {
    try {
      await doUserFetch(id)
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [])

  const closeEditUserModal = async () => {
    await fetchUser(userId)
    setIsEditingUser(false)
  }
  const openResetPasswordModal = () => setIsResetPasswordModalOpen(true)
  const closeResetPasswordModal = () => setIsResetPasswordModalOpen(false)
  const handleResetPassword = async () => {
    try {
      await doPasswordReset(user.email)
      closeResetPasswordModal()
      doShowSnackbar('A reset-password email was sent to the user', 'success')
    } catch (err) {
      setResetPasswordError(JSON.stringify(err))
    }
  }
  const handleDeleteUser = async () => {
    try {
      await doUserDelete(userId)
      doUpdateUrl(userUrls.list)
      doShowSnackbar(`User: ${user.email} deleted successfully`, 'success')
    } catch (err) {
      setDeleteError(JSON.stringify(err))
    }
  }

  const handleReactivateUser = async () => {
    const res = await doUserListBulkReactivate([userId])
    const errorMessage = parseApiErrors(res?.error?.response)

    if (errorMessage) {
      doShowSnackbar(errorMessage, 'error')
    } else {
      doUserFetch(userId)
      doShowSnackbar(`Successfully reactivated ${user.email}`, 'success')
    }
    setReactivateModelOpen(false)
  }

  const tabs = [
    { label: 'User', component: <UserTab user={user} />, hash: 'user' },
    {
      label: 'Audit Log',
      component: <AuditLogTab key="audit_log" objectId={userId} />,
      hash: 'auditLog',
    },
    {
      label: 'Actions',
      component: (
        <AuditLogTab key="actions" modifiedBy={userId} showModifiedBy={false} />
      ),
      hash: 'actions',
    },
  ]

  useHashFilter(isEmpty(hashObject) ? tabs[0].hash : hashObject, (hash) => {
    const tabIndex = tabs.findIndex((item) => item.hash === hash)
    if (tabIndex !== -1) {
      setTabValue(tabIndex)
    }
  })

  useEffect(() => {
    fetchUser(userId)
  }, [userId])

  const getRoleName = (roleLevel) =>
    systemPortalRoles?.find((role) => role.id === roleLevel)?.label ?? 'N/A'

  const UserStatusChips = useCallback(
    () => (
      <>
        <Chip
          icon={<CircleIcon />}
          variant="outlined"
          size="small"
          label={user.isActive ? 'Active' : 'Inactive'}
          color={user.isActive ? 'success' : 'error'}
        />
        <Chip
          variant="outlined"
          size="small"
          label={getRoleName(user.portalRole)}
          sx={{ mx: 1 }}
        />
      </>
    ),
    [user],
  )

  if (userFetchStatus === 'failed') {
    return <Typography>Invalid ID or something or other</Typography>
  }

  if (userFetchStatus === 'loading') {
    return <Loading />
  }

  return (
    <div>
      <UserForm open={isEditingUser} onClose={closeEditUserModal} instance={user} />
      <ResetPasswordModal
        open={isResetPasswordModalOpen}
        onConfirmResetPassword={handleResetPassword}
        onCancelResetPassword={closeResetPasswordModal}
        error={resetPasswordError}
      />
      <DeleteModal
        open={isDeleteModalOpen}
        error={deleteError}
        onConfirmDelete={handleDeleteUser}
        onCancelDelete={() => setIsDeleteModalOpen(false)}
      />
      <ReactivateUserDialog
        open={reactivateModelOpen}
        onConfirm={handleReactivateUser}
        onCancel={() => setReactivateModelOpen(false)}
      />
      {user ? (
        <Box sx={{ px: isSmallScreen ? 0 : 2, m: isSmallScreen ? 3 : 4 }}>
          <Breadcrumbs
            links={[
              { label: 'Home', href: homeUrls.home },
              { label: 'Users', href: userUrls.list },
              { label: user.name || 'N/A' },
            ]}
          />
          <Box>
            <Box display="flex" alignItems="center">
              <Typography variant="caption" color="text.secondary">
                id: {userId}
              </Typography>
              <CopyAll
                sx={{ m: 0.5, cursor: 'pointer', fontSize: 16 }}
                onClick={() => {
                  navigator.clipboard.writeText(userId)
                  doShowSnackbar('ID copied to clipboard')
                }}
              />
            </Box>
            {isSmallScreen ? (
              <Box>
                <ListPageTitle
                  title={user.name}
                  menuItems={
                    isAtLeastAdmin
                      ? [
                          { label: 'Edit User', onClick: () => setIsEditingUser(true) },
                          { label: 'Reset Password', onClick: openResetPasswordModal },
                          ...(user.isActive
                            ? [
                                {
                                  label: 'Delete User',
                                  onClick: () => setIsDeleteModalOpen(true),
                                },
                              ]
                            : [
                                {
                                  label: 'Reactivate User',
                                  onClick: () => setReactivateModelOpen(true),
                                },
                              ]),
                        ]
                      : null
                  }
                />
                <UserStatusChips />
              </Box>
            ) : (
              <Box display="flex" justifyContent="space-between" gap={2}>
                <Box>
                  <Typography variant="h3" mb={2}>
                    {user.name}
                  </Typography>
                  <UserStatusChips />
                </Box>

                {isAtLeastAdmin && (
                  <Box>
                    <Button
                      variant="outlined"
                      startIcon={<Edit />}
                      sx={{ marginRight: 2 }}
                      onClick={() => setIsEditingUser(true)}
                    >
                      Edit User
                    </Button>
                    <Button
                      variant="outlined"
                      startIcon={<LockResetIcon />}
                      sx={{ marginRight: 2 }}
                      onClick={openResetPasswordModal}
                    >
                      Reset Password
                    </Button>
                    {user.isActive ? (
                      <Button
                        variant="outlined"
                        color="error"
                        startIcon={<DeleteIcon />}
                        onClick={() => setIsDeleteModalOpen(true)}
                      >
                        Delete User
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        startIcon={<RefreshIcon />}
                        onClick={() => setReactivateModelOpen(true)}
                      >
                        Reactivate User
                      </Button>
                    )}
                  </Box>
                )}
              </Box>
            )}
            <TabComponent
              tabs={tabs}
              externalState={{ value: tabValue, setValue: setTabValue }}
              sx={{ mt: 1 }}
            />
          </Box>
        </Box>
      ) : (
        'No user found'
      )}
    </div>
  )
}
