import { useConnect } from 'redux-bundler-hook'

import { BroadcastOnPersonalRounded, Circle } from '@mui/icons-material'
import { Box, Chip, Typography } from '@mui/material'

import { titleize } from 'inflection'

import { useSmallScreen } from '@common/utils'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.status
 * @param {'chip'|'tablerow'} props.status
 */
export default function PropertyStatus({ status, type }) {
  const isSmallScreen = useSmallScreen()
  const { systemPropertyStatuses } = useConnect('selectSystemPropertyStatuses')

  const statusData = systemPropertyStatuses.find((item) => item.value === status)
  const label = statusData ? statusData.name : titleize(status ?? 'Unknown')

  let iconColor = 'grey'

  switch (status) {
    case 'INTERNAL': {
      iconColor = 'olive'
      break
    }
    case 'ONBOARDING': {
      iconColor = 'warning.main'
      break
    }
    case 'LIVE': {
      iconColor = 'success.main'
      break
    }
    case 'SUSPENDED':
    case 'CANCELLED': {
      iconColor = 'error.main'
      break
    }
    default: {
      iconColor = 'grey'
    }
  }

  if (type === 'chip') {
    return (
      <Chip
        icon={<BroadcastOnPersonalRounded sx={{ '&&': { color: iconColor } }} />}
        variant="outlined"
        size="small"
        label={label}
        sx={{
          color: iconColor,
          borderColor: iconColor,
          userSelect: 'none',
          paddingLeft: 0.3,
        }}
      />
    )
  }
  if (type === 'tablerow') {
    return (
      <Box display="flex" alignItems="center" gap={0.5}>
        <Circle sx={{ fontSize: 10, color: iconColor }} />
        <Typography variant={isSmallScreen ? 'caption' : 'body2'}>{label}</Typography>
      </Box>
    )
  }
  throw new Error(`Invalid type: ${type}`)
}
