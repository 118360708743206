function parseApiErrors(response) {
  let errorMessage = ''

  if (Array.isArray(response?.nonFieldErrors)) {
    errorMessage = response.nonFieldErrors.join('\n')
  } else if (response?.detail) {
    errorMessage = response.detail
  }

  return errorMessage
}

export function isRequestError(obj) {
  return Object.prototype.toString.call(obj) === '[object Error]' || obj.error
}

export function isAbortError(err) {
  return err?.name === 'AbortError' || err === 'AbortError'
}

export default parseApiErrors
