import { useState } from 'react'

import { isEmpty } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { Box } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'

import { Field } from 'formik'
import { DateTime } from 'luxon'
import * as yup from 'yup'

import FormDialog from '@portal/UI/components/FormDialog'
import FormikStatePropagator from '@portal/UI/components/FormikStatePropagator'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {Object} [props.instance]
 * @param {string} props.instance.id
 * @param {string} [props.instance.demoModeStart]
 * @param {string} [props.instance.demoModeEnd]
 */
export default function DemoModeForm({ open, onClose, instance = {} }) {
  const { doUnitUpdateDemoMode } = useConnect('doUnitUpdateDemoMode')

  const [formikProps, setFormikProps] = useState({
    values: {},
    errors: {},
    setFieldValue: () => {},
  })

  const validationSchema = yup.object().shape({
    demoModeStart: yup
      .date()
      .required('Start date is required')
      .test(
        'is-before-end',
        'Start date must be before the end date',
        (value, { parent }) =>
          DateTime.fromJSDate(value) < DateTime.fromJSDate(parent.demoModeEnd),
      ),
    demoModeEnd: yup
      .date()
      .required('End date is required')
      .test(
        'is-after-start',
        'End date must be after the start date',
        (value, { parent }) =>
          DateTime.fromJSDate(value) > DateTime.fromJSDate(parent.demoModeStart),
      ),
  })

  const initialValues = {
    id: '',
    demoModeStart: '',
    demoModeEnd: '',
  }

  if (instance) {
    Object.keys(initialValues).forEach((field) => {
      initialValues[field] = instance[field] ?? ''
    })
  }

  const onSave = async (data) => {
    const payload = {}

    Object.keys(data).forEach((field) => {
      const fieldValue = data[field]
      if (fieldValue === '') {
        const schemaField = validationSchema.fields[field]
        if (schemaField?.spec?.nullable) {
          payload[field] = null
        }
      } else {
        payload[field] = fieldValue
      }
    })

    return doUnitUpdateDemoMode(payload)
  }

  const getValue = (field) =>
    formikProps.values[field] ? DateTime.fromISO(formikProps?.values?.[field]) : null

  const setValue = (field, value) => formikProps.setFieldValue(field, value?.toISO())

  const sharedFieldProps = { clearable: true, size: 'small' }

  return (
    <FormDialog
      label="Demo Mode"
      open={open}
      onSave={onSave}
      onClose={onClose}
      successMessage="Demo mode has been enabled"
      submitOptions={{ label: 'Enable', disabled: !isEmpty(formikProps.errors) }}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-evenly', gap: '1rem' }}>
        <Field
          component={DateTimePicker}
          label="Start time"
          name="demoModeStart"
          slotProps={{
            field: {
              ...sharedFieldProps,
              error: !!formikProps.errors.demoModeStart,
              helperText: formikProps.errors.demoModeStart,
            },
          }}
          value={getValue('demoModeStart')}
          onChange={(value) => setValue('demoModeStart', value)}
        />
        <Field
          component={DateTimePicker}
          label="End Time"
          name="demoModeEnd"
          slotProps={{
            field: {
              ...sharedFieldProps,
              error: !!formikProps.errors.demoModeEnd,
              helperText: formikProps.errors.demoModeEnd,
            },
          }}
          value={getValue('demoModeEnd')}
          onChange={(value) => setValue('demoModeEnd', value)}
        />
      </Box>
      <FormikStatePropagator propSetter={setFormikProps} />
    </FormDialog>
  )
}
