import { useCallback, useEffect, useMemo, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import {
  AccessTimeRounded,
  AssessmentOutlined,
  Delete,
  QuestionMarkRounded,
} from '@mui/icons-material'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import AutoFixOffIcon from '@mui/icons-material/AutoFixOff'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import MoneyOffIcon from '@mui/icons-material/MoneyOff'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { GridActionsCellItem } from '@mui/x-data-grid'

import { ThemeProvider } from '@emotion/react'
import { DateTime } from 'luxon'

import {
  ClickableCell,
  MobileList,
  MobileListCardRow,
  MobileListDefaultCard,
} from '@common/components'
import { SmokeQualityReport } from '@common/components/SmokeQualityReport'
import { SmokeIcon } from '@common/icons'
import { formatCurrency, useSmallScreen } from '@common/utils'
import { buildReasonsById } from '@common/utils/adjustmentReasonsUtils'
import propertyUrls from '@portal/pages/Properties/urls'
import ScenarioForm from '@portal/pages/Scenarios/ScenarioForm'
import unitUrls from '@portal/pages/Units/urls'
import { EventTypeCell, GridCellExpand } from '@portal/UI/components/cells'
import List from '@portal/UI/components/ProList'
import { darkTheme } from '@portal/UI/Theme'

import AdditionalOccurenciesModal from './AdditionalOccurenciesModal'
import AuditLogModal from './AuditLogModal'
import DeleteEventModal from './DeleteEventModal'
import EventListRowDetail from './EventListRowDetail'
import EventViewModal from './EventModal/EventViewModal'
import ScenarioOptions from './ScenarioOptions'

function isBoolean(value) {
  return value === true || value === false
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} [props.eventsList]
 * @param {Object[]} [props.eventsList.results]
 * @param {number} [props.eventsList.current]
 * @param {number} [props.eventsList.pageSize]
 * @param {number} [props.eventsList.count]
 * @param {number} [props.eventsList.numPages]
 * @param {number} [props.eventsList.organizationName]
 * @param {boolean} [props.showBillingInfo]
 */
export default function EventsList({ eventsList = {}, showBillingInfo = false }) {
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [scenarioFormOpen, setScenarioFormOpen] = useState(false)
  const [scenarioData, setScenarioData] = useState({})
  const [additionalOccurenciesData, setAdditionalOccurenciesData] = useState(null)
  const [rowIdToRemove, setRowIdToRemove] = useState(null)
  const [reportData, setReportData] = useState(null)

  const {
    isAtLeastDataScience,
    eventsListRaw: { ordering = [] },
    eventsListIsLoading,
    smokeAdjustmentReasons,
    doEventsListSetPage,
    doEventsListSetPageSize,
    doEventsListSetOrdering,
    doEventsListSetSearch,
    doMarkEventsListAsOutdated,
    doScenarioCreateFromReadings,
    queryObject,
    doUpdateQuery,
  } = useConnect(
    'selectIsAtLeastDataScience',
    'selectEventsListRaw',
    'selectEventsListIsLoading',
    'selectSmokeAdjustmentReasons',
    'doEventsListSetPage',
    'doEventsListSetPageSize',
    'doEventsListSetOrdering',
    'doEventsListSetSearch',
    'doMarkEventsListAsOutdated',
    'doScenarioCreateFromReadings',
    'selectQueryObject',
    'doUpdateQuery',
  )

  const isSmallScreen = useSmallScreen()

  const detailRowContent = useCallback(
    ({ row }) =>
      row.childCount === 0 ? null : (
        <EventListRowDetail
          row={row}
          setScenarioData={setScenarioData}
          setScenarioFormOpen={setScenarioFormOpen}
          setReportData={setReportData}
        />
      ),
    [eventsList],
  )

  const detailRowHeight = useCallback(() => 'auto', [])

  useEffect(() => {
    if (queryObject?.grafanaEvent) {
      setSelectedEvent(queryObject.grafanaEvent)
    }
  }, [queryObject])

  const onScenarioFormClose = () => {
    setScenarioFormOpen(false)
    doMarkEventsListAsOutdated()
  }

  const reasonsById = useMemo(
    () => buildReasonsById(smokeAdjustmentReasons),
    [smokeAdjustmentReasons],
  )

  const getDetectedOnTime = useCallback(({ createdOn, endTime, timeZone }) => {
    const createdLuxon = DateTime.fromISO(createdOn, { zone: timeZone })
    const endLuxon = endTime ? DateTime.fromISO(endTime, { zone: timeZone }) : null

    const formattedCreatedOn = createdLuxon.toLocaleString(DateTime.DATETIME_SHORT)
    const endTimeFormat =
      endLuxon && createdLuxon.hasSame(endLuxon, 'day')
        ? DateTime.TIME_SIMPLE
        : DateTime.DATETIME_MED
    const formattedEndTime = endLuxon
      ? endLuxon.toLocaleString(endTimeFormat)
      : 'Ongoing'

    return [formattedCreatedOn, formattedEndTime]
  }, [])

  const LastModifiedItem = useCallback(
    ({ row, formattedValue }) => {
      let modifiedOn = null
      if (row.eventClass === 'NOISE') {
        modifiedOn = row.noiseFeedback?.modifiedOn
      } else if (row.eventClass === 'SMOKE' && row.feedbackOn) {
        modifiedOn = row.smokeFeedback?.modifiedOn || row.feedbackOn
      }

      let createdLuxon = null
      if (modifiedOn) {
        createdLuxon = DateTime.fromISO(modifiedOn, {
          zone: row.timeZone,
        })
      }
      return (
        <Stack textAlign={isSmallScreen ? 'end' : 'start'}>
          <Typography
            variant="body1"
            fontSize={isSmallScreen ? 12 : 14}
            whiteSpace="pre-line"
          >
            {row.smokeFeedback?.autocharge && formattedValue === 'N/A'
              ? 'Autocharge'
              : formattedValue}
          </Typography>
          {createdLuxon && (
            <Typography variant="caption" color="grey.500" fontWeight={600}>
              {createdLuxon.toLocaleString(DateTime.DATETIME_SHORT)}
            </Typography>
          )}
        </Stack>
      )
    },
    [isSmallScreen],
  )

  const netChargeValueGetter = useCallback((row) => {
    if (
      row.smokeFeedback?.netChargeAmount === null &&
      row.smokeFeedback?.chargeAmount === null
    ) {
      return [null, null]
    }
    return [
      Number(row.smokeFeedback?.netChargeAmount),
      Number(row.smokeFeedback?.chargeAmount),
    ]
  }, [])

  const netChargeValueFormatter = useCallback(({ value }) => {
    const formattedValue = formatCurrency(value[0], '-')
    const asterisk = !Number.isNaN(value[1]) && value[1] !== value[0] ? '*' : ''
    return `${formattedValue}${asterisk}`
  }, [])

  const NetChargeItem = useCallback(
    ({ row, formattedValue }) => {
      const isAutochargePending =
        row.smokeFeedback?.autochargeMetadata?.chargeStatus === 'pending'

      return (
        <Box display="flex" alignItems="center">
          {isAutochargePending && (
            <Tooltip title="Auto-charge pending">
              <AccessTimeRounded
                fontSize="small"
                sx={{ mr: 1, fontSize: isSmallScreen ? 16 : 20 }}
                color="warning"
              />
            </Tooltip>
          )}
          {isBoolean(row.smokeFeedback?.autocharge) &&
            (row.smokeFeedback.autocharge ? (
              <Tooltip title="Auto-charge successful">
                <AutoFixHighIcon
                  fontSize="small"
                  sx={{ mr: 1, fontSize: isSmallScreen ? 16 : 20 }}
                  color="success"
                />
              </Tooltip>
            ) : (
              <Tooltip title="Auto-charge failed">
                <AutoFixOffIcon
                  fontSize="small"
                  sx={{ mr: 1, fontSize: isSmallScreen ? 16 : 20 }}
                  color="error"
                />
              </Tooltip>
            ))}
          <Tooltip title="View smoke feedback audit log" placement="top-end">
            <Box>
              <Typography
                onClick={() => {
                  doUpdateQuery(
                    { auditLog: `${row.smokeFeedback.id},${row.timeZone}` },
                    { maintainScrollPosition: true },
                  )
                }}
                variant={isSmallScreen ? 'caption' : 'body2'}
                color="primary"
                align="right"
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
              >
                {formattedValue}
              </Typography>
              <Typography variant="caption" color="grey.500" align="right">
                {reasonsById?.[row.smokeFeedback?.adjustmentReason] ?? ''}
              </Typography>
            </Box>
          </Tooltip>
        </Box>
      )
    },
    [isSmallScreen],
  )

  const showExplanation = (row) =>
    row.eventType === 'REMOVED' && !!row.deletedExplanation

  const columns = [
    {
      field: 'eventType',
      sortable: false,
      headerName: '',
      flex: 0.01,
      minWidth: 76,
      renderCell: ({ row }) => (
        <Box display="flex" justifyContent="space-between" gap={1} mr={-4}>
          <EventTypeCell type={row.eventType} />
          {showExplanation(row) && (
            <Tooltip
              placement="right-end"
              title={
                <Typography variant="caption" sx={{ whiteSpace: 'pre-line' }}>
                  {row.deletedExplanation}
                </Typography>
              }
            >
              <ChatBubbleOutlineIcon
                color="action"
                fontSize="small"
                sx={{ marginTop: 0.5 }}
              />
            </Tooltip>
          )}
        </Box>
      ),
    },
    {
      field: 'billable',
      sortable: false,
      headerName: '',
      flex: 0.01,
      renderCell: ({ row }) =>
        row.eventType === 'EXTERNAL' ? (
          <Tooltip title={row.billable ? 'Billable' : 'Not Billable'}>
            {row.billable ? (
              <AttachMoneyIcon color="success" />
            ) : (
              <MoneyOffIcon color="error" />
            )}
          </Tooltip>
        ) : (
          <span>-</span>
        ),
    },
    {
      field: 'eventClass',
      sortField: 'eventClass',
      headerName: 'Type',
      flex: 0.2,
      sortable: true,
      renderCell: ({ row }) => {
        switch (row.eventClass) {
          case 'NOISE':
            return (
              <Tooltip title="Noise">
                <VolumeUpIcon color="action" />
              </Tooltip>
            )
          case 'SMOKE':
            return (
              <Tooltip title="Smoke">
                <SmokeIcon color="action" style={{ transform: 'rotate(-90deg)' }} />
              </Tooltip>
            )
          default:
            return null
        }
      },
    },
    {
      field: 'unitName',
      sortField: 'unit__name',
      headerName: 'Unit',
      flex: 0.2,
      minWidth: 100,
      sortable: true,
      renderCell: ({ row }) => (
        <ClickableCell
          label={row.unitName}
          url={unitUrls.entity.replace(':id', row.unit)}
        />
      ),
    },
    {
      field: 'propertyName',
      sortField: 'property__name',
      headerName: 'Property',
      flex: 0.5,
      minWidth: 200,
      sortable: true,
      renderCell: ({ row }) => (
        <ClickableCell
          label={row.propertyName}
          url={propertyUrls.entity.replace(':id', row.property)}
        />
      ),
    },
    {
      field: 'createdOn',
      sortable: true,
      headerName: 'Detected On (Property Time)',
      flex: 0.7,
      minWidth: 140,
      renderCell: ({ row: { createdOn, endTime, timeZone } }) => {
        const [start, end] = getDetectedOnTime({ createdOn, endTime, timeZone })
        return (
          <Tooltip title={`${start} - ${end}`} placement="top">
            <Typography fontSize={isSmallScreen ? 12 : 14}>{start}</Typography>
          </Tooltip>
        )
      },
    },
    {
      field: 'comments',
      sortable: false,
      maxWidth: 200,
      minWidth: 200,
      headerName: 'Comments',
      flex: 1,
      valueGetter: (_, row) =>
        row.smokeFeedback?.comments ?? row.noiseFeedback?.comments,
      renderCell: GridCellExpand,
      renderMobile: ({ row }) =>
        row.smokeFeedback?.comments ?? row.noiseFeedback?.comments,
    },
    {
      field: 'feedback.modifiedByName',
      sortable: false,
      headerName: 'Last Modified',
      flex: 0.4,
      minWidth: 200,
      valueGetter: (_, row) =>
        row.eventClass === 'NOISE'
          ? row.noiseFeedback?.modifiedByName
          : row.smokeFeedback?.modifiedByName,
      valueFormatter: (value) => value ?? 'N/A',
      renderCell: (params) => (
        <LastModifiedItem row={params.row} formattedValue={params.formattedValue} />
      ),
    },
    {
      field: 'feedback.netChargeAmount',
      headerName: 'Net Charge',
      sortable: false,
      type: 'number',
      minWidth: 100,
      flex: 0.4,
      valueGetter: (_, row) => netChargeValueGetter(row),
      valueFormatter: (value) => netChargeValueFormatter({ value }),
      renderCell: (params) =>
        params.row.smokeFeedback?.id && (
          <NetChargeItem row={params.row} formattedValue={params.formattedValue} />
        ),
    },
    {
      field: 'extra_actions',
      sortable: false,
      headerName: 'Actions',
      headerAlign: 'center',
      width: isAtLeastDataScience ? 225 : 100,
      renderCell: ({ row }) => (
        <Box display="flex" gap={1} alignItems="center">
          <ScenarioOptions
            row={row}
            setScenarioData={setScenarioData}
            setScenarioFormOpen={setScenarioFormOpen}
          />
          <Tooltip title="View Report">
            <span>
              <IconButton
                disabled={!row.endTime}
                color="primary"
                onClick={() => setReportData(row)}
              >
                <AssessmentOutlined />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      display: 'flex',
      width: 40,
      getActions: ({ row }) => [
        <GridActionsCellItem
          icon={<Delete />}
          label={`Remove${row.eventType === 'REMOVED' ? 'd' : ''}`}
          onClick={() => setRowIdToRemove(row.id)}
          disabled={row.eventType === 'REMOVED'}
          showInMenu
        />,
      ],
    },
  ]

  const MobileItemHeader = useCallback(({ row }) => {
    let EventIcon = QuestionMarkRounded
    let label = 'Unknown'
    if (row.eventClass === 'NOISE') {
      EventIcon = VolumeUpIcon
      label = 'Noise'
    } else if (row.eventClass === 'SMOKE') {
      EventIcon = SmokeIcon
      label = 'Smoke'
    }
    const iconSx = { fontSize: 18, ml: 0.5 }
    return (
      <Box display="flex" sx={{ '&&': { mb: 1 } }}>
        <Box display="flex" alignItems="center">
          <EventTypeCell type={row.eventType} />
          <EventIcon
            color="action"
            sx={iconSx}
            style={row.eventClass === 'SMOKE' ? { transform: 'rotate(-90deg)' } : null}
          />
          {row.eventType === 'EXTERNAL' && (
            <Box display="flex">
              {row.billable ? (
                <AttachMoneyIcon color="success" sx={iconSx} />
              ) : (
                <MoneyOffIcon color="error" sx={iconSx} />
              )}
            </Box>
          )}
          <Typography variant="caption" fontWeight="bold" ml={0.5}>
            {label} Event
          </Typography>
        </Box>
        {row.eventType !== 'EXTERNAL' && (
          <Typography variant="caption" ml={0.5} fontStyle="italic">
            (INTERNAL)
          </Typography>
        )}
      </Box>
    )
  }, [])

  const MobileItemFooter = useCallback(
    ({ row }) => (
      <>
        <MobileListCardRow
          label="Additional Occurencies"
          value={
            row.childCount ? (
              <Typography
                variant="caption"
                color="primary"
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => setAdditionalOccurenciesData(row)}
              >
                View
              </Typography>
            ) : null
          }
        />
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          sx={{ '&&': { mt: 1, mb: 0.5 } }}
        >
          <ScenarioOptions
            fullWidth
            row={row}
            setScenarioData={setScenarioData}
            setScenarioFormOpen={setScenarioFormOpen}
          />
          <IconButton
            disabled={!row.endTime}
            color="primary"
            onClick={() => setReportData(row)}
          >
            <AssessmentOutlined />
          </IconButton>
        </Box>
      </>
    ),
    [],
  )

  const MobileListItem = useCallback(
    (row) =>
      MobileListDefaultCard({
        row,
        columns,
        ignoredFields: [
          'billable',
          'eventType',
          'eventClass',
          'actions',
          'extra_actions',
        ],
        header: <MobileItemHeader row={row} />,
        footer: <MobileItemFooter row={row} />,
      }),
    [columns],
  )

  const actionsLabels = { delete: 'Remove' }

  const auditLogParams = useMemo(() => {
    if (queryObject?.auditLog) {
      const [id, tz] = queryObject.auditLog.split(',')
      return { id, tz }
    }
    return null
  }, [queryObject])

  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <EventViewModal
          id={selectedEvent}
          entity="event"
          onClose={() => {
            doUpdateQuery({}, { maintainScrollPosition: true })
            setSelectedEvent(null)
          }}
        />
      </ThemeProvider>
      <AuditLogModal
        open={!!auditLogParams}
        label="Smoke event feedback"
        onClose={() => doUpdateQuery({}, { maintainScrollPosition: true })}
        smokeFeedbackId={auditLogParams?.id}
        tz={auditLogParams?.tz}
      />
      <AdditionalOccurenciesModal
        open={!!additionalOccurenciesData}
        onClose={() => setAdditionalOccurenciesData(null)}
        setScenarioData={setScenarioData}
        setScenarioFormOpen={setScenarioFormOpen}
      />
      <DeleteEventModal
        open={!!rowIdToRemove}
        eventIds={[rowIdToRemove]}
        onClose={() => setRowIdToRemove(null)}
      />
      <SmokeQualityReport
        event={reportData}
        onClose={() => {
          setReportData(null)
        }}
      />
      <Box>
        <ScenarioForm
          open={scenarioFormOpen}
          onClose={onScenarioFormClose}
          onSave={doScenarioCreateFromReadings}
          instance={scenarioData}
        />
        {isSmallScreen ? (
          <MobileList
            title="Events"
            rows={eventsList.results}
            actions={{ delete: setRowIdToRemove }}
            actionsLabels={actionsLabels}
            itemBuilder={MobileListItem}
            page={Math.max(0, eventsList.current ?? 0)}
            pageChange={doEventsListSetPage}
            pageSize={eventsList.pageSize}
            pageSizeChange={doEventsListSetPageSize}
            rowCount={eventsList?.count ?? 0}
            setSearch={doEventsListSetSearch}
            pageSizeOptions={[25]}
            loading={eventsListIsLoading}
            hideFooter={(eventsList?.numPages ?? 0) <= 1}
          />
        ) : (
          <List
            key={`events-${showBillingInfo}`} // force initial-render when showBillingInfo changes
            columnsAutosize
            columns={columns}
            rows={eventsList.results}
            page={Math.max(0, eventsList.current ?? 0)}
            pageChange={doEventsListSetPage}
            pageSize={eventsList.pageSize}
            pageSizeChange={doEventsListSetPageSize}
            rowCount={eventsList?.count ?? 0}
            sortChange={doEventsListSetOrdering}
            setSearch={doEventsListSetSearch}
            actionsLabels={actionsLabels}
            pageSizeOptions={[25]}
            disableColumnMenu
            autoHeight
            loading={eventsListIsLoading}
            hideFooter={(eventsList?.numPages ?? 0) <= 1}
            getDetailPanelContent={detailRowContent}
            getDetailPanelHeight={detailRowHeight}
            currentOrdering={ordering}
          />
        )}
      </Box>
    </>
  )
}
