import { useCallback, useEffect, useState } from 'react'

import { isEmpty } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { Box, CircularProgress, Grid2 } from '@mui/material'

import { Field } from 'formik'
import * as yup from 'yup'

import { TextField } from '@common/components/Form'
import { StaticSelect } from '@common/components/Selects'
import { parseApiErrors } from '@common/utils'
import ConfirmationDialog from '@portal/UI/components/ConfirmationDialog'
import FormDialog from '@portal/UI/components/FormDialog'
import FormikStatePropagator from '@portal/UI/components/FormikStatePropagator'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {string} [props.profileId]
 */
export default function SmokeProfileForm({ open, onClose, profileId = undefined }) {
  const {
    systemSmokeModelKeys,
    doSmokeProfileFetch,
    doSmokeProfileSchemaFetch,
    doSmokeProfileListBulkPreview,
    doMarkSmokeProfileListAsOutdated,
    doSmokeProfileSave,
    doFetchSystem,
    doShowSnackbar,
  } = useConnect(
    'selectSystemSmokeModelKeys',
    'doSmokeProfileFetch',
    'doSmokeProfileSchemaFetch',
    'doSmokeProfileListBulkPreview',
    'doMarkSmokeProfileListAsOutdated',
    'doSmokeProfileSave',
    'doFetchSystem',
    'doShowSnackbar',
  )

  const defaultFormikState = {
    values: {},
    setFieldValue: () => {},
    getFieldProps: () => {},
  }
  const [formikProps, setFormikProps] = useState(defaultFormikState)

  const [loading, setLoading] = useState(true)
  const [fieldsData, setFieldsData] = useState([])
  const [profile, setProfile] = useState(null)

  const [previewData, setPreviewData] = useState(null)

  const initialValues = {}

  const validationSchema = yup.object().shape({
    id: yup.string().uuid(),
    name: yup.string().max(255).required(),
    description: yup.string(),
    modelKey: yup.string().required(),
  })

  const fetchInitialData = useCallback(async () => {
    setLoading(true)
    try {
      const schemaResponse = await doSmokeProfileSchemaFetch()
      const profileResponse = profileId
        ? await doSmokeProfileFetch({ id: profileId })
        : {}

      if (schemaResponse?.error || profileResponse?.error) {
        throw schemaResponse.error || profileResponse.error
      }

      setFieldsData(schemaResponse)
      setProfile(profileResponse)
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    } finally {
      setLoading(false)
    }
  }, [profileId])

  const fetchPreviewData = useCallback(
    async (formData) => {
      try {
        const previewResult = profileId
          ? await doSmokeProfileListBulkPreview({
              ids: [profileId],
              operation: 'change',
            })
          : []
        setPreviewData({ messages: previewResult, formData })
      } catch (err) {
        const parsedError = parseApiErrors(err?.response)
        doShowSnackbar(parsedError, 'error')
      }
    },
    [profileId],
  )

  const handleProfileUpdate = useCallback(
    async (data) => {
      try {
        const result = await doSmokeProfileSave({ ...profile, ...data })
        setPreviewData(null)

        if (result.error) {
          formikProps?.setErrors(result.error?.response)
          throw result.error
        }

        onClose(true)
        doMarkSmokeProfileListAsOutdated()
        doFetchSystem()
      } catch (err) {
        const parsedError = parseApiErrors(err?.response)
        doShowSnackbar(parsedError, 'error')
      }
    },
    [profile],
  )

  const handleSaveAsNew = useCallback(
    async (data) => {
      try {
        if (initialValues.name === data.name) {
          formikProps?.setFieldError(
            'name',
            'Please use a new name to save a new profile.',
          )
          return
        }

        const result = await doSmokeProfileSave({ ...profile, ...data, id: null })
        setPreviewData(null)

        if (result.error) {
          formikProps?.setErrors(result.error?.response)
          throw result.error
        }

        onClose(true)
        doMarkSmokeProfileListAsOutdated()
        doFetchSystem()
      } catch (err) {
        const parsedError = parseApiErrors(err?.response)
        doShowSnackbar(parsedError, 'error')
      }
    },
    [profile],
  )

  useEffect(() => {
    if (open) {
      fetchInitialData()
    } else {
      setFieldsData([])
      setProfile(null)
    }
  }, [open])

  if (!isEmpty(fieldsData)) {
    fieldsData.forEach((field) => {
      initialValues[field.name] =
        profile[field.name] ?? (field.many ? [] : field.default)
    })
  }

  if (profile) {
    initialValues.id = profile.id
    initialValues.name = profile.name
    initialValues.description = profile.description
    initialValues.modelKey = profile.modelKey
  }

  const handleValueChange = (field, value) => {
    if (field.kind === 'duration') {
      let inputTime = value.replace(/[^0-9]/g, '')

      if (inputTime.length > 2 && inputTime.charAt(2) !== ':') {
        inputTime = `${inputTime.slice(0, 2)}:${inputTime.slice(2)}`
      }
      if (inputTime.length > 5 && inputTime.charAt(5) !== ':') {
        inputTime = `${inputTime.slice(0, 5)}:${inputTime.slice(5)}`
      }

      if (inputTime.length <= 8) {
        formikProps.setFieldValue(field.name, inputTime)
      }
    } else {
      formikProps.setFieldValue(field.name, value)
    }
  }

  const buildConfirmationMessage = useCallback(
    (messages) => {
      if (messages && !isEmpty(messages)) {
        return messages
          .filter((data) => data.kind !== 'error')
          .map((data) => `${data.propertyName}: ${data.message}`)
          .join('\n\n')
      }
      return profileId
        ? 'Are you sure you want to change this profile?'
        : 'Are you sure you want to create profile with such parameters?'
    },
    [profileId],
  )

  const buildFieldOptions = useCallback(
    (field) =>
      field.many
        ? field.choices.map((item) => ({
            id: item.value,
            name: item.label,
          }))
        : null,
    [],
  )

  return (
    <>
      <ConfirmationDialog
        open={!!previewData}
        title="Confirm Profile Change"
        message={buildConfirmationMessage(previewData?.messages)}
        maxWidth="xl"
        fullWidth={false}
        contentFontSize={14}
        error={previewData?.messages
          .filter((data) => data.kind === 'error')
          .map((data) => data.message)
          .join('\n\n')}
        onConfirm={() => handleProfileUpdate(previewData.formData)}
        onCancel={() => setPreviewData(null)}
      />
      <FormDialog
        label={`${profileId ? 'Edit' : 'Create'} Smoke Profile`}
        open={open}
        onSave={(data) => {
          if (profileId) {
            fetchPreviewData(data)
          } else {
            handleProfileUpdate(data)
          }
        }}
        onClose={onClose}
        initialValues={initialValues}
        validationSchema={validationSchema}
        extraActions={[
          ...(profileId
            ? [
                {
                  label: 'Save As New',
                  color: 'secondary',
                  onClick: handleSaveAsNew,
                },
              ]
            : []),
        ]}
        extraActionsPosition="right"
        maxWidth="xl"
      >
        {loading && (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
        {!loading && (
          <>
            <Box display="flex" gap={4} mb={2}>
              <Field
                fullWidth
                component={TextField}
                label="Name"
                name="name"
                variant="standard"
                error={!!formikProps.errors.name}
                helperText={formikProps.errors.name}
                focused={formikProps.values.name}
                sx={{ width: '100%' }}
              />
              {!profileId && (
                <Field
                  fullWidth
                  component={StaticSelect}
                  options={systemSmokeModelKeys
                    .filter((model) => model.active)
                    .map((model) => ({ id: model.key, label: model.key }))}
                  label="Model"
                  name="modelKey"
                  variant="standard"
                  error={formikProps.errors.modelKey}
                  helperText={formikProps.errors.modelKey}
                  focused={formikProps.values.modelKey}
                  sx={{ width: '100%' }}
                />
              )}
            </Box>
            <Field
              fullWidth
              component={TextField}
              label="Description (optional)"
              name="description"
              variant="standard"
              error={formikProps.errors.description}
              helperText={formikProps.errors.description}
              focused={formikProps.values.description}
              sx={{ width: '100%', mb: 4 }}
            />
          </>
        )}
        {!loading && (
          <Grid2 container spacing={4}>
            {fieldsData.map((field) => (
              <Grid2 size={{ xs: 3 }}>
                <Field
                  fullWidth
                  multiple={field.many}
                  disableCloseOnSelect={field.many}
                  component={field.many ? StaticSelect : TextField}
                  type={field.kind === 'float' ? 'number' : 'text'}
                  options={buildFieldOptions(field)}
                  label={field.label}
                  name={field.name}
                  value={formikProps.values[field.name] || (field.many ? [] : '')}
                  variant="standard"
                  focused={
                    formikProps.values[field.name] !== undefined &&
                    formikProps.values[field.name] !== null &&
                    formikProps.values[field.name] !== ''
                  }
                  helperText={formikProps.errors[field.name] ?? field.helpText}
                  placeholder={field.kind === 'duration' ? 'hh:mm:ss' : null}
                  onChange={(event) => {
                    if (Array.isArray(event)) {
                      handleValueChange(field, event)
                    } else {
                      const { value } = event.target
                      handleValueChange(field, value)
                    }
                  }}
                  sx={{ width: '100%' }}
                  inputProps={{
                    maxLength: field.kind === 'duration' ? 8 : null,
                  }}
                />
              </Grid2>
            ))}
          </Grid2>
        )}
        <FormikStatePropagator propSetter={setFormikProps} />
      </FormDialog>
    </>
  )
}
