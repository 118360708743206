import { path } from 'ramda'

import { isAbortError } from '@common/utils'

const RESERVATION_HELPER_OPTIONS_LOADING = 'RESERVATION_HELPER_OPTIONS_LOADING'
const RESERVATION_HELPER_OPTIONS_LOADED = 'RESERVATION_HELPER_OPTIONS_LOADED'
const RESERVATION_HELPER_OPTIONS_FAILED = 'RESERVATION_HELPER_OPTIONS_FAILED'

const RESERVATION_HELPER_UNIT_LOADING = 'RESERVATION_HELPER_UNIT_LOADING'
const RESERVATION_HELPER_UNIT_LOADED = 'RESERVATION_HELPER_UNIT_LOADED'
const RESERVATION_HELPER_UNIT_FAILED = 'RESERVATION_HELPER_UNIT_FAILED'

const RESERVATION_HELPER_PROPERTY_LOADING = 'RESERVATION_HELPER_PROPERTY_LOADING'
const RESERVATION_HELPER_PROPERTY_LOADED = 'RESERVATION_HELPER_PROPERTY_LOADED'
const RESERVATION_HELPER_PROPERTY_FAILED = 'RESERVATION_HELPER_PROPERTY_FAILED'

const RESERVATION_HELPER_RESET_STATE = 'RESERVATION_HELPER_RESET_STATE'

const defaultState = {}

const entityName = 'reservationHelper'

export default {
  name: entityName,
  reducer: (state, action) => {
    if (action.type === RESERVATION_HELPER_RESET_STATE) {
      return defaultState
    }
    if (action.type === RESERVATION_HELPER_OPTIONS_LOADING) {
      return { ...state, options: { ...action.meta, payload: action.payload } }
    }
    if (action.type === RESERVATION_HELPER_OPTIONS_LOADED) {
      return { ...state, options: { ...action.meta, data: action.payload } }
    }
    if (action.type === RESERVATION_HELPER_OPTIONS_FAILED) {
      return { ...state, options: { ...action.meta, error: action.payload } }
    }
    if (action.type === RESERVATION_HELPER_UNIT_LOADING) {
      return { ...state, unit: { ...action.meta, payload: action.payload } }
    }
    if (action.type === RESERVATION_HELPER_UNIT_LOADED) {
      return { ...state, unit: { ...action.meta, data: action.payload } }
    }
    if (action.type === RESERVATION_HELPER_UNIT_FAILED) {
      return { ...state, unit: { ...action.meta, error: action.payload } }
    }
    if (action.type === RESERVATION_HELPER_PROPERTY_LOADING) {
      return { ...state, property: { ...action.meta, payload: action.payload } }
    }
    if (action.type === RESERVATION_HELPER_PROPERTY_LOADED) {
      return { ...state, property: { ...action.meta, data: action.payload } }
    }
    if (action.type === RESERVATION_HELPER_PROPERTY_FAILED) {
      return { ...state, property: { ...action.meta, error: action.payload } }
    }
    return state || defaultState
  },
  selectReservationOptionsIsLoading: ({ reservationHelper }) => {
    const status = path(['options', 'status'], reservationHelper)
    return status === 'loading'
  },
  selectReservationUnitDataIsLoading: ({ reservationHelper }) => {
    const status = path(['unit', 'status'], reservationHelper)
    return status === 'loading'
  },
  selectReservationPropertyDataIsLoading: ({ reservationHelper }) => {
    const status = path(['property', 'status'], reservationHelper)
    return status === 'loading'
  },
  selectReservationOptions: ({ reservationHelper }) =>
    path(['options', 'data'], reservationHelper),
  selectReservationUnitData: ({ reservationHelper }) =>
    path(['unit', 'data'], reservationHelper),
  selectReservationPropertyData: ({ reservationHelper }) =>
    path(['property', 'data'], reservationHelper),
  selectReservationOptionsError: ({ reservationHelper }) =>
    path(['options', 'error'], reservationHelper),
  selectReservationUnitDataError: ({ reservationHelper }) =>
    path(['unit', 'error'], reservationHelper),
  selectReservationPropertyDataError: ({ reservationHelper }) =>
    path(['property', 'error'], reservationHelper),
  doResetReservationHelperState:
    () =>
    ({ dispatch }) =>
      dispatch({ type: RESERVATION_HELPER_RESET_STATE }),
  doFetchReservationOptions:
    (payload) =>
    async ({ dispatch, apiFetch }) => {
      try {
        dispatch({
          type: RESERVATION_HELPER_OPTIONS_LOADING,
          payload,
          meta: { status: 'loading' },
        })

        const { property } = payload ?? {}

        const propertiesResult = await apiFetch(
          `/properties/`,
          { pageSize: 9999 },
          { cancelationPrefix: entityName },
        )
        const unitsResult = await apiFetch(
          `/units/`,
          {
            pageSize: 9999,
            ...(property ? { property } : {}),
          },
          { cancelationPrefix: entityName },
        )

        const data = {
          properties: propertiesResult?.results ?? [],
          units: unitsResult?.results ?? [],
        }

        await dispatch({
          type: RESERVATION_HELPER_OPTIONS_LOADED,
          payload: data,
          meta: { status: 'succeeded' },
        })
        return data
      } catch (err) {
        if (!isAbortError(err)) {
          dispatch({
            type: RESERVATION_HELPER_OPTIONS_FAILED,
            payload: err,
            meta: { status: 'failed' },
          })
          throw err
        }
        return null
      }
    },
  doFetchReservationUnitData:
    (payload) =>
    async ({ dispatch, apiFetch }) => {
      try {
        dispatch({
          type: RESERVATION_HELPER_UNIT_LOADING,
          payload,
          meta: { status: 'loading' },
        })

        const unitResult = await apiFetch(`/units/${payload}/`, null, {
          cancelationPrefix: entityName,
        })

        await dispatch({
          type: RESERVATION_HELPER_UNIT_LOADED,
          payload: unitResult,
          meta: { status: 'succeeded' },
        })
        return unitResult
      } catch (err) {
        if (!isAbortError(err)) {
          dispatch({
            type: RESERVATION_HELPER_UNIT_FAILED,
            payload: err,
            meta: { status: 'failed' },
          })
          throw err
        }
        return null
      }
    },
  doFetchReservationPropertyData:
    (payload) =>
    async ({ dispatch, apiFetch }) => {
      try {
        dispatch({
          type: RESERVATION_HELPER_PROPERTY_LOADING,
          payload,
          meta: { status: 'loading' },
        })

        const propertyResult = await apiFetch(`/properties/${payload}/`, null, {
          cancelationPrefix: entityName,
        })

        await dispatch({
          type: RESERVATION_HELPER_PROPERTY_LOADED,
          payload: propertyResult,
          meta: { status: 'succeeded' },
        })
        return propertyResult
      } catch (err) {
        if (!isAbortError(err)) {
          dispatch({
            type: RESERVATION_HELPER_PROPERTY_FAILED,
            payload: err,
            meta: { status: 'failed' },
          })
          throw err
        }
        return null
      }
    },
}
