import { useMemo } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { BlockRounded } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

/**
 * A component that protects a route by checking if the user has one of the allowed portal roles.
 * If the `roles` prop is not provided, the page is accessible for everyone (except `INSTALLER` role).
 * If the user doesn't have a portal role all pages will not be accessible
 *
 * @param {Object} props - The component props
 * @param {React.Node} props.children - The content to render if the user has access
 * @param {string[]} [props.roles] - The allowed roles. If not provided, the page is accessible for everyone
 *
 * @example
 * <ProtectedRoute roles={['ADMIN', 'SUPERUSER']}>
 *   <RoutedComponent />
 * </ProtectedRoute>
 *
 * In the example, only users with 'ADMIN' or 'SUPERUSER' roles can access `RoutedComponent`.
 */
export default function ProtectedRoute({ children, roles }) {
  const { currentRole, systemPortalRoles } = useConnect(
    'selectCurrentRole',
    'selectSystemPortalRoles',
  )

  const allowedRoles = useMemo(
    () =>
      roles ??
      systemPortalRoles
        ?.filter((role) => role.id !== 'INSTALLER')
        ?.map((role) => role.id),
    [systemPortalRoles, roles],
  )

  if (!allowedRoles) return children

  if (!allowedRoles.includes(currentRole)) {
    return (
      <Box
        height="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={1}
      >
        <BlockRounded sx={{ fontSize: 60, color: 'error.main' }} />
        <Typography variant="h5">Oops. You dont have access to this page</Typography>
      </Box>
    )
  }

  return children
}
