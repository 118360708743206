import { createSelector } from 'redux-bundler'

import { getAsyncActionIdentifiers } from '@common/bundles/utils'
import { clearTokens, Project, setTokens } from '@common/config'
import { getApiFetch, parseApiErrors } from '@common/utils'
import cache from '@common/utils/cache'
import { homeUrls } from '@portal/pages/Home'
import installersUrls from '@portal/pages/Installers/urls'
import loginUrls from '@portal/pages/Login/urls'

export const defaultState = {
  authenticated: false,
  login: {
    status: null,
    message: null,
  },
  logout: {
    status: null,
    message: null,
  },
}

const loginActions = getAsyncActionIdentifiers('login', 'auth')
const logoutActions = getAsyncActionIdentifiers('logout', 'auth')

const authBundle = {
  name: 'auth',
  getExtraArgs: (store) => ({
    apiFetch: getApiFetch(store),
  }),
  reducer: (state, action) => {
    switch (action.type) {
      case loginActions.types.start:
        return {
          ...state,
          authenticated: false,
          login: { ...defaultState.login, status: 'started' },
        }
      case loginActions.types.failed:
        return {
          ...state,
          authenticated: false,
          login: { ...defaultState.login, status: 'failed', message: action.payload },
        }
      case loginActions.types.succeeded:
        return {
          ...state,
          authenticated: true,
          login: { ...defaultState.login, status: 'succeeded' },
        }
      case logoutActions.types.started:
        return {
          ...state,
          logout: { ...defaultState.logout, status: 'started' },
        }
      case logoutActions.types.failed:
        return {
          ...state,
          logout: { ...defaultState.logout, status: 'failed' },
        }
      case logoutActions.types.succeeded:
        return {
          ...state,
          authenticated: false,
          logout: { ...defaultState.logout, status: 'succeeded' },
        }
      default:
        return state || defaultState
    }
  },
  selectAuth: (state) => state.auth,
  selectIsAuthenticated: createSelector('selectAuth', (auth) => auth.authenticated),
  doAuthLoginWithTokens:
    (payload) =>
    async ({ store, dispatch }) => {
      setTokens(payload)
      dispatch({ type: loginActions.types.succeeded })
      store.doMarkMeAsOutdated()
      store.doMarkSystemAsOutdated()
    },
  [loginActions.actionName]:
    (payload) =>
    async ({ apiFetch, store, dispatch }) => {
      const { types } = loginActions

      dispatch({ type: types.started })

      try {
        const auth = await apiFetch('/token/', payload, {
          method: 'POST',
          useAuth: false,
        })
        setTokens(auth)
        dispatch({ type: types.succeeded })
        store.doMarkMeAsOutdated()
        store.doMarkSystemAsOutdated()
      } catch (error) {
        dispatch({ type: types.failed, payload: parseApiErrors(error?.response) })
      }
    },
  [logoutActions.actionName]:
    () =>
    async ({ dispatch }) => {
      const { types } = logoutActions

      dispatch({ type: types.started })

      let success
      try {
        clearTokens()
        dispatch({ type: types.succeeded })
        await cache.clear()
        success = true
      } catch (error) {
        success = false
        dispatch({ type: types.failed, error })
      }

      return success
    },
  reactRedirectToHome: createSelector(
    'selectRouteInfo',
    'selectIsAuthenticated',
    'selectCurrentRole',
    ({ url }, authenticated, currentRole) => {
      if (!currentRole) return undefined
      if (url.startsWith(loginUrls.login) && authenticated) {
        return {
          actionCreator: 'doUpdateUrl',
          args: [currentRole === 'INSTALLER' ? installersUrls.list : homeUrls.home],
        }
      }
      if (currentRole === 'INSTALLER' && !url.startsWith(installersUrls.list)) {
        return { actionCreator: 'doUpdateUrl', args: [installersUrls.list] }
      }
      if (Project.isPortal && !url.startsWith(homeUrls.home)) {
        Project.switch(url)
        return undefined
      }
      return undefined
    },
  ),
  reactRedirectToLogin: createSelector(
    'selectRoute',
    'selectRouteInfo',
    'selectIsAuthenticated',
    (route, { url }, authenticated) => {
      if (!route.publicAccess && !authenticated && url.startsWith(homeUrls.home)) {
        globalThis.location.replace(loginUrls.login)
      }
      return undefined
    },
  ),
}

export default authBundle
