import { useCallback, useEffect, useMemo, useState } from 'react'

import { isEmpty } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { WarningAmberRounded, WarningRounded } from '@mui/icons-material'
import { Alert, Box, Button, Divider, Stack, Tooltip, Typography } from '@mui/material'

import { DateTime } from 'luxon'

import { useSmallScreen } from '@common/utils'

import ContractDetailModal from '../Contracts/ContractDetailModal'
import { CONTRACTS_TAB_HASH_PARAMS } from '../Contracts/Contracts'
import { PROPERTY_TAB_HASH } from './Property'

export default function ActiveContractsBlock() {
  const isSmallScreen = useSmallScreen()
  const {
    propertyActiveContracts,
    propertyActiveContractsError,
    propertyActiveContractsIsLoading,
    doFetchPropertyActiveContracts,
    doUpdateHash,
  } = useConnect(
    'selectPropertyActiveContracts',
    'selectPropertyActiveContractsError',
    'selectPropertyActiveContractsIsLoading',
    'doFetchPropertyActiveContracts',
    'doUpdateHash',
  )

  const [openDetailModal, setOpenDetailModal] = useState(false)

  const fetchActiveContracts = useCallback(async () => {
    try {
      await doFetchPropertyActiveContracts()
    } catch (err) {
      // no need to show snackbar or smth. We use `propertyActiveContractsError` to handle error
    }
  }, [])

  useEffect(() => {
    fetchActiveContracts()
  }, [])

  const contractPeriod = useMemo(() => {
    if (propertyActiveContracts?.length === 1) {
      const contract = propertyActiveContracts[0]
      const format = 'MMM yyyy'
      const parsedStart = DateTime.fromISO(contract.startDate)
      const parsedEnd = DateTime.fromISO(contract.endDate)
      return `${parsedStart.toFormat(format)} - ${parsedEnd.toFormat(format)}`
    }
    return null
  }, [propertyActiveContracts])

  const ErrorView = useCallback(
    () => (
      <Alert
        severity="error"
        variant="outlined"
        action={
          <Button
            variant="outlined"
            color="error"
            size="small"
            sx={{ fontSize: 10 }}
            onClick={fetchActiveContracts}
          >
            Retry
          </Button>
        }
        sx={{ mt: 1 }}
      >
        <Typography fontSize={12} sx={{ userSelect: 'none' }}>
          Something went wrong
        </Typography>
      </Alert>
    ),
    [],
  )

  const OverlappingView = useCallback(
    () => (
      <Box
        display="flex"
        gap={1}
        py={1}
        sx={{ cursor: 'pointer' }}
        onClick={() => doUpdateHash({ [PROPERTY_TAB_HASH]: CONTRACTS_TAB_HASH_PARAMS })}
      >
        <WarningRounded color="warning" />
        <Box>
          <Typography fontSize={14} sx={{ userSelect: 'none' }}>
            Overlapping contracts!
          </Typography>
          <Typography
            variant="caption"
            sx={{ userSelect: 'none', textDecoration: 'underline' }}
          >
            Check Contracts tab for more details
          </Typography>
        </Box>
      </Box>
    ),
    [],
  )

  const ContractView = useCallback(() => {
    const contract = propertyActiveContracts[0]
    const terms =
      contract.terms.length > 2
        ? [...contract.terms.slice(0, 2), `...`].join(', ')
        : contract.terms.join(', ')

    let validationErrors
    if (contract.errors && !isEmpty(contract.errors)) {
      validationErrors =
        typeof contract.errors === 'string' ? [contract.errors] : contract.errors
    }

    return (
      <Stack direction="column">
        <Box height="19.9px" />
        <Tooltip
          enterDelay={700}
          enterNextDelay={700}
          title={
            <div style={{ whiteSpace: 'pre-line' }}>
              {validationErrors
                ? validationErrors.join('\n')
                : contract.terms.join('\n')}
            </div>
          }
        >
          <Box display="flex" alignItems="center" gap={0.5}>
            {validationErrors && (
              <WarningAmberRounded color="warning" sx={{ fontSize: 20 }} />
            )}
            <Typography
              variant="body1"
              fontSize={isSmallScreen ? 14 : 16}
              onClick={() => setOpenDetailModal(true)}
              sx={{
                textWrap: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textDecoration: 'underline',
                cursor: 'pointer',
                '&:hover': {
                  color: 'primary.main',
                },
              }}
            >
              {validationErrors ? 'Invalid!' : terms}
            </Typography>
          </Box>
        </Tooltip>
      </Stack>
    )
  }, [propertyActiveContracts])

  const showError = !propertyActiveContractsIsLoading && propertyActiveContractsError
  const showData = !propertyActiveContractsIsLoading && !propertyActiveContractsError

  if (propertyActiveContractsIsLoading || propertyActiveContracts?.length < 1)
    return null

  return (
    <>
      <ContractDetailModal
        open={openDetailModal}
        onClose={() => setOpenDetailModal(false)}
        contract={propertyActiveContracts?.[0]}
      />
      <Stack
        sx={{
          mt: 1,
          pt: 0.4,
          maxWidth: 540,
          overflow: 'hidden',
        }}
      >
        <Stack sx={{ width: 1 }}>
          <Typography
            variant="caption"
            color="grey.500"
            fontWeight={600}
            fontSize={isSmallScreen ? 10 : 12}
          >
            Active Contract {contractPeriod ? `(${contractPeriod})` : ''}
          </Typography>
          <Divider />
          {showError && <ErrorView />}
          {showData && propertyActiveContracts?.length === 1 && <ContractView />}
          {showData && propertyActiveContracts?.length > 1 && <OverlappingView />}
        </Stack>
      </Stack>
    </>
  )
}
