import { AUTOMOTIVE_LAYOUT_KEY, DEFAULT_LAYOUT_KEY } from '@common/constants'

// eslint-disable-next-line import/prefer-default-export
export const MIGRATIONS = [
  {
    id: 1,
    description: 'update structure for some grafana view related stuff',
    operation: async () => {
      // layout data migration
      const initialLayoutData = localStorage.getItem('portalGrafanaLayout')
      if (!initialLayoutData) return
      const parsedInitialLayoutData = JSON.parse(initialLayoutData)
      if (Array.isArray(parsedInitialLayoutData)) {
        const updatedLayoutData = {
          [DEFAULT_LAYOUT_KEY]: parsedInitialLayoutData,
          [AUTOMOTIVE_LAYOUT_KEY]: [],
        }
        localStorage.setItem('portalGrafanaLayout', JSON.stringify(updatedLayoutData))
      }

      // cards visibility migration
      const initialCardsVisibilityData = localStorage.getItem(
        'portalGrafanaCardsVisibility',
      )
      if (!initialCardsVisibilityData) return
      const parsedInitialCardsVisibilityData = JSON.parse(initialCardsVisibilityData)
      if (
        !parsedInitialCardsVisibilityData[DEFAULT_LAYOUT_KEY] &&
        !parsedInitialCardsVisibilityData[AUTOMOTIVE_LAYOUT_KEY]
      ) {
        const updatedVisibilityData = {
          [DEFAULT_LAYOUT_KEY]: parsedInitialCardsVisibilityData,
          [AUTOMOTIVE_LAYOUT_KEY]: {},
        }
        localStorage.setItem(
          'portalGrafanaCardsVisibility',
          JSON.stringify(updatedVisibilityData),
        )
      }

      // portal device data browser data visibility migration
      const initialDataBrowserVisibleData = localStorage.getItem('visibleData')
      if (!initialDataBrowserVisibleData) return
      const parsedInitialDataBrowserVisibleData = JSON.parse(
        initialDataBrowserVisibleData,
      )
      const updatedDataBrowserVisibleData = {
        [DEFAULT_LAYOUT_KEY]: parsedInitialDataBrowserVisibleData,
        [AUTOMOTIVE_LAYOUT_KEY]: {},
      }
      localStorage.setItem(
        'portalDeviceDataBrowserVisibleData',
        JSON.stringify(updatedDataBrowserVisibleData),
      )
      localStorage.removeItem('visibleData')
    },
  },
]
