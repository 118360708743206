/* eslint-disable import/prefer-default-export */
import convert from 'color-convert'

function generateColors({
  count = 30,
  saturationMultiplier = 50,
  brightnessMultiplier = 30,
} = {}) {
  const generatedColors = []
  const hueIncrement = 360 / 30
  let currentHue = 0

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < count; i++) {
    const randomSaturation = 50 + Math.random() * saturationMultiplier
    const randomBrightness = 50 + Math.random() * brightnessMultiplier
    const color = convert.hsl.hex([currentHue, randomSaturation, randomBrightness])
    generatedColors.push(`#${color}`)
    currentHue += hueIncrement
  }

  return generatedColors
}

export { generateColors }
