import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import * as Sentry from '@sentry/react'
import ms from 'milliseconds'

const entityName = 'me'

const meBundle = createAsyncResourceBundle({
  name: entityName,
  retryAfter: ms.seconds(10),
  persist: true,
  getPromise: async ({ apiFetch }) =>
    apiFetch('/me/', null, { useOrgHeader: false, cancelationPrefix: entityName }),
})

export default {
  ...meBundle,
  reducer: (state, action) => {
    if (action.type === 'ME_FETCH_FAILED') {
      if (action?.error?.error?.status === 401) {
        return {
          ...state,
          unauthorized: true,
        }
      }
    }
    return meBundle.reducer(state, action)
  },
  reactMeFetch: createSelector(
    'selectIsAuthenticated',
    'selectMeShouldUpdate',
    (authenticated, shouldUpdate) => {
      if (authenticated && shouldUpdate) {
        return { actionCreator: 'doFetchMe' }
      }
      return undefined
    },
  ),
  reactSetSentry: createSelector('selectMe', (me) => {
    if (me) {
      Sentry.setUser({ email: me.email, id: me.id, username: me.name })
    } else {
      Sentry.setUser(null)
    }
    return undefined
  }),
  reactUnauthorized: createSelector('selectMeRaw', (meRaw) => {
    if (meRaw?.unauthorized) {
      return { actionCreator: 'doAuthLogout' }
    }
    return undefined
  }),
  selectCurrentRole: createSelector('selectMe', (me) => me?.portalRole),
  selectIsStaff: createSelector(
    'selectCurrentRole',
    'selectSystemPortalRoles',
    (currentRole, systemPortalRoles) =>
      systemPortalRoles?.map((role) => role.id).includes(currentRole),
  ),
  selectIsSuperuser: createSelector(
    'selectCurrentRole',
    (currentRole) => currentRole === 'SUPERUSER',
  ),
  selectIsAtLeastAdmin: createSelector('selectCurrentRole', (currentRole) =>
    ['SUPERUSER', 'ADMIN'].includes(currentRole),
  ),
  selectIsAtLeastFinance: createSelector('selectCurrentRole', (currentRole) =>
    ['SUPERUSER', 'ADMIN', 'FINANCE'].includes(currentRole),
  ),
  selectIsAtLeastDataScience: createSelector('selectCurrentRole', (currentRole) =>
    ['SUPERUSER', 'ADMIN', 'DATA_SCIENCE'].includes(currentRole),
  ),
}
