import { useMemo, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.loading
 * @param {Object} props.visibleSmokeModels
 * @param {Function} props.onSave
 */
export default function SmokeModelsSelector({ loading, visibleSmokeModels, onSave }) {
  const { systemSmokeModelKeys } = useConnect('selectSystemSmokeModelKeys')

  const [selectedSmokeModels, setSelectedSmokeModels] = useState(visibleSmokeModels)

  const smokeModelsOptions = useMemo(
    () =>
      systemSmokeModelKeys
        .filter((model) => model.active)
        .map((model) => ({ id: model.key, label: model.key })),
    [systemSmokeModelKeys],
  )

  const onReset = () => {
    setSelectedSmokeModels(
      smokeModelsOptions.reduce((acc, model) => {
        acc[model.id] = visibleSmokeModels[model.id]
        return acc
      }, {}),
    )
  }

  const isDirty = useMemo(
    () =>
      Object.keys(selectedSmokeModels).some(
        (key) => selectedSmokeModels[key] !== visibleSmokeModels[key],
      ),
    [selectedSmokeModels, visibleSmokeModels],
  )

  const actionButtonStyle = {
    size: 'small',
    variant: 'outlined',
    sx: { fontSize: 10, maxHeight: 22 },
  }

  return (
    <>
      <Box display="flex" alignItems="center">
        <Typography variant="h6" px={2} sx={{ fontWeight: 'bold' }}>
          Smoke Models
        </Typography>
        {isDirty && (
          <Box display="flex" gap={1}>
            <Button
              {...actionButtonStyle}
              color="success"
              onClick={() => onSave(selectedSmokeModels)}
            >
              Apply
            </Button>
            <Button {...actionButtonStyle} color="error" onClick={onReset}>
              Reset
            </Button>
          </Box>
        )}
      </Box>
      <Stack direction="row" gap={1} pl={2}>
        {smokeModelsOptions.map((model) => (
          <FormControlLabel
            key={model.id}
            label={model.label.replace(/moneymaker/gi, 'MM')}
            labelPlacement="end"
            slotProps={{ typography: { fontSize: 12 } }}
            disabled={loading}
            control={
              <Checkbox
                checked={selectedSmokeModels[model.id]}
                size="small"
                onChange={() => {
                  setSelectedSmokeModels((prev) => ({
                    ...prev,
                    [model.id]: !prev[model.id],
                  }))
                }}
              />
            }
          />
        ))}
      </Stack>
    </>
  )
}
