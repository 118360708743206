import { useEffect, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { Box } from '@mui/material'

import { Field } from 'formik'
import * as yup from 'yup'

import { TextField } from '@common/components/Form'
import { FormikDynamicSelect, FreeSoloDynamicSelect } from '@common/components/Selects'
import { parseApiErrors } from '@common/utils'
import FormDialog from '@portal/UI/components/FormDialog'
import FormikStatePropagator from '@portal/UI/components/FormikStatePropagator'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onClose
 */
export default function InstallerForm({ open, onClose }) {
  const { doAddInstaller, doShowSnackbar } = useConnect(
    'doAddInstaller',
    'doShowSnackbar',
  )

  const [formikProps, setFormikProps] = useState({
    values: {},
    errors: {},
    setValues: () => {},
    setFieldValue: () => {},
  })

  const validationSchema = yup.object().shape({
    email: yup.string().email().required(),
    name: yup.string().when('freeSoloEmail', {
      is: (val) => val !== '' && val !== undefined,
      then: (field) => field.required(),
      otherwise: (field) => field.nullable(),
    }),
    property: yup.string().uuid().required(),
  })

  const initialValues = {
    email: '',
    name: '',
    property: '',
  }

  const onSave = async (params) => {
    try {
      const saveResult = await doAddInstaller(params)
      return saveResult
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
      throw err
    }
  }

  useEffect(() => {
    if (formikProps.values.existingEmail) {
      formikProps.setFieldValue('name', '')
    }
    formikProps.setFieldValue(
      'email',
      formikProps.values.existingEmail || formikProps.values.freeSoloEmail,
    )
  }, [formikProps.values.existingEmail, formikProps.values.freeSoloEmail])

  return (
    <FormDialog
      label="Add Installer"
      open={open}
      onSave={onSave}
      onClose={onClose}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <Field
          autoSelect
          name="existingEmail"
          altField="freeSoloEmail"
          label="Email"
          endpoint="users"
          component={FreeSoloDynamicSelect}
          valueProp="email"
          inputValueProp="email"
          primaryTextAttr="email"
          secondaryTextAttr="name"
          error={formikProps.errors.email}
        />
        <Field
          sx={{ width: 1 }}
          disabled={!!formikProps.values.existingEmail}
          component={TextField}
          label="Name"
          name="name"
        />
        <Field
          label="Property"
          name="property"
          endpoint="properties"
          variant="standard"
          component={FormikDynamicSelect}
          secondaryTextAttr="organizationName"
        />
      </Box>
      <FormikStatePropagator propSetter={setFormikProps} />
    </FormDialog>
  )
}
