import { useCallback, useEffect, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { Box, Typography } from '@mui/material'

import {
  Breadcrumbs,
  ClickableCell,
  ErrorComponent,
  MobileList,
  MobileListDefaultCard,
} from '@common/components'
import { DynamicSelect } from '@common/components/Selects'
import { parseApiErrors, useSmallScreen } from '@common/utils'
import { homeUrls } from '@portal/pages/Home'
import propertyUrls from '@portal/pages/Properties/urls'
import ConfirmationDialog from '@portal/UI/components/ConfirmationDialog'
import List from '@portal/UI/components/List'
import ListPageTitle from '@portal/UI/components/ListPageTitle'

import InstallerForm from './InstallerForm'

export default function Installers() {
  const isSmallScreen = useSmallScreen()

  const {
    currentRole,
    installerList,
    installerListIsLoading,
    installerListApiParams,
    doInstallerListSetPageSize,
    doInstallerListSetSearch,
    doInstallerListSetOrdering,
    doInstallerListSetPage,
    doInstallerListSetFilter,
    doInstallerListClearParams,
    doMarkInstallerListAsOutdated,
    doDeleteInstaller,
    doShowSnackbar,
  } = useConnect(
    'selectCurrentRole',
    'selectInstallerList',
    'selectInstallerListIsLoading',
    'selectInstallerListApiParams',
    'doInstallerListSetPageSize',
    'doInstallerListSetSearch',
    'doInstallerListSetOrdering',
    'doInstallerListSetPage',
    'doInstallerListSetFilter',
    'doInstallerListClearParams',
    'doMarkInstallerListAsOutdated',
    'doDeleteInstaller',
    'doShowSnackbar',
  )

  const [installerFormOpen, setInstallerFormOpen] = useState(false)
  const [removeInstallerData, setRemoveInstallerData] = useState(null)

  useEffect(() => {
    doMarkInstallerListAsOutdated()
  }, [])

  const handleClear = () => {
    doInstallerListClearParams()
  }

  const handleInstallerRemove = async ({ id, email }) => {
    try {
      await doDeleteInstaller({ id })

      doShowSnackbar(`${email} successfully removed`)
      doMarkInstallerListAsOutdated()
      setRemoveInstallerData(null)
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }

  if (!installerList.results) {
    return <ErrorComponent title="Installers" callback={handleClear} />
  }

  const propertyOption = useCallback(
    (option) => (
      <Box>
        <Typography variant="h6">{option.name}</Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          textTransform="uppercase"
          fontSize="0.7rem"
        >
          {option.organizationName}
        </Typography>
      </Box>
    ),
    [],
  )

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      sortField: 'user__name',
      flex: 0.5,
    },
    {
      field: 'email',
      headerName: 'E-mail',
      sortField: 'user__email',
      flex: 0.5,
    },
    {
      field: 'propertyName',
      headerName: 'Property',
      sortField: 'property__name',
      renderCell:
        currentRole === 'INSTALLER'
          ? undefined
          : ({ row }) => (
              <ClickableCell
                label={row.propertyName}
                url={propertyUrls.entity.replace(':id', row.property)}
              />
            ),
      flex: 0.5,
    },
  ]

  const DeleteDialogContent = useCallback(() => {
    if (removeInstallerData) {
      return (
        <Box component="span">
          Are you sure you want to remove{' '}
          <Box fontWeight="bold" display="inline" component="span">
            {`${removeInstallerData.name} (${removeInstallerData.email})`}
          </Box>{' '}
          from{' '}
          <Box fontWeight="bold" display="inline" component="span">
            {removeInstallerData.propertyName}
          </Box>
          ?
        </Box>
      )
    }
    return ''
  }, [removeInstallerData])

  const MobileListItem = useCallback(
    (row) =>
      MobileListDefaultCard({
        row,
        columns,
      }),
    [columns],
  )

  const propertySelectorProps = {
    forceActiveFilter: true,
    useValueInRequest: false,
    label: 'Property',
    endpoint: 'properties',
    value: installerListApiParams.property,
    onChange: (property) => {
      doInstallerListSetFilter({ ...installerListApiParams, property })
    },
    disabled: installerListIsLoading,
    optionRender: propertyOption,
    variant: 'outlined',
    size: 'small',
    sx: { minWidth: 300 },
  }

  const listActions = {
    create: () => setInstallerFormOpen(true),
    delete: (_, installer) => setRemoveInstallerData(installer),
  }

  const listActionsPermission = { default: true }

  return (
    <>
      <InstallerForm
        open={installerFormOpen}
        onClose={(success) => {
          if (success === true) {
            doMarkInstallerListAsOutdated()
          }
          setInstallerFormOpen(false)
        }}
      />
      <ConfirmationDialog
        title="Remove Installer"
        message={<DeleteDialogContent />}
        open={!!removeInstallerData}
        onConfirm={() => handleInstallerRemove(removeInstallerData)}
        onCancel={() => setRemoveInstallerData(null)}
      />

      <Box p={3} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Breadcrumbs
          links={[
            {
              label: 'Home',
              href: currentRole === 'INSTALLER' ? undefined : homeUrls.home,
            },
            { label: 'Installers' },
          ]}
        />
        <ListPageTitle title="Installers" mb={2} />
        <Box display="flex">
          <Box flex={1} overflow="hidden" minHeight="900px">
            {isSmallScreen ? (
              <>
                <DynamicSelect
                  {...propertySelectorProps}
                  sx={{ ...propertySelectorProps.sx, mb: 1, mt: 0.8 }}
                />
                <MobileList
                  title="Installers"
                  loading={installerListIsLoading}
                  actions={listActions}
                  actionsPermission={listActionsPermission}
                  itemBuilder={MobileListItem}
                  page={installerList?.current || 1}
                  pageChange={doInstallerListSetPage}
                  pageSize={installerList?.pageSize}
                  pageSizeChange={doInstallerListSetPageSize}
                  rows={installerList?.results ?? []}
                  rowCount={installerList?.count || 0}
                  setSearch={doInstallerListSetSearch}
                />
              </>
            ) : (
              <List
                columnsAutosize
                title="Installers"
                loading={installerListIsLoading}
                actions={listActions}
                actionsPermission={listActionsPermission}
                columns={columns}
                page={installerList?.current || 1}
                pageChange={doInstallerListSetPage}
                pageSize={installerList?.pageSize}
                pageSizeChange={doInstallerListSetPageSize}
                rows={installerList?.results ?? []}
                rowCount={installerList?.count || 0}
                setSearch={doInstallerListSetSearch}
                sortChange={doInstallerListSetOrdering}
                extraHeaderContent={<DynamicSelect {...propertySelectorProps} />}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}
