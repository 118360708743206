import { AUTOMOTIVE_LAYOUT_KEY, DEFAULT_LAYOUT_KEY } from '@common/constants'
import { generateColors } from '@common/utils'
import {
  CATEGORY_AUTOMOTIVE_ENVIRONMENT,
  CATEGORY_AUTOMOTIVE_GASES,
  CATEGORY_AUTOMOTIVE_INDEXES,
  CATEGORY_AUTOMOTIVE_MOX,
  CATEGORY_AUTOMOTIVE_NC,
  CATEGORY_AUTOMOTIVE_PM,
  CATEGORY_AUTOMOTIVE_RAW,
  CATEGORY_CO2_TVOC,
  CATEGORY_ENVIRONMENT,
  CATEGORY_PM,
} from '@common/utils/grafanaViewDataProcessor'

export const COLOR_7F66B3 = '#7F66B3'
export const COLOR_669CC3 = '#669CC3'
export const COLOR_CF8473 = '#CF8473'
export const COLOR_344B78 = '#344B78'
export const COLOR_547E7C = '#547E7C'
export const COLOR_C55A86 = '#C55A86'
export const COLOR_F5B961 = '#F5B961'
export const COLOR_B8B8B8 = '#B8B8B8'
export const COLOR_EC304A = '#EC304A'

export const RESERVATION_COLOR = '#4A6FB5'

export const colorsPool = [
  COLOR_7F66B3,
  COLOR_669CC3,
  COLOR_CF8473,
  COLOR_344B78,
  COLOR_547E7C,
  COLOR_C55A86,
  COLOR_F5B961,
  COLOR_B8B8B8,
  COLOR_EC304A,
  ...generateColors(),
]

function generateAutomotiveTypesMeta() {
  const initialData = {
    // PM
    mc_1p0_mean: {
      color: colorsPool[0],
      category: CATEGORY_AUTOMOTIVE_PM,
    },
    mc_2p5_mean: {
      color: colorsPool[1],
      category: CATEGORY_AUTOMOTIVE_PM,
    },
    mc_4p0_mean: {
      color: colorsPool[2],
      category: CATEGORY_AUTOMOTIVE_PM,
    },
    mc_10p0_mean: {
      color: colorsPool[3],
      category: CATEGORY_AUTOMOTIVE_PM,
    },
    // GASES
    etoh: {
      color: colorsPool[0],
      category: CATEGORY_AUTOMOTIVE_GASES,
    },
    eco2_mean: {
      color: colorsPool[1],
      category: CATEGORY_AUTOMOTIVE_GASES,
    },
    co2: {
      color: colorsPool[2],
      category: CATEGORY_AUTOMOTIVE_GASES,
    },
    tvoc_mean: {
      color: colorsPool[3],
      category: CATEGORY_AUTOMOTIVE_GASES,
    },
    // INDEXES
    voc_mean: {
      color: colorsPool[0],
      category: CATEGORY_AUTOMOTIVE_INDEXES,
    },
    nox_mean: {
      color: colorsPool[1],
      category: CATEGORY_AUTOMOTIVE_INDEXES,
    },
    zmod_iaq: {
      color: colorsPool[2],
      category: CATEGORY_AUTOMOTIVE_INDEXES,
    },
    zmod_rel_iaq: {
      color: colorsPool[3],
      category: CATEGORY_AUTOMOTIVE_INDEXES,
    },
    // ENV
    temp_mean: {
      color: colorsPool[0],
      category: CATEGORY_AUTOMOTIVE_ENVIRONMENT,
    },
    hum_mean: {
      color: colorsPool[1],
      category: CATEGORY_AUTOMOTIVE_ENVIRONMENT,
    },
    pressure: {
      color: colorsPool[2],
      category: CATEGORY_AUTOMOTIVE_ENVIRONMENT,
    },
    // altitude: {
    //   color: colorsPool[3],
    //   category: CATEGORY_AUTOMOTIVE_ENVIRONMENT,
    // },
    rhtr: {
      color: colorsPool[4],
      category: CATEGORY_AUTOMOTIVE_ENVIRONMENT,
    },
    log_rcda: {
      color: colorsPool[5],
      category: CATEGORY_AUTOMOTIVE_ENVIRONMENT,
    },
    // NC
    nc_0p5_mean: {
      color: colorsPool[0],
      category: CATEGORY_AUTOMOTIVE_NC,
    },
    nc_1p0_mean: {
      color: colorsPool[1],
      category: CATEGORY_AUTOMOTIVE_NC,
    },
    nc_2p5_mean: {
      color: colorsPool[2],
      category: CATEGORY_AUTOMOTIVE_NC,
    },
    nc_4p0_mean: {
      color: colorsPool[3],
      category: CATEGORY_AUTOMOTIVE_NC,
    },
    nc_10p0_mean: {
      color: colorsPool[4],
      category: CATEGORY_AUTOMOTIVE_NC,
    },
    // RAW
    sen_voc_raw_mean: {
      color: colorsPool[0],
      category: CATEGORY_AUTOMOTIVE_RAW,
    },
    sen_nox_raw_mean: {
      color: colorsPool[1],
      category: CATEGORY_AUTOMOTIVE_RAW,
    },
    raw_co2: {
      color: colorsPool[2],
      category: CATEGORY_AUTOMOTIVE_RAW,
    },
    // MOX
    ...Array.from(Array(13)).reduce(
      (acc, _, index) => ({
        ...acc,
        [`mox${index}`]: {
          color: colorsPool[index],
          category: CATEGORY_AUTOMOTIVE_MOX,
        },
      }),
      {},
    ),
  }

  return Object.entries(initialData).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: { ...value, key, systemName: key, tooltipLabel: key, visible: true },
    }),
    {},
  )
}

function generateDefaultTypesMeta() {
  const initialData = {
    // PM
    mc_1p0_mean: {
      tooltipLabel: 'PM 0.3-1.0',
      color: colorsPool[0],
      category: CATEGORY_PM,
    },
    mc_2p5_mean: {
      tooltipLabel: 'PM 1.0-2.5',
      color: colorsPool[1],
      category: CATEGORY_PM,
    },
    mc_4p0_mean: {
      tooltipLabel: 'PM 2.5-4.0',
      color: colorsPool[2],
      category: CATEGORY_PM,
    },
    mc_10p0_mean: {
      tooltipLabel: 'PM 4.0-10.0',
      color: colorsPool[3],
      category: CATEGORY_PM,
    },
    tpm_mean: {
      tooltipLabel: 'particle size',
      color: COLOR_EC304A,
      category: CATEGORY_PM,
      unit: 'particle size',
    },
    // ENV
    temp: {
      systemName: 'temp_mean',
      color: colorsPool[0],
      category: CATEGORY_ENVIRONMENT,
    },
    rel_hum: {
      systemName: 'hum_mean',
      color: colorsPool[1],
      category: CATEGORY_ENVIRONMENT,
    },
    abs_hum: {
      systemName: '-',
      color: colorsPool[2],
      category: CATEGORY_ENVIRONMENT,
      unit: 'g/m³',
      template: '{{ value }} g/m³',
    },
    // CO2/TVOC
    tvoc_mean: {
      tooltipLabel: 'tvoc',
      color: colorsPool[0],
      category: CATEGORY_CO2_TVOC,
    },
    eco2_mean: {
      tooltipLabel: 'co2',
      color: colorsPool[1],
      category: CATEGORY_CO2_TVOC,
    },
  }

  return Object.entries(initialData).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: {
        ...value,
        key,
        systemName: value.systemName ?? key,
        tooltipLabel: value.tooltipLabel ?? key,
        visible: true,
      },
    }),
    {},
  )
}

export function getEventTypesMeta(
  systemDataTypes,
  { layout = DEFAULT_LAYOUT_KEY } = {},
) {
  const typesMeta =
    layout === AUTOMOTIVE_LAYOUT_KEY
      ? generateAutomotiveTypesMeta()
      : generateDefaultTypesMeta()
  return Object.fromEntries(
    Object.entries(typesMeta).map(([k, v]) => {
      const systemDataType = systemDataTypes.find((type) =>
        type.key.startsWith(v.systemName),
      )
      let { template, unit } = v
      if (systemDataType) {
        template ??= systemDataType.template
        unit ??= systemDataType.template.replace('{{ value }}', '').trim()
        if (unit.startsWith('/')) {
          unit = `#${unit}`
        }
      }
      return [k, { ...v, unit: unit ?? '', template: template ?? '{{ value }}' }]
    }),
  )
}
