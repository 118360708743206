import { formatErrors } from '@common/components/Form/utils'

import DynamicSelect from './DynamicSelect'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.field
 * @param {string} [props.field.name]
 * @param {Object} props.form
 * @param {boolean} props.form.dirty
 * @param {Object} props.form.values
 * @param {Function} props.form.getFieldMeta
 * @param {Function} props.form.setFieldValue
 * @param {string} props.label
 * @param {string} props.endpoint
 * @param {boolean} [props.multiple]
 * @param {Function} [props.onChange]
 * @param {string} [props.primaryTextAttr]
 * @param {string} [props.secondaryTextAttr]
 * @param {Function} [props.primaryTextFormatter]
 * @param {Function} [props.secondaryTextFormatter]
 */
export default function FormikDynamicSelect({
  field,
  form,
  label,
  endpoint,
  multiple = false,
  onChange = () => {},
  primaryTextAttr = 'name',
  secondaryTextAttr = undefined,
  primaryTextFormatter,
  secondaryTextFormatter,
  ...rest
}) {
  const meta = form.getFieldMeta(field?.name)
  const [hasFormikError, formikErrorText] = formatErrors({ field, form })
  const showError = form?.dirty && meta?.touched && hasFormikError

  return (
    <DynamicSelect
      multiple={multiple}
      label={label}
      endpoint={endpoint}
      primaryTextAttr={primaryTextAttr}
      secondaryTextAttr={secondaryTextAttr}
      secondaryTextFormatter={secondaryTextFormatter}
      primaryTextFormatter={primaryTextFormatter}
      variant="standard"
      onChange={(acc) => {
        form.setFieldValue(field?.name, acc || '')
        onChange(acc)
      }}
      value={form?.values?.[field?.name]}
      showError={showError}
      error={formikErrorText}
      {...rest}
    />
  )
}
