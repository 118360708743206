import { useCallback, useEffect, useMemo, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { FileDownload, SpeakerPhone, SsidChartRounded } from '@mui/icons-material'
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'

import { captureException } from '@sentry/react'
import { DateTime } from 'luxon'

import { MobileList, MobileListCardRow, SearchBox } from '@common/components'
import { getValueFromColumnDef } from '@common/components/MobileList'
import {
  downloadFile,
  humanizedAgo,
  useQueryFilter,
  useSmallScreen,
} from '@common/utils'
import { List } from '@rest/UI/components'
import { StackedListCell } from '@rest/UI/components/cells'
import { formatCSVData } from '@rest/Utils/csv'

import DeviceDataModal from './DeviceDataModal/DeviceDataModal'

function PropertyDevicesEmptyState() {
  const {
    propertyDeviceListRaw: { filter, search },
  } = useConnect('selectPropertyDeviceListRaw')

  const activeFilters = search !== '' || filter?.status

  return (
    <Stack
      sx={{ mt: 5 }}
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <SpeakerPhone sx={{ fontSize: 60, mb: 2 }} />
      {activeFilters
        ? 'No sensors match the selected filters'
        : 'No sensors have been assigned to this property yet.'}
    </Stack>
  )
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.property
 * @param {string} props.property.id
 * @param {string} props.property.name
 * @param {string[]} props.property.expandedFlags
 */
export default function PropertyDevices({ property }) {
  const {
    queryObject,
    propertyDeviceList,
    propertyDeviceListIsLoading,
    propertyDeviceListApiParams,
    deviceFilterStatuses,
    doPropertyDeviceListSetPage,
    doPropertyDeviceListSetPageSize,
    doPropertyDeviceListSetOrdering,
    doPropertyDeviceListSetFilter,
    doPropertyDeviceListSetSearch,
    doPropertyDeviceListExport,
    doUpdateQuery,
  } = useConnect(
    'selectQueryObject',
    'selectPropertyDeviceList',
    'selectPropertyDeviceListIsLoading',
    'selectPropertyDeviceListApiParams',
    'selectDeviceFilterStatuses',
    'doPropertyDeviceListSetPage',
    'doPropertyDeviceListSetPageSize',
    'doPropertyDeviceListSetOrdering',
    'doPropertyDeviceListSetFilter',
    'doPropertyDeviceListSetSearch',
    'doPropertyDeviceListExport',
    'doUpdateQuery',
  )

  const isSmallScreen = useSmallScreen()

  const [viewDataDevice, setViewDataDevice] = useState(null)

  useQueryFilter({
    query: queryObject,
    apiParams: propertyDeviceListApiParams,
    setFilter: doPropertyDeviceListSetFilter,
    setPageSize: doPropertyDeviceListSetPageSize,
    defaultParams: { page: 1, pageSize: 25 },
  })

  const statusOptions = useMemo(
    () => [
      { id: 'ALL', label: 'All Statuses' },
      ...deviceFilterStatuses.map((status) => ({
        id: status.value,
        label: status.name,
      })),
    ],
    [deviceFilterStatuses],
  )

  const queryStatusFilter = queryObject.status ?? 'ALL'
  const initialStatus = statusOptions.find((option) => option.id === queryStatusFilter)
    ? queryStatusFilter
    : 'ALL'

  const [statusFilter, setStatusFilter] = useState(initialStatus)

  useEffect(() => {
    doPropertyDeviceListSetSearch()
    doPropertyDeviceListSetFilter(
      initialStatus === 'ALL' ? {} : { status: initialStatus },
    )
  }, [property])

  const searchHandler = useCallback(
    (search) => {
      const queryParams = { ...queryObject, page: 1, search }
      if (!queryParams.search) {
        delete queryParams.search
      }
      doUpdateQuery(queryParams)
    },
    [queryObject],
  )

  const getFormattedLastActivityData = useCallback((row) => {
    let cellValue = null
    if (row.seenOn) {
      const seenOn = DateTime.fromISO(row.seenOn)
      try {
        const determineAgo = humanizedAgo(seenOn)
        cellValue = [seenOn.toLocaleString(DateTime.DATETIME_MED), determineAgo]
      } catch (e) {
        captureException(e, {
          extra: {
            seenOn: row.seenOn,
            mainMac: row.mainMac,
          },
        })
        cellValue = [seenOn.toLocaleString(DateTime.DATETIME_MED), '']
      }
    }
    return cellValue
  }, [])

  const handleExport = useCallback(async () => {
    const result = await doPropertyDeviceListExport()
    const formattedResult = result.map((item) => ({
      ...item,
      timeAgo: item.seenOn ? humanizedAgo(DateTime.fromISO(item.seenOn)) : null,
    }))
    const data = formatCSVData(formattedResult, {
      unitName: 'Unit Name',
      status: 'Status',
      seenOn: 'Last Seen',
      timeAgo: 'Last Activity',
      mainMac: 'MAC Address',
    })
    downloadFile({
      data: [data],
      fileName: `${property.name}_sensor_export.csv`,
      fileType: 'text/csv',
    })
  }, [property])

  const viewDataAllowed = useMemo(
    () => property?.expandedFlags?.includes('DATA_BROWSER') ?? false,
    [property],
  )

  const onViewDataPressed = (row) => setViewDataDevice(row)

  const columns = [
    {
      field: 'zoneName',
      sortField: 'zone__name',
      headerName: 'Name',
      flex: 0.25,
      sortable: true,
    },
    { field: 'status', headerName: 'Status', flex: 0.25, sortable: true },
    {
      field: 'seenOn',
      headerName: 'Last Activity',
      flex: 0.25,
      sortable: true,
      renderCell: ({ row }) => {
        const cellValue = getFormattedLastActivityData(row)
        return StackedListCell({ cellValue })
      },
      renderMobile: ({ row }) => {
        const cellValue = getFormattedLastActivityData(row)
        if (!cellValue) return '--'

        return (
          <Box display="flex" flexDirection="column" alignItems="end">
            <Typography variant="caption" color="grey.600">
              {cellValue[0] || '--'}
            </Typography>
            <Typography variant="caption">{cellValue[1] || '--'}</Typography>
          </Box>
        )
      },
    },
    { field: 'mainMac', headerName: 'MAC Address', flex: 0.25, sortable: false },
    ...(viewDataAllowed
      ? [
          {
            field: 'extraActions',
            headerName: 'Actions',
            flex: 0.1,
            align: 'center',
            headerAlign: 'center',
            sortable: false,
            renderCell: ({ row }) => (
              <Button
                variant="outlined"
                size="small"
                startIcon={<SsidChartRounded />}
                onClick={() => onViewDataPressed(row)}
              >
                View Data
              </Button>
            ),
          },
        ]
      : []),
  ]

  const MobileListItem = useCallback(
    (row) => {
      const filteredColumns = columns.filter(
        (column) => !['zoneName', 'extraActions'].includes(column.field),
      )
      return (
        <Stack spacing={0.5}>
          <Box display="flex" sx={{ '&&': { mb: 1 } }}>
            <Box display="flex" alignItems="center">
              <Typography fontWeight="bold" fontSize={14}>
                {row.zoneName}
              </Typography>
            </Box>
          </Box>
          {filteredColumns.map((column) => {
            const value = getValueFromColumnDef({ row, column })
            return (
              <MobileListCardRow
                key={column.field}
                label={column.headerName}
                value={value}
              />
            )
          })}
          {viewDataAllowed && (
            <Button
              variant="outlined"
              size="small"
              startIcon={<SsidChartRounded />}
              onClick={() => onViewDataPressed(row)}
            >
              View Data
            </Button>
          )}
        </Stack>
      )
    },
    [columns],
  )

  return (
    <>
      <DeviceDataModal
        device={viewDataDevice}
        onClose={() => {
          setViewDataDevice(null)
        }}
      />
      <Stack direction="row" justifyContent="space-between">
        {!isSmallScreen && (
          <SearchBox title="Sensors" onSetSearch={searchHandler} minLength={0} />
        )}
        <Box
          display="flex"
          width={isSmallScreen ? '100%' : null}
          alignItems="center"
          justifyContent={isSmallScreen ? 'space-between' : null}
          mb={isSmallScreen ? 1 : 0}
        >
          <FormControl
            data-testid="status_filter"
            variant="standard"
            sx={{ m: 1, minWidth: 200 }}
          >
            <InputLabel>Status Filter</InputLabel>
            <Select
              options={statusOptions}
              label=""
              value={statusFilter}
              onChange={(e) => {
                const { value } = e.target
                setStatusFilter(value)
                doPropertyDeviceListSetFilter(value === 'ALL' ? {} : { status: value })
              }}
              MenuProps={{ disableScrollLock: true }}
            >
              {statusOptions.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {isSmallScreen ? (
            <IconButton
              data-testid="export_devices"
              color="primary"
              onClick={handleExport}
            >
              <FileDownload />
            </IconButton>
          ) : (
            <Button
              data-testid="export_devices"
              variant="contained"
              onClick={handleExport}
              startIcon={<FileDownload />}
              sx={{ ml: 3 }}
            >
              Export
            </Button>
          )}
        </Box>
      </Stack>
      {isSmallScreen ? (
        <MobileList
          data-testid="devices_list"
          title="Devices"
          rows={propertyDeviceList.results}
          itemBuilder={MobileListItem}
          page={propertyDeviceList?.current ?? 0}
          rowCount={propertyDeviceList?.count ?? 0}
          pageChange={doPropertyDeviceListSetPage}
          pageSizeOptions={[25]}
          setSearch={searchHandler}
          slots={{ noRowsOverlay: PropertyDevicesEmptyState }}
          hideFooter={(propertyDeviceList?.numPages ?? 0) <= 1}
          loading={propertyDeviceListIsLoading}
        />
      ) : (
        <List
          autoHeight
          disableColumnMenu
          data-testid="devices_list"
          columns={columns}
          rows={propertyDeviceList.results}
          page={propertyDeviceList?.current ?? 0}
          rowCount={propertyDeviceList?.count ?? 0}
          pageChange={doPropertyDeviceListSetPage}
          sortChange={doPropertyDeviceListSetOrdering}
          pageSizeOptions={[25]}
          slots={{ noRowsOverlay: PropertyDevicesEmptyState }}
          hideFooter={(propertyDeviceList?.numPages ?? 0) <= 1}
          loading={propertyDeviceListIsLoading}
        />
      )}
    </>
  )
}
