import { isEmpty, path } from 'ramda'

import { DateTime } from 'luxon'

import { isAbortError } from '@common/utils'

const UNIT_DETAILS_DATA_LOADING = 'UNIT_DETAILS_DATA_LOADING'
const UNIT_DETAILS_DATA_LOADED = 'UNIT_DETAILS_DATA_LOADED'
const UNIT_DETAILS_DATA_FAILED = 'UNIT_DETAILS_DATA_FAILED'

const UNIT_DETAILS_RESET_STATE = 'UNIT_DETAILS_RESET_STATE'

const defaultState = {}

const entityName = 'unitDetails'

export default {
  name: entityName,
  reducer: (state, action) => {
    if (action.type === UNIT_DETAILS_RESET_STATE) {
      return defaultState
    }
    if (action.type === UNIT_DETAILS_DATA_LOADING) {
      return { ...state, data: { ...action.meta, payload: action.payload } }
    }
    if (action.type === UNIT_DETAILS_DATA_LOADED) {
      return { ...state, data: { ...action.meta, data: action.payload } }
    }
    if (action.type === UNIT_DETAILS_DATA_FAILED) {
      return { ...state, data: { ...action.meta, error: action.payload } }
    }
    return state || defaultState
  },
  selectUnitDetailsIsLoading: ({ unitDetails }) => {
    const status = path(['data', 'status'], unitDetails)
    return status === 'loading'
  },
  selectUnitDetails: ({ unitDetails }) => path(['data', 'data'], unitDetails),
  selectUnitDetailsError: ({ unitDetails }) => path(['data', 'error'], unitDetails),
  doResetUnitDetailsState:
    () =>
    ({ dispatch }) =>
      dispatch({ type: UNIT_DETAILS_RESET_STATE }),
  doFetchUnitDetails:
    (payload) =>
    async ({ dispatch, apiFetch }) => {
      try {
        dispatch({
          type: UNIT_DETAILS_DATA_LOADING,
          payload,
          meta: { status: 'loading' },
        })

        const unitResult = await apiFetch(`/units/${payload}/`, null, {
          cancelationPrefix: entityName,
        })
        const apiResults = await Promise.all([
          apiFetch(`/properties/${unitResult.property}/`, null, {
            cancelationPrefix: entityName,
          }),
          apiFetch(
            '/devices/',
            { pageSize: 999, unit: [payload] },
            { cancelationPrefix: entityName },
          ),
        ])
        const propertyResult = apiResults[0]
        const devicesResult = apiResults[1]

        const lastSeenMillis = devicesResult?.results
          ?.filter((device) => device.seenOn)
          ?.map((device) => DateTime.fromISO(device.seenOn).toMillis())
        const latestSeenOn =
          !lastSeenMillis || isEmpty(lastSeenMillis)
            ? null
            : DateTime.fromMillis(Math.max(...lastSeenMillis))

        const data = {
          property: propertyResult,
          unit: unitResult,
          unitLastSeen: latestSeenOn,
        }

        await dispatch({
          type: UNIT_DETAILS_DATA_LOADED,
          payload: data,
          meta: { status: 'succeeded' },
        })
        return data
      } catch (err) {
        if (!isAbortError(err)) {
          dispatch({
            type: UNIT_DETAILS_DATA_FAILED,
            payload: err,
            meta: { status: 'failed' },
          })
          throw err
        }
        return null
      }
    },
}
