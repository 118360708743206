import { groupBy, path } from 'ramda'

import { DateTime } from 'luxon'

import { isAbortError, isDateRangeOverlap } from '@common/utils'
import { automotiveCategoryToTypesMap } from '@common/utils/grafanaViewDataProcessor'

const DEVICE_DATA_MODAL_DATA_LOADING = 'DEVICE_DATA_MODAL_DATA_LOADING'
const DEVICE_DATA_MODAL_DATA_LOADED = 'DEVICE_DATA_MODAL_DATA_LOADED'
const DEVICE_DATA_MODAL_DATA_FAILED = 'DEVICE_DATA_MODAL_DATA_FAILED'

const DEVICE_DATA_MODAL_CHART_LOADING = 'DEVICE_DATA_MODAL_CHART_LOADING'
const DEVICE_DATA_MODAL_CHART_SUCCESS = 'DEVICE_DATA_MODAL_CHART_SUCCESS'
const DEVICE_DATA_MODAL_CHART_FAILED = 'DEVICE_DATA_MODAL_CHART_FAILED'

const DEVICE_DATA_MODAL_EXPORT_LOADING = 'DEVICE_DATA_MODAL_EXPORT_LOADING'
const DEVICE_DATA_MODAL_EXPORT_SUCCESS = 'DEVICE_DATA_MODAL_EXPORT_SUCCESS'
const DEVICE_DATA_MODAL_EXPORT_FAILED = 'DEVICE_DATA_MODAL_EXPORT_FAILED'

const DEVICE_DATA_MODAL_RESET_STATE = 'DEVICE_DATA_MODAL_RESET_STATE'

const defaultState = {}

const entityName = 'deviceDataModal'

export default {
  name: entityName,
  reducer: (state, action) => {
    if (action.type === DEVICE_DATA_MODAL_RESET_STATE) {
      return defaultState
    }
    if (action.type === DEVICE_DATA_MODAL_DATA_LOADING) {
      return { ...state, data: { ...action.meta, payload: action.payload } }
    }
    if (action.type === DEVICE_DATA_MODAL_DATA_LOADED) {
      return { ...state, data: { ...action.meta, data: action.payload } }
    }
    if (action.type === DEVICE_DATA_MODAL_DATA_FAILED) {
      return { ...state, data: { ...action.meta, error: action.payload } }
    }
    if (action.type === DEVICE_DATA_MODAL_CHART_LOADING) {
      return { ...state, chart: { ...action.meta, payload: action.payload } }
    }
    if (action.type === DEVICE_DATA_MODAL_CHART_SUCCESS) {
      return { ...state, chart: { ...action.meta, data: action.payload } }
    }
    if (action.type === DEVICE_DATA_MODAL_CHART_FAILED) {
      return { ...state, chart: { ...action.meta, error: action.payload } }
    }
    if (action.type === DEVICE_DATA_MODAL_EXPORT_LOADING) {
      return { ...state, export: { ...action.meta, payload: action.payload } }
    }
    if (action.type === DEVICE_DATA_MODAL_EXPORT_SUCCESS) {
      return { ...state, export: { ...action.meta, data: action.payload } }
    }
    if (action.type === DEVICE_DATA_MODAL_EXPORT_FAILED) {
      return { ...state, export: { ...action.meta, error: action.payload } }
    }
    return state || defaultState
  },
  selectDeviceDataModalDataIsLoading: ({ deviceDataModal }) => {
    const status = path(['data', 'status'], deviceDataModal)
    return status === 'loading'
  },
  selectDeviceDataModalChartDataIsLoading: ({ deviceDataModal }) => {
    const status = path(['chart', 'status'], deviceDataModal)
    return status === 'loading'
  },
  selectDeviceDataModalExportDataIsLoading: ({ deviceDataModal }) => {
    const status = path(['export', 'status'], deviceDataModal)
    return status === 'loading'
  },
  selectDeviceDataModalIsLoading: ({ deviceDataModal }) => {
    const dataStatus = path(['data', 'status'], deviceDataModal)
    const chartStatus = path(['chart', 'status'], deviceDataModal)
    const exportStatus = path(['export', 'status'], deviceDataModal)
    return (
      dataStatus === 'loading' ||
      chartStatus === 'loading' ||
      exportStatus === 'loading'
    )
  },
  selectDeviceDataModalData: ({ deviceDataModal }) =>
    path(['data', 'data'], deviceDataModal),
  selectDeviceDataModalChartData: ({ deviceDataModal }) =>
    path(['chart', 'data'], deviceDataModal),
  selectDeviceDataModalDataError: ({ deviceDataModal }) =>
    path(['data', 'error'], deviceDataModal),
  selectDeviceDataModalChartDataError: ({ deviceDataModal }) =>
    path(['chart', 'error'], deviceDataModal),
  selectDeviceDataModalExportDataError: ({ deviceDataModal }) =>
    path(['export', 'error'], deviceDataModal),
  doResetDeviceDataModalState:
    () =>
    ({ dispatch }) =>
      dispatch({ type: DEVICE_DATA_MODAL_RESET_STATE }),
  doFetchDeviceDataModalData:
    (payload) =>
    async ({ dispatch, apiFetch }) => {
      try {
        dispatch({
          type: DEVICE_DATA_MODAL_DATA_LOADING,
          payload,
          meta: { status: 'loading' },
        })

        const { property } = payload

        const propertyDevices = await apiFetch(
          `/devices/`,
          { property, pageSize: 9999 },
          { cancelationPrefix: entityName },
        )
        const groupedByUnit = groupBy((device) => device.unit)(
          propertyDevices?.results ?? [],
        )

        const data = {
          devicesByUnit: groupedByUnit,
        }

        dispatch({
          type: DEVICE_DATA_MODAL_DATA_LOADED,
          payload: data,
          meta: { status: 'succeeded' },
        })
        return data
      } catch (err) {
        if (!isAbortError(err)) {
          dispatch({
            type: DEVICE_DATA_MODAL_DATA_FAILED,
            payload: err,
            meta: { status: 'failed' },
          })
          throw err
        }
        return null
      }
    },
  doFetchDeviceDataModalChartData:
    (payload) =>
    async ({ dispatch, store, apiFetch }) => {
      try {
        dispatch({
          type: DEVICE_DATA_MODAL_CHART_LOADING,
          payload,
          meta: { status: 'loading' },
        })

        const { device, selectedArea, selectedDateTimeData } = payload

        const timeZone = device.timezone

        const { custom, range } = selectedDateTimeData
        const [selectedStart, selectedEnd] = range

        const timeNow = DateTime.now().setZone(timeZone)
        const start = selectedStart.setZone(timeZone)
        const end = selectedEnd.setZone(timeZone)

        const timeOffset = { hours: custom ? 0 : 12 }
        const dataResolution = 1000

        let apiStart = start.minus(timeOffset).toISO()
        let apiEnd =
          end.plus(timeOffset) > timeNow
            ? timeNow.toISO()
            : end.plus(timeOffset).toISO()

        if (selectedArea) {
          apiStart = selectedArea.start
          apiEnd = selectedArea.end
        }

        const defaultDataTypes = [
          'mc_1p0_mean',
          'mc_2p5_mean',
          'mc_4p0_mean',
          'mc_10p0_mean',
          'tpm_mean',
          'hum_mean',
          'temp_mean',
          'eco2_mean',
          'tvoc_mean',
        ]
        const automotiveDataTypes = Object.values(automotiveCategoryToTypesMap).reduce(
          (acc, arr) => [...acc, ...arr],
          [],
        )

        const modelData = store
          .selectSystemDeviceModels()
          ?.find((model) => model.id === device?.model)
        const isAutomotive = modelData?.key === 'ag0i'

        const chartResponse = await apiFetch(
          `/devices/${device?.id}/chart/`,
          {
            start: apiStart,
            end: apiEnd,
            resolution: dataResolution,
            dataTypes: isAutomotive ? automotiveDataTypes : defaultDataTypes,
          },
          { cancelationPrefix: entityName },
        )

        const reservationsResponse = await apiFetch(
          `/reservations/`,
          { unit: device?.unit, pageSize: 999 },
          { cancelationPrefix: entityName },
        )
        const processedReservations = reservationsResponse?.results
          ?.map((reservation) => ({
            ...reservation,
            checkIn: DateTime.fromISO(reservation.checkIn, { zone: timeZone }),
            checkOut: DateTime.fromISO(reservation.checkOut, { zone: timeZone }),
          }))
          .filter((reservation) =>
            isDateRangeOverlap(
              { start: reservation.checkIn, end: reservation.checkOut },
              { start: apiStart, end: apiEnd },
            ),
          )

        const finalResult = {
          data: chartResponse,
          reservations: processedReservations ?? [],
        }

        dispatch({
          type: DEVICE_DATA_MODAL_CHART_SUCCESS,
          payload: finalResult,
          meta: { status: 'succeeded' },
        })
        return finalResult
      } catch (err) {
        if (!isAbortError(err)) {
          dispatch({
            type: DEVICE_DATA_MODAL_CHART_FAILED,
            payload: err,
            meta: { status: 'failed' },
          })
          throw err
        }
        return null
      }
    },
  doExportDeviceDataModal:
    (payload) =>
    async ({ dispatch, apiFetch }) => {
      try {
        const { id, start, end } = payload

        dispatch({
          type: DEVICE_DATA_MODAL_EXPORT_LOADING,
          payload,
          meta: { status: 'loading' },
        })

        await apiFetch(
          `/devices/{${id}}/export/`,
          { start, end },
          { method: 'POST', cancelationPrefix: entityName },
        )

        dispatch({
          type: DEVICE_DATA_MODAL_EXPORT_SUCCESS,
          payload: true,
          meta: { status: 'succeeded' },
        })
        return true
      } catch (err) {
        if (!isAbortError(err)) {
          dispatch({
            type: DEVICE_DATA_MODAL_EXPORT_FAILED,
            payload: err,
            meta: { status: 'failed' },
          })
          throw err
        }
        return null
      }
    },
}
