import { useCallback, useEffect, useMemo, useState } from 'react'

import { isEmpty } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { CheckRounded, InfoOutlined, WarningAmberRounded } from '@mui/icons-material'
import { Box, Tooltip, Typography } from '@mui/material'

import { MobileList, MobileListDefaultCard, Picker } from '@common/components'
import { StaticSelect } from '@common/components/Selects'
import { useSmallScreen } from '@common/utils'
import { MainCell } from '@portal/UI/components/cells'
import Filter from '@portal/UI/components/Filter'
import List from '@portal/UI/components/List'

import ContractDetailModal from './ContractDetailModal'

const contractTypeColorPool = [
  '#366139',
  '#8d31c9',
  '#7ba300',
  '#00c172',
  '#8693ff',
  '#ff7aca',
  '#ff5780',
  '#007361',
  '#903952',
  '#86ccff',
]

export const CONTRACTS_TAB_HASH_PARAMS = 'contracts'

export default function Contracts() {
  const isSmallScreen = useSmallScreen()

  const [contractDetail, setContractDetail] = useState(null)
  const [filterModalOpen, setFilterModalOpen] = useState(false)

  const {
    systemContractTypes,
    propertyContractList,
    propertyContractListRaw: { ordering = [] },
    propertyContractListIsLoading,
    propertyContractListApiParams,
    doPropertyContractListClearParams,
    doPropertyContractListSetFilter,
    doPropertyContractListSetPage,
    doPropertyContractListSetPageSize,
    doPropertyContractListSetOrdering,
    doPropertyContractListSetSearch,
  } = useConnect(
    'selectSystemContractTypes',
    'selectPropertyContractList',
    'selectPropertyContractListRaw',
    'selectPropertyContractListIsLoading',
    'selectPropertyContractListApiParams',
    'doPropertyContractListClearParams',
    'doPropertyContractListSetFilter',
    'doPropertyContractListSetPage',
    'doPropertyContractListSetPageSize',
    'doPropertyContractListSetOrdering',
    'doPropertyContractListSetSearch',
  )

  useEffect(() => {
    doPropertyContractListClearParams()
  }, [])

  const typesColors = useMemo(
    () =>
      systemContractTypes.reduce(
        (acc, type, i) => ({ ...acc, [type.id]: contractTypeColorPool[i] }),
        {},
      ),
    [systemContractTypes],
  )

  const columns = [
    {
      field: 'contractNumber',
      headerName: 'Contract Number',
      renderCell: ({ row }) => (
        <Box display="flex" gap={1} alignItems="center">
          <Tooltip
            title={
              systemContractTypes.find((type) => type.id === row.contractType)?.label
            }
          >
            <InfoOutlined sx={{ fontSize: 14, color: typesColors[row.contractType] }} />
          </Tooltip>
          <MainCell label={row.contractNumber} onClick={() => setContractDetail(row)} />
        </Box>
      ),
      flex: 0.4,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.2,
      renderCell: ({ row }) => {
        const Icon = row.invalid ? WarningAmberRounded : CheckRounded
        return (
          <Tooltip title={`Contract ${row.invalid ? 'invalid' : 'valid'}`}>
            <Icon color={row.invalid ? 'warning' : 'success'} />
          </Tooltip>
        )
      },
      renderMobile: ({ row }) => {
        const Icon = row.invalid ? WarningAmberRounded : CheckRounded
        return (
          <Box display="flex" gap={0.5} alignItems="center">
            <Icon color={row.invalid ? 'warning' : 'success'} sx={{ fontSize: 16 }} />
            <Typography variant="caption">
              {row.invalid ? 'Invalid' : 'Valid'}
            </Typography>
          </Box>
        )
      },
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      flex: 0.3,
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      flex: 0.3,
    },
    {
      field: 'terms',
      headerName: 'Terms',
      renderCell: ({ row }) => (
        <Typography
          variant={isSmallScreen ? 'caption' : 'body2'}
          whiteSpace="pre-line"
          textAlign={isSmallScreen ? 'right' : 'left'}
        >
          {row.terms.join('\n')}
        </Typography>
      ),
      flex: 1,
      sortable: false,
    },
  ]

  const rows =
    propertyContractList?.results?.map((contract) => ({
      ...contract,
      invalid: contract.errors && !isEmpty(contract.errors),
    })) ?? []

  const MobileListItem = useCallback(
    (row) => MobileListDefaultCard({ row, columns }),
    [columns],
  )

  const typeOption = useCallback(
    (option) => (
      <Box display="flex" gap={1} alignItems="center">
        <InfoOutlined sx={{ fontSize: 14, color: typesColors[option.id] }} />
        <Typography variant="body">{option.label}</Typography>
      </Box>
    ),
    [typesColors],
  )

  return (
    <>
      <ContractDetailModal
        open={!!contractDetail}
        onClose={() => setContractDetail(null)}
        contract={contractDetail}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '900px',
        }}
      >
        <Box display="flex">
          <Filter
            mode={isSmallScreen ? 'modal' : 'drawer'}
            disabled={propertyContractListIsLoading}
            apiParams={propertyContractListApiParams}
            setApiParams={doPropertyContractListSetFilter}
            dialogOpen={filterModalOpen}
            dialogOnClose={() => setFilterModalOpen(false)}
          >
            <StaticSelect
              multiple
              label="Type"
              optionRender={typeOption}
              filterName="contractType"
              options={systemContractTypes}
            />
            <Picker
              type="date"
              label="Effective Date"
              disableMaskedInput
              filterName="effectiveDate"
              apiFormat="yyyy-MM-dd"
              value={propertyContractListApiParams.effectiveDate}
            />
          </Filter>
          <Box ml={isSmallScreen ? 0 : 2} flex={1} overflow="hidden" minHeight="900px">
            {isSmallScreen ? (
              <MobileList
                title="Contracts"
                loading={propertyContractListIsLoading}
                showActions={false}
                itemBuilder={MobileListItem}
                onFilterPressed={() => setFilterModalOpen(true)}
                page={propertyContractList?.current || 1}
                pageChange={doPropertyContractListSetPage}
                pageSize={propertyContractList?.pageSize}
                pageSizeChange={doPropertyContractListSetPageSize}
                rows={rows}
                rowCount={propertyContractList?.count || 0}
                setSearch={doPropertyContractListSetSearch}
              />
            ) : (
              <List
                dynamicRowHeight
                title="Contracts"
                loading={propertyContractListIsLoading}
                showActions={false}
                showAddButton={false}
                columns={columns}
                page={propertyContractList?.current || 1}
                pageChange={doPropertyContractListSetPage}
                pageSize={propertyContractList?.pageSize}
                pageSizeChange={doPropertyContractListSetPageSize}
                rows={rows}
                rowCount={propertyContractList?.count || 0}
                setSearch={doPropertyContractListSetSearch}
                sortChange={doPropertyContractListSetOrdering}
                currentOrdering={ordering}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}
