import { useEffect, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { SmokingRoomsRounded } from '@mui/icons-material'
import { Box, Button } from '@mui/material'

import { DateTime } from 'luxon'

import { ErrorComponent, Picker, SearchBox } from '@common/components'
import { StaticSelect } from '@common/components/Selects'
import { objToHash, useSmallScreen } from '@common/utils'
import { EVENT_HASH } from '@portal/pages/EventDashboard/Events'
import EventsList from '@portal/pages/EventDashboard/EventsList'
import eventDashboardUrls from '@portal/pages/EventDashboard/urls'
import Filter from '@portal/UI/components/Filter'
import boolOptions, { viewableEventClasses } from '@portal/Utils/constants'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {'Organization'|'Account'|'Property'|'Unit'|'Device'} props.entity
 * @param {React.Node} [props.customButton]
 */
export default function EventLogTab({ entity, customButton = null }) {
  const [filterModalOpen, setFilterModalOpen] = useState(false)

  const {
    routeParams: { id: entityId },
    doEventsListClearParams,
    doEventsListSetFilter,
    doEventsListSetSearch,
    doUpdateUrl,
    eventsList,
    eventsListApiParams,
    eventsListIsLoading,
    systemEventClasses,
    systemEventTypes,
  } = useConnect(
    'selectRouteParams',
    'doEventsListClearParams',
    'doEventsListSetFilter',
    'doEventsListSetSearch',
    'doUpdateUrl',
    'selectEventsList',
    'selectEventsListApiParams',
    'selectEventsListIsLoading',
    'selectSystemEventClasses',
    'selectSystemEventTypes',
  )

  const isSmallScreen = useSmallScreen()

  const initialFilter = { eventClass: 'SMOKE' }

  const handleInitialParams = () => {
    doEventsListClearParams()
    doEventsListSetFilter(initialFilter)
  }

  const handleViewInEventDashboard = () => {
    const hashParams = objToHash({
      ...eventsListApiParams,
      [entity.toLowerCase()]: entityId,
      ...(eventsListApiParams.localCreatedOnDateAfter
        ? {
            localCreatedOnDateAfter: DateTime.fromISO(
              eventsListApiParams.localCreatedOnDateAfter,
            ).toMillis(),
          }
        : {}),
      ...(eventsListApiParams.localCreatedOnDateBefore
        ? {
            localCreatedOnDateBefore: DateTime.fromISO(
              eventsListApiParams.localCreatedOnDateBefore,
            ).toMillis(),
          }
        : {}),
    })
    doUpdateUrl({
      pathname: eventDashboardUrls.list,
      hash: { [EVENT_HASH]: hashParams },
    })
  }

  useEffect(() => {
    handleInitialParams()
  }, [])

  if (!eventsList.results)
    return <ErrorComponent title={`${entity} Events`} callback={handleInitialParams} />

  return (
    <Box display="flex">
      <Box display="flex" marginRight={isSmallScreen ? 0 : 1} marginTop={1.5}>
        <Filter
          open={false}
          mode={isSmallScreen ? 'modal' : 'drawer'}
          disabled={eventsListIsLoading}
          apiParams={eventsListApiParams}
          setApiParams={doEventsListSetFilter}
          immutableFilters={initialFilter}
          dialogOpen={filterModalOpen}
          dialogOnClose={() => setFilterModalOpen(false)}
        >
          <Picker
            range
            type="date"
            label="Created at"
            filterName="localCreatedOnDate"
            lowerCondition="After"
            upperCondition="Before"
            conditionSeparator=""
            apiFormat="yyyy-MM-dd"
          />
          <StaticSelect
            multiple
            label="Event Types"
            filterName="eventType"
            options={systemEventTypes.map((item) => ({
              id: item.value,
              label: item.name,
            }))}
          />
          <StaticSelect
            multiple
            label="Event Classes"
            filterName="eventClass"
            options={systemEventClasses
              .filter((item) => viewableEventClasses.includes(item.value))
              .map((item) => ({
                id: item.value,
                label: item.name,
              }))}
          />
          <StaticSelect label="Billable" filterName="billable" options={boolOptions} />
        </Filter>
      </Box>
      <Box flex={1} height="100%" overflow="hidden" sx={{ minHeight: '500px' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <SearchBox
            title="Event Log"
            initialValue={eventsListApiParams.search}
            onSetSearch={doEventsListSetSearch}
            sx={{ width: '400px', pb: 1 }}
          />
          <Box display="flex" gap={0.5}>
            <Button
              variant="outlined"
              onClick={handleViewInEventDashboard}
              startIcon={<SmokingRoomsRounded />}
              sx={{ ml: 1 }}
            >
              View in Event Dashboard
            </Button>
            {customButton}
          </Box>
        </Box>
        <EventsList eventsList={eventsList} />
      </Box>
    </Box>
  )
}
