import { DateTime } from 'luxon'

// eslint-disable-next-line import/prefer-default-export
export function isDemoModeActive(unit) {
  if (unit) {
    const now = DateTime.utc()
    return (
      unit.demoModeStart &&
      DateTime.fromISO(unit.demoModeStart, { zone: 'UTC' }) <= now &&
      unit.demoModeEnd &&
      DateTime.fromISO(unit.demoModeEnd, { zone: 'UTC' }) > DateTime.utc()
    )
  }
  return false
}
