import { isEmpty } from 'ramda'

import { StaticMultiSelect } from '@common/components/Selects'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object[]} props.typeFilter
 * @param {Function} props.setTypeFilter
 * @param {Object[]} props.options
 * @param {string} props.options[].id
 * @param {string} props.options[].label
 * @param {boolean} props.isLoading
 * @param {Function} props.onSave
 */
export default function TypeFilter({
  typeFilter,
  setTypeFilter,
  options,
  isLoading,
  onSave,
}) {
  return (
    <StaticMultiSelect
      disableClearable={typeFilter.length === options.length}
      label="Type"
      options={options}
      value={typeFilter}
      disabled={isLoading}
      onChange={setTypeFilter}
      onSave={(selected) => {
        if (isEmpty(selected)) {
          setTypeFilter(options)
          onSave(options)
        } else {
          onSave(selected)
        }
      }}
      size="small"
      limitTags={2}
      sx={{ minWidth: 300 }}
    />
  )
}
