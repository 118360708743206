import { useCallback, useEffect, useMemo, useState } from 'react'

import { isEmpty, map, pick, pickBy } from 'ramda'
import { useConnect, useReduxBundlerStore } from 'redux-bundler-hook'

import { Clear, FileDownload, FilterAlt, Refresh } from '@mui/icons-material'
import { Box, IconButton, Popover, Stack, Typography } from '@mui/material'

import { pluralize, titleize, underscore } from 'inflection'
import { DateTime } from 'luxon'

import { Breadcrumbs, Picker, SearchBox } from '@common/components'
import {
  DynamicSelect,
  StaticMultiSelect,
  StaticSelect,
} from '@common/components/Selects'
import {
  downloadFile,
  formatCurrency,
  getApiFetch,
  hashToObj,
  objToHash,
  parseApiErrors,
  useHashFilter,
  useSmallScreen,
} from '@common/utils'
import { homeUrls } from '@portal/pages/Home'
import ListPageTitle from '@portal/UI/components/ListPageTitle'
import boolOptions, { viewableEventClasses } from '@portal/Utils/constants'
import { arrayToCsv } from '@portal/Utils/csv'
import EventsList from '~/portal/pages/EventDashboard/EventsList'

import ChargeSummary from './ChargeSummary'
import EventsMobileFilter from './EventsMobileFilter'
import FiltersInfoHeader from './FiltersInfoHeader'
import { eventCsvHeaders, getHeaderFields, validateEventPayload } from './utils'

export const EVENT_HASH = 'eventDashboard'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.type
 * @param {Object} props.filters
 * @param {string} [props.value]
 * @param {Function} props.onChange
 * @param {Object} props.currentEntities
 * @param {Object} props.currentEntities.organizationGroup
 * @param {Object} props.currentEntities.organization
 * @param {Object} props.currentEntities.account
 * @param {Object} props.currentEntities.property
 * @param {Object} props.currentEntities.unit
 * @param {Object} props.currentEntities.device
 */
function EntitySelector({
  type,
  filters,
  value = undefined,
  onChange,
  currentEntities,
}) {
  const formattedType = pluralize(underscore(type))
  const nameField = type === 'device' ? 'mainMac' : 'name'

  const sanitizedFilters = { ...filters }
  delete sanitizedFilters[type]

  return (
    <DynamicSelect
      getFullEntity
      key={type}
      size="small"
      filters={sanitizedFilters}
      filterName={type}
      value={value}
      endpoint={formattedType}
      label={titleize(formattedType)}
      onChange={onChange}
      initialInput={currentEntities[type]?.[nameField]}
      primaryTextAttr={nameField}
      secondaryTextAttr={type === 'device' ? 'unitName' : null}
      sx={{ minWidth: '307px' }}
    />
  )
}

export default function EventDashboard() {
  const isSmallScreen = useSmallScreen()

  const defaultEntities = {
    organizationGroup: '',
    organization: '',
    account: '',
    property: '',
    unit: '',
    device: '',
  }
  const defaultEntityObjs = map(() => ({}), defaultEntities)
  const defaultHashObj = {
    ...defaultEntities,
    localCreatedOnDateBefore: DateTime.now().toMillis(),
    localCreatedOnDateAfter: DateTime.now().minus({ days: 7 }).toMillis(),
  }

  const [currentEntities, setCurrentEntities] = useState(defaultEntityObjs)
  const [mobileFilterOpen, setMobileFilterOpen] = useState(false)
  const [desktopFilterAnchor, setDesktopFilterAnchor] = useState(false)

  const [selectedEventTypes, setSelectedEventTypes] = useState([])
  const [selectedEventClasses, setSelectedEventClasses] = useState([])

  const store = useReduxBundlerStore()
  const apiFetch = getApiFetch(store)

  const {
    isAtLeastAdmin,
    hashObject,
    eventsList,
    eventsListApiParams,
    systemEventTypes,
    systemEventClasses,
    doEventsListSetSearch,
    doEventsListSetFilter,
    doMarkEventsListAsOutdated,
    doUpdateHash,
    doFetchChargeSummary,
    doFetchEventEntitiesFilterInitialData,
    doEventsListClear,
    doShowSnackbar,
  } = useConnect(
    'selectIsAtLeastAdmin',
    'selectHashObject',
    'selectEventsList',
    'selectEventsListApiParams',
    'selectSystemEventTypes',
    'selectSystemEventClasses',
    'doEventsListSetSearch',
    'doEventsListSetFilter',
    'doMarkEventsListAsOutdated',
    'doUpdateHash',
    'doFetchChargeSummary',
    'doFetchEventEntitiesFilterInitialData',
    'doEventsListClear',
    'doShowSnackbar',
  )

  const [isExporting, setIsExporting] = useState(false)

  const onExport = useCallback(async () => {
    setIsExporting(true)
    try {
      const response = await apiFetch(
        '/events/',
        {
          ...eventsListApiParams,
          eventClass: 'SMOKE',
          pageSize: eventsList.count,
          page: 1,
        },
        { cancelationPrefix: 'event_dashboard' },
      )

      if (response?.results) {
        const data = arrayToCsv([eventCsvHeaders, ...getHeaderFields(response.results)])

        downloadFile({
          data: [data],
          fileName: `events_dashboard_${DateTime.now().toFormat(
            'yyyy-MM-dd_hh-mma',
          )}.csv`,
          fileType: 'text/csv',
        })
      }
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    } finally {
      setIsExporting(false)
    }
  }, [eventsList, eventsListApiParams])

  const initializeFilterIfNeeded = useCallback(
    async ({ organizationGroup, organization, account, property, unit, device }) => {
      const shouldFetchOrgGroup =
        organizationGroup && isEmpty(Object.keys(currentEntities.organizationGroup))
      const shouldFetchOrg =
        organization && isEmpty(Object.keys(currentEntities.organization))
      const shouldFetchAccount =
        account && isEmpty(Object.keys(currentEntities.account))
      const shouldFetchProperty =
        property && isEmpty(Object.keys(currentEntities.property))
      const shouldFetchUnit = unit && isEmpty(Object.keys(currentEntities.unit))
      const shouldFetchDevice = device && isEmpty(Object.keys(currentEntities.device))

      const shouldFetch =
        shouldFetchOrgGroup ||
        shouldFetchOrg ||
        shouldFetchAccount ||
        shouldFetchProperty ||
        shouldFetchUnit ||
        shouldFetchDevice

      const result = shouldFetch
        ? await doFetchEventEntitiesFilterInitialData({
            organizationGroup: shouldFetchOrgGroup ? organizationGroup : null,
            organization: shouldFetchOrg ? organization : null,
            account: shouldFetchAccount ? account : null,
            property: shouldFetchProperty ? property : null,
            unit: shouldFetchUnit ? unit : null,
            device: shouldFetchDevice ? device : null,
          })
        : null
      if (result) {
        setCurrentEntities(result)
      }
    },
    [currentEntities],
  )

  const onHashChanged = async (hash) => {
    if (hash) {
      if (!hash.startsWith(EVENT_HASH)) {
        doUpdateHash({ [EVENT_HASH]: objToHash(defaultHashObj) })
        return
      }

      const hashObj = hashToObj(hashObject[EVENT_HASH])
      const {
        billable,
        eventClass,
        localCreatedOnDateBefore,
        localCreatedOnDateAfter,
        ...rest
      } = hashObj

      await initializeFilterIfNeeded({
        organizationGroup: hashObj.organizationGroup,
        organization: hashObj.organization,
        account: hashObj.account,
        property: hashObj.property,
        unit: hashObj.unit,
        device: hashObj.device,
      })

      const parsedLocalCreatedOnDateBefore = localCreatedOnDateBefore
        ? DateTime.fromMillis(Number(localCreatedOnDateBefore)).toISODate()
        : null
      const parsedLocalCreatedOnDateAfter = localCreatedOnDateAfter
        ? DateTime.fromMillis(Number(localCreatedOnDateAfter)).toISODate()
        : null

      const payload = {
        ...rest,
        ...(billable === 'null' ? {} : { billable }),
        ...(eventClass
          ? { eventClass }
          : { eventClass: viewableEventClasses.join(',') }),
        ...(parsedLocalCreatedOnDateBefore
          ? { localCreatedOnDateBefore: parsedLocalCreatedOnDateBefore }
          : {}),
        ...(parsedLocalCreatedOnDateAfter
          ? { localCreatedOnDateAfter: parsedLocalCreatedOnDateAfter }
          : {}),
      }

      doEventsListSetFilter(
        validateEventPayload(rest) ? payload : { ...payload, ...defaultEntities },
      )
    }
  }

  const handleInitialHash = () =>
    isEmpty(hashObject) ? { [EVENT_HASH]: objToHash(defaultHashObj) } : hashObject

  useEffect(() => doEventsListClear, [])

  useHashFilter(handleInitialHash(), onHashChanged)

  const resetLowerEntityValues = (type) => {
    if (type === 'organizationGroup')
      return { organization: '', account: '', property: '', unit: '', device: '' }
    if (type === 'organization')
      return { account: '', property: '', unit: '', device: '' }
    if (type === 'account') return { property: '', unit: '', device: '' }
    if (type === 'property') return { unit: '', device: '' }
    if (type === 'unit') return { device: '' }
    return {}
  }

  const handleClear = (clearSearch = true) => {
    doEventsListClear()
    if (clearSearch) doEventsListSetSearch('')
    setCurrentEntities(defaultEntityObjs)
    doUpdateHash({ [EVENT_HASH]: objToHash(defaultHashObj) })
  }

  const handleRestFilterChange = (value, param) => {
    const hashObj = hashToObj(hashObject[EVENT_HASH])
    const newHashObj = {
      ...hashObj,
      [param]: Array.isArray(value) ? value.join(',') : value,
    }
    if (!newHashObj[param]) {
      delete newHashObj[param]
    }
    doUpdateHash({ [EVENT_HASH]: objToHash(newHashObj) })
  }

  const handleFilterChange = (entity, type) => {
    const hashObj = hashToObj(hashObject[EVENT_HASH])
    const hierarchyValues = entity ? resetLowerEntityValues(type) : {}
    const newHashObj = { ...hashObj, ...hierarchyValues, [type]: entity?.id ?? '' }
    const hash = objToHash(newHashObj)

    const { localCreatedOnDateBefore, localCreatedOnDateAfter } = newHashObj

    const combinedEntities = {
      ...currentEntities,
      [type]: { ...entity, localCreatedOnDateBefore, localCreatedOnDateAfter },
    }

    const hasValue = (v) => Boolean(v)
    const selectedKeys = Object.keys(pickBy(hasValue, newHashObj))

    const updatedEntities = {
      ...defaultEntityObjs,
      ...pick(selectedKeys, combinedEntities),
    }

    const { organizationGroup, account, organization, property } = updatedEntities
    if (
      isEmpty(organizationGroup) &&
      isEmpty(organization) &&
      isEmpty(account) &&
      isEmpty(property)
    ) {
      handleClear(false)
    }

    setCurrentEntities(updatedEntities)
    doUpdateHash({ [EVENT_HASH]: hash })
  }

  const handleDateChange = (dateObj) => {
    const dates = Object.entries(dateObj).reduce((acc, [attr, date]) => {
      const formattedDate = DateTime.fromJSDate(new Date(date)).toMillis().toString()
      return { ...acc, [attr]: formattedDate }
    }, [])

    const hashObj = hashToObj(hashObject[EVENT_HASH])
    const hash = objToHash({ ...hashObj, ...dates })
    doUpdateHash({ [EVENT_HASH]: hash })
  }

  const handleBillableChange = (value) => {
    const isObject = typeof value === 'object' && value !== null
    const billable = isObject ? value.id : value
    const hashObj = hashToObj(hashObject[EVENT_HASH])
    const newHashObj = { ...hashObj, billable }
    doUpdateHash({ [EVENT_HASH]: objToHash(newHashObj) })
  }

  const getValue = (filter) => eventsListApiParams[filter] ?? null

  const hierarchyFilters = {
    active: true,
    ordering: 'name',
    ...Object.entries(currentEntities).reduce(
      (acc, [type, entity]) => ({ ...acc, [type]: entity?.id }),
      {},
    ),
  }

  const totalEvents = eventsList?.count ?? 0

  const eventTypesOptions = useMemo(
    () =>
      systemEventTypes?.map((item) => ({
        id: item.value,
        label: item.name,
      })) ?? [],
    [systemEventTypes],
  )

  const eventClassesOptions = useMemo(
    () =>
      systemEventClasses
        ?.filter((item) => viewableEventClasses.includes(item.value))
        ?.map((item) => ({
          id: item.value,
          label: item.name,
        })) ?? [],
    [systemEventClasses],
  )

  useEffect(() => {
    if (eventsListApiParams?.eventType) {
      const apiTypes = eventsListApiParams?.eventType.split(',')
      setSelectedEventTypes(
        eventTypesOptions.filter((opt) => apiTypes.includes(opt.id)),
      )
    } else {
      setSelectedEventTypes([])
    }
  }, [eventsListApiParams?.eventType, eventTypesOptions])

  useEffect(() => {
    if (eventsListApiParams?.eventClass) {
      const apiClasses = eventsListApiParams?.eventClass.split(',')
      setSelectedEventClasses(
        eventClassesOptions.filter((opt) => apiClasses.includes(opt.id)),
      )
    } else {
      setSelectedEventClasses([])
    }
  }, [eventsListApiParams?.eventClass, eventClassesOptions])

  return (
    <>
      <Popover
        open={!!desktopFilterAnchor}
        onClose={() => setDesktopFilterAnchor(null)}
        anchorEl={desktopFilterAnchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          spacing={2}
          alignItems="flex-start"
          sx={{ p: 3 }}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Picker
              range
              type="date"
              label="Created at"
              disableMaskedInput
              conditionSeparator=""
              filterName="localCreatedOnDate"
              lowerCondition="After"
              upperCondition="Before"
              onChange={handleDateChange}
              value={pick(
                ['localCreatedOnDateBefore', 'localCreatedOnDateAfter'],
                eventsListApiParams,
              )}
            />
            <IconButton
              sx={{ mr: 2 }}
              color="primary"
              onClick={() => {
                doMarkEventsListAsOutdated()
              }}
            >
              <Refresh />
            </IconButton>
            <IconButton sx={{ mr: 2 }} color="primary" onClick={handleClear}>
              <Clear />
            </IconButton>
          </Stack>

          <Stack direction="row" spacing={2} alignItems="center">
            {Object.keys(defaultEntities)
              .slice(0, 2)
              .map((type) => (
                <EntitySelector
                  key={type}
                  type={type}
                  currentEntities={currentEntities}
                  filters={hierarchyFilters}
                  value={getValue(type)}
                  onChange={(ent) => handleFilterChange(ent, type)}
                />
              ))}
          </Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            {Object.keys(defaultEntities)
              .slice(2, 4)
              .map((type) => (
                <EntitySelector
                  key={type}
                  type={type}
                  currentEntities={currentEntities}
                  filters={hierarchyFilters}
                  value={getValue(type)}
                  onChange={(ent) => handleFilterChange(ent, type)}
                />
              ))}
          </Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            {Object.keys(defaultEntities)
              .slice(4)
              .map((type) => (
                <EntitySelector
                  key={type}
                  type={type}
                  currentEntities={currentEntities}
                  filters={hierarchyFilters}
                  value={getValue(type)}
                  onChange={(ent) => handleFilterChange(ent, type)}
                />
              ))}
          </Stack>
          <Stack direction="row" spacing={2} alignItems="center" width="100%">
            <StaticMultiSelect
              fullWidth
              dynamicWidth
              label="Event Type"
              size="small"
              limitTags={4}
              chipMaxWidth={130}
              value={selectedEventTypes}
              onChange={(opt) => setSelectedEventTypes(opt)}
              onSave={(opt) =>
                handleRestFilterChange(
                  opt.map((i) => i.id),
                  'eventType',
                )
              }
              options={eventTypesOptions}
            />
            <StaticMultiSelect
              fullWidth
              dynamicWidth
              disableClearable
              label="Event Class"
              size="small"
              limitTags={4}
              chipMaxWidth={130}
              value={selectedEventClasses}
              onChange={(opt) => setSelectedEventClasses(opt)}
              onSave={(opt) =>
                handleRestFilterChange(
                  opt.map((i) => i.id),
                  'eventClass',
                )
              }
              options={eventClassesOptions}
            />
          </Stack>
          <StaticSelect
            fullWidth
            size="small"
            label="Billable"
            filterName="billable"
            value={
              eventsListApiParams.billable !== 'null' &&
              eventsListApiParams.billable !== null &&
              eventsListApiParams.billable !== undefined
                ? boolOptions.find(
                    (option) => option.id === (eventsListApiParams.billable === 'true'),
                  )
                : null
            }
            onChange={handleBillableChange}
            options={boolOptions}
          />
        </Stack>
      </Popover>
      {isSmallScreen && (
        <EventsMobileFilter
          open={mobileFilterOpen}
          onClose={() => setMobileFilterOpen(false)}
          defaultEntities={defaultEntities}
          eventTypesOptions={eventTypesOptions}
          eventClassesOptions={eventClassesOptions}
          hierarchyFilters={hierarchyFilters}
          selectedEventClassesState={[selectedEventClasses, setSelectedEventClasses]}
          handleDateChange={handleDateChange}
          handleFilterChange={handleFilterChange}
          handleRestFilterChange={handleRestFilterChange}
          handleBillableChange={handleBillableChange}
          handleClear={handleClear}
        />
      )}

      <Box m={3} display="flex" flexDirection="column">
        <Breadcrumbs
          links={[{ label: 'Home', href: homeUrls.home }, { label: 'Events' }]}
        />
        <Box display="flex" flexDirection="column" pb={2}>
          {isSmallScreen && (
            <ListPageTitle
              title="Event Dashboard"
              onFilterPressed={() => setMobileFilterOpen(true)}
              menuItems={
                isAtLeastAdmin
                  ? [
                      {
                        label: 'Export',
                        onClick: onExport,
                      },
                    ]
                  : null
              }
              mb={2}
            />
          )}
          {!isSmallScreen && (
            <Stack
              mb={2}
              direction="row"
              alignItems="start"
              justifyContent="space-between"
            >
              <Stack direction="column" spacing={0.3}>
                <Stack direction="row" spacing={1.5} alignItems="end" mb={1}>
                  <Typography variant="h3">Event Dashboard</Typography>
                  <IconButton onClick={(e) => setDesktopFilterAnchor(e.currentTarget)}>
                    <FilterAlt />
                  </IconButton>
                </Stack>
                <FiltersInfoHeader
                  range={Object.values(
                    pick(
                      ['localCreatedOnDateAfter', 'localCreatedOnDateBefore'],
                      eventsListApiParams,
                    ),
                  )}
                  entities={currentEntities}
                  eventTypes={selectedEventTypes}
                  eventClasses={selectedEventClasses}
                  billable={eventsListApiParams.billable}
                />
              </Stack>
              <Stack
                direction="column"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  justifyContent="center"
                >
                  <SearchBox
                    title="Events"
                    initialValue={eventsListApiParams.search}
                    onSetSearch={doEventsListSetSearch}
                    sx={{ width: '400px', paddingBottom: 1 }}
                  />
                  {isAtLeastAdmin && (
                    <IconButton
                      color="primary"
                      onClick={onExport}
                      disabled={isExporting || totalEvents === 0}
                    >
                      <FileDownload />
                    </IconButton>
                  )}
                </Stack>

                <ChargeSummary
                  eventList={eventsList}
                  fetch={doFetchChargeSummary}
                  formatCurrency={formatCurrency}
                />
              </Stack>
            </Stack>
          )}

          {isSmallScreen && (
            <Box sx={{ mb: 2 }}>
              <ChargeSummary
                eventList={eventsList}
                fetch={doFetchChargeSummary}
                formatCurrency={formatCurrency}
              />
            </Box>
          )}
          <EventsList eventsList={eventsList} />
        </Box>
      </Box>
    </>
  )
}
