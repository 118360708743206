import { createSelector } from 'redux-bundler'

import { DateTime } from 'luxon'
import reduceReducers from 'reduce-reducers'

import { createListBundle, isAbortError } from '@common/utils'
import propertyUrls from '@portal/pages/Properties/urls'

const entityName = 'propertyContract'

const PROPERTY_ACTIVE_CONTRACTS_LOADING = 'PROPERTY_ACTIVE_CONTRACTS_LOADING'
const PROPERTY_ACTIVE_CONTRACTS_LOADED = 'PROPERTY_ACTIVE_CONTRACTS_LOADED'
const PROPERTY_ACTIVE_CONTRACTS_FAILED = 'PROPERTY_ACTIVE_CONTRACTS_FAILED'

const propertyContaractListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, store, params }) =>
    apiFetch(
      '/contracts/',
      { property: store.selectRouteParams().id, ...params },
      { cancelationPrefix: entityName },
    ),
  urlTest: (_, pattern, hash) => propertyUrls.entity === pattern && hash === 'property',
})

export default {
  ...propertyContaractListBundle,
  reducer: reduceReducers(propertyContaractListBundle.reducer, (state, action) => {
    switch (action.type) {
      case PROPERTY_ACTIVE_CONTRACTS_LOADING:
        return {
          ...state,
          activeContracts: { ...action.meta, payload: action.payload },
        }
      case PROPERTY_ACTIVE_CONTRACTS_LOADED:
        return { ...state, activeContracts: { ...action.meta, data: action.payload } }
      case PROPERTY_ACTIVE_CONTRACTS_FAILED:
        return { ...state, activeContracts: { ...action.meta, error: action.payload } }
      default:
        return state
    }
  }),
  doFetchPropertyActiveContracts:
    () =>
    async ({ apiFetch, store, dispatch }) => {
      const payload = {
        property: store.selectRouteParams().id,
        effectiveDate: DateTime.now().toFormat('yyyy-MM-dd'),
      }

      dispatch({
        type: PROPERTY_ACTIVE_CONTRACTS_LOADING,
        payload,
        meta: { status: 'loading' },
      })
      try {
        const response = await apiFetch(`/contracts/`, payload, {
          method: 'GET',
          cancelationPrefix: `${entityName}_active`,
        })
        await dispatch({
          type: PROPERTY_ACTIVE_CONTRACTS_LOADED,
          payload: response?.results ?? [],
          meta: { status: 'succeeded' },
        })
        return response?.results ?? []
      } catch (err) {
        if (!isAbortError(err)) {
          dispatch({
            type: PROPERTY_ACTIVE_CONTRACTS_FAILED,
            payload: err,
            meta: { status: 'failed' },
          })
          throw err
        }
        return null
      }
    },
  selectPropertyActiveContracts: createSelector(
    'selectPropertyContractListRaw',
    (propListRaw) => propListRaw?.activeContracts?.data,
  ),
  selectPropertyActiveContractsIsLoading: createSelector(
    'selectPropertyContractListRaw',
    (propListRaw) => propListRaw?.activeContracts?.status === 'loading',
  ),
  selectPropertyActiveContractsError: createSelector(
    'selectPropertyContractListRaw',
    (propListRaw) => propListRaw?.activeContracts?.error,
  ),
}
