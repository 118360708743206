import { memo } from 'react'

import { join, map, pipe, split, toLower } from 'ramda'

import {
  AccountBalanceOutlined as AccountBalanceIcon,
  BusinessRounded as BusinessIcon,
  HolidayVillage as HolidayVillageIcon,
  House as HouseIcon,
  Store as StoreIcon,
} from '@mui/icons-material'
import { Stack, ToggleButtonGroup, Typography } from '@mui/material'

import { ToggleButtonWithTooltip } from '@common/components'

export const formatGrouping = pipe(split(/(?=[A-Z])/), map(toLower), join('_'))

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.selectedGrouping
 * @param {string} props.currentEntity
 * @param {Function} props.setSelectedGrouping
 * @param {boolean} props.disabled
 */
const GroupingSelector = memo(
  ({ selectedGrouping, currentEntity, setSelectedGrouping, disabled }) => (
    <Stack direction="column" spacing={1}>
      <Typography variant="body1">Grouping</Typography>
      <ToggleButtonGroup
        color="primary"
        value={selectedGrouping}
        exclusive
        onChange={(_, selectedValue) => {
          if (selectedValue !== null) {
            setSelectedGrouping(formatGrouping(selectedValue))
          }
        }}
        disabled={disabled}
      >
        <ToggleButtonWithTooltip
          disabled={
            currentEntity !== 'organizationGroup' ||
            selectedGrouping === 'organization_group'
          }
          tooltip="By Organization Group"
          value="organization_group"
          sx={{ py: 0.8 }}
        >
          <BusinessIcon />
        </ToggleButtonWithTooltip>
        <ToggleButtonWithTooltip
          disabled={
            (currentEntity !== 'organization' &&
              currentEntity !== 'organizationGroup') ||
            selectedGrouping === 'organization'
          }
          tooltip="By Organization"
          value="organization"
          sx={{ py: 0.8 }}
        >
          <StoreIcon />
        </ToggleButtonWithTooltip>
        <ToggleButtonWithTooltip
          disabled={
            currentEntity === 'property' ||
            currentEntity === 'propertyGroup' ||
            selectedGrouping === 'account'
          }
          tooltip="By Account"
          value="account"
          sx={{ py: 0.8 }}
        >
          <AccountBalanceIcon />
        </ToggleButtonWithTooltip>
        <ToggleButtonWithTooltip
          value="property_group"
          tooltip="By Property Group"
          disabled={
            currentEntity === 'property' || selectedGrouping === 'property_group'
          }
          sx={{ py: 0.8 }}
        >
          <HolidayVillageIcon />
        </ToggleButtonWithTooltip>
        <ToggleButtonWithTooltip
          value="property"
          tooltip="By Property"
          disabled={selectedGrouping === 'property'}
          sx={{ py: 0.8 }}
        >
          <HouseIcon />
        </ToggleButtonWithTooltip>
      </ToggleButtonGroup>
    </Stack>
  ),
)

export default GroupingSelector
