import { useEffect, useMemo, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import {
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Popover,
  Stack,
  Typography,
} from '@mui/material'

export default function MetricsSettingsPopover({
  anchor,
  onClose,
  isLoading,
  selectedView,
  showTotal,
  setShowTotal,
  groupByEntity,
  setGroupByEntity,
  initialPropertyStatuses,
}) {
  const { systemPropertyStatuses } = useConnect('selectSystemPropertyStatuses')

  const [selectedPropertyStatuses, setSelectedPropertyStatuses] = useState({})

  useEffect(() => {
    setSelectedPropertyStatuses(
      initialPropertyStatuses.reduce((acc, status) => ({ ...acc, [status]: true }), {}),
    )
  }, [initialPropertyStatuses])

  const onlyLiveSelected = useMemo(() => {
    const checkedOptions = Object.entries(selectedPropertyStatuses).filter(
      (data) => data[1],
    )
    return checkedOptions.length === 1 && checkedOptions[0][0] === 'LIVE'
  }, [selectedPropertyStatuses])

  const allSelected = useMemo(() => {
    const selectedValues = Object.values(selectedPropertyStatuses)
    return (
      systemPropertyStatuses.length === selectedValues.length &&
      selectedValues.every((v) => v)
    )
  }, [selectedPropertyStatuses])

  return (
    <Popover
      open={!!anchor}
      onClose={() => {
        onClose({
          propertyStatuses: Object.entries(selectedPropertyStatuses).reduce(
            (acc, item) => {
              const [status, checked] = item
              if (checked) {
                return [...acc, status]
              }
              return acc
            },
            [],
          ),
        })
      }}
      anchorEl={anchor}
      anchorOrigin={{ vertical: 40, horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    >
      <Stack direction="column" px={2} py={0.5}>
        {selectedView === 'graph' && (
          <FormControlLabel
            slotProps={{ typography: { fontSize: 14 } }}
            control={
              <Checkbox
                checked={showTotal}
                size="small"
                onChange={() => setShowTotal((v) => !v)}
              />
            }
            label="Show total"
          />
        )}
        {selectedView === 'table' && (
          <FormControlLabel
            disabled={isLoading}
            slotProps={{ typography: { fontSize: 14 } }}
            control={
              <Checkbox
                checked={groupByEntity}
                size="small"
                onChange={() => setGroupByEntity((v) => !v)}
              />
            }
            label="Group results by entity"
          />
        )}
        <Box
          display="flex"
          flexDirection="column"
          border={1}
          borderColor="lightgrey"
          position="relative"
          padding={1}
          borderRadius={1}
          mx={-1}
          mt={2}
          mb={0.5}
          minWidth={210}
        >
          <Typography
            variant="caption"
            component="div"
            style={{
              position: 'absolute',
              top: -10,
              left: 12,
              backgroundColor: 'white',
            }}
          >
            Show properies with status
          </Typography>
          {systemPropertyStatuses.map(({ name, value }) => (
            <FormControlLabel
              key={value}
              disabled={
                isLoading ||
                (selectedPropertyStatuses[value] &&
                  Object.values(selectedPropertyStatuses).filter((v) => v).length <= 1)
              }
              slotProps={{ typography: { fontSize: 14 } }}
              control={
                <Checkbox
                  checked={selectedPropertyStatuses[value] ?? false}
                  size="small"
                  onChange={(event) => {
                    setSelectedPropertyStatuses((statuses) => ({
                      ...statuses,
                      [value]: event.target.checked,
                    }))
                  }}
                />
              }
              label={name}
            />
          ))}
          <Divider />
          <Box width={1} display="flex" justifyContent="space-between" mt={1}>
            <Chip
              label="All"
              size="small"
              onClick={() => {
                setSelectedPropertyStatuses(() =>
                  systemPropertyStatuses.reduce(
                    (acc, item) => ({ ...acc, [item.value]: true }),
                    {},
                  ),
                )
              }}
              color="primary"
              variant={allSelected ? 'standard' : 'outlined'}
              sx={{
                minWidth: '48%',
                pointerEvents: allSelected ? 'none' : 'auto',
              }}
            />
            <Chip
              label="Only Live"
              size="small"
              onClick={() => {
                setSelectedPropertyStatuses(
                  Object.entries(selectedPropertyStatuses).reduce((acc, item) => {
                    const [status] = item
                    return { ...acc, [status]: status === 'LIVE' }
                  }, {}),
                )
              }}
              color="primary"
              variant={onlyLiveSelected ? 'standard' : 'outlined'}
              sx={{
                minWidth: '48%',
                pointerEvents: onlyLiveSelected ? 'none' : 'auto',
              }}
            />
          </Box>
        </Box>
      </Stack>
    </Popover>
  )
}
