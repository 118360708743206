import { useCallback, useMemo } from 'react'

import { isEmpty } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { Close } from '@mui/icons-material'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'

import { humanize, underscore } from 'inflection'

import { formatCurrency } from '@common/utils'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {function} props.onClose
 * @param {Object} [props.contract]
 * @param {string} props.contract.id
 * @param {string} props.contract.contractNumber
 * @param {string} props.contract.contractType
 * @param {string} props.contract.salesforceId
 * @param {string} props.contract.startDate
 * @param {string} props.contract.endDate
 * @param {string[]} props.contract.terms
 */
export default function ContractDetailModal({ open, onClose, contract }) {
  const { systemContractTypes } = useConnect('selectSystemContractTypes')

  const Row = useCallback(
    ({ label, value, showUnderline = true, labelColor }) => (
      <Box display="flex" width={1}>
        <Typography variant="body2" fontWeight={500} color={labelColor}>
          {label}:
        </Typography>
        <Divider sx={{ flex: 1, opacity: showUnderline ? 0.5 : 0 }} />
        <Typography variant="body2" whiteSpace="pre-line" textAlign="right">
          {value}
        </Typography>
      </Box>
    ),
    [],
  )

  const ignoreRows = [
    'id',
    'property',
    'propertyName',
    'contractNumber',
    'contractType',
    'salesforceId',
    'terms',
    'startDate',
    'endDate',
    'errors',
  ]

  const currencyFields = [
    'installationFees',
    'contractedSmokingFee',
    'preThresholdFeePerCharge',
    'postThresholdFeePerCharge',
    'fixedSubscriptionFees',
    'variableServiceFeeFlatPerBillable',
    'variableServiceFeeFlatPerCharged',
    'creditAmounts',
    'totalRevShare',
  ]
  const percentageFields = [
    'minimumChargeRate',
    'minimumChargeRateThreshold',
    'minimumChargeRatePercentage',
    'variableServiceFeePercentagePerBillable',
    'variableServiceFeePercentagePerCharged',
  ]

  const roundUp = (num, precision) => {
    const formattedPrecision = 10 ** precision
    return Math.round(num * formattedPrecision) / formattedPrecision
  }

  const contractErrors = useMemo(() => {
    if (contract?.errors) {
      const errors =
        typeof contract.errors === 'string' ? [contract.errors] : contract.errors
      return errors ?? []
    }
    return []
  }, [contract])

  const formatValue = useCallback(({ field, value }) => {
    if (currencyFields.includes(field)) {
      return !isNaN(value) ? formatCurrency(value) : 'N/A'
    }
    if (percentageFields.includes(field)) {
      return !isNaN(value) ? `${roundUp(value * 100, 2)}%` : 'N/A'
    }
    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No'
    }
    return value ?? '--'
  }, [])

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{ display: 'flex', justifyContent: 'space-between', pt: 3, pb: 0, pl: 4 }}
      >
        <Box width={1}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h6">Contract Detail</Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
          {contract && !isEmpty(contractErrors) && (
            <Box mt={1}>
              <Row
                label="Errors"
                value={contractErrors.join('\n')}
                showUnderline={false}
                labelColor="error"
              />
            </Box>
          )}
        </Box>
      </DialogTitle>
      <DialogContent sx={{ mt: 2.5, px: 4 }}>
        <Row label="Contract Number" value={contract?.contractNumber} />
        <Row
          label="Contract Period"
          value={`${contract?.startDate} to ${contract?.endDate}`}
        />
        <Row
          label="Contract Type"
          value={
            systemContractTypes?.find((type) => type.id === contract?.contractType)
              ?.label ?? contract?.contractType
          }
        />
        <Row label="Salesforce ID" value={contract?.salesforceId} />
        <Divider sx={{ my: 2 }} />
        <Row label="Terms" value={contract?.terms?.join('\n')} showUnderline={false} />
        <Divider sx={{ my: 2 }} />

        <Stack direction="column" spacing={0.5}>
          {Object.entries(contract ?? {})
            .filter(
              ([key, value]) =>
                !ignoreRows.includes(key) &&
                value !== null &&
                value !== undefined &&
                value !== '',
            )
            .map(([key, value]) => (
              <Row
                key={key}
                label={humanize(underscore(key))}
                value={formatValue({ field: key, value })}
              />
            ))}
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
