import { useCallback, useMemo, useState } from 'react'

import { isEmpty } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { Alert, Box, Typography } from '@mui/material'

import { Field } from 'formik'
import * as yup from 'yup'

import { TextField } from '@common/components/Form'
import { StaticSelect } from '@common/components/Selects'
import { parseApiErrors } from '@common/utils'
import ConfirmationDialog from '@portal/UI/components/ConfirmationDialog'
import FormDialog from '@portal/UI/components/FormDialog'
import FormikStatePropagator from '@portal/UI/components/FormikStatePropagator'

const defaultValues = {
  id: '',
  name: '',
  description: '',
  windowType: '',
  additionalVotesNeeded: 0,
  primaryProfile: '',
  secondaryProfiles: [],
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {Object} [props.profile]
 * @param {string} props.profile.id
 * @param {string} props.profile.name
 * @param {string} [props.profile.description]
 * @param {string} [props.profile.windowType]
 * @param {number} props.profile.additionalVotesNeeded
 * @param {string} [props.profile.primaryProfile]
 * @param {string[]} [props.profile.secondaryProfiles]
 */
export default function SmokeProfileEnsebleForm({
  open,
  onClose,
  profile = undefined,
}) {
  const {
    systemSmokeProfiles,
    systemSmokeProfileEnsembleWindowTypes,
    doSmokeProfileEnsemblesListBulkPreview,
    doSmokeProfileEnsembleSave,
    doFetchSystem,
    doShowSnackbar,
  } = useConnect(
    'selectSystemSmokeProfiles',
    'selectSystemSmokeProfileEnsembleWindowTypes',
    'doSmokeProfileEnsemblesListBulkPreview',
    'doSmokeProfileEnsembleSave',
    'doFetchSystem',
    'doShowSnackbar',
  )

  const defaultFormikState = {
    dirty: false,
    values: {},
    errors: {},
    getFieldMeta: () => {},
    getFieldProps: () => {},
    setFieldValue: () => {},
    setFieldTouched: () => {},
  }
  const [formikProps, setFormikProps] = useState(defaultFormikState)

  const [previewData, setPreviewData] = useState(null)
  const [requestErrors, setRequestErrors] = useState(null)

  const initialValues = { ...defaultValues }

  if (profile) {
    Object.keys(initialValues).forEach((field) => {
      initialValues[field] = profile[field] ?? initialValues[field]
    })
  }

  const validationSchema = yup.object().shape({
    id: yup.string().uuid(),
    name: yup.string().max(255).required(),
    description: yup.string(),
    windowType: yup
      .string()
      .transform((value) => value ?? '')
      .test(
        'window-type-validation',
        'Window type is required if additional votes > 0',
        (value, testContext) => {
          const { additionalVotesNeeded } = testContext.parent
          if (!value && parseInt(additionalVotesNeeded, 10) > 0) {
            formikProps?.setFieldTouched('windowType')
            return false
          }
          return true
        },
      ),
    additionalVotesNeeded: yup
      .string()
      .required()
      .test(
        'additional-votes-validation',
        'Additional votes must be > 0 if window type is not empty',
        (value, testContext) => {
          const { windowType } = testContext.parent
          if (windowType && windowType.trim() !== '') {
            formikProps?.setFieldTouched('additionalVotesNeeded')
            return !!value && parseInt(value, 10) > 0
          }
          return true
        },
      ),
    primaryProfile: yup.string().uuid().required(),
    secondaryProfiles: yup.array().of(yup.string().uuid()),
  })

  const smokeProfilesOptions = useMemo(
    () =>
      systemSmokeProfiles
        ?.filter((item) => ['AVAILABLE', 'DEFAULT_ALERTING'].includes(item.status))
        ?.map((item) => ({
          id: item.id,
          label: `${item.modelKey} - ${item.name}`,
        })) ?? [],
    [systemSmokeProfiles],
  )

  const windowTypeOptions = useMemo(
    () =>
      systemSmokeProfileEnsembleWindowTypes?.map((type) => ({
        id: type.value,
        label: type.name,
      })) ?? [],
    [systemSmokeProfileEnsembleWindowTypes],
  )

  const fetchPreviewData = useCallback(
    async (formData) => {
      try {
        const previewResult = profile
          ? await doSmokeProfileEnsemblesListBulkPreview({
              ids: [profile.id],
              operation: 'change',
            })
          : []
        setPreviewData({ messages: previewResult, formData })
      } catch (err) {
        const parsedError = parseApiErrors(err?.response)
        doShowSnackbar(parsedError, 'error')
      }
    },
    [profile],
  )

  const handleProfileUpdate = useCallback(
    async (data) => {
      try {
        const result = await doSmokeProfileEnsembleSave({ ...profile, ...data })
        setPreviewData(null)

        if (result.error) {
          formikProps?.setErrors(result.error?.response)
          throw result.error
        }

        onClose(true)
        doFetchSystem()
        setRequestErrors(null)
      } catch (err) {
        if (err?.response) {
          Object.entries(err.response).forEach(([key, value]) => {
            if (key !== 'nonFieldErrors') {
              const message = Array.isArray(value) ? value.join('\n') : value
              formikProps?.setFieldError(key, message)
            }
          })
          setRequestErrors(err.response.nonFieldErrors)
        } else {
          doShowSnackbar('Oops. Something went wrong', 'error')
        }
      }
    },
    [profile, formikProps],
  )

  if (profile) {
    initialValues.id = profile.id
    initialValues.name = profile.name
    initialValues.description = profile.description
  }

  const buildConfirmationMessage = useCallback(
    (messages) => {
      if (messages && !isEmpty(messages)) {
        return messages
          .filter((data) => data.kind !== 'error')
          .map((data) => `${data.propertyName}: ${data.message}`)
          .join('\n\n')
      }
      return profile
        ? 'Are you sure you want to change this profile?'
        : 'Are you sure you want to create profile with such parameters?'
    },
    [profile],
  )

  const onSave = useCallback(
    (data) => {
      const formattedData = Object.entries(data).reduce(
        (acc, item) => ({
          ...acc,
          [item[0]]: item[1] ?? defaultValues[item[0]],
        }),
        {},
      )
      if (profile) {
        fetchPreviewData(formattedData)
      } else {
        handleProfileUpdate(formattedData)
      }
    },
    [defaultValues, fetchPreviewData, handleProfileUpdate],
  )

  const fieldStyle = { width: '100%', mb: 2 }

  return (
    <>
      <ConfirmationDialog
        open={!!previewData}
        title="Confirm Profile Change"
        message={buildConfirmationMessage(previewData?.messages)}
        maxWidth="xl"
        fullWidth={false}
        contentFontSize={14}
        error={previewData?.messages
          .filter((data) => data.kind === 'error')
          .map((data) => data.message)
          .join('\n\n')}
        onConfirm={() => handleProfileUpdate(previewData.formData)}
        onCancel={() => setPreviewData(null)}
      />
      <FormDialog
        label={`${profile ? 'Edit' : 'Create'} Smoke Profile Ensemble`}
        open={open}
        onSave={onSave}
        onClose={(data) => {
          onClose(data)
          setRequestErrors(null)
        }}
        initialValues={initialValues}
        validationSchema={validationSchema}
        extraActionsPosition="right"
      >
        {!isEmpty(requestErrors ?? []) && (
          <Alert severity="error" sx={{ mb: 2 }}>
            <Box>
              {requestErrors.map((err) => (
                <Typography key={err}>{err}</Typography>
              ))}
            </Box>
          </Alert>
        )}
        <Field
          required
          fullWidth
          component={TextField}
          label="Name"
          name="name"
          variant="standard"
          error={!!formikProps.errors.name || !!requestErrors?.name}
          helperText={formikProps.errors.name || requestErrors?.name}
          sx={fieldStyle}
        />
        <Field
          fullWidth
          component={TextField}
          label="Description (optional)"
          name="description"
          variant="standard"
          error={!!formikProps.errors.description || !!requestErrors?.description}
          helperText={formikProps.errors.description || requestErrors?.description}
          sx={fieldStyle}
        />

        <Box display="flex" gap={3}>
          <Field
            required
            fullWidth
            component={TextField}
            label="Additional Votes Needed"
            name="additionalVotesNeeded"
            type="number"
            variant="standard"
            error={
              !!formikProps.errors.additionalVotesNeeded ||
              !!requestErrors?.additionalVotesNeeded
            }
            helperText={
              formikProps.errors.additionalVotesNeeded ||
              requestErrors?.additionalVotesNeeded
            }
            sx={fieldStyle}
          />
          <Field
            fullWidth
            component={StaticSelect}
            label="Window Type"
            name="windowType"
            variant="standard"
            options={windowTypeOptions}
            sx={fieldStyle}
          />
        </Box>
        <Field
          required
          fullWidth
          component={StaticSelect}
          label="Primary Profile"
          name="primaryProfile"
          variant="standard"
          options={smokeProfilesOptions}
          sx={fieldStyle}
        />
        <Field
          fullWidth
          multiple
          component={StaticSelect}
          label="Secondary Profiles"
          name="secondaryProfiles"
          variant="standard"
          options={smokeProfilesOptions}
          sx={fieldStyle}
        />
        <FormikStatePropagator propSetter={setFormikProps} />
      </FormDialog>
    </>
  )
}
