import { path } from 'ramda'

import { DateTime } from 'luxon'

import { isAbortError, isDateRangeOverlap } from '@common/utils'
import { automotiveCategoryToTypesMap } from '@common/utils/grafanaViewDataProcessor'

const EVENT_MODAL_DATA_LOADING = 'EVENT_MODAL_DATA_LOADING'
const EVENT_MODAL_DATA_LOADED = 'EVENT_MODAL_DATA_LOADED'
const EVENT_MODAL_DATA_FAILED = 'EVENT_MODAL_DATA_FAILED'

const EVENT_MODAL_CHART_LOADING = 'EVENT_MODAL_CHART_LOADING'
const EVENT_MODAL_CHART_SUCCESS = 'EVENT_MODAL_CHART_SUCCESS'
const EVENT_MODAL_CHART_FAILED = 'EVENT_MODAL_CHART_FAILED'

const EVENT_MODAL_UNIT_DEVICES_LOADING = 'EVENT_MODAL_UNIT_DEVICES_LOADING'
const EVENT_MODAL_UNIT_DEVICES_LOADED = 'EVENT_MODAL_UNIT_DEVICES_LOADED'
const EVENT_MODAL_UNIT_DEVICES_FAILED = 'EVENT_MODAL_UNIT_DEVICES_FAILED'

const EVENT_MODAL_RESET_STATE = 'EVENT_MODAL_RESET_STATE'

const defaultState = {}

const entityName = 'eventModal'

function generateTimeSequence({ start, end }) {
  const result = []
  let currentTime = start
  while (currentTime <= end) {
    result.push(
      currentTime
        .set({ seconds: 0 })
        .startOf('second')
        .toISO({ suppressMilliseconds: true }),
    )
    currentTime = currentTime.plus({ minutes: 1 })
  }
  return result
}

function getDataFromPromiseResult(result) {
  if (result.status === 'rejected') {
    throw result.reason
  }
  return result.status === 'fulfilled' ? result.value : null
}

function processChartModelData({ start, end, data }) {
  const zone = '+00:00'
  const initialDataArray = generateTimeSequence({
    start: DateTime.fromISO(start).setZone(zone),
    end: DateTime.fromISO(end).setZone(zone),
  })
  const dataObject = data.reduce((acc, { time, value }) => {
    const parsedTime = DateTime.fromISO(time, { zone })
    acc[parsedTime.set({ seconds: 0 }).toISO({ suppressMilliseconds: true })] = {
      originalTime: parsedTime.toISO({ suppressMilliseconds: true }),
      value,
    }
    return acc
  }, {})
  return initialDataArray.map((time) => ({
    time: dataObject[time]?.originalTime ?? time,
    value: dataObject[time]?.value ?? null,
  }))
}

async function fetchSingleModel({ apiFetch, deviceId, start, end, modelKey }) {
  let result
  try {
    const response = await apiFetch(
      `/devices/${deviceId}/model_chart/`,
      {
        start,
        end,
        modelKey,
      },
      { cancelationPrefix: `${entityName}_${modelKey}` },
    )
    result = response.map((item) => ({
      ...item,
      data: processChartModelData({ start, end, data: item.data }),
      category: modelKey,
    }))
  } catch (err) {
    const errMessage =
      err.error?.status === 400 && typeof err.error?.response === 'object'
        ? Object.values(err.error.response)
            .reduce((acc, errors) => [...acc, ...errors], [])
            .join('\n')
        : null
    result = { error: new Error(errMessage || ''), category: modelKey }
  }
  return result
}

async function fetchSmokeChartModels({
  apiFetch,
  deviceId,
  start,
  end,
  smokeModelsKeys,
}) {
  const results = await Promise.allSettled(
    smokeModelsKeys.map((modelKey) =>
      fetchSingleModel({ apiFetch, deviceId, start, end, modelKey }),
    ),
  )
  return results.reduce((acc, result) => {
    const parsedResult = getDataFromPromiseResult(result)
    return [...acc, ...(Array.isArray(parsedResult) ? parsedResult : [parsedResult])]
  }, [])
}

export default {
  name: entityName,
  reducer: (state, action) => {
    if (action.type === EVENT_MODAL_RESET_STATE) {
      return defaultState
    }
    if (action.type === EVENT_MODAL_DATA_LOADING) {
      return { ...state, data: { ...action.meta, payload: action.payload } }
    }
    if (action.type === EVENT_MODAL_DATA_LOADED) {
      return { ...state, data: { ...action.meta, data: action.payload } }
    }
    if (action.type === EVENT_MODAL_DATA_FAILED) {
      return { ...state, data: { ...action.meta, error: action.payload } }
    }
    if (action.type === EVENT_MODAL_CHART_LOADING) {
      return { ...state, chart: { ...action.meta, payload: action.payload } }
    }
    if (action.type === EVENT_MODAL_CHART_SUCCESS) {
      return { ...state, chart: { ...action.meta, data: action.payload } }
    }
    if (action.type === EVENT_MODAL_CHART_FAILED) {
      return { ...state, chart: { ...action.meta, error: action.payload } }
    }
    return state || defaultState
  },
  selectEventModalDataIsLoading: ({ eventModal }) => {
    const status = path(['data', 'status'], eventModal)
    return status === 'loading'
  },
  selectEventModalChartDataIsLoading: ({ eventModal }) => {
    const status = path(['chart', 'status'], eventModal)
    return status === 'loading'
  },
  selectEventModalIsLoading: ({ eventModal }) => {
    const dataStatus = path(['data', 'status'], eventModal)
    const chartStatus = path(['chart', 'status'], eventModal)
    return dataStatus === 'loading' || chartStatus === 'loading'
  },
  selectEventModalData: ({ eventModal }) => path(['data', 'data'], eventModal),
  selectEventModalChartData: ({ eventModal }) => path(['chart', 'data'], eventModal),
  selectEventModalDataError: ({ eventModal }) => path(['data', 'error'], eventModal),
  selectEventModalChartDataError: ({ eventModal }) =>
    path(['chart', 'error'], eventModal),
  doResetEventModalState:
    () =>
    ({ dispatch }) =>
      dispatch({ type: EVENT_MODAL_RESET_STATE }),
  doFetchEventModalData:
    (payload) =>
    async ({ dispatch, apiFetch }) => {
      try {
        dispatch({
          type: EVENT_MODAL_DATA_LOADING,
          payload,
          meta: { status: 'loading' },
        })

        const { id, entity } = payload

        const eventResponse =
          entity === 'event'
            ? await apiFetch(
                `/events/${id}/`,
                { includeChildEvents: true },
                { cancelationPrefix: entityName },
              )
            : null
        const deviceResponse = await apiFetch(
          `/devices/${entity === 'event' ? eventResponse.device : id}/`,
          null,
          { cancelationPrefix: entityName },
        )
        const unitResponse = await apiFetch(`/units/${deviceResponse.unitId}/`, null, {
          cancelationPrefix: entityName,
        })

        const data = {
          event: eventResponse,
          unit: unitResponse,
          device: deviceResponse,
        }

        dispatch({
          type: EVENT_MODAL_DATA_LOADED,
          payload: data,
          meta: { status: 'succeeded' },
        })
        return data
      } catch (err) {
        if (!isAbortError(err)) {
          dispatch({
            type: EVENT_MODAL_DATA_FAILED,
            payload: err,
            meta: { status: 'failed' },
          })
          throw err
        }
        return null
      }
    },
  doFetchEventModalChartData:
    (payload) =>
    async ({ store, dispatch, apiFetch }) => {
      try {
        dispatch({
          type: EVENT_MODAL_CHART_LOADING,
          payload,
          meta: { status: 'loading' },
        })

        const {
          event,
          device,
          selectedArea,
          selectedTimezone,
          selectedDateTimeData,
          selectedSmokeModels,
        } = payload

        const timeZone = event?.timeZone ?? device?.timezone

        const { custom, range } = selectedDateTimeData
        const [selectedStart, selectedEnd] = range

        const zone = selectedTimezone ?? timeZone
        const unit = event?.unit ?? device?.unitId

        const timeNow = DateTime.now().setZone(zone)
        const start = selectedStart.setZone(zone)
        const end = selectedEnd.setZone(zone)

        const timeOffset = { hours: custom ? 0 : 12 }
        const dataResolution = 1000

        let apiStart = start.minus(timeOffset).toISO()
        let apiEnd =
          end.plus(timeOffset) > timeNow
            ? timeNow.toISO()
            : end.plus(timeOffset).toISO()

        if (selectedArea) {
          apiStart = selectedArea.start
          apiEnd = selectedArea.end
        }

        const deviceId = event?.device ?? device?.id

        const smokeProfilesIds = store
          .selectSystemSmokeProfiles()
          .map((profile) => profile.id)
        const smokeModelsKeys = Object.entries(selectedSmokeModels)
          .filter((data) => data[1])
          .map(([model]) => model)
        const deviceModelData = store
          .selectSystemDeviceModels()
          ?.find((model) => model.id === device?.model)

        const defaultDataTypes = [
          'mc_1p0_mean',
          'mc_2p5_mean',
          'mc_4p0_mean',
          'mc_10p0_mean',
          'tpm_mean',
          'dba_mean',
          'nrs',
          'hum_mean',
          'temp_mean',
          'eco2_mean',
          'tvoc_mean',
          'nox_mean',
          'voc_mean',
        ]
        const automotiveDataTypes = Object.values(automotiveCategoryToTypesMap).reduce(
          (acc, arr) => [...acc, ...arr],
          [],
        )

        const isAutomotive = deviceModelData?.label === 'ag0i'

        const apiResults = await Promise.allSettled([
          apiFetch(
            `/devices/${deviceId}/chart/`,
            {
              start: apiStart,
              end: apiEnd,
              resolution: dataResolution,
              dataTypes: isAutomotive ? automotiveDataTypes : defaultDataTypes,
            },
            { cancelationPrefix: entityName },
          ),
          ...(smokeProfilesIds.length
            ? [
                apiFetch(
                  `/devices/${deviceId}/probability_chart/`,
                  {
                    start: apiStart,
                    end: apiEnd,
                    resolution: dataResolution,
                    profiles: smokeProfilesIds,
                  },
                  { cancelationPrefix: entityName },
                ),
              ]
            : [null]),
          ...(smokeModelsKeys?.length
            ? [
                fetchSmokeChartModels({
                  apiFetch,
                  deviceId,
                  start: apiStart,
                  end: apiEnd,
                  smokeModelsKeys,
                }),
              ]
            : [null]),

          apiFetch(
            `/events/`,
            {
              device: deviceId,
              createdOnAfter: apiStart,
              createdOnBefore: apiEnd,
              eventClass: ['NOISE'],
              includeDeletedEvents: false,
              pageSize: 9999,
            },
            { cancelationPrefix: entityName },
          ),
          apiFetch(
            `/alarms/`,
            {
              deviceId,
              startAfter: apiStart,
              startBefore: apiEnd,
              pageSize: 9999,
            },
            { cancelationPrefix: entityName },
          ),
          apiFetch(
            `/reservations/`,
            { unit, pageSize: 999 },
            { cancelationPrefix: entityName },
          ),
          apiFetch(
            `/scenarios/`,
            { sourceDevice: deviceId, pageSize: 9999 },
            { cancelationPrefix: entityName },
          ),
        ])

        const chartResponseData = apiResults[0]
        const probabilityResponseData = apiResults[1]
        const modelChartResponseData = apiResults[2]
        const noiseEventsResponseData = apiResults[3]
        const alarmsResponseData = apiResults[4]
        const reservationsResponseData = apiResults[5]
        const scenariosResponseData = apiResults[6]

        const chartResponse = getDataFromPromiseResult(chartResponseData)
        const probabilityResponse = getDataFromPromiseResult(probabilityResponseData)
        const modelChartResponse = getDataFromPromiseResult(modelChartResponseData)
        const noiseEventsResponse = getDataFromPromiseResult(noiseEventsResponseData)
        const alarmsResponse = getDataFromPromiseResult(alarmsResponseData)
        const reservationsResponse = getDataFromPromiseResult(reservationsResponseData)
        const scenariosResponse = getDataFromPromiseResult(scenariosResponseData)

        const processedNoiseEvents = noiseEventsResponse?.results?.map((eventData) => ({
          ...eventData,
          createdOn: DateTime.fromISO(eventData.createdOn, { zone }),
          endTime: eventData.endTime
            ? DateTime.fromISO(eventData.endTime, { zone })
            : timeNow,
          ongoing: !eventData.endTime,
        }))
        const processedAlarms = alarmsResponse?.results?.map((alarm) => ({
          ...alarm,
          start: DateTime.fromISO(alarm.start, { zone }),
          end: alarm.end ? DateTime.fromISO(alarm.end, { zone }) : timeNow,
          ongoing: !alarm.end,
        }))
        const processedReservations = reservationsResponse?.results
          ?.map((reservation) => ({
            ...reservation,
            checkIn: DateTime.fromISO(reservation.checkIn, { zone }),
            checkOut: DateTime.fromISO(reservation.checkOut, { zone }),
          }))
          .filter((reservation) =>
            isDateRangeOverlap(
              { start: reservation.checkIn, end: reservation.checkOut },
              { start: apiStart, end: apiEnd },
            ),
          )
        const processedScenarios = scenariosResponse?.results?.filter((scenario) =>
          isDateRangeOverlap(
            { start: scenario.readingStart, end: scenario.readingEnd },
            { start: apiStart, end: apiEnd },
          ),
        )

        let data = chartResponse
        if (probabilityResponse) {
          data = data.concat(probabilityResponse)
        }
        if (modelChartResponse) {
          data = data.concat(
            modelChartResponse.map((item) => {
              if (item.error) {
                return {
                  key: item.category,
                  category: item.category,
                  modelChart: true,
                  data: [],
                  error: item.error,
                }
              }
              return {
                ...item,
                modelChart: true,
                category: item.category,
              }
            }),
          )
        }
        const finalResult = {
          data,
          alarms: processedAlarms ?? [],
          reservations: processedReservations ?? [],
          scenarios: processedScenarios ?? [],
          noiseEvents: processedNoiseEvents ?? [],
        }

        dispatch({
          type: EVENT_MODAL_CHART_SUCCESS,
          payload: finalResult,
          meta: { status: 'succeeded' },
        })
        return finalResult
      } catch (err) {
        if (!isAbortError(err)) {
          dispatch({
            type: EVENT_MODAL_CHART_FAILED,
            payload: err,
            meta: { status: 'failed' },
          })
          throw err
        }
        return null
      }
    },
  doFetchEventModalUnitDevices:
    (payload) =>
    async ({ dispatch, apiFetch }) => {
      try {
        dispatch({
          type: EVENT_MODAL_UNIT_DEVICES_LOADING,
          payload,
          meta: { status: 'loading' },
        })

        const unitDevicesResponse = await apiFetch(
          '/devices/',
          {
            pageSize: 9999,
            unit: payload,
          },
          { cancelationPrefix: entityName },
        )
        const devices = unitDevicesResponse?.results ?? []

        dispatch({
          type: EVENT_MODAL_UNIT_DEVICES_LOADED,
          payload: devices,
          meta: { status: 'succeeded' },
        })
        return devices
      } catch (err) {
        if (!isAbortError(err)) {
          dispatch({
            type: EVENT_MODAL_UNIT_DEVICES_FAILED,
            payload: err,
            meta: { status: 'failed' },
          })
          throw err
        }
        return null
      }
    },
}
