import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { fromPairs, isEmpty, pickBy, sortBy, toPairs } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import {
  Add,
  CopyAll,
  Delete,
  Edit,
  FileDownload,
  LabelRounded,
  RestartAlt,
  SsidChartRounded,
  ZoomOut,
} from '@mui/icons-material'
import {
  Box,
  Button,
  Chip,
  Divider,
  FormControlLabel,
  Grid2,
  IconButton,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
} from '@mui/material'

import { titleize } from 'inflection'
import { DateTime } from 'luxon'

import { Breadcrumbs, Loading } from '@common/components'
import { Chart, MemoChartMinimap } from '@common/components/chart'
import { formatDuration, parseApiErrors, useSmallScreen } from '@common/utils'
import {
  DIAGNOSTIC_CATEGORY,
  ENVIRONMENT_CATEGORY,
  getDeviceMetaData,
  NOISE_CATEGORY,
  OCCUPANCY_GATEGORY,
  SMOKE_CATEGORY,
  SMOKE_MASS_CONCENTRATION_CATEGORY,
  SMOKE_NUMBER_COUNT_CATEGORY,
} from '@portal/pages/Devices/deviceMetaData'
import devicesUrls from '@portal/pages/Devices/urls'
import { homeUrls } from '@portal/pages/Home'
import DeleteModal from '@portal/UI/components/DeleteModal'
import DetailItem from '@portal/UI/components/DetailItem'
import ListPageTitle from '@portal/UI/components/ListPageTitle'
import { darkTheme } from '@portal/UI/Theme'

import EventViewModal from '../EventDashboard/EventModal/EventViewModal'
import GenerateLabelsModal from './GenerateLabelsModal'
import ScenarioForm from './ScenarioForm'
import scenariosUrls from './urls'

export default function ScenarioDetails() {
  const [currentScenario, setCurrentScenario] = useState(null)
  const [grafanaViewDevice, setGrafanaViewDevice] = useState(null)
  const [generateLabelsReadings, setGenerateLabelsReadings] = useState(null)
  const [editFormOpen, setEditFormOpen] = useState(false)
  const [deleteFormOpen, setDeleteFormOpen] = useState(false)
  const [deleteError, setDeleteError] = useState()
  const [cursor, setCursor] = useState(null)

  const [selectorId, setSelectorId] = useState(null)
  const [selectorStart, setSelectorStart] = useState(null)
  const [selectorEnd, setSelectorEnd] = useState(null)
  const [selectedArea, setSelectedArea] = useState(null)
  const selectedAreaHistory = useRef([])

  const [scenarioLabels, setScenarioLabels] = useState([])
  const [labelAreas, setLabelAreas] = useState(null)

  const isSmallScreen = useSmallScreen()

  const {
    doUpdateQuery,
    doScenarioListExport,
    doScenarioDelete,
    doScenarioFetch,
    doScenarioSave,
    doShowSnackbar,
    doUpdateUrl,
    routeParams: { id: scenarioId },
    scenarioFetch: scenario,
    scenarioFetchStatus,
    scenarioSaveStatus,
    systemDeviceModels,
    systemDataTypes,
    isAtLeastDataScience,
  } = useConnect(
    'doUpdateQuery',
    'doScenarioListExport',
    'doScenarioDelete',
    'doScenarioFetch',
    'doScenarioSave',
    'doShowSnackbar',
    'doUpdateUrl',
    'selectRouteParams',
    'selectScenarioFetch',
    'selectScenarioFetchStatus',
    'selectScenarioSaveStatus',
    'selectSystemDeviceModels',
    'selectSystemDataTypes',
    'selectIsAtLeastDataScience',
  )

  const fetchScenario = useCallback(async (id) => {
    try {
      await doScenarioFetch(id)
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [])

  useEffect(() => {
    fetchScenario(scenarioId)
  }, [])

  useEffect(() => {
    if (scenario) {
      setScenarioLabels(
        isEmpty(scenario.labels)
          ? []
          : scenario.labels.map((label) => ({ id: Math.random(), ...label })),
      )
    }
  }, [scenario])

  const deviceMetaData = getDeviceMetaData(systemDataTypes)

  const categoriesOrder = {
    [`${SMOKE_MASS_CONCENTRATION_CATEGORY}`]: 0,
    [`${SMOKE_CATEGORY}`]: 1,
    [`${SMOKE_NUMBER_COUNT_CATEGORY}`]: 2,
    [`${ENVIRONMENT_CATEGORY}`]: 3,
    [`${NOISE_CATEGORY}`]: 4,
    [`${OCCUPANCY_GATEGORY}`]: 5,
    [`${DIAGNOSTIC_CATEGORY}`]: 6,
  }

  const handleEditClick = () => {
    setEditFormOpen(true)
    setCurrentScenario(scenario)
  }

  const handleDeleteClick = () => {
    setDeleteFormOpen(true)
    setCurrentScenario(scenario)
  }

  const handleDelete = async () => {
    try {
      await doScenarioDelete(currentScenario.id)
      doShowSnackbar('Successfully deleted scenario')
      setDeleteFormOpen(false)
      doUpdateUrl(scenariosUrls.list)
    } catch (error) {
      setDeleteError(error)
    }
  }

  const handleSaveLabel = useCallback(async () => {
    if (labelAreas && scenario) {
      try {
        const { readings, ...scenarioAttrs } = scenario

        // TODO: remove after API update
        delete scenarioAttrs.labelStart
        delete scenarioAttrs.labelEnd

        await doScenarioSave({
          ...scenarioAttrs,
          labels: labelAreas.map((area) => ({
            start: area.x0i.toISOString(),
            end: area.x1i.toISOString(),
          })),
        })

        setLabelAreas(null)
        doShowSnackbar('Label has been updated', 'success')
        fetchScenario(scenarioId)
      } catch (err) {
        const errMessage = parseApiErrors(err?.response)
        doShowSnackbar(errMessage, 'error')
      }
      return
    }
    doShowSnackbar('Oops. An unexpected error occurred', 'error')
  }, [labelAreas, scenario, scenarioId])

  const handleLabelMode = () => {
    if (labelAreas) {
      setLabelAreas(null)
    } else {
      const initialAreas = !isEmpty(scenarioLabels)
        ? scenarioLabels.map((label) => ({
            ...label,
            initial: true,
            start: DateTime.fromISO(label.start),
            end: DateTime.fromISO(label.end),
          }))
        : []

      setLabelAreas(initialAreas)
    }
  }

  const handleZoomOut = () => {
    selectedAreaHistory.current.splice(-1)
    const latestHistoryArea = selectedAreaHistory.current.at(-1)
    setSelectedArea(
      latestHistoryArea ? { ...latestHistoryArea, updateBrush: true } : null,
    )
  }

  const handleZoomReset = () => {
    selectedAreaHistory.current.length = 0
    setSelectedArea(null)
  }

  const dtCategories = Object.entries(deviceMetaData).reduce(
    (acc, [dt, meta]) => ({
      ...acc,
      [meta.category]:
        meta.category in acc
          ? [...acc[meta.category], { ...meta, name: dt }]
          : [{ ...meta, name: dt }],
    }),
    [],
  )

  const formatReadings = (readings, dts) => {
    const dtKeys = dts.map((dt) => dt.name)
    const hasDtPrefix = (val, key) => ['time', ...dtKeys].some((k) => key.includes(k))
    return readings.map((r) => {
      const filteredDts = pickBy(hasDtPrefix, r)
      return { ...filteredDts, time: new Date(filteredDts.time) }
    })
  }

  const sortByCategory = (data) => {
    const arr = toPairs(data)
    const sortedArr = sortBy((pair) => categoriesOrder[`${pair[0]}`], arr)
    return fromPairs(sortedArr)
  }

  const processedData = useMemo(() => {
    if (scenario?.readings) {
      return Object.entries(dtCategories).reduce((acc, [category, dataTypes]) => {
        const readings = formatReadings(scenario.readings, dataTypes)
        return { ...acc, [category]: readings }
      }, {})
    }
    return {}
  }, [scenario])

  const labelsRanges = useMemo(
    () =>
      scenario?.labels.map((label) => ({
        start: DateTime.fromISO(label.start),
        end: DateTime.fromISO(label.end),
      })) ?? [],
    [scenario],
  )

  const sourceDeviceLink = useMemo(() => {
    if (scenario?.sourceDevice) {
      const minuteBuffer = 30
      const baseUrl = devicesUrls.entity.replace(':id', scenario.sourceDevice)
      const startTime = DateTime.fromISO(scenario.readingStart)
        .minus({ minute: minuteBuffer })
        .toMillis()
      const endTime = DateTime.fromISO(scenario.readingEnd)
        .plus({ minute: minuteBuffer })
        .toMillis()
      return `${baseUrl}#device=start:${startTime}&end:${endTime}`
    }
    return null
  }, [scenario])

  const formatLabelDateText = (value, originalValue) => {
    const format = 'LLL dd, yyyy hh:mma'
    if (value === undefined || value === null) {
      if (originalValue) {
        return DateTime.fromISO(originalValue).toFormat(format)
      }
      return '--'
    }
    if (value instanceof Date) {
      return DateTime.fromJSDate(value).toFormat(format)
    }
    return Math.round(value)
  }

  const LabelRow = useCallback(
    ({ id, x0, x1, originalX0, originalX1 }) => (
      <TableRow key={id ?? Math.random()}>
        <TableCell component="th" scope="row">
          {formatLabelDateText(x0, originalX0)}
        </TableCell>
        <TableCell>{formatLabelDateText(x1, originalX1)}</TableCell>
        <TableCell align="right">
          <IconButton
            sx={{
              opacity: labelAreas?.length > 1 ? 1 : 0,
              pointerEvents: labelAreas?.length > 1 ? 'auto' : 'none',
            }}
            onClick={() => {
              setLabelAreas((prev) => {
                const updated = [...prev]
                const indexToRemove = updated.findIndex((item) => item.id === id)
                if (indexToRemove !== -1) {
                  updated.splice(indexToRemove, 1)
                }
                return updated
              })
            }}
          >
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
    ),
    [labelAreas],
  )

  const isSaveLabelAllowed = (() => {
    if (scenarioSaveStatus === 'loading' || !isAtLeastDataScience) {
      return false
    }
    if (labelAreas && !isEmpty(labelAreas) && scenario) {
      return (
        labelAreas.length !== scenarioLabels.length ||
        labelAreas.some((area) => {
          const invalidData = area.x0i === undefined || area.x1i === undefined
          if (invalidData) {
            return false
          }

          const originalLabel = scenarioLabels.find((label) => label.id === area.id)
          if (!originalLabel) {
            return true
          }

          const newStart = DateTime.fromJSDate(area.x0i).toMillis()
          const newEnd = DateTime.fromJSDate(area.x1i).toMillis()
          const originalStart = DateTime.fromISO(originalLabel.start).toMillis()
          const originalEnd = DateTime.fromISO(originalLabel.end).toMillis()
          return originalStart !== newStart || originalEnd !== newEnd
        })
      )
    }
    return false
  })()

  const ActionButtons = useCallback(
    ({ fullWidth }) => (
      <Stack direction="row" alignItems="center" gap={2}>
        {isAtLeastDataScience && (
          <Button
            fullWidth={fullWidth}
            variant="outlined"
            disabled={!isSaveLabelAllowed}
            startIcon={<LabelRounded />}
            onClick={handleSaveLabel}
          >
            Save Labels
          </Button>
        )}
        <Button
          fullWidth={fullWidth}
          disabled={!scenario?.sourceDevice}
          variant="outlined"
          startIcon={<SsidChartRounded />}
          onClick={() => {
            setGrafanaViewDevice(scenario?.sourceDevice)
            doUpdateQuery(
              { grafanaDevice: scenario.sourceDevice },
              { maintainScrollPosition: true },
            )
          }}
        >
          Grafana View
        </Button>
      </Stack>
    ),
    [scenario, labelAreas, isAtLeastDataScience, isSaveLabelAllowed],
  )

  if (scenarioFetchStatus === 'failed') {
    return <Typography>Invalid ID or something or other</Typography>
  }

  if (scenarioFetchStatus === 'loading') {
    return <Typography>Loading...</Typography>
  }

  if (!scenario) return <Loading />

  const durationText = (() => {
    if (scenario.readings?.length > 0) {
      const firstReadingTime = DateTime.fromISO(scenario.readings[0].time)
      const lastReadingTime = DateTime.fromISO(
        scenario.readings[scenario.readings.length - 1].time,
      )
      return formatDuration(firstReadingTime, lastReadingTime)
    }

    return null
  })()

  return (
    <>
      <ScenarioForm
        open={editFormOpen}
        onClose={() => {
          setCurrentScenario(null)
          setEditFormOpen(false)
          fetchScenario(currentScenario.id)
        }}
        instance={currentScenario}
      />
      <DeleteModal
        open={deleteFormOpen}
        error={deleteError}
        onConfirmDelete={handleDelete}
        onCancelDelete={() => {
          setDeleteFormOpen(false)
          setCurrentScenario(null)
        }}
      />
      <GenerateLabelsModal
        open={!!generateLabelsReadings}
        readings={generateLabelsReadings}
        onClose={() => setGenerateLabelsReadings(null)}
        onApply={(labels) => {
          const processedLabels = labels.map((label) => ({
            id: Math.random(),
            initial: true,
            start: DateTime.fromISO(label.start),
            end: DateTime.fromISO(label.end),
          }))
          setLabelAreas(processedLabels)
          setGenerateLabelsReadings(null)
        }}
      />
      <ThemeProvider theme={darkTheme}>
        <EventViewModal
          id={grafanaViewDevice}
          entity="device"
          initialTimeRange={[
            DateTime.fromISO(scenario.readingStart).minus({ hours: 1 }),
            DateTime.fromISO(scenario.readingEnd).plus({ hours: 1 }),
          ]}
          onClose={() => {
            doUpdateQuery({}, { maintainScrollPosition: true })
            setGrafanaViewDevice(null)
          }}
        />
      </ThemeProvider>
      <Box sx={{ px: isSmallScreen ? 0 : 2, m: isSmallScreen ? 3 : 4 }}>
        <Breadcrumbs
          links={[
            { label: 'Home', href: homeUrls.home },
            { label: 'Scenarios', href: scenariosUrls.list },
            { label: scenario?.name || 'Loading...' },
          ]}
        />
        {scenario?.id && (
          <Box display="flex" alignItems="center" my={1}>
            <Typography variant="caption" color="text.secondary">
              id: {scenario.id}
            </Typography>
            <CopyAll
              sx={{ m: 0.5, cursor: 'pointer', fontSize: 16 }}
              onClick={() => {
                navigator.clipboard.writeText(scenario.id)
                doShowSnackbar('ID copied to clipboard')
              }}
            />
          </Box>
        )}
        {isSmallScreen ? (
          <Box mb={1}>
            <ListPageTitle
              title={scenario?.name}
              menuItems={[
                {
                  label: 'Export',
                  onClick: () => doScenarioListExport({ scenarios: [scenario.id] }),
                },
                ...(isAtLeastDataScience
                  ? [
                      { label: 'Edit', onClick: handleEditClick },
                      { label: 'Delete', onClick: handleDeleteClick },
                    ]
                  : []),
              ]}
            />
          </Box>
        ) : (
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h3">{scenario?.name}</Typography>
            <Box>
              <Button
                startIcon={<FileDownload />}
                variant="outlined"
                sx={{ mr: 2 }}
                onClick={() => doScenarioListExport({ scenarios: [scenario.id] })}
              >
                Export
              </Button>
              {isAtLeastDataScience && (
                <>
                  <Button
                    startIcon={<Edit />}
                    variant="outlined"
                    sx={{ mr: 2 }}
                    onClick={handleEditClick}
                  >
                    Edit
                  </Button>
                  <Button
                    startIcon={<Delete />}
                    variant="outlined"
                    color="error"
                    onClick={handleDeleteClick}
                  >
                    Delete
                  </Button>
                </>
              )}
            </Box>
          </Box>
        )}
        <Grid2 container mb={2} pt={1} gap={isSmallScreen ? 1 : 3}>
          <DetailItem
            label="Source Device"
            value={scenario.sourceDeviceMac ?? '-'}
            linkTo={sourceDeviceLink}
          />
          <DetailItem
            label="Model"
            value={
              systemDeviceModels?.find((model) => model.id === scenario?.deviceModel)
                ?.name
            }
          />
          {durationText && <DetailItem label="Duration" value={durationText} />}
          <DetailItem
            label="Tags"
            value={scenario?.scenarioTags}
            renderValue={(value) => {
              if (value.length > 0) {
                return (
                  <Box
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    gap={1}
                    sx={{ mt: 0.5 }}
                  >
                    {value.map((tag) => (
                      <Chip
                        key={tag}
                        label={tag}
                        variant="outlined"
                        size="small"
                        color="primary"
                      />
                    ))}
                  </Box>
                )
              }
              return <Typography variant="h6">--</Typography>
            }}
          />
        </Grid2>
        <Grid2 container mb={2} gap={isSmallScreen ? 1 : 3}>
          <DetailItem label="Description" value={scenario?.description ?? '--'} />
          <DetailItem label="Feedback" value={scenario?.feedback ?? '--'} />
        </Grid2>
        <Divider />
        <Box
          height={42}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mt={2}
        >
          <Stack direction="row" alignItems="center">
            {isAtLeastDataScience && (
              <FormControlLabel
                control={<Switch checked={!!labelAreas} onChange={handleLabelMode} />}
                label="Labeling mode"
              />
            )}
          </Stack>
          {!isSmallScreen && <ActionButtons />}
        </Box>
        {isSmallScreen && (
          <Box my={1}>
            <ActionButtons fullWidth />
          </Box>
        )}
        <Paper sx={{ mt: 2 }}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant={isSmallScreen ? 'body2' : 'h5'} pl={2} py={2}>
                Labels
              </Typography>
              {labelAreas && (
                <IconButton
                  size="small"
                  onClick={() => {
                    setLabelAreas((prev) => [
                      ...prev,
                      {
                        id: Math.random(),
                        initial: true,
                        start: DateTime.fromISO(
                          scenario.readingStart ?? scenario.readings.at(0).time,
                        ),
                        end: DateTime.fromISO(
                          scenario.readingEnd ?? scenario.readings.at(-1).time,
                        ),
                      },
                    ])
                  }}
                >
                  <Add />
                </IconButton>
              )}
            </Box>
            {labelAreas && (
              <Button
                sx={{ mr: 2 }}
                onClick={() => setGenerateLabelsReadings(scenario.readings)}
              >
                Generate
              </Button>
            )}
          </Box>
          {(!labelAreas || isEmpty(labelAreas)) && isEmpty(scenarioLabels) ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Typography
                variant={isSmallScreen ? 'body2' : 'h6'}
                sx={{ p: isSmallScreen ? 2 : 4 }}
              >
                No Labels Found
              </Typography>
            </Box>
          ) : (
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Start</TableCell>
                    <TableCell>End</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!labelAreas &&
                    scenarioLabels.map((label) => (
                      <LabelRow
                        id={label.id}
                        key={label.id}
                        x0={null}
                        x1={null}
                        originalX0={label.start}
                        originalX1={label.end}
                      />
                    ))}
                  {labelAreas &&
                    labelAreas.map((area) => {
                      const label = scenarioLabels.find((item) => item.id === area.id)
                      return (
                        <LabelRow
                          id={area.id}
                          key={area.id}
                          x0={area.x0i}
                          x1={area.x1i}
                          originalX0={label?.start}
                          originalX1={label?.end}
                        />
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Paper>

        {selectedArea && (
          <Box display="flex" gap={1} my={2}>
            <Button
              disabled={!!labelAreas}
              startIcon={<ZoomOut />}
              variant="outlined"
              onClick={handleZoomOut}
            >
              Zoom Out
            </Button>
            <Button
              disabled={!!labelAreas}
              startIcon={<RestartAlt />}
              variant="outlined"
              onClick={handleZoomReset}
            >
              Reset Zoom
            </Button>
          </Box>
        )}
        <Box sx={{ mb: 1 }}>
          <MemoChartMinimap
            id="chart_minimap"
            height={50}
            show={processedData && selectedArea}
            data={Object.values(processedData)[0]}
            selectedArea={selectedArea}
          />
        </Box>
        {Object.entries(sortByCategory(dtCategories)).map(([category, dataTypes]) => {
          const readings = processedData[category]
          return readings?.length ? (
            <Box key={category} my={3} display="flex" flexDirection="column">
              <Typography variant="h6" color="text.secondary">
                {titleize(category)}
              </Typography>
              <Chart
                id={category}
                data={readings}
                dataTypes={dataTypes}
                highlightedAreas={labelsRanges.map((label) => ({
                  ...label,
                  id: Math.random(),
                  priority: 0,
                }))}
                cursorState={[cursor, setCursor]}
                adjustableAreasState={[labelAreas, setLabelAreas]}
                zoomState={{
                  selectorIdState: [selectorId, setSelectorId],
                  selectorStartState: [selectorStart, setSelectorStart],
                  selectorEndState: [selectorEnd, setSelectorEnd],
                  selectedAreaState: [selectedArea, setSelectedArea],
                  historyRef: selectedAreaHistory,
                }}
                settings={{
                  showCursor: !labelAreas,
                  rightAxisProps: { hideAxis: isSmallScreen },
                  bottomAxisProps: { fontSize: isSmallScreen ? 10 : null },
                }}
              />
            </Box>
          ) : (
            <Box
              key={category}
              width="100%"
              height="100%"
              display="flex"
              m={5}
              justifyContent="center"
            >
              <Typography variant="h5" mb={1} color="text.secondary">
                No {titleize(category)} data to display.
              </Typography>
            </Box>
          )
        })}
      </Box>
    </>
  )
}
