import { useMemo, useRef } from 'react'

import { DragIndicatorRounded, Settings } from '@mui/icons-material'
import { Box, Card, IconButton, Typography } from '@mui/material'

import { ParentSize } from '@visx/responsive'
import { titleize } from 'inflection'

import { Chart } from '@common/components/chart'
import {
  CATEGORY_MODEL_SCORES,
  CATEGORY_SOUND_LEVELS,
} from '@common/utils/grafanaViewDataProcessor'

import { GRID_IDLE_MODE } from './constants'
import { humanizeCategory } from './utils'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.category
 * @param {'idle'|'resizing'|'dragging'} props.gridMode
 * @param {boolean} props.loading
 * @param {Object} props.theme
 * @param {boolean} props.categoryHasData
 * @param {Object} props.processedData
 * @param {Object} props.currentData
 * @param {Object[]} props.highlightedAreas
 * @param {Object[]} props.dataTypes
 * @param {string} [props.error]
 * @param {(Function|Object)[]} props.cursorState
 * @param {Object} props.zoomState
 * @param {Object} props.chartSettings
 * @param {Function} props.onSettingsClick
 */
export default function ChartCard({
  category,
  gridMode,
  loading,
  theme,
  categoryHasData,
  processedData,
  currentData,
  highlightedAreas,
  dataTypes,
  error,
  cursorState,
  zoomState,
  chartSettings,
  onSettingsClick,
}) {
  const cardRef = useRef(null)

  const cardTitle = useMemo(
    () => humanizeCategory(category).replace(/moneymaker/gi, 'MM'),
    [category],
  )

  return (
    <Box
      ref={cardRef}
      width={1}
      height={1}
      sx={{
        pointerEvents: gridMode !== GRID_IDLE_MODE || loading ? 'none' : 'auto',
        userSelect: 'none',
      }}
    >
      <ParentSize>
        {({ width: parentWidth, height: parentHeight }) => (
          <Card
            key={`${category}_data`}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'start',
              height: 1,
              width: 1,
              backgroundImage:
                'linear-gradient(rgba(255, 255, 255, 0.02), rgba(255, 255, 255, 0.02))',
            }}
          >
            <Box
              width="100%"
              display="flex"
              position="relative"
              justifyContent="center"
            >
              <DragIndicatorRounded
                className="dragHandle"
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  m: 1.5,
                  color: theme.palette.text.secondary,
                  cursor: 'grab',
                }}
              />
              <Typography m={2}>{cardTitle}</Typography>
              {categoryHasData && (
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    m: 1,
                    color: theme.palette.text.secondary,
                  }}
                  onClick={(event) =>
                    onSettingsClick({ category, anchor: event.currentTarget })
                  }
                >
                  <Settings />
                </IconButton>
              )}
            </Box>
            {categoryHasData && !error ? (
              <Chart
                id={category}
                data={processedData[category].current}
                zoomedData={processedData[category].zoomed}
                highlightedAreas={highlightedAreas}
                dataTypes={dataTypes}
                noiseThresholdRules={
                  category === CATEGORY_SOUND_LEVELS ? currentData.thresholdRules : null
                }
                yMin={category === CATEGORY_MODEL_SCORES ? 0 : null}
                yMax={category === CATEGORY_MODEL_SCORES ? 100 : null}
                cursorState={cursorState}
                zoomState={zoomState}
                settings={chartSettings}
                height={Math.max(0, parentHeight - 74)}
                width={Math.max(0, parentWidth - 10)}
              />
            ) : (
              <Box
                height="100%"
                display="flex"
                m={4}
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h5" color="text.secondary" textAlign="center">
                  {error || `No ${titleize(category)} data to display.`}
                </Typography>
              </Box>
            )}
          </Card>
        )}
      </ParentSize>
    </Box>
  )
}
