/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { equals, isEmpty } from 'ramda'

import { Box, Typography, useTheme } from '@mui/material'

import { curveLinear, curveStepBefore } from '@visx/curve'
import { localPoint } from '@visx/event'
import { GridRows } from '@visx/grid'
import { ParentSize } from '@visx/responsive'
import { scaleLinear, scaleTime } from '@visx/scale'
import { Line, LinePath } from '@visx/shape'
import {
  defaultStyles,
  Tooltip,
  TooltipWithBounds,
  useTooltip,
  useTooltipInPortal,
} from '@visx/tooltip'
import { bisector } from 'd3-array'
import { DateTime } from 'luxon'

import {
  addAlphaToColor,
  generateFixedBottomTicks,
  tzAwareTickFormat,
} from './chartUtils'
import AdjustableArea from './components/AdjustableArea'
import Cursor from './components/Cursor'
import GraphContainer from './components/GraphContainer'
import LineMarker from './components/LineMarker'
import SelectionRect from './components/SelectionRect'
import MemoAreaClosed from './memo/MemoAreaClosed'
import MemoAxisBottom from './memo/MemoAxisBottom'
import MemoAxisRight from './memo/MemoAxisRight'
import MemoCircle from './memo/MemoCircle'
import MemoHighlightedArea from './memo/MemoHighlightedArea'
import MemoHorizontalThresholdedLine from './memo/MemoHorizontalThresholdedLine'
import MemoLine from './memo/MemoLine'
import MemoVerticalThresholdedLine from './memo/MemoVerticalThresholdedLine'

const defaultSettings = {
  tooltipInPortal: false,
  showNotAvailableInTooltip: true,
  showGridRows: false,
  showDateTooltip: true,
  showThresholdLine: true,
  showCursor: true,
  highlightSelectedPoints: true,
  alwaysShowStartEndPoints: false,
  alwaysShowAllPoints: false,
  graphContainer: {},
  bottomAxisProps: {},
  rightAxisProps: {},
  tooltip: {},
  gridRows: {},
  cursor: {},
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.id
 * @param {Object[]} props.data
 * @param {Object} [props.data[].time]
 *
 * @param {Object[]} [props.zoomedData]
 * @param {Object} [props.zoomedData[].time]
 *
 * @param {(Function|Object)[]} [props.cursorState]
 * - {Object} [props.cursorState.xValue]
 * - {number} [props.cursorState.y]

 * @param {number} [props.height]
 * @param {number} [props.width]
 * @param {Function} [props.curve]
 * @param {number} [props.yMin]
 * @param {number} [props.yMax]
 *
 * @param {Object[]} [props.highlightedAreas]
 * @param {string|number} props.highlightedAreas[].id
 * @param {Object} props.highlightedAreas[].start
 * @param {Object} props.highlightedAreas[].end
 * @param {number} [props.highlightedAreas[].priority]
 * @param {Object} [props.highlightedAreas[].payload]
 * @param {Object} [props.highlightedAreas[].style]
 *
 * @param {Object[]} [props.markers]
 * @param {string|number} props.markers[].id
 * @param {Object} props.markers[].date
 * @param {boolean} [props.markers[].dashed]
 * @param {boolean} [props.markers[].showCaret]
 * @param {string} [props.markers[].color]
 *
 * @param {Object[]} props.dataTypes
 * @param {string} props.dataTypes[].name
 * @param {string} props.dataTypes[].unit
 * @param {string} props.dataTypes[].color
 * @param {boolean} props.dataTypes[].visible
 * @param {boolean} [props.dataTypes[].hasMinMax]
 * @param {boolean} [props.dataTypes[].scatter]
 * @param {boolean} [props.dataTypes[].fillArea]
 * @param {boolean} [props.dataTypes[].fillArea]
 *
 * @param {Object[]} [props.horizontalGuidelines]
 * @param {number} props.horizontalGuidelines[].value
 * @param {string} props.horizontalGuidelines[].color
 * @param {string} props.horizontalGuidelines[].unit
 * @param {boolean} [props.horizontalGuidelines[].dotted]
 *
 * @param {Object} [props.zoomState]
 * @param {(Function|Object|string)[]} props.zoomState.selectorIdState
 * @param {(Function|Object)[]} props.zoomState.selectorStartState
 * @param {(Function|Object)[]} props.zoomState.selectorEndState
 * @param {(Function|Object)[]} props.zoomState.selectedAreaState
 * @param {Object} props.zoomState.historyRef
 *
 * @param {(Object|Function)[]} [props.adjustableAreasState]
 * - {string} props.adjustableAreasState.id
 * - {number} props.adjustableAreasState.x01
 * - {number} props.adjustableAreasState.x1i

 * @param {boolean} [props.minMaxVisible]
 * @param {Function} [props.yThresholdValueGetter]
 * @param {Object} [props.xThresholdBound]
 *
 * @param {Object} [props.graphPadding]
 * @param {number} [props.graphPadding.top]
 * @param {number} [props.graphPadding.bottom]
 * @param {number} [props.graphPadding.left]
 * @param {number} [props.graphPadding.right]
 *
 * @param {Function} [props.tooltipItemBuilder]
 *
 * @param {Object} [props.settings]
 * @param {boolean} [props.settings.showNotAvailableInTooltip]
 * @param {boolean} [props.settings.showGridRows]
 * @param {boolean} [props.settings.showDateTooltip]
 * @param {boolean} [props.settings.showThresholdLine]
 * @param {boolean} [props.settings.showCursor]
 * @param {boolean} [props.settings.alwaysShowStartEndPoints]
 * @param {boolean} [props.settings.alwaysShowAllPoints]
 * @param {boolean} [props.settings.tooltipInPortal]
 * @param {boolean} [props.settings.highlightSelectedPoints]
 *
 * @param {Object} [props.settings.graphContainer]
 * @param {string} [props.settings.graphContainer.color]
 * @param {number} [props.settings.graphContainer.shadowMultuiplayer]
 *
 * @param {Object} [props.settings.bottomAxisProps]
 * @param {boolean} [props.settings.bottomAxisProps.hideAxisLine]
 * @param {boolean} [props.settings.bottomAxisProps.hideTicks]
 * @param {string} [props.settings.bottomAxisProps.label]
 * @param {Object} [props.settings.bottomAxisProps.tick]
 * @param {number} [props.settings.bottomAxisProps.fontSize]
 *
 * @param {Object} [props.settings.rightAxisProps]
 * @param {boolean} [props.settings.rightAxisProps.hideAxis]
 * @param {boolean} [props.settings.rightAxisProps.hideAxisLine]
 * @param {boolean} [props.settings.rightAxisProps.hideTicks]
 * @param {boolean} [props.settings.rightAxisProps.hideLabel]
 * @param {Function} [props.settings.rightAxisProps.spacingBuilder]
 * @param {number} [props.settings.rightAxisProps.offsetWithUnit]
 * @param {number} [props.settings.rightAxisProps.offsetWithoutUnit]
 * @param {Object} [props.settings.rightAxisProps.label]
 * @param {Object} [props.settings.rightAxisProps.tick]
 * @param {number[]} [props.settings.rightAxisProps.tickValues]
 * @param {Function} [props.settings.rightAxisProps.tickFormat]
 *
 * @param {Object} [props.settings.tooltip]
 * @param {string} [props.settings.tooltip.backgroundColor]
 * @param {number} [props.settings.tooltip.borderRadius]
 * @param {string} [props.settings.tooltip.notAvailableText]
 *
 * @param {Object} [props.settings.gridRows]
 * @param {string} [props.settings.gridRows.stroke]
 * @param {number} [props.settings.gridRows.strokeWidth]
 * @param {number} [props.settings.gridRows.opacity]
 * @param {number} [props.settings.gridRows.numTicks]
 * @param {number[]} [props.settings.gridRows.strokeDasharray]
 * @param {number[]} [props.settings.gridRows.tickValues]
 *
 * @param {Object} [props.settings.cursor]
 * @param {string} [props.settings.cursor.color]
 * @param {number} [props.settings.cursor.strokeWidth]
 * @param {number} [props.settings.cursor.caretSize]
 * @param {boolean} [props.settings.cursor.showCaret]
 */
export default function Chart({
  id,
  data,
  zoomedData = null,
  zoomState = undefined,
  cursorState = null,
  height = 250,
  width = undefined,
  minMaxVisible = false,
  yThresholdValueGetter = undefined,
  xThresholdBound = undefined,
  curve = undefined,
  yMin = undefined,
  yMax = undefined,
  highlightedAreas = [],
  markers = [],
  dataTypes,
  horizontalGuidelines = null,
  adjustableAreasState = undefined,
  graphPadding = undefined,
  tooltipItemBuilder = undefined,
  settings = undefined,
}) {
  const componentSettings = { ...defaultSettings, ...settings }
  const lineCurve = curve ?? curveLinear

  const [cursor, setCursor] = cursorState ?? useState(null)

  const [selectorId, setSelectorId] = zoomState?.selectorIdState ?? useState(null)
  const [selectorStart, setSelectorStart] =
    zoomState?.selectorStartState ?? useState(null)
  const [selectorEnd, setSelectorEnd] = zoomState?.selectorEndState ?? useState(null)
  const [selectedArea, setSelectedArea] = zoomState?.selectedAreaState ?? useState(null)

  const selectedAreaHistory = zoomState?.historyRef ?? useRef([])

  const theme = useTheme()

  const chartRef = useRef(null)

  const indexedData = useMemo(
    () => data.map((item, index) => ({ ...item, index })),
    [data],
  )

  const indexedZoomedData = useMemo(
    () => zoomedData?.map((item, index) => ({ ...item, index })),
    [zoomedData],
  )

  const graphData = useMemo(() => {
    if (selectedArea) {
      if (indexedZoomedData) {
        return indexedZoomedData
      }

      const startIndex = selectedArea.isDateTimeRange
        ? indexedData.findIndex((item) => equals(item.time, selectedArea.start))
        : indexedData.findIndex((item) => equals(item.index, selectedArea.start))
      const endIndex = selectedArea.isDateTimeRange
        ? indexedData.findIndex((item) => equals(item.time, selectedArea.end))
        : indexedData.findIndex((item) => equals(item.index, selectedArea.end))

      if (startIndex !== -1 && endIndex !== -1) {
        const slice = indexedData.slice(startIndex, endIndex + 1)
        if (slice.length <= 1) {
          const historyStartIndex = indexedData.findIndex((item) =>
            equals(item.time, selectedAreaHistory.current.at(-1).start),
          )
          const historyEndIndex = indexedData.findIndex((item) =>
            equals(item.time, selectedAreaHistory.current.at(-1).end),
          )
          return indexedData.slice(historyStartIndex, historyEndIndex)
        }
        return slice
      }
    }
    return indexedData
  }, [indexedData, indexedZoomedData, selectedArea, dataTypes])

  const zoneName = useMemo(
    () => graphData.find((d) => d.time)?.time.zoneName,
    [graphData],
  )

  // geometry
  const padding = {
    top: 5,
    left: 20,
    right: 20,
    bottom: 30,
    ...graphPadding,
  }

  const graphBottom = height - padding.bottom
  const graphTop = padding.top
  const graphLeft = padding.left
  const innerHeight = graphBottom - graphTop

  // scales
  const time = (d) => d.time
  const index = (d) => d.index
  const getDomain = useCallback(
    (accessor) => {
      const filteredValues = graphData
        .map(accessor)
        .filter((value) => value !== null && value !== undefined)
      return [Math.min(...filteredValues), Math.max(...filteredValues)]
    },
    [graphData],
  )

  const [tMin, tMax] = getDomain(time)
  const [lMin, lMax] = getDomain(index)

  const validDateTimeDomain = !isNaN(tMin) && !isNaN(tMax)
  const linearScaleDomain =
    !isNaN(lMin) && !isNaN(lMax) ? [lMin, lMax] : [0, graphData.length - 1]

  const noiseThresholdData = useMemo(
    () =>
      graphData
        .filter((d) => 'nrs_threshold' in d)
        .map((d) => ({ time: d.time, value: d.nrs_threshold })),
    [graphData],
  )

  const graphScale = useMemo(
    () =>
      validDateTimeDomain
        ? scaleTime({ domain: [tMin, tMax] })
        : scaleLinear({ domain: linearScaleDomain }),
    [tMin, tMax, lMin, lMax],
  )

  const yScalesData = useMemo(
    () =>
      dataTypes.reduce((acc, curr) => {
        if (curr.hasMinMax) {
          const min = `${curr.name}_min`
          const mean = `${curr.name}_mean`
          const max = `${curr.name}_max`
          const y = {
            [mean]: (d) => d[mean],
            [min]: (d) => d[min],
            [max]: (d) => d[max],
          }

          const hasMinData = graphData.some(y[min])
          const hasMaxData = graphData.some(y[max])
          const [yMinInner, yMaxInner] = [
            yMin ??
              Math.min(
                ...graphData
                  .map(y[hasMinData && minMaxVisible ? min : mean])
                  .filter((value) => value !== null),
              ),
            yMax ??
              Math.max(
                ...graphData
                  .map(y[hasMaxData && minMaxVisible ? max : mean])
                  .filter((value) => value !== null),
              ),
          ]

          return {
            ys: {
              ...acc.ys,
              ...y,
            },
            yScales: {
              ...acc.yScales,
              [curr.name]: scaleLinear({
                domain: [yMinInner, yMaxInner],
                range: [graphBottom, graphTop],
              }),
            },
          }
        }
        const [yMinInner, yMaxInner] =
          curr.name === 'nrs' && noiseThresholdData?.length > 0
            ? [0, 100]
            : getDomain((d) => d[curr.name])
        return {
          ys: {
            ...acc.ys,
            [curr.name]: (d) => d[curr.name],
          },
          yScales: {
            ...acc.yScales,
            [curr.name]: scaleLinear({
              domain: [yMin ?? yMinInner, yMax ?? yMaxInner],
              range: [graphBottom, graphTop],
            }),
          },
        }
      }, {}),
    [indexedData, graphData, graphBottom, graphTop],
  )

  const { ys, yScales } = yScalesData

  const visibleYScaleByUnit = useMemo(() => {
    const combineDomains = ([min1, max1], [min2, max2]) => [
      Math.min(...[min1, min2].filter((num) => !isNaN(num))),
      Math.max(...[max1, max2].filter((num) => !isNaN(num))),
    ]

    return dataTypes
      .filter((d) => d.visible)
      .reduce((acc, curr) => {
        let scale = yScales[curr.name]
        if (!scale) {
          return acc
        }
        if (curr.unit in acc) {
          scale = scaleLinear({
            domain: combineDomains(
              yScales[curr.name].domain(),
              acc[curr.unit].domain(),
            ),
            range: [graphBottom, graphTop],
          })
        }
        return {
          ...acc,
          [curr.unit]: scale,
        }
      }, {})
  }, [yScales, dataTypes])

  const calculateRightAxisPositionParams = useCallback(
    (scale, prevScale, unit) => {
      const getMaxLenght = (inputScale) => {
        if (inputScale) {
          const tickFormat = inputScale.tickFormat()
          const formattedTicks = inputScale.ticks().map(tickFormat)
          return Math.max(...formattedTicks.map((tick) => tick.length), 1)
        }
        return null
      }

      const offsetWithUnit = componentSettings.rightAxisProps.offsetWithUnit ?? 20
      const offsetWithoutUnit = componentSettings.rightAxisProps.offsetWithoutUnit ?? 0

      const getOffset = (length) => {
        const initialOffset = unit ? offsetWithUnit : offsetWithoutUnit
        const offset = initialOffset + Math.min(length, 5) * length
        return offset
      }

      const maxLength = getMaxLenght(scale)
      const prevMaxLength = getMaxLenght(prevScale)

      const offset = getOffset(maxLength)
      const prevOffset = getOffset(prevMaxLength)

      return {
        offset,
        padding:
          (componentSettings.rightAxisProps.spacingBuilder
            ? componentSettings.rightAxisProps.spacingBuilder(maxLength)
            : 26 + 2 * maxLength) + prevOffset,
      }
    },
    [componentSettings.rightAxisProps],
  )

  const rightAxesPaddings = useMemo(() => {
    if (componentSettings.rightAxisProps.hideAxis) {
      return [0]
    }

    const entries = Object.entries(visibleYScaleByUnit)
    return entries.map(([unit, scale], i) => {
      const previousScale = i !== 0 ? entries[i - 1][1] : null
      const axisParams = calculateRightAxisPositionParams(scale, previousScale, unit)
      return axisParams.padding
    })
  }, [
    visibleYScaleByUnit,
    calculateRightAxisPositionParams,
    componentSettings.rightAxisProps,
    padding,
  ])

  Object.defineProperty(padding, 'right', {
    value: padding.right + rightAxesPaddings.reduce((acc, p) => acc + p, 0),
  })

  const {
    hideTooltip,
    showTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft,
    tooltipTop,
  } = useTooltip()

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true,
  })

  const TooltipComponent = componentSettings.tooltipInPortal
    ? TooltipInPortal
    : TooltipWithBounds

  const handleCursorChange = useCallback((event, xValue) => {
    const { y } = localPoint(event) || { y: 0 }
    setCursor({ y, xValue })
  }, [])

  const hideCursor = useCallback(() => setCursor(null), [])

  const bisectTime = bisector((d) => d.time).left

  const getDataFromX = (x) => {
    const x0 = graphScale.invert(x)
    const i = validDateTimeDomain ? bisectTime(graphData, x0, 1) : Math.round(x0) + 1
    const d0 = graphData[i - 1]
    const d1 = graphData[i]
    let d = d0
    if (d1 && time(d1)) {
      d =
        x0.valueOf() - time(d0).valueOf() > time(d1).valueOf() - x0.valueOf() ? d1 : d0
    }
    return d
  }

  const handleSelectionStart = (event) => {
    const { x } = localPoint(event) || { x: 0 }
    const x0 = graphScale.invert(x)
    setSelectorStart(x0)
  }

  const handleSelectorChange = (_, xValue) => setSelectorEnd(xValue)

  const getSelectedAreaBounds = (domainStart, domainEnd, chartData) => {
    let start
    let end

    if (validDateTimeDomain) {
      const startIndex = bisectTime(chartData, domainStart, 1)
      const endIndex = bisectTime(chartData, domainEnd, 1)

      start = chartData[startIndex - 1]?.time
      end = chartData[endIndex - 1]?.time
    } else {
      start = Math.max(0, Math.round(domainStart))
      end = Math.max(0, Math.round(domainEnd))
    }

    return { start, end, isDateTimeRange: validDateTimeDomain }
  }

  const handleSelectionEnd = () => {
    if (selectorStart && selectorEnd) {
      const domainStart = selectorStart < selectorEnd ? selectorStart : selectorEnd
      const domainEnd = selectorStart < selectorEnd ? selectorEnd : selectorStart

      const { start, end, isDateTimeRange } = getSelectedAreaBounds(
        domainStart,
        domainEnd,
        graphData,
      )

      if (start !== null && end !== null && start !== end) {
        const area = { start, end, isDateTimeRange }
        selectedAreaHistory.current.push(area)
        setSelectedArea(area)
      }
    }
    setSelectorStart(null)
    setSelectorEnd(null)
  }

  const handleTooltip = useCallback(
    (cursorParam) => {
      if (!cursorParam) {
        return {
          show: false,
          tooltipData: '',
          tooltipLeft: 0,
          tooltipTop: 0,
        }
      }
      const { y, xValue } = cursorParam
      const x = graphScale(xValue)
      const pointData = getDataFromX(x)

      return {
        show: true,
        tooltipData: pointData,
        tooltipLeft: x,
        tooltipTop: y,
      }
    },
    [showTooltip, graphScale, graphData],
  )

  useEffect(() => {
    if (componentSettings.showCursor) {
      const { show, ...tooltipArgs } = handleTooltip(cursor)
      if (show) showTooltip(tooltipArgs)
      else hideTooltip()
    }
  }, [cursor])

  const formatTooltip = useCallback((value, name, template) => {
    if (typeof value === 'number') {
      const formattedValue = value.toFixed(name === 'nrs' ? 3 : 2)
      return template.replace('{{ value }}', formattedValue)
    }
    return componentSettings.tooltip.notAvailableText ?? 'N/A'
  }, [])

  const defined = (d, name, suffix) => {
    if (Array.isArray(suffix)) {
      return suffix
        .map((s) => {
          const key = `${name}_${s}`
          return d[key] !== null && !isNaN(d[key])
        })
        .filter(Boolean).length
    }
    const key = suffix ? `${name}_${suffix}` : name
    return d[key] !== null && !isNaN(d[key])
  }

  const filterMinMax = (key) =>
    !minMaxVisible ? !(key.endsWith('_max') || key.endsWith('_min')) : true

  const selectorTooltipText = (() => {
    if (selectorStart && selectorEnd) {
      const formatOption = DateTime.DATETIME_SHORT_WITH_SECONDS
      const start = selectorStart < selectorEnd ? selectorStart : selectorEnd
      const end = selectorStart < selectorEnd ? selectorEnd : selectorStart
      const formattedStart = DateTime.fromJSDate(start)
        .setZone(zoneName)
        .toLocaleString(formatOption)
      const formattedEnd = DateTime.fromJSDate(end)
        .setZone(zoneName)
        .toLocaleString(formatOption)
      return `${formattedStart} - ${formattedEnd}`
    }
    return null
  })()

  const handleX = (d, scale) => {
    const value = validDateTimeDomain ? time(d) ?? 0 : index(d)
    return scale(value)
  }

  const buildLine = useCallback(
    ({
      key,
      lineId,
      chartData,
      x,
      y,
      color,
      definedFn,
      domainScale,
      yScale,
      graphWidth,
    }) => {
      if (xThresholdBound) {
        return (
          <MemoHorizontalThresholdedLine
            key={key}
            id={lineId.replaceAll(' ', '')}
            data={chartData}
            x={x}
            y={y}
            yScale={yScale}
            color={color}
            curve={lineCurve}
            defined={definedFn}
            upperBound={domainScale(xThresholdBound)}
            height={height}
            width={graphWidth}
            padding={padding}
          />
        )
      }
      if (yThresholdValueGetter) {
        return (
          <MemoVerticalThresholdedLine
            key={key}
            id={lineId}
            data={chartData}
            curve={lineCurve}
            x={x}
            y={y}
            yScale={yScale}
            defined={definedFn}
            threshold={(d) => yScale(yThresholdValueGetter(d)) ?? -1}
            yMax={graphBottom}
            yMin={graphTop}
            color={color}
            graphWidth={graphWidth}
            padding={padding}
          />
        )
      }
      return (
        <MemoLine
          key={key}
          id={lineId}
          data={chartData}
          defined={definedFn}
          curve={lineCurve}
          x={x}
          y={y}
          yScale={yScale}
          stroke={color}
          graphWidth={graphWidth}
          padding={padding}
        />
      )
    },
    [xThresholdBound, defined, handleX, height, padding],
  )

  const buildGraph = ({ chartData, ysArr, domainScale, graphWidth }) =>
    dataTypes.reduce(
      (
        acc,
        {
          name,
          color,
          unit,
          visible,
          hasMinMax,
          fillArea,
          scatter,
          scatterShowNull,
          scatterNullRenderValue,
          scatterNullRenderStyle,
        },
      ) => {
        const yScale = visibleYScaleByUnit[unit]
        if (visible) {
          if (scatter) {
            return [
              ...acc,
              ...chartData.map((d) => {
                if (
                  d[name] >= 0 &&
                  (d[name] !== null || (d[name] === null && scatterShowNull))
                ) {
                  const hightlight = equals(tooltipData, d)
                  const highlightedRadius = d[name] === null ? 6 : 3
                  const rawY = ysArr[name](d)

                  const defaultRadius =
                    d[name] === null ? scatterNullRenderStyle?.radius ?? 2 : 2

                  return (
                    <MemoCircle
                      key={`${name}_circle_${d.time}`}
                      id={`${name}_circle_${d.time}`}
                      cx={handleX(d, domainScale)}
                      cy={
                        d[name] === null
                          ? yScale(scatterNullRenderValue ?? rawY)
                          : yScale(rawY)
                      }
                      r={hightlight ? highlightedRadius : defaultRadius}
                      stroke={theme.palette.text.secondary}
                      strokeWidth={hightlight ? 1 : 0}
                      fill={
                        d[name] === null
                          ? scatterNullRenderStyle?.color ?? color
                          : color
                      }
                      graphWidth={graphWidth}
                      padding={padding}
                    />
                  )
                }
                return null
              }),
            ]
          }
          if (hasMinMax) {
            if (minMaxVisible) {
              return [
                ...acc,
                <React.Fragment key={`${name}_${id}`}>
                  {buildLine({
                    key: `line_minmax_${name}_${id}`,
                    lineId: `line_minmax_${name}_${id}`,
                    chartData,
                    definedFn: (d) => defined(d, name, 'mean'),
                    x: (d) => handleX(d, domainScale),
                    y: (d) => yScale(ysArr[`${name}_mean`](d)) ?? 0,
                    color,
                    domainScale,
                    yScale,
                    graphWidth,
                  })}
                  <MemoAreaClosed
                    key={`area_minmax_${name}_${id}`}
                    id={`area_minmax_${name}_${id}`}
                    data={chartData}
                    yScale={yScale}
                    defined={(d) => defined(d, name, ['min', 'max'])}
                    y0={(d) => yScale(ysArr[`${name}_min`](d))}
                    y1={(d) => yScale(ysArr[`${name}_max`](d))}
                    x={(d) => handleX(d, domainScale)}
                    fill={color}
                    fillOpacity={0.2}
                    graphWidth={graphWidth}
                    padding={padding}
                  />
                </React.Fragment>,
                ...(componentSettings.alwaysShowAllPoints
                  ? [
                      ...chartData.map((d) => {
                        if (d[name] >= 0) {
                          const hightlight =
                            componentSettings.highlightSelectedPoints &&
                            equals(tooltipData, d)
                          return (
                            <MemoCircle
                              key={`${name}_circle_${d.time}_always`}
                              id={`${name}_circle_${d.time}_always`}
                              cx={handleX(d, domainScale)}
                              cy={yScale(ysArr[name](d))}
                              r={hightlight ? 3 : 2}
                              stroke={theme.palette.text.secondary}
                              strokeWidth={hightlight ? 1 : 0}
                              fill={color}
                              graphWidth={graphWidth}
                              padding={padding}
                            />
                          )
                        }
                        return null
                      }),
                    ]
                  : []),
              ]
            }
            return [
              ...acc,
              buildLine({
                key: `line_has_minmax_${name}_${id}`,
                lineId: `line_has_minmax_${name}_${id}`,
                chartData,
                definedFn: (d) => defined(d, name, 'mean'),
                x: (d) => handleX(d, domainScale),
                y: (d) => yScale(ysArr[`${name}_mean`](d)),
                color,
                domainScale,
                yScale,
                graphWidth,
              }),
              ...(componentSettings.alwaysShowAllPoints
                ? [
                    ...chartData.map((d) => {
                      if (d[name] >= 0) {
                        const hightlight =
                          componentSettings.highlightSelectedPoints &&
                          equals(tooltipData, d)
                        return (
                          <MemoCircle
                            key={`${name}_circle_${d.time}_always`}
                            id={`${name}_circle_${d.time}_always`}
                            cx={handleX(d, domainScale)}
                            cy={yScale(ysArr[name](d))}
                            r={hightlight ? 3 : 2}
                            stroke={theme.palette.text.secondary}
                            strokeWidth={hightlight ? 1 : 0}
                            fill={color}
                            graphWidth={graphWidth}
                            padding={padding}
                          />
                        )
                      }
                      return null
                    }),
                  ]
                : []),
            ]
          }

          return [
            ...acc,
            buildLine({
              key: `${name}_line_${id}`,
              lineId: `${name}_line_${id}`,
              chartData,
              definedFn: (d) => defined(d, name),
              x: (d) => handleX(d, domainScale),
              y: (d) => yScale(ysArr[name](d)),
              color,
              domainScale,
              yScale,
              graphWidth,
            }),
            ...(fillArea
              ? [
                  <MemoAreaClosed
                    key={`${name}_area_${id}`}
                    id={`${name}_area_${id}`}
                    data={chartData}
                    defined={(d) => defined(d, name)}
                    x={(d) => handleX(d, domainScale)}
                    y={(d) => yScale(ysArr[name](d))}
                    yScale={yScale}
                    strokeWidth={0}
                    stroke={color}
                    fill={color}
                    fillOpacity={0.1}
                    graphWidth={graphWidth}
                  />,
                ]
              : []),
            ...(componentSettings.alwaysShowAllPoints
              ? [
                  ...chartData.map((d) => {
                    if (d[name] >= 0) {
                      const hightlight =
                        componentSettings.highlightSelectedPoints &&
                        equals(tooltipData, d)
                      return (
                        <MemoCircle
                          key={`${name}_circle_${d.time}_always`}
                          id={`${name}_circle_${d.time}_always`}
                          cx={handleX(d, domainScale)}
                          cy={yScale(ysArr[name](d))}
                          r={hightlight ? 3 : 2}
                          stroke={theme.palette.text.secondary}
                          strokeWidth={hightlight ? 1 : 0}
                          fill={color}
                          graphWidth={graphWidth}
                          padding={padding}
                        />
                      )
                    }
                    return null
                  }),
                ]
              : []),
          ]
        }
        return acc
      },
      [],
    )

  const NoiseThresholdLine = useCallback(() => {
    const yScalesByUnit = Object.values(visibleYScaleByUnit)
    if (yScalesByUnit.length > 0) {
      const stroke = '#FF0000'
      const strokeWidth = 1.5
      const strokeDasharray = [4, 4]

      const nullLines = []
      noiseThresholdData.forEach((item, i) => {
        if (i === 0) return

        const prev = noiseThresholdData[i - 1]
        if (prev.value === null && item.value !== null) {
          nullLines.push(
            <Line
              key={`${item.time.toISO()}_null`}
              from={{ x: graphScale(time(item)), y: yScalesByUnit[0](0) }}
              to={{ x: graphScale(time(item)), y: yScalesByUnit[0](item.value) }}
              stroke={stroke}
              strokeWidth={strokeWidth}
              strokeDasharray={strokeDasharray}
            />,
          )
        } else if (prev.value !== null && item.value === null) {
          nullLines.push(
            <Line
              key={`${prev.time.toISO()}_null`}
              from={{ x: graphScale(time(prev)), y: yScalesByUnit[0](0) }}
              to={{ x: graphScale(time(prev)), y: yScalesByUnit[0](prev.value) }}
              stroke={stroke}
              strokeWidth={strokeWidth}
              strokeDasharray={strokeDasharray}
            />,
          )
        }
      })

      return (
        <>
          <LinePath
            data={noiseThresholdData}
            defined={(d) =>
              typeof d.value === 'number' &&
              d.time >= graphData.at(0).time &&
              d.time <= graphData.at(-1).time
            }
            x={(d) => graphScale(time(d)) ?? 0}
            y={(d) => yScalesByUnit[0](d.value) ?? 0}
            curve={curveStepBefore}
            stroke={stroke}
            strokeWidth={strokeWidth}
            strokeDasharray={strokeDasharray}
          />
          {nullLines}
        </>
      )
    }
    return null
  }, [graphData, noiseThresholdData, visibleYScaleByUnit, graphScale])

  const thresholdLine = useMemo(() => {
    const yScalesByUnit = Object.values(visibleYScaleByUnit)
    if (
      yThresholdValueGetter &&
      componentSettings.showThresholdLine &&
      yScalesByUnit.length > 0
    ) {
      return (
        <LinePath
          data={data}
          defined={(d) => typeof yThresholdValueGetter(d) === 'number'}
          x={(d) => graphScale(time(d)) ?? 0}
          y={(d) => yScalesByUnit[0](yThresholdValueGetter(d)) ?? 0}
          curve={curveStepBefore}
          stroke="#BDBDBD"
          strokeWidth={1}
          strokeDasharray={[4, 4]}
        />
      )
    }
    return null
  }, [visibleYScaleByUnit, graphScale, yThresholdValueGetter])

  const alwaysVisiblePoints = useMemo(
    () =>
      componentSettings.alwaysShowStartEndPoints && graphData.length > 1
        ? [
            graphData.find((dt) =>
              Object.entries(dt).some(
                ([key, value]) =>
                  key !== 'time' && key !== 'index' && typeof value === 'number',
              ),
            ),
            graphData.findLast((dt) =>
              Object.entries(dt).some(
                ([key, value]) =>
                  key !== 'time' && key !== 'index' && typeof value === 'number',
              ),
            ),
          ]
        : [],
    [componentSettings.alwaysShowStartEndPoints, graphData],
  )

  const getChartRect = () =>
    chartRef?.current?.getBoundingClientRect() ?? {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    }

  const buildDataTooltip = () => {
    const content = Object.keys(ys)
      .filter(filterMinMax)
      .sort((a, b) => {
        const valueA = ys[a](tooltipData)
        const valueB = ys[b](tooltipData)
        if (a === 'total') {
          return 1
        }
        if (b === 'total') {
          return -1
        }
        return valueB - valueA
      })
      .map((key, i) => {
        const accessor = ys[key]
        const dataType =
          dataTypes.find((d) => key === d.name) ??
          dataTypes.find((d) => key.includes(d.name))

        const { color, name, template, tooltipLabel, visible } = dataType

        const dataKeys = Object.keys(tooltipData)
        const dataTypeHasData = dataKeys.some((k) => k === name || k.includes(name))

        if (visible && dataTypeHasData) {
          const value = accessor(tooltipData)
          const date = tooltipData.time

          if (
            !componentSettings.showNotAvailableInTooltip &&
            typeof value !== 'number'
          ) {
            return null
          }

          const dataTypeLabel = minMaxVisible ? key : key.replace('_mean', '')

          if (tooltipItemBuilder) {
            return tooltipItemBuilder({
              dataType,
              key,
              value,
              date,
              i,
            })
          }

          return (
            <span
              key={key}
              style={{
                color,
                fontFamily: theme.typography.subtitle2.fontFamily,
              }}
            >
              <strong>{tooltipLabel ?? dataTypeLabel}: </strong>
              {formatTooltip(value, name, template)}
              <br />
            </span>
          )
        }
        return null
      })
      .filter((item) => item)

    return (
      <TooltipComponent
        left={
          (componentSettings.tooltipInPortal ? getChartRect().left : 0) +
          tooltipLeft +
          10
        }
        top={
          (componentSettings.tooltipInPortal ? getChartRect().top : 0) + tooltipTop + 10
        }
        style={{
          ...defaultStyles,
          backgroundColor:
            componentSettings.tooltip.backgroundColor ??
            theme.palette.background.default,
          borderRadius: componentSettings.tooltip.borderRadius ?? 5,
          visibility: isEmpty(content) ? 'hidden' : 'visible',
        }}
      >
        {content}
      </TooltipComponent>
    )
  }

  return (
    <Box style={{ width: '100%', position: 'relative' }}>
      <ParentSize
        style={{ display: 'flex', justifyContent: 'center', alightItems: 'center' }}
      >
        {({ width: parentWidth }) => {
          const finalWidth = width ?? parentWidth
          const graphRight = Math.max(0, finalWidth - padding.right)
          graphScale.range([graphLeft, graphRight])

          const bottomTickValues = generateFixedBottomTicks({
            graphData,
            count: finalWidth * 0.015,
          })

          return (
            <>
              <svg
                ref={(ref) => {
                  containerRef.current = ref
                  chartRef.current = ref
                }}
                height={height}
                width={Math.max(0, finalWidth)}
              >
                <GraphContainer
                  height={innerHeight}
                  width={Math.max(0, graphRight - graphLeft)}
                  left={graphLeft}
                  top={graphTop}
                  fill={componentSettings.graphContainer.color ?? 'transparent'}
                  shadowMultiplayer={componentSettings.graphContainer.shadowMultiplayer}
                >
                  {Object.entries(visibleYScaleByUnit).map(([unit, scale], i) => {
                    const dataType = dataTypes.find((d) => d.unit === unit)
                    const color = dataType?.color

                    const previousScale =
                      i !== 0 ? Object.entries(visibleYScaleByUnit)[i - 1][1] : null
                    const axisParams = calculateRightAxisPositionParams(
                      scale,
                      previousScale,
                      unit,
                    )
                    const label = (() => {
                      if (componentSettings.rightAxisProps.hideLabel) {
                        return ''
                      }
                      return dataType?.name.includes('nrs') ? 'NRS' : unit
                    })()

                    return (
                      <React.Fragment key={unit}>
                        {i === 0 && componentSettings.showGridRows && (
                          <GridRows
                            key="grid_rows"
                            scale={scale}
                            stroke={componentSettings.gridRows.stroke ?? '#E0E0E0'}
                            strokeWidth={componentSettings.gridRows.strokeWidth ?? 0.5}
                            opacity={componentSettings.gridRows.opacity}
                            numTicks={componentSettings.gridRows.numTicks}
                            strokeDasharray={componentSettings.gridRows.strokeDasharray}
                            tickValues={componentSettings.gridRows.tickValues}
                            left={graphLeft}
                            width={Math.max(0, graphRight - graphLeft)}
                            height={height}
                          />
                        )}
                        {!componentSettings.rightAxisProps.hideAxis && (
                          <MemoAxisRight
                            id={unit}
                            key={unit}
                            orientation="right"
                            hideAxisLine={componentSettings.rightAxisProps.hideAxisLine}
                            hideTicks={componentSettings.rightAxisProps.hideTicks}
                            numTicks={componentSettings.rightAxisProps.numTicks}
                            tickValues={componentSettings.rightAxisProps.tickValues}
                            tickFormat={componentSettings.rightAxisProps.tickFormat}
                            left={
                              graphRight +
                              (i !== 0 ? axisParams.padding : 0) +
                              rightAxesPaddings
                                .slice(1, i)
                                .reduce((sum, v) => sum + v, 0)
                            }
                            scale={scale}
                            label={label}
                            labelProps={{
                              fill: color,
                              textAnchor: 'middle',
                              ...componentSettings.rightAxisProps.label,
                            }}
                            labelOffset={axisParams.offset}
                            stroke={color}
                            tickStroke={color}
                            tickLabelProps={() => ({
                              fontSize: 12,
                              dominantBaseline: 'middle',
                              fill: color,
                              x: 10,
                              ...componentSettings.rightAxisProps.tick,
                            })}
                            graphWidth={Math.max(0, finalWidth)}
                            padding={padding}
                          />
                        )}
                      </React.Fragment>
                    )
                  })}

                  {horizontalGuidelines?.map((guideline) => {
                    const yScale = visibleYScaleByUnit[guideline.unit]
                    if (yScale) {
                      return (
                        <Line
                          key={Math.random()}
                          from={{
                            x: padding.left,
                            y: yScale(guideline.value),
                          }}
                          to={{ x: graphRight, y: yScale(guideline.value) }}
                          stroke={guideline.color}
                          strokeWidth={2}
                          strokeDasharray={[guideline.dotted ? 2 : 0]}
                        />
                      )
                    }
                    return null
                  })}
                  {thresholdLine}
                  {highlightedAreas
                    .sort((a, b) => a.priority - b.priority)
                    .map((area) => (
                      <MemoHighlightedArea
                        id={area.id ?? `${area.start}_${area.end}_${area.color}`}
                        key={area.id ?? `${area.start}_${area.end}_${area.color}`}
                        width={Math.max(0, finalWidth - padding.right - padding.left)}
                        height={innerHeight}
                        offsetLeft={graphLeft}
                        offsetTop={graphTop}
                        scale={graphScale}
                        x0={graphScale(area.start)}
                        x1={graphScale(area.end)}
                        tooltipData={area.payload}
                        style={area.style}
                      />
                    ))}

                  {buildGraph({
                    chartData: graphData,
                    ysArr: ys,
                    domainScale: graphScale,
                    graphWidth: Math.max(0, finalWidth),
                  })}

                  <NoiseThresholdLine />

                  {selectorStart && selectorEnd && (
                    <SelectionRect
                      x0={
                        selectorEnd > selectorStart
                          ? graphScale(selectorStart)
                          : graphScale(selectorEnd)
                      }
                      x1={
                        selectorEnd > selectorStart
                          ? graphScale(selectorEnd)
                          : graphScale(selectorStart)
                      }
                      limitX0={padding.left}
                      limitX1={Math.max(0, finalWidth - padding.right)}
                      y={graphTop}
                      height={innerHeight}
                    />
                  )}
                </GraphContainer>
                <Cursor
                  id={id}
                  chartRef={chartRef}
                  selectorIdState={[selectorId, setSelectorId]}
                  showCaret={componentSettings.cursor.showCaret ?? false}
                  showCursor={componentSettings.showCursor ?? true}
                  date={cursor?.xValue}
                  scale={graphScale}
                  xMin={validDateTimeDomain ? tMin : lMin}
                  xMax={validDateTimeDomain ? tMax : lMax}
                  onMouseMove={(event, xValue) => {
                    if (selectorStart) {
                      handleSelectorChange(event, xValue)
                    }
                    handleCursorChange(event, xValue)
                  }}
                  onMouseLeave={hideCursor}
                  onMouseDown={zoomState ? handleSelectionStart : () => {}}
                  onMouseUp={handleSelectionEnd}
                  graphTop={graphTop}
                  graphLeft={graphLeft}
                  graphHeight={graphBottom - graphTop}
                  graphWidth={Math.max(0, graphRight - graphLeft)}
                  style={{
                    color: componentSettings.cursor.color ?? '#999999',
                    strokeWidth: componentSettings.cursor.strokeWidth ?? 2,
                    caretSize: componentSettings.cursor.caretSize ?? 9,
                  }}
                />
                <MemoAxisBottom
                  id="axis_bottom"
                  scale={graphScale}
                  hideAxisLine={componentSettings.bottomAxisProps.hideAxisLine}
                  hideTicks={componentSettings.bottomAxisProps.hideTicks}
                  curve={lineCurve}
                  top={graphBottom}
                  orientation="bottom"
                  stroke={theme.palette.text.primary}
                  tickStroke="none"
                  tickValues={bottomTickValues}
                  tickFormat={(value, i) =>
                    validDateTimeDomain
                      ? tzAwareTickFormat(
                          {
                            time: value,
                            index: i,
                            tickValues: bottomTickValues,
                          },
                          graphScale,
                          zoneName,
                        )
                      : value
                  }
                  tickLabelProps={() => ({
                    fontSize: componentSettings.bottomAxisProps.fontSize ?? 12,
                    fontFamily: 'sans-serif',
                    textAnchor: 'middle',
                    fill: theme.palette.text.primary,
                    ...componentSettings.bottomAxisProps.tick,
                  })}
                  graphData={graphData}
                  graphWidth={Math.max(0, finalWidth)}
                  padding={padding}
                />
                {adjustableAreasState?.[0] &&
                  adjustableAreasState[0].map((area) => (
                    <AdjustableArea
                      id={area.id}
                      key={area.id}
                      width={Math.max(0, finalWidth - padding.right - padding.left)}
                      height={innerHeight + padding.top}
                      offsetLeft={graphLeft}
                      scale={graphScale}
                      externalAreasState={adjustableAreasState}
                    />
                  ))}
                {markers.map((marker) => (
                  <LineMarker
                    dashed={marker.dashed}
                    key={`${marker.id}`}
                    x={graphScale(marker.date)}
                    top={padding.top}
                    height={innerHeight}
                    color={marker.color}
                    showCaret={marker.showCaret}
                  />
                ))}

                {alwaysVisiblePoints.length > 0 && (
                  <>
                    {Object.keys(yScales).map((type) => {
                      const dataType = dataTypes.find((dtype) => dtype.key === type)
                      const scale = visibleYScaleByUnit[dataType?.unit]

                      if (scale) {
                        const components = alwaysVisiblePoints.map((dt) => {
                          const cy = scale(dt[type])
                          if (cy !== undefined) {
                            const cx = graphScale(dt.time)
                            return (
                              <MemoCircle
                                id={`always_${cx}_${cy}_${dataType.name}`}
                                key={`always_${cx}_${cy}_${dataType.name}`}
                                cx={cx}
                                cy={cy}
                                r={3.5}
                                fill={dataType.color}
                                stroke={theme.palette.text.secondary}
                                strokeWidth={0.5}
                                pointerEvents="none"
                                graphWidth={Math.max(0, finalWidth)}
                                padding={padding}
                              />
                            )
                          }
                          return null
                        })
                        return components.reduce((acc, item) => [...acc, item], [])
                      }
                      return null
                    })}
                    {alwaysVisiblePoints.map((dt) => {
                      const chartRect = getChartRect()
                      return (
                        <TooltipInPortal
                          key={Math.random()}
                          left={graphScale(dt.time) + chartRect.left}
                          top={chartRect.bottom - padding.bottom + 20}
                          style={{
                            ...defaultStyles,
                            backgroundColor:
                              addAlphaToColor(
                                componentSettings.tooltip.backgroundColor,
                                0.9,
                              ) ??
                              addAlphaToColor(theme.palette.background.default, 0.9),

                            borderRadius: componentSettings.tooltip.borderRadius ?? 5,
                          }}
                        >
                          <div>
                            {Object.keys(ys)
                              .filter(filterMinMax)
                              .sort((a, b) => {
                                const valueA = ys[a](dt)
                                const valueB = ys[b](dt)
                                return valueB - valueA
                              })
                              .map((key, i) => {
                                const accessor = ys[key]
                                const dataType =
                                  dataTypes.find((d) => key === d.name) ??
                                  dataTypes.find((d) => key.includes(d.name))

                                const { color, name, template, tooltipLabel, visible } =
                                  dataType

                                if (visible) {
                                  const value = accessor(dt)
                                  const date = dt.time

                                  if (
                                    !componentSettings.showNotAvailableInTooltip &&
                                    typeof value !== 'number'
                                  ) {
                                    return null
                                  }

                                  const dataTypeLabel = minMaxVisible
                                    ? key
                                    : key.replace('_mean', '')

                                  if (tooltipItemBuilder) {
                                    return tooltipItemBuilder({
                                      dataType,
                                      key,
                                      value,
                                      date,
                                      i,
                                    })
                                  }

                                  return (
                                    <span
                                      key={`always_${
                                        tooltipLabel ?? dataTypeLabel
                                      }_${name}`}
                                      style={{
                                        color,
                                        fontFamily:
                                          theme.typography.subtitle2.fontFamily,
                                      }}
                                    >
                                      <strong>{tooltipLabel ?? dataTypeLabel}: </strong>
                                      {formatTooltip(value, name, template)}
                                      <br />
                                    </span>
                                  )
                                }
                                return null
                              })}
                          </div>
                        </TooltipInPortal>
                      )
                    })}
                  </>
                )}

                {tooltipData && (
                  <g>
                    {Object.keys(yScales).map((type) => {
                      const dataType = dataTypes.find((dtype) => dtype.key === type)
                      const scale = visibleYScaleByUnit[dataType?.unit]
                      const isVisible = dataType?.visible ?? true

                      if (
                        scale &&
                        !dataType.scatter &&
                        isVisible &&
                        componentSettings.highlightSelectedPoints &&
                        !componentSettings.alwaysShowAllPoints
                      ) {
                        const cy = scale(tooltipData[type])
                        if (cy !== undefined) {
                          const cx = graphScale(tooltipData.time)
                          return (
                            <MemoCircle
                              id={`${cx}_${cy}_${dataType.name}`}
                              key={`${cx}_${cy}_${dataType.name}`}
                              cx={cx}
                              cy={cy}
                              r={3.5}
                              fill={dataType.color}
                              stroke={theme.palette.text.secondary}
                              strokeWidth={0.5}
                              pointerEvents="none"
                              graphWidth={Math.max(0, finalWidth)}
                              padding={padding}
                            />
                          )
                        }
                      }
                      return null
                    })}
                  </g>
                )}
              </svg>
              {tooltipOpen &&
                tooltipData &&
                tooltipLeft != null &&
                tooltipTop != null && (
                  <>
                    {buildDataTooltip()}
                    {componentSettings.showDateTooltip && validDateTimeDomain && (
                      <Tooltip
                        top={graphTop - 40}
                        left={tooltipLeft}
                        style={{
                          ...defaultStyles,
                          minWidth: 72,
                          textAlign: 'center',
                          transform: 'translateX(-50%)',
                          backgroundColor: theme.palette.background.default,
                        }}
                      >
                        <Typography variant="body" color={theme.palette.text.secondary}>
                          {selectorTooltipText ||
                            `${time(tooltipData).toLocaleString(
                              DateTime.DATETIME_SHORT_WITH_SECONDS,
                            )}`}
                        </Typography>
                      </Tooltip>
                    )}
                  </>
                )}
            </>
          )
        }}
      </ParentSize>
    </Box>
  )
}
