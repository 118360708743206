import { useMemo } from 'react'

import { ExpandMore } from '@mui/icons-material'
import { Autocomplete, Popper, TextField, Typography } from '@mui/material'

import { formatErrors } from '@common/components/Form/utils'

const styles = () => ({
  popper: { maxWidth: 'fit-content' },
})

function MyPopper(props) {
  return <Popper {...props} style={styles.popper} placement="bottom-start" />
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} [props.multiple]
 * @param {Object} [props.field]
 * @param {string} [props.field.name]
 * @param {string} [props.filterName]
 * @param {Object} [props.form]
 * @param {boolean} [props.form.dirty]
 * @param {Object} [props.form.values]
 * @param {Function} [props.form.getFieldMeta]
 * @param {Function} [props.form.getFieldProps]
 * @param {Function} [props.form.setFieldValue]
 * @param {Function} [props.form.setFieldTouched]
 * @param {string} [props.label]
 * @param {string} [props.error]
 * @param {Function} [props.onChange]
 * @param {string} [props.placeholder]
 * @param {string[]|Object[]} [props.options]
 * @param {string} [props.variant]
 * @param {string|number|Object[]|Object} [props.value]
 * @param {boolean} [props.hideLabel]
 * @param {boolean} [props.alwaysShowPlaceholder]
 * @param {Function} [props.optionLabelFormatter]
 * @param {Function} [props.optionsFilter]
 * @param {boolean} [props.getFullEntity]
 * @param {string} [props.size]
 * @param {Object} [props.inputStyle]
 * @param {Object} [props.sx]
 */
export default function StaticSelect({
  multiple = undefined,
  label,
  field = null,
  filterName,
  form = {
    dirty: false,
    values: null,
    getFieldMeta: () => {},
    setFieldValue: () => {},
    setFieldTouched: () => {},
  },
  options = [],
  optionsFilter = undefined,
  optionLabelFormatter = undefined,
  optionRender = undefined,
  getFullEntity = false,
  value = null,
  onChange = () => {},
  placeholder = '',
  error = '',
  variant = 'outlined',
  hideLabel = false,
  alwaysShowPlaceholder = false,
  size = undefined,
  inputStyle = {},
  sx = {},
  ...rest
}) {
  const isFormik = !!form?.values && !!field?.name

  const meta = form.getFieldMeta(field?.name)
  const [hasFormikError, formikErrorText] = formatErrors({ field, form })
  const hasError = hasFormikError || !!error?.trim()
  const showError = ((form?.dirty && meta?.touched) || !isFormik) && hasError

  const filteredOptions = useMemo(() => {
    if (optionsFilter) {
      return options.filter(optionsFilter)
    }
    return options
  }, [options, optionsFilter])

  const getValueToReturn = (newValue) => {
    if (multiple) {
      if (getFullEntity) {
        return newValue
      }
      return newValue?.map((item) => item?.id ?? item)
    }
    return newValue?.id && !getFullEntity ? newValue.id : newValue
  }

  const handleChange = (_, newValue) => {
    const valueToReturn = getValueToReturn(newValue)
    if (isFormik) {
      form.setFieldValue(field?.name, valueToReturn).then(() => {
        form.setFieldTouched(field?.name)
      })
    }
    if (onChange) {
      onChange(valueToReturn)
    }
  }

  const handleEqualityCheck = (opt, val) => {
    if (multiple && !getFullEntity) {
      return (opt?.id ?? opt) === (val?.id ?? val)
    }
    const objOtions = val?.id && opt?.id
    return objOtions ? opt.id === val.id : opt === val
  }

  const parsedValue = isFormik ? form?.getFieldProps(field?.name)?.value : value

  const buildHelperText = () => {
    if (showError) {
      return hasFormikError ? formikErrorText : error
    }
    return rest?.helperText
  }

  const defaultOptionFormatter = (option) => {
    if (typeof option === 'object') {
      return option.label ?? option.name
    }
    const opt = options.find((o) =>
      typeof o === 'string' ? o === option : o.id === option,
    )
    return opt?.label ?? opt?.name ?? option
  }

  const shrinkValue = useMemo(() => {
    if (hideLabel) {
      return false
    }
    return alwaysShowPlaceholder ? true : undefined
  }, [hideLabel, alwaysShowPlaceholder])

  return (
    <Autocomplete
      multiple={multiple}
      options={filteredOptions}
      value={parsedValue || (multiple ? [] : null)}
      onChange={handleChange}
      getOptionLabel={optionLabelFormatter || defaultOptionFormatter}
      isOptionEqualToValue={handleEqualityCheck}
      size={size}
      sx={{ minWidth: '150px', ...sx }}
      PopperComponent={MyPopper}
      popupIcon={<ExpandMore />}
      renderInput={(params) => (
        <TextField
          {...params}
          required={rest.required}
          label={hideLabel ? null : label}
          error={showError}
          helperText={buildHelperText()}
          variant={variant}
          placeholder={placeholder}
          InputLabelProps={{
            ...params.InputLabelProps,
            shrink: shrinkValue,
          }}
          sx={inputStyle}
        />
      )}
      renderOption={(optProps, option) => (
        <li {...optProps} key={option.id}>
          {optionRender ? (
            optionRender(option)
          ) : (
            <Typography variant="body">
              {optionLabelFormatter?.(option) || defaultOptionFormatter(option)}
            </Typography>
          )}
        </li>
      )}
      {...rest}
    />
  )
}
