import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { equals, isEmpty } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { DisplaySettingsRounded, Settings } from '@mui/icons-material'
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid2,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'

import { humanize, pluralize } from 'inflection'
import { DateTime, Duration } from 'luxon'

import { Breadcrumbs, Loading } from '@common/components'
import {
  isAbortError,
  parseApiErrors,
  useLocalStorage,
  useWindowSize,
} from '@common/utils'
import { homeUrls } from '@portal/pages/Home'

import AggregationSelector from './components/AggregationSelector'
import DateRangeSelector from './components/DateRangeSelector'
import DisplaySelector from './components/DisplaySelector'
import EntitySelector from './components/EntitySelector'
import GroupedByEntityView from './components/GroupedByEntityView'
import GroupedByMetricView from './components/GroupedByMetricView'
import GroupingSelector, { formatGrouping } from './components/GroupingSelector'
import MetricsSettingsPopover from './components/MetricsSettingsPopover'
import getSmokeMetricsConstants from './constants'
import CustomDateModal from './CustomDateModal'
import ExportMetrics from './ExportMetrics'
import metrics from './metrics'
import MonthWeekModal from './MonthWeekModal'

const { availableIntervals, datePresets, weeklyRanges, monthlyRanges } =
  getSmokeMetricsConstants()

export default function SmokeMetrics() {
  const [initialLoading, setInitialLoading] = useState(true)

  const [selectedOrganizationGroups, setSelectedOrganizationGroups] = useState([])
  const [selectedOrganizations, setSelectedOrganizations] = useState([])
  const [selectedAccounts, setSelectedAccounts] = useState([])
  const [selectedProperties, setSelectedProperties] = useState([])
  const [selectedPropertyGroups, setSelectedPropertyGroups] = useState([])

  const [currentEntity, setCurrentEntity] = useState('organization')

  const [selectedPropertyStatuses, setSelectedPropertyStatuses] = useState(['LIVE'])
  const [selectedMetrics, setSelectedMetrics] = useLocalStorage('enabledMetrics', {})
  const [selectedAggregationRange, setSelectedAggregationRange] = useState(4)
  const [selectedInterval, setSelectedInterval] = useState(availableIntervals.weekly)
  const [selectedView, setSelectedView] = useState('graph')
  const [selectedGrouping, setSelectedGrouping] = useState(currentEntity)
  const [datePreset, setDatePreset] = useState(datePresets.threeMonths.value)
  const [openCustomDate, setOpenCustomDate] = useState(false)
  const [openMonthWeekModal, setOpenMonthWeekModal] = useState(false)
  const [settingsAnchor, setSettingsAnchor] = useState(false)
  const [enabledMetricsAnchor, setEnabledMetricsAnchor] = useState(false)

  const [showTotal, setShowTotal] = useState(false)
  const [groupByEntity, setGroupByEntity] = useState(false)

  const [width] = useWindowSize()

  const calculateWeeksIntervalTimeframe = useCallback(
    (now) => {
      const offset = Duration.fromObject({ days: 1 })
      const start = now
        .minus({ weeks: selectedAggregationRange - 1 })
        .plus(offset)
        .startOf('week')
        .minus(offset)
      const end = now.endOf('week').minus(offset)
      return [start, end]
    },
    [selectedAggregationRange],
  )

  const [defaultStart, defaultEnd] = calculateWeeksIntervalTimeframe(DateTime.now())
  const [dateRangeValue, setDateRangeValue] = useState([defaultStart, defaultEnd])

  const requestParams = useRef({})

  const {
    smokeMetrics,
    smokeMetricsIsLoading,
    smokeMetricsError,
    allOrgGroupSelectorGroups,
    allOrganizations,
    allAccounts,
    allProperties,
    allPropertyGroups,
    reportMetrics,
    doFetchSmokeMetrics,
    doOrgGroupSelectorListFetchAll,
    doOrganizationListFetchAll,
    doPropertyListFetchAll,
    doAccountListFetchAll,
    doPropertyGroupsListFetchAll,
    doResetSmokeMetricsState,
    doShowSnackbar,
  } = useConnect(
    'selectSmokeMetrics',
    'selectSmokeMetricsIsLoading',
    'selectSmokeMetricsError',
    'selectAllOrgGroupSelectorGroups',
    'selectAllOrganizations',
    'selectAllAccounts',
    'selectAllProperties',
    'selectAllPropertyGroups',
    'selectReportMetrics',
    'doFetchSmokeMetrics',
    'doOrgGroupSelectorListFetchAll',
    'doOrganizationListFetchAll',
    'doPropertyListFetchAll',
    'doAccountListFetchAll',
    'doPropertyGroupsListFetchAll',
    'doResetSmokeMetricsState',
    'doShowSnackbar',
  )

  const hasSelectedEntities = () =>
    !isEmpty(selectedOrganizationGroups) ||
    !isEmpty(selectedOrganizations) ||
    !isEmpty(selectedAccounts) ||
    !isEmpty(selectedProperties) ||
    !isEmpty(selectedPropertyGroups)

  const entityMap = useMemo(
    () => ({
      organizationGroup: {
        fetch: doOrgGroupSelectorListFetchAll,
        current: allOrgGroupSelectorGroups,
        selected: selectedOrganizationGroups,
        setSelected: setSelectedOrganizationGroups,
      },
      organization: {
        fetch: doOrganizationListFetchAll,
        current: allOrganizations,
        selected: selectedOrganizations,
        setSelected: setSelectedOrganizations,
      },
      account: {
        fetch: doAccountListFetchAll,
        current: allAccounts,
        selected: selectedAccounts,
        setSelected: setSelectedAccounts,
        groupBy: (opt) => opt.organizationName,
        sortByFields: ['organizationName', 'name'],
      },
      propertyGroup: {
        fetch: doPropertyGroupsListFetchAll,
        current: allPropertyGroups,
        selected: selectedPropertyGroups,
        setSelected: setSelectedPropertyGroups,
      },
      property: {
        fetch: doPropertyListFetchAll,
        current: allProperties,
        selected: selectedProperties,
        setSelected: setSelectedProperties,
        groupBy: (opt) => opt.accountName,
        sortByFields: ['accountName', 'name'],
        filter: !isEmpty(selectedPropertyStatuses)
          ? (opt) => selectedPropertyStatuses.includes(opt.status)
          : null,
      },
    }),
    [
      doOrgGroupSelectorListFetchAll,
      doOrganizationListFetchAll,
      doAccountListFetchAll,
      doPropertyGroupsListFetchAll,
      doPropertyListFetchAll,
      allOrgGroupSelectorGroups,
      allOrganizations,
      allAccounts,
      allPropertyGroups,
      allProperties,
      selectedOrganizationGroups,
      selectedOrganizations,
      selectedAccounts,
      selectedPropertyGroups,
      selectedProperties,
      setSelectedOrganizations,
      setSelectedAccounts,
      setSelectedPropertyGroups,
      setSelectedProperties,
      selectedPropertyStatuses,
    ],
  )

  const setupAggregatedDateRange = () => {
    if (selectedInterval !== 1) {
      const now = DateTime.now()
      let start
      let end
      if (selectedInterval.value === 30) {
        start = now.minus({ months: selectedAggregationRange - 1 }).startOf('month')
        end = now.endOf('month')
      } else if (selectedInterval.value === 7) {
        const [calcStart, calcEnd] = calculateWeeksIntervalTimeframe(now)
        start = calcStart
        end = calcEnd
      }
      setDateRangeValue([start, end])
    }
  }

  useEffect(() => {
    setupAggregatedDateRange()
  }, [selectedInterval, selectedAggregationRange])

  const allowedMetrics = useMemo(
    () =>
      [
        metrics.incidentRate,
        metrics.bookedIncidentRate,
        metrics.netCharges,
        metrics.netChargesPerEvent,
        metrics.netChargesPerDevice,
        metrics.netChargesPerChargedEvent,
        metrics.missedRevenue,
        metrics.chargeRate,
        metrics.billableNetChargeRate,
        metrics.grossChargeRate,
        metrics.billableGrossChargeRate,
        metrics.adjustmentRate,
        metrics.eventsTotal,
        metrics.billableEventsTotal,
        metrics.eventsCharged,
        metrics.billableEventsCharged,
        metrics.eventsRemovedStaff,
        metrics.eventsRemovedUser,
        metrics.connectivity,
        metrics.recidivismRate,
      ].map((metric) => {
        const systemMetric = reportMetrics.find((obj) => obj.key === metric.apiValue)
        const humanizedMetric = systemMetric?.name ?? humanize(metric.apiValue)
        return { ...metric, name: humanizedMetric }
      }),
    [reportMetrics],
  )

  useEffect(() => {
    // make sure that newly added metrics will be enabled by default
    setSelectedMetrics((prev) => {
      const updated = { ...prev }
      allowedMetrics.forEach((metric) => {
        if (updated[metric.apiValue] === undefined) {
          updated[metric.apiValue] = true
        }
      })
      return updated
    })
  }, [allowedMetrics])

  const fetchMetrics = async ({ propertyStatuses } = {}) => {
    if (
      hasSelectedEntities() &&
      dateRangeValue.every((value) => value !== null && value !== undefined)
    ) {
      requestParams.current = {
        start: dateRangeValue[0].toFormat('yyyy-MM-dd'),
        end: dateRangeValue[1].toFormat('yyyy-MM-dd'),
        intervalType: selectedInterval.type,
        interval: 1,
        metrics: allowedMetrics.map((item) => item.apiValue),
        propertyStatuses: propertyStatuses ?? selectedPropertyStatuses,
        grouping: selectedGrouping,
        [pluralize(currentEntity)]: entityMap[currentEntity].selected.map(
          (entity) => entity.id,
        ),
      }

      try {
        await doFetchSmokeMetrics(
          requestParams.current,
          selectedInterval.type,
          selectedInterval.value,
        )
      } catch (err) {
        const parsedError = parseApiErrors(err?.response)
        doShowSnackbar(parsedError, 'error')
      }
    }

    if (initialLoading) {
      setInitialLoading(false)
    }
  }

  useEffect(() => {
    const fetchOrgs = async () => {
      try {
        const orgs = await doOrganizationListFetchAll({ active: true })
        if (!orgs) return

        if (orgs.payload.length > 0) {
          setSelectedOrganizations(orgs.payload)
          setupAggregatedDateRange()
        } else {
          setInitialLoading(false)
        }
      } catch (err) {
        const parsedError = parseApiErrors(err)
        doShowSnackbar(parsedError, 'error')
        setInitialLoading(false)
      }
    }
    fetchOrgs()
    return () => doResetSmokeMetricsState()
  }, [])

  useEffect(() => {
    fetchMetrics()
  }, [selectedGrouping, dateRangeValue])

  useEffect(() => {
    if (smokeMetricsError) {
      const parsedError = parseApiErrors(smokeMetricsError?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [smokeMetricsError])

  const isEmptyResult = smokeMetrics ? Object.keys(smokeMetrics).length === 0 : true

  const handlePresetChange = useCallback(
    (value) => {
      if (value === datePresets.custom.value) {
        setOpenCustomDate(true)
        return
      }

      let start
      let end
      switch (value) {
        case datePresets.week.value: {
          start = DateTime.now().minus({ days: 7 })
          end = DateTime.now()
          break
        }
        case datePresets.month.value: {
          start = DateTime.now().minus({ month: 1 })
          end = DateTime.now()
          break
        }
        case datePresets.twoMonths.value: {
          start = DateTime.now().minus({ month: 2 })
          end = DateTime.now()
          break
        }
        case datePresets.threeMonths.value: {
          start = DateTime.now().minus({ month: 3 })
          end = DateTime.now()
          break
        }
        case datePresets.monthToDate.value: {
          start = DateTime.now().startOf('month')
          end = DateTime.now()
          break
        }
        case datePresets.lastMonth.value: {
          const prevMonth = DateTime.now().minus({ month: 1 })
          start = prevMonth.startOf('month')
          end = prevMonth.endOf('month')
          break
        }
        case datePresets.yearToDate.value: {
          start = DateTime.now().startOf('year')
          end = DateTime.now()
          break
        }
        default:
          throw Error(`${value} preset is not supported`)
      }
      setDatePreset(value)
      setDateRangeValue([start, end])
    },
    [datePresets],
  )

  const aggregatedDateRanges = useMemo(() => {
    if (selectedInterval.value !== 1) {
      return selectedInterval.value === 7 ? weeklyRanges : monthlyRanges
    }
    return null
  }, [selectedInterval])

  const handleAggregatedDateRangeChange = useCallback(
    (value) => {
      if (value === datePresets.custom.value) {
        setOpenMonthWeekModal(true)
        return
      }
      setSelectedAggregationRange(value)
    },
    [datePresets],
  )

  const visibleDatePresetKeys = useMemo(() => {
    if (equals(selectedInterval, availableIntervals.weekly)) {
      return Object.keys(datePresets).filter(
        (key) => datePresets[key].value !== datePresets.week.value,
      )
    }
    if (equals(selectedInterval, availableIntervals.monthly)) {
      return Object.keys(datePresets).filter(
        (key) =>
          ![datePresets.week.value, datePresets.month.value].includes(
            datePresets[key].value,
          ),
      )
    }
    return Object.keys(datePresets)
  }, [selectedInterval, availableIntervals, datePresets])

  useEffect(() => {
    if (selectedInterval.value === 1) {
      handlePresetChange(datePresets.threeMonths.value)
    }
  }, [selectedInterval])

  const fetchEntities = async (entity) => {
    try {
      entityMap[entity].fetch({ active: true })
    } catch (err) {
      if (!isAbortError(err)) {
        const parsedError = parseApiErrors(err?.response)
        doShowSnackbar(parsedError, 'error')
      }
    }
  }

  const resetAdjacentEntities = (entity) =>
    Object.keys(entityMap)
      .filter((e) => e !== entity)
      .forEach((ent) => entityMap[ent].setSelected([]))

  const groupedByEntityData = useMemo(() => {
    if (!smokeMetrics) return {}

    const metricsEntries = Object.entries(smokeMetrics)
    const result = {}

    metricsEntries.forEach((entries) => {
      entries[1].data.forEach((data) => {
        const key = [...data.key.split(':').slice(0, 2), data.name].join(':')

        const systemMetric = reportMetrics.find((obj) => obj.key === data.metric)
        const humanizedMetric = systemMetric?.name ?? humanize(data.metric)
        const mData = { ...data, name: humanizedMetric }
        if (key in result) {
          result[key].push(mData)
        } else {
          result[key] = [mData]
        }
      })
    })
    return result
  }, [smokeMetrics])

  return (
    <>
      <MonthWeekModal
        open={openMonthWeekModal}
        entity={selectedInterval.value === 7 ? 'week' : 'month'}
        onCancel={() => setOpenMonthWeekModal(false)}
        onSave={(value) => {
          setSelectedAggregationRange(value)
          setOpenMonthWeekModal(false)
        }}
      />
      <CustomDateModal
        open={openCustomDate}
        onCancel={() => setOpenCustomDate(false)}
        onSave={(dateRange) => {
          setDateRangeValue(dateRange)
          setDatePreset(datePresets.custom.value)
          setOpenCustomDate(false)
        }}
      />
      <Popover
        open={!!enabledMetricsAnchor}
        onClose={() => setEnabledMetricsAnchor(null)}
        anchorEl={enabledMetricsAnchor}
        anchorOrigin={{ vertical: 40, horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Stack direction="column" px={2} py={0.5}>
          <Typography variant="h6">Available Metrics</Typography>
          {allowedMetrics
            .sort((a, b) => (a.position > b.position ? 1 : -1))
            .map((metric) => (
              <FormControlLabel
                key={metric.apiValue}
                slotProps={{ typography: { fontSize: 14 } }}
                control={
                  <Checkbox
                    checked={selectedMetrics[metric.apiValue]}
                    size="small"
                    onChange={() =>
                      setSelectedMetrics((prev) => ({
                        ...prev,
                        [metric.apiValue]: !prev[metric.apiValue],
                      }))
                    }
                  />
                }
                label={metric.name}
              />
            ))}
        </Stack>
      </Popover>
      <MetricsSettingsPopover
        anchor={settingsAnchor}
        onClose={({ propertyStatuses }) => {
          setSettingsAnchor(null)

          if (!equals(selectedPropertyStatuses, propertyStatuses)) {
            if (currentEntity === 'property') {
              entityMap[currentEntity].setSelected([])
            }
            fetchMetrics({ propertyStatuses })
          }
          setSelectedPropertyStatuses(propertyStatuses)
        }}
        isLoading={smokeMetricsIsLoading}
        selectedView={selectedView}
        showTotal={showTotal}
        setShowTotal={setShowTotal}
        groupByEntity={groupByEntity}
        setGroupByEntity={setGroupByEntity}
        initialPropertyStatuses={selectedPropertyStatuses}
      />
      <Box m={3} display="flex" flexDirection="column">
        <Breadcrumbs
          links={[{ label: 'Home', href: homeUrls.home }, { label: 'Metrics' }]}
        />
        <Typography variant="h3" mb={2}>
          Metrics Dashboard
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="flex-end" mb={2}>
          <Box display="flex" alignItems="flex-end" gap={2}>
            <Box display="flex" flexDirection="column" gap={0.5}>
              <Tooltip title="Toggle available metrics" placement="right">
                <span>
                  <IconButton
                    onClick={(e) => setEnabledMetricsAnchor(e.currentTarget)}
                    disabled={smokeMetricsIsLoading || initialLoading}
                  >
                    <DisplaySettingsRounded />
                  </IconButton>
                </span>
              </Tooltip>
              <IconButton
                onClick={(e) => setSettingsAnchor(e.currentTarget)}
                disabled={smokeMetricsIsLoading || initialLoading}
              >
                <Settings />
              </IconButton>
            </Box>
            <EntitySelector
              entityMap={entityMap}
              currentEntity={currentEntity}
              onSave={fetchMetrics}
              onOpen={() => fetchEntities(currentEntity)}
              onEntityTypeChange={(type) => {
                resetAdjacentEntities(type)
                setCurrentEntity(type)
                setSelectedGrouping(formatGrouping(type))
              }}
              onEntitiesChange={(entity) => {
                if (isEmpty(entity)) {
                  requestParams.current = {}
                  doResetSmokeMetricsState()
                }
                entityMap[currentEntity].setSelected(entity)
              }}
              onOptionChanged={() => setSelectedGrouping(formatGrouping(currentEntity))}
              disabled={smokeMetricsIsLoading || initialLoading}
            />
            <AggregationSelector
              selectedInterval={selectedInterval}
              setSelectedInterval={setSelectedInterval}
              availableIntervals={availableIntervals}
              disabled={smokeMetricsIsLoading || initialLoading}
            />
            <DisplaySelector
              selectedView={selectedView}
              setSelectedView={setSelectedView}
              disabled={smokeMetricsIsLoading || initialLoading}
            />
            <GroupingSelector
              selectedGrouping={selectedGrouping}
              currentEntity={currentEntity}
              setSelectedGrouping={setSelectedGrouping}
              disabled={smokeMetricsIsLoading || initialLoading}
            />
          </Box>
          <Box display="flex" alignItems="flex-start">
            <Stack direction="row" alignItems="end">
              <DateRangeSelector
                aggregatedDateRanges={aggregatedDateRanges}
                selectedAggregationRange={selectedAggregationRange}
                selectedInterval={selectedInterval}
                visibleDatePresetKeys={visibleDatePresetKeys}
                datePreset={datePreset}
                datePresets={datePresets}
                dateRangeValue={dateRangeValue}
                handlePresetChange={handlePresetChange}
                handleAggregatedDateRangeChange={handleAggregatedDateRangeChange}
                disabled={smokeMetricsIsLoading || initialLoading}
              />
              {requestParams.current?.start && (
                <ExportMetrics
                  requestParams={requestParams.current}
                  interval={selectedInterval.value}
                  sx={{ ml: 1 }}
                />
              )}
            </Stack>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="calc(100vh - 130px)"
        >
          {!hasSelectedEntities() && !initialLoading ? (
            <Typography variant="h4" color="grey">
              No Selected Entities
            </Typography>
          ) : (
            <>
              {(smokeMetricsIsLoading || initialLoading) && <Loading />}
              {smokeMetrics && isEmptyResult && (
                <Typography variant="h4" color="grey">
                  No Data For Selected Filters
                </Typography>
              )}
              {smokeMetrics && !isEmptyResult && !smokeMetricsIsLoading && (
                <Box
                  display="flex"
                  height={1}
                  width={1}
                  alignItems="start"
                  minHeight="calc(100vh - 130px)"
                >
                  <Grid2
                    container
                    spacing={2}
                    columns={selectedView === 'graph' && width > 1700 ? 18 : 12}
                    mb={4}
                    sx={{ width: 1 }}
                  >
                    {selectedView === 'table' && groupByEntity && (
                      <GroupedByEntityView
                        groupedByEntityData={groupedByEntityData}
                        selectedMetrics={selectedMetrics}
                      />
                    )}
                    {((selectedView === 'table' && !groupByEntity) ||
                      selectedView === 'graph') && (
                      <GroupedByMetricView
                        smokeMetrics={smokeMetrics}
                        selectedMetrics={selectedMetrics}
                        selectedView={selectedView}
                        selectedInterval={selectedInterval}
                        dateRangeValue={dateRangeValue}
                        showTotal={showTotal}
                      />
                    )}
                  </Grid2>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  )
}
