import { pick, pipe } from 'ramda'
import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import { camelize, underscore } from 'inflection'
import ms from 'milliseconds'
import reduceReducers from 'reduce-reducers'

import { isAbortError } from './parseApiErrors'

const defaultOptions = {
  staleAfter: ms.minutes(60),
  retryAfter: ms.seconds(10),
}

const defaultListActions = [
  'set_filter',
  'set_page',
  'set_page_size',
  'set_search',
  'set_ordering',
]

const listParams = defaultListActions.map((action) =>
  camelize(action.replace('set_', ''), true),
)

const defaultSearchTest = (payload = '') => payload.length >= 3 || payload.length === 0

export const defaultInitialState = {
  data: { results: [] },
  search: '',
  filter: {},
  ordering: null,
  page: 0,
  pageSize: 25,
}

const createListActionTypes = (actionBaseType, actions) =>
  actions.map((action) => `${actionBaseType}_${action.toUpperCase()}`)

const createActionTypesMap = (actionTypes) =>
  actionTypes.reduce((acc, action) => {
    if (!action.includes('_SET_')) return acc
    return {
      ...acc,
      [action]: camelize(action.split('_SET_').pop().toLowerCase(), true),
    }
  }, {})

const isValidParam = (param) => {
  if (Boolean(param) === param) return true
  if (param == null) return false
  if (Array.isArray(param)) return Boolean(param.length)
  if (param instanceof Date) return Boolean(param.getTime())
  return Boolean(param)
}

const defaultParamTransform = (params) =>
  Object.keys(params).reduce((acc, key) => {
    if (key === 'filter') {
      return { ...acc, ...params.filter }
    }
    return { ...acc, [key]: params[key] }
  }, {})

/**
 * @callback fetchHandler
 * @param {{ apiFetch: function, dispatch: function, listState: Object }} kwargs
 */
/**
 * @param {Object} config
 * @param {string[]} config.actions Actions allowed for each entity list, typically the default array
 * @param {string} config.entityName Name of entity, i,e. device
 * @param {string} [config.name=`${config.entityName}List`] Default bundle name
 * @param {Object} [config.initialState] State in which to initialize list
 * @param {fetchHandler} config.fetchHandler Function that calls entity list API
 * @param {authorizationSelector} [config.authorizationSelector] Action to select authenticated functions
 * @param {boolean} [config.authorizationTest] Checks if data/user passed is authorized to perform operations
 * @param {boolean} [config.transformParams=function] function to transform params before passing to fetchHandler
 * @param {boolean} [urlTest] Checks that url being called is valid for list
 * @return {{ name: string, reducer: function }} - asyncResourceBundle
 */

const createListBundle = (config) => {
  const {
    actions = defaultListActions,
    entityName,
    name = `${entityName}List`,
    initialState = defaultInitialState,
    fetchHandler,
    authorizationSelector = 'selectIsAuthenticated',
    authorizationTest = Boolean,
    searchTest = defaultSearchTest,
    transformParams = defaultParamTransform,
    urlTest = Boolean,
    ...options
  } = config

  const lfNameFragment = camelize(name, true)
  const rbNameFragment = camelize(name)
  const actionBaseType = underscore(name).toUpperCase()
  const markOutdatedName = `doMark${rbNameFragment}AsOutdated`
  const actionTypes = createListActionTypes(actionBaseType, actions)
  const actionTypesMap = createActionTypesMap(actionTypes, rbNameFragment)

  const { [markOutdatedName]: initialMarkOutdated, ...initialBundle } =
    createAsyncResourceBundle({
      ...defaultOptions,
      ...options,
      actionBaseType,
      name,
      getPromise: async (kwargs) => {
        const { store } = kwargs
        const {
          [`${lfNameFragment}Raw`]: listState,
          [`${lfNameFragment}ApiParams`]: params,
        } = store.select([
          `select${rbNameFragment}Raw`,
          `select${rbNameFragment}ApiParams`,
        ])

        try {
          const response = await fetchHandler({ ...kwargs, listState, params })
          return response
        } catch (error) {
          if (!isAbortError(error)) throw error
        }
        return listState?.data
      },
    })

  return {
    ...initialBundle,
    [markOutdatedName]: initialMarkOutdated,
    reducer: reduceReducers(initialBundle.reducer, (state, action) => {
      if (
        action.type === `${actionBaseType}_CLEARED` ||
        action.type === `${actionBaseType}_PARAMS_CLEARED`
      ) {
        return { ...state, ...initialState }
      }
      if (
        (!action.type || !action.type.startsWith(actionBaseType)) &&
        Object.keys(initialState).some(
          (key) => state[key] == null && initialState[key] != null,
        )
      ) {
        return {
          ...state,
          ...Object.entries(initialState).reduce(
            (params, [key, initialValue]) =>
              state[key] == null && initialValue != null
                ? { ...params, [key]: initialValue }
                : params,
            {},
          ),
        }
      }
      if (action.type in actionTypesMap) {
        const key = actionTypesMap[action.type]
        return {
          ...state,
          page: initialState.page,
          [key]: action.payload,
          fetchOutdated: state.isLoading,
        }
      }
      if (action.type === `${actionBaseType}_PARAMS_SET`) {
        const payload = pick(Object.keys(initialState), action.payload)
        return {
          ...(action.meta?.replace ? { ...state, ...initialState } : state),
          ...payload,
          fetchOutdated: state.isLoading,
        }
      }
      if (action.type === `${actionBaseType}_FETCH_FINISHED` && state.fetchOutdated) {
        return { ...state, fetchOutdated: false, isOutdated: true }
      }
      return state
    }),
    [`do${rbNameFragment}Clear`]:
      () =>
      ({ dispatch }) => {
        dispatch({ type: `${actionBaseType}_CLEARED` })
        dispatch({ actionCreator: markOutdatedName, args: [] })
      },
    [`do${rbNameFragment}ClearParams`]:
      () =>
      ({ dispatch }) => {
        dispatch({ type: `${actionBaseType}_PARAMS_CLEARED` })
        dispatch({ actionCreator: markOutdatedName, args: [] })
      },
    [`do${rbNameFragment}SetParams`]:
      (payload, meta) =>
      ({ dispatch }) => {
        dispatch({ type: `${actionBaseType}_PARAMS_SET`, payload, meta })
        dispatch({ actionCreator: markOutdatedName, args: [] })
      },
    ...Object.entries(actionTypesMap).reduce(
      (acc, [type, key]) => ({
        ...acc,
        [`do${rbNameFragment}Set${camelize(key)}`]:
          (payload) =>
          ({ dispatch, store }) => {
            dispatch({ type, payload })

            if (type.endsWith('SET_SEARCH') && !searchTest(payload)) return

            if (store[`select${rbNameFragment}IsLoading`]()) return

            dispatch({ actionCreator: markOutdatedName, args: [] })
          },
      }),
      {},
    ),
    [`react${rbNameFragment}Fetch`]: createSelector(
      authorizationSelector,
      `select${rbNameFragment}ShouldUpdate`,
      'selectRouteInfo',
      'selectHashObject',
      'selectQueryObject',
      (authorizationData, shouldUpdate, { url, pattern }, hashObject, queryObject) => {
        if (
          authorizationTest(authorizationData) &&
          shouldUpdate &&
          urlTest(url, pattern, Object.keys(hashObject)[0], queryObject)
        ) {
          return { actionCreator: `doFetch${rbNameFragment}` }
        }
        return undefined
      },
    ),
    [`select${rbNameFragment}ShouldUpdate`]: createSelector(
      'selectIsOnline',
      `select${rbNameFragment}IsLoading`,
      `select${rbNameFragment}FailedPermanently`,
      `select${rbNameFragment}IsWaitingToRetry`,
      `select${rbNameFragment}`,
      `select${rbNameFragment}IsStale`,
      (isOnline, isLoading, failedPermanently, isWaitingToRetry, data, isStale) => {
        if (!isOnline || isLoading || failedPermanently || isWaitingToRetry) {
          return false
        }
        // permission/flag checks might happen here
        if (data === initialState.data) {
          return true
        }
        return isStale
      },
    ),
    [`select${rbNameFragment}Params`]: createSelector(
      `select${rbNameFragment}Raw`,
      pick(listParams),
    ),
    [`select${rbNameFragment}ApiParams`]: createSelector(
      `select${rbNameFragment}Params`,
      pipe(
        (params) =>
          Object.entries(params).reduce((acc, [key, value]) => {
            if (Array.isArray(value) && value.length === 2) {
              const [field, direction] = value
              if (direction === 'asc' || direction === 'desc') {
                return { ...acc, [key]: direction === 'asc' ? field : `-${field}` }
              }
            }
            return isValidParam(value) ? { ...acc, [key]: value } : acc
          }, []),
        transformParams,
      ),
    ),
  }
}

export default createListBundle
