import { createListBundle } from '@common/utils'

import reservationUrls from './urls'

const entityName = 'reservation'

const reservationListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, params }) =>
    apiFetch('/reservations/', params, { cancelationPrefix: entityName }),
  urlTest: (url, pattern, hash) =>
    url === reservationUrls.reservations || hash === 'reservations',
})

export default reservationListBundle
