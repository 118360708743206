import { useCallback, useEffect, useMemo, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { CheckCircleRounded, Circle, Close, WarningRounded } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { tableCellClasses } from '@mui/material/TableCell'

import html2pdf from 'html2pdf.js'
import { camelize } from 'inflection'
import { DateTime } from 'luxon'

import { sanitizeFileName } from '@common/utils'

import AqiChart, { EVENT_AQI_X } from './AqiChart'
import {
  dataQualityBasicNameMapping,
  dataQualityNormalRanges,
  faqData,
  getFooterText,
} from './constants'

const FONT_SIZE = 8
const ACCENT_COLOR = '#252e4b'
const TEXT_COLOR = '#9ea3aa'

const innerBlockHeaderStyle = {
  fontSize: FONT_SIZE + 4,
  fontWeight: 600,
  color: ACCENT_COLOR,
  textTransform: 'uppercase',
  fontFamily: 'Degular',
  mb: 1,
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} [props.event]
 * @param {string} props.event.id
 * @param {string} props.event.createdOn
 * @param {string} props.event.propertyName
 * @param {string} props.event.unitName
 * @param {number} props.event.childCount
 * @param {Object} [props.event.reservation]
 * @param {string} props.event.reservation.id
 * @param {string} props.event.reservation.confirmationNumber
 * @param {string} props.event.timeZone
 * @param {number} [props.event.eventPosition]
 * @param {Function} props.onClose
 */
export default function SmokeQualityReport({ event = undefined, onClose }) {
  const [initialLoading, setInitialLoading] = useState(true)

  const {
    systemDataTypes,
    systemDeviceModels,
    smokeQualityReport,
    smokeQualityReportError,
    smokeQualityReportIsLoading,
    doFetchSmokeQualityReport,
    doResetSmokeQualityReportState,
  } = useConnect(
    'selectSystemDataTypes',
    'selectSystemDeviceModels',
    'selectSmokeQualityReport',
    'selectSmokeQualityReportError',
    'selectSmokeQualityReportIsLoading',
    'doFetchSmokeQualityReport',
    'doResetSmokeQualityReportState',
  )

  const fetchReport = useCallback(async () => {
    try {
      await doFetchSmokeQualityReport(event)
    } catch (err) {
      // no need to show snackbar or smth. We use `eventReportError` to handle error
    } finally {
      setInitialLoading(false)
    }
  }, [event])

  useEffect(() => {
    if (event) {
      fetchReport()
    } else {
      doResetSmokeQualityReportState()
      setInitialLoading(true)
    }
  }, [event])

  const dataQualityNameMapping = useMemo(
    () =>
      Object.entries(dataQualityBasicNameMapping)
        .map(([key, name]) => {
          const template = systemDataTypes.find((type) => type.key === key)?.template
          const unit = template?.replace('{{ value }}', '')?.trim()
          return { [key]: unit ? `${name} (${unit})` : name }
        })
        .reduce((acc, item) => ({ ...acc, ...item })),
    [systemDataTypes],
  )

  const eventDateTime = useMemo(() => {
    if (event) {
      const timezone = event.timeZone || smokeQualityReport?.property?.timezone
      const dateTime = DateTime.fromISO(event.createdOn, { zone: timezone })
      const formattedDate = dateTime.toFormat('MM/dd/yyyy')
      const formattedTime = dateTime.toFormat('hh:mma z')
      return { date: formattedDate, time: formattedTime }
    }
    return null
  }, [event, smokeQualityReport])

  const propertyAddress = useMemo(() => {
    if (smokeQualityReport?.property) {
      const { property } = smokeQualityReport
      return {
        part1: property.address1,
        part2: `${property.city}, ${property.state} ${property.zipCode}`,
      }
    }
    return null
  }, [smokeQualityReport?.property])

  const deviceData = useMemo(() => {
    if (smokeQualityReport?.device && smokeQualityReport?.report) {
      const { device, report } = smokeQualityReport
      const formattedInstallDate = report.deviceInstalledOn
        ? DateTime.fromISO(report.deviceInstalledOn, {
            zone: device.timezone,
          }).toFormat('MM/dd/yyyy')
        : null
      const model =
        device.modelName ??
        systemDeviceModels?.find((data) => data.id === device.model)?.name

      return {
        model,
        serial: device.serialNumber,
        mac: device.mainMac,
        installDate: formattedInstallDate,
      }
    }
    return null
  }, [smokeQualityReport?.device, smokeQualityReport?.report])

  const generateReport = async () => {
    const { propertyName, unitName, createdOn } = event
    const fileName = `event_report_${propertyName}_${unitName}_${createdOn}.pdf`

    const input = document.getElementById('printable_canvas')
    await html2pdf()
      .from(input)
      .set({
        filename: sanitizeFileName(fileName),
        html2canvas: { scale: 2 },
        jsPDF: {
          orientation: 'portrait',
          format: [297, 210],
        },
      })
      .save()
  }

  const StyledTableCell = styled(TableCell)(({ color }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: 7,
      padding: '0px 3px',
      border: 0,
      color,
      backgroundColor: '#f0f1f4',
    },
    [`&.${tableCellClasses.head}:first-of-type`]: {
      borderRadius: '1em 0 0 1em',
    },
    [`&.${tableCellClasses.head}:last-child`]: {
      borderRadius: '0 1em 1em 0',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 7,
      padding: 2,
      border: 0,
      color,
    },
  }))

  const DataBlock = useCallback(
    ({ header, children }) => (
      <Box
        sx={{
          border: 1,
          borderRadius: 3,
          borderColor: '#f3f3f3',
          backgroundColor: '#fdfdfd',
          width: 1,
          overflow: 'hidden',
        }}
      >
        <Typography
          sx={{
            backgroundColor: ACCENT_COLOR,
            px: 3,
            py: 1.5,
            textTransform: 'uppercase',
            color: 'white',
            fontFamily: 'Degular',
            fontSize: 14,
            fontWeight: 700,
          }}
        >
          {header}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', p: 2, gap: 1.5 }}>
          {children}
        </Box>
      </Box>
    ),
    [],
  )

  const DataRow = useCallback(
    ({ title, text }) => (
      <Box display="flex" flexDirection="column">
        <Typography sx={{ fontSize: FONT_SIZE, color: ACCENT_COLOR, fontWeight: 600 }}>
          {title}
        </Typography>
        <Typography
          sx={{ fontSize: FONT_SIZE, color: TEXT_COLOR, whiteSpace: 'pre-line' }}
        >
          {text || '--'}
        </Typography>
      </Box>
    ),
    [],
  )

  const SensorInfoBlock = useCallback(
    () => (
      <Box display="flex" flexDirection="column" width={1}>
        <Typography sx={innerBlockHeaderStyle}>Sensor Information</Typography>
        <Box display="flex" width={1} gap={1}>
          <Box display="flex" flexDirection="column" width={1} gap={1}>
            <DataRow title="Sensor Model" text={deviceData?.model?.split('(')[0]} />
            {deviceData?.serial && (
              <DataRow title="Serial Number" text={deviceData?.serial} />
            )}
            <DataRow title="MAC" text={deviceData?.mac} />
            <DataRow
              title="Status"
              text={
                <Box
                  component="span"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap={0.2}
                >
                  <Circle sx={{ fontSize: 10, color: '#95fbb7' }} />
                  Online
                </Box>
              }
            />
            <DataRow
              title="Sensor Quality Check"
              text={
                <Box
                  component="span"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap={0.2}
                >
                  <CheckCircleRounded sx={{ fontSize: 10, color: '#95fbb7' }} />
                  Pass
                </Box>
              }
            />
          </Box>
          <Box display="flex" flexDirection="column" width={1} gap={1}>
            {deviceData?.installDate && (
              <DataRow title="Install Date" text={deviceData?.installDate} />
            )}
          </Box>
        </Box>
      </Box>
    ),
    [deviceData],
  )

  const DataQualityChecksBlock = useCallback(() => {
    const highlightedDataTypes = [
      'mc1p0Mean',
      'mc2p5Mean',
      'mc4p0Mean',
      'mc10p0Mean',
      'smokeIndex',
    ]

    const data = Object.entries(dataQualityNameMapping).map(([key, value]) => {
      const formattedKey = camelize(key, true)
      let normalRange = dataQualityNormalRanges[key]
      if (key === 'temp_mean') {
        const template = systemDataTypes.find((type) => type.key === key)?.template
        const unit = template?.replace('{{ value }}', '')?.trim()
        if (unit === '°C') {
          normalRange = dataQualityNormalRanges.temp_mean_celsius
        }
      }
      return {
        id: formattedKey,
        name: value,
        prior:
          typeof smokeQualityReport?.report?.priorData?.[formattedKey] === 'number'
            ? Math.round(smokeQualityReport.report.priorData[formattedKey] * 10) / 10
            : null,
        normal: normalRange,
        event:
          typeof smokeQualityReport?.report?.eventData?.[formattedKey] === 'number'
            ? Math.round(smokeQualityReport.report.eventData[formattedKey] * 10) / 10
            : null,
      }
    })

    return (
      <Box width={1}>
        <Typography sx={innerBlockHeaderStyle}>Sensor Readings Overview</Typography>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell />
                <StyledTableCell color={ACCENT_COLOR} align="left">
                  Normal Ranges
                </StyledTableCell>
                <StyledTableCell color={ACCENT_COLOR} align="left">
                  At start of event
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item) => (
                <TableRow key={item.name}>
                  <StyledTableCell component="th" scope="row">
                    {item.name}
                  </StyledTableCell>
                  <StyledTableCell align="left" color="#9ba1a8">
                    {item.normal}
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    color={
                      highlightedDataTypes.find((type) => type === item.id)
                        ? '#D16565'
                        : 'inherit'
                    }
                  >
                    {item.event}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  }, [smokeQualityReport?.report])

  const IndoorAqiBlock = useCallback(
    () => (
      <Box width={1}>
        <Typography sx={innerBlockHeaderStyle}>Indoor AQI</Typography>
        <AqiChart
          data={[
            {
              x: EVENT_AQI_X,
              y: smokeQualityReport?.report?.eventAqi,
            },
          ]}
        />
      </Box>
    ),
    [smokeQualityReport?.report],
  )

  return (
    <Dialog open={!!event} fullScreen onClose={onClose}>
      <DialogContent sx={{ position: 'relative' }}>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 25,
            right: 25,
            zIndex: 1001,
          }}
        >
          <Close />
        </IconButton>

        <Button
          disabled={smokeQualityReportIsLoading || !smokeQualityReport}
          variant="contained"
          sx={{
            position: 'absolute',
            top: 25,
            right: 75,
            zIndex: 1001,
          }}
          onClick={generateReport}
        >
          Generate PDF
        </Button>

        <Box style={{ margin: 10, marginLeft: 30, marginTop: 75 }}>
          <div
            id="printable_canvas"
            style={{
              display: 'flex',
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                border: '1px solid',
                width: '210mm',
                height: '296.8mm',
                px: 6,
                pt: event?.childCount ? 4 : 6,
                pb: 2,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontFamily: 'Degular',
                    fontWeight: '600',
                    lineHeight: 0.2,
                    textTransform: 'uppercase',
                    color: '#8d66ef',
                  }}
                >
                  Report
                </Typography>
                <Typography
                  sx={{ fontFamily: 'Degular', fontSize: 30, fontWeight: '600' }}
                >
                  Smoking Policy Violation Report
                </Typography>
              </Box>
              {smokeQualityReportIsLoading && (
                <Box
                  id="loading"
                  display="flex"
                  flexDirection="column"
                  width={1}
                  height={1}
                  alignItems="center"
                  justifyContent="center"
                >
                  <CircularProgress size={100} sx={{ height: 1, width: 1 }} />
                </Box>
              )}
              {!smokeQualityReport &&
                !smokeQualityReportIsLoading &&
                !initialLoading && (
                  <Box
                    id="error"
                    display="flex"
                    flexDirection="column"
                    width={1}
                    height={1}
                    gap={2}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <WarningRounded sx={{ fontSize: 100, color: 'error.main' }} />
                    <Typography sx={{ fontSize: 26, fontWeight: 600 }}>
                      {smokeQualityReportError?.error?.response?.nonFieldErrors ||
                        'Oops. Something went wrong'}
                    </Typography>
                  </Box>
                )}
              {smokeQualityReport && !smokeQualityReportIsLoading && (
                <Box id="content" display="flex" flexDirection="column" gap={2}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: 2,
                      mt: 1,
                      height: 210,
                    }}
                  >
                    <DataBlock header="Property details">
                      <DataRow title="Property Name" text={event?.propertyName} />
                      <DataRow
                        title="Address"
                        text={
                          propertyAddress
                            ? `${propertyAddress.part1}\n${propertyAddress.part2}`
                            : null
                        }
                      />
                    </DataBlock>
                    <DataBlock header="Smoking Policy">
                      <Typography sx={{ fontSize: FONT_SIZE, color: TEXT_COLOR }}>
                        {smokeQualityReport?.property?.smokingPolicy || '--'}
                      </Typography>
                    </DataBlock>
                    <DataBlock header="Smoking Event Summary">
                      <DataRow title="Room Number" text={event?.unitName} />
                      {event?.reservation?.confirmationNumber && (
                        <DataRow
                          title="Reservation Number"
                          text={event?.reservation?.confirmationNumber}
                        />
                      )}
                      <DataRow title="Date" text={eventDateTime?.date} />
                      <DataRow title="Time" text={eventDateTime?.time} />
                      {(!!smokeQualityReport?.report?.allChildEventsCount ||
                        !!smokeQualityReport?.report?.parentAllChildEventsCount) && (
                        <DataRow
                          title="Additional Occurences"
                          text={`Event ${event?.eventPosition ?? 1} of ${
                            (smokeQualityReport.report.allChildEventsCount ??
                              smokeQualityReport.report.parentAllChildEventsCount) + 1
                          } for this reservation`}
                        />
                      )}
                    </DataBlock>
                  </Box>
                  <DataBlock header="System Details and Diagnostics">
                    <Box display="flex" width={1} gap={2}>
                      <SensorInfoBlock />
                      <DataQualityChecksBlock />
                      <IndoorAqiBlock />
                    </Box>
                  </DataBlock>
                  <DataBlock header="Report Summary">
                    {Object.entries(faqData).map(([title, text]) => (
                      <Box key={title}>
                        <Typography
                          sx={{
                            fontSize: FONT_SIZE,
                            fontWeight: 600,
                            color: ACCENT_COLOR,
                          }}
                        >
                          {title}
                        </Typography>
                        <Typography sx={{ fontSize: FONT_SIZE, color: TEXT_COLOR }}>
                          {text}
                        </Typography>
                      </Box>
                    ))}
                  </DataBlock>
                  <Typography sx={{ fontSize: FONT_SIZE }}>
                    {getFooterText(event?.propertyName)}
                  </Typography>
                </Box>
              )}
            </Box>
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
