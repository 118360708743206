import { createListBundle } from '@common/utils'

import installersUrls from './urls'

const entityName = 'installer'

const installerListBundle = createListBundle({
  entityName,
  fetchHandler: ({ apiFetch, params }) =>
    apiFetch('/installers/', params, { cancelationPrefix: entityName }),
  urlTest: (url) => url === installersUrls.list,
})

export default {
  ...installerListBundle,
  doAddInstaller:
    ({ email, name, property }) =>
    async ({ apiFetch }) =>
      apiFetch(
        `/installers/`,
        { email, property, ...(name ? { name } : {}) },
        {
          method: 'POST',
          cancelationPrefix: entityName,
        },
      ),
  doDeleteInstaller:
    (payload) =>
    async ({ apiFetch }) =>
      apiFetch('/installers/', payload, {
        method: 'DELETE',
        cancelationPrefix: entityName,
      }),
}
