import { DEFAULT_LAYOUT_KEY } from '@common/constants'
import { generateColors } from '@common/utils'

export const DIAGNOSTIC_CATEGORY = 'diagnostic'
export const NOISE_CATEGORY = 'noise'
export const OCCUPANCY_GATEGORY = 'occupancy'
export const ENVIRONMENT_CATEGORY = 'environment'
export const SMOKE_CATEGORY = 'smoke'
export const SMOKE_MASS_CONCENTRATION_CATEGORY = 'smoke - mass concentration'
export const SMOKE_NUMBER_COUNT_CATEGORY = 'smoke - number count'

export const DATABROWSER_AUTOMOTIVE_PM = 'pm'
export const DATABROWSER_AUTOMOTIVE_GASES = 'gases'
export const DATABROWSER_AUTOMOTIVE_INDEXES = 'indexes'
export const DATABROWSER_AUTOMOTIVE_ENVIRONMENT = 'environment'
export const DATABROWSER_AUTOMOTIVE_NC = 'nc'
export const DATABROWSER_AUTOMOTIVE_RAW = 'raw'
export const DATABROWSER_AUTOMOTIVE_MOX = 'mox'

const COLOR_7F66B3 = '#7F66B3'
const COLOR_669CC3 = '#669CC3'
const COLOR_CF8473 = '#CF8473'
const COLOR_344B78 = '#344B78'
const COLOR_547E7C = '#547E7C'

export const colorsPool = [
  COLOR_7F66B3,
  COLOR_669CC3,
  COLOR_CF8473,
  COLOR_344B78,
  COLOR_547E7C,
  ...generateColors({ saturationMultiplier: 40, brightnessMultiplier: 0 }),
]

const automotiveTypesMeta = {
  mc_1p0_mean: {
    color: colorsPool[0],
    category: DATABROWSER_AUTOMOTIVE_PM,
    visible: true,
  },
  mc_2p5_mean: {
    color: colorsPool[1],
    category: DATABROWSER_AUTOMOTIVE_PM,
    visible: true,
  },
  mc_4p0_mean: {
    color: colorsPool[2],
    category: DATABROWSER_AUTOMOTIVE_PM,
    visible: true,
  },
  mc_10p0_mean: {
    color: colorsPool[3],
    category: DATABROWSER_AUTOMOTIVE_PM,
    visible: true,
  },

  etoh: {
    color: colorsPool[0],
    category: DATABROWSER_AUTOMOTIVE_GASES,
    visible: true,
  },
  eco2_mean: {
    color: colorsPool[1],
    category: DATABROWSER_AUTOMOTIVE_GASES,
    visible: true,
  },
  co2: {
    color: colorsPool[2],
    category: DATABROWSER_AUTOMOTIVE_GASES,
    visible: true,
  },
  tvoc_mean: {
    color: colorsPool[3],
    category: DATABROWSER_AUTOMOTIVE_GASES,
    visible: true,
  },

  voc_mean: {
    color: colorsPool[0],
    category: DATABROWSER_AUTOMOTIVE_INDEXES,
    visible: true,
  },
  nox_mean: {
    color: colorsPool[1],
    category: DATABROWSER_AUTOMOTIVE_INDEXES,
    visible: true,
  },
  zmod_iaq: {
    color: colorsPool[2],
    category: DATABROWSER_AUTOMOTIVE_INDEXES,
    visible: true,
  },
  zmod_rel_iaq: {
    color: colorsPool[3],
    category: DATABROWSER_AUTOMOTIVE_INDEXES,
    visible: true,
  },

  temp_mean: {
    color: colorsPool[0],
    category: DATABROWSER_AUTOMOTIVE_ENVIRONMENT,
    visible: true,
  },
  hum_mean: {
    color: colorsPool[1],
    category: DATABROWSER_AUTOMOTIVE_ENVIRONMENT,
    visible: true,
  },
  pressure: {
    color: colorsPool[2],
    category: DATABROWSER_AUTOMOTIVE_ENVIRONMENT,
    visible: true,
  },
  // altitude: {
  //   color: colorsPool[3],
  //   category: DATABROWSER_AUTOMOTIVE_ENVIRONMENT,
  //   visible: true,
  // },
  rhtr: {
    color: colorsPool[4],
    category: DATABROWSER_AUTOMOTIVE_ENVIRONMENT,
    visible: true,
  },
  log_rcda: {
    color: colorsPool[5],
    category: DATABROWSER_AUTOMOTIVE_ENVIRONMENT,
    visible: true,
  },

  nc_0p5_mean: {
    color: colorsPool[0],
    category: DATABROWSER_AUTOMOTIVE_NC,
    visible: true,
  },
  nc_1p0_mean: {
    color: colorsPool[1],
    category: DATABROWSER_AUTOMOTIVE_NC,
    visible: true,
  },
  nc_2p5_mean: {
    color: colorsPool[2],
    category: DATABROWSER_AUTOMOTIVE_NC,
    visible: true,
  },
  nc_4p0_mean: {
    color: colorsPool[3],
    category: DATABROWSER_AUTOMOTIVE_NC,
    visible: true,
  },
  nc_10p0_mean: {
    color: colorsPool[4],
    category: DATABROWSER_AUTOMOTIVE_NC,
    visible: true,
  },

  sen_voc_raw_mean: {
    color: colorsPool[0],
    category: DATABROWSER_AUTOMOTIVE_RAW,
    visible: true,
  },
  sen_nox_raw_mean: {
    color: colorsPool[1],
    category: DATABROWSER_AUTOMOTIVE_RAW,
    visible: true,
  },
  raw_co2: {
    color: colorsPool[2],
    category: DATABROWSER_AUTOMOTIVE_RAW,
    visible: true,
  },

  ...Array.from(Array(13)).reduce(
    (acc, _, index) => ({
      ...acc,
      [`mox${index}`]: {
        color: colorsPool[index],
        category: DATABROWSER_AUTOMOTIVE_MOX,
        visible: true,
      },
    }),
    {},
  ),
}

const deviceMetaData = {
  tvoc: {
    label: 'Total Volatile Organic Compounds',
    color: '#669CC3',
    category: SMOKE_CATEGORY,
    visible: true,
    hasMinMax: true,
  },
  tpm: {
    label: 'Typical Particle Size',
    color: '#7F66B3',
    category: SMOKE_CATEGORY,
    visible: true,
    hasMinMax: true,
  },
  sen_voc_raw: {
    label: 'Volatile Organic Compound',
    color: '#CF8473',
    category: SMOKE_CATEGORY,
    visible: true,
    hasMinMax: true,
  },
  sen_nox_raw: {
    label: 'Nitrogen Oxide',
    color: '#344B78',
    category: SMOKE_CATEGORY,
    visible: true,
    hasMinMax: true,
  },
  mc_1p0: {
    label: '< 1µm',
    color: '#7F66B3',
    category: SMOKE_MASS_CONCENTRATION_CATEGORY,
    visible: true,
    hasMinMax: true,
  },
  mc_2p5: {
    label: '< 2.5µm',
    color: '#669CC3',
    category: SMOKE_MASS_CONCENTRATION_CATEGORY,
    visible: true,
    hasMinMax: true,
  },
  mc_4p0: {
    label: '< 4µm',
    color: '#CF8473',
    category: SMOKE_MASS_CONCENTRATION_CATEGORY,
    visible: true,
    hasMinMax: true,
  },
  mc_10p0: {
    label: '< 10µm',
    color: '#344B78',
    category: SMOKE_MASS_CONCENTRATION_CATEGORY,
    visible: true,
    hasMinMax: true,
  },
  nc_0p5: {
    label: '< 0.5µm',
    color: '#7F66B3',
    category: SMOKE_NUMBER_COUNT_CATEGORY,
    visible: true,
    hasMinMax: true,
  },
  nc_1p0: {
    label: '< 1µm',
    color: '#669CC3',
    category: SMOKE_NUMBER_COUNT_CATEGORY,
    visible: true,
    hasMinMax: true,
  },
  nc_2p5: {
    label: '< 2.5µm',
    color: '#CF8473',
    category: SMOKE_NUMBER_COUNT_CATEGORY,
    visible: true,
    hasMinMax: true,
  },
  nc_4p0: {
    label: '< 4µm',
    color: '#344B78',
    category: SMOKE_NUMBER_COUNT_CATEGORY,
    visible: true,
    hasMinMax: true,
  },
  nc_10p0: {
    label: '< 10µm',
    color: '#547E7C',
    category: SMOKE_NUMBER_COUNT_CATEGORY,
    visible: true,
    hasMinMax: true,
  },
  nrs: {
    label: 'NRS',
    color: '#7F66B3',
    category: NOISE_CATEGORY,
    visible: true,
    hasMinMax: false,
  },
  temp: {
    label: 'Temperature',
    color: '#7F66B3',
    category: ENVIRONMENT_CATEGORY,
    visible: true,
    hasMinMax: true,
  },
  hum: {
    label: 'Humidity',
    color: '#669CC3',
    category: ENVIRONMENT_CATEGORY,
    visible: true,
    hasMinMax: true,
  },
  eco2: {
    label: 'CO₂',
    color: '#CF8473',
    category: ENVIRONMENT_CATEGORY,
    visible: true,
    hasMinMax: true,
  },
  rssi: {
    label: 'RSSI',
    color: '#7F66B3',
    category: DIAGNOSTIC_CATEGORY,
    visible: false,
    hasMinMax: false,
  },
  battery: {
    label: 'Battery',
    color: '#669CC3',
    category: DIAGNOSTIC_CATEGORY,
    visible: false,
    hasMinMax: false,
  },
  ble_ors: {
    label: 'BLE',
    color: '#7F66B3',
    category: OCCUPANCY_GATEGORY,
    visible: false,
    hasMinMax: false,
  },
  wifi_ors: {
    label: 'WiFi',
    color: '#669CC3',
    category: OCCUPANCY_GATEGORY,
    visible: false,
    hasMinMax: false,
  },
  ens_ors: {
    label: 'ENS',
    color: '#CF8473',
    category: OCCUPANCY_GATEGORY,
    visible: false,
    hasMinMax: false,
  },
}

export function getDeviceMetaData(
  systemDataTypes,
  { layout = DEFAULT_LAYOUT_KEY } = {},
) {
  const getUnit = (systemDataType) => {
    if (systemDataType.key === 'nrs') {
      return 'NRS'
    }
    if (
      systemDataType.key.includes('sen_voc_raw') ||
      systemDataType.key.includes('sen_nox_raw') ||
      systemDataType.key.includes('sen_nox_raw_mean') ||
      systemDataType.key.includes('sen_nox_raw_mean') ||
      systemDataType.key.includes('raw_co2')
    ) {
      return 'raw'
    }
    return systemDataType.template.replace('{{ value }}', '').trim()
  }

  return Object.fromEntries(
    Object.entries(
      layout === DEFAULT_LAYOUT_KEY ? deviceMetaData : automotiveTypesMeta,
    ).map(([k, v]) => {
      const systemDataType = systemDataTypes?.find((type) => type.key.startsWith(k))
      let template = '{{ value }} ?'
      let unit = '?'
      let { label } = v
      if (systemDataType) {
        label = label ?? (systemDataType.shortName || k)
        template = systemDataType.template
        unit = getUnit(systemDataType)

        if (unit.startsWith('/')) {
          unit = `#${unit}`
        }
      }
      return [k, { ...v, unit, template, label }]
    }),
  )
}
