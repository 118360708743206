import { useCallback, useState } from 'react'

import { isEmpty } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { VisibilityOffRounded, VisibilityRounded } from '@mui/icons-material'
import { Box, Divider, Typography } from '@mui/material'

import { titleize, underscore } from 'inflection'

import { useSmallScreen } from '@common/utils'
import organizationUrls from '@portal/pages/Organizations/urls'
import propertyUrls from '@portal/pages/Properties/urls'
import unitUrls from '@portal/pages/Units/urls'
import DetailItem from '@portal/UI/components/DetailItem'
import NestedDetailItem from '@portal/UI/components/NestedDetailItem'
import VerticalTabs from '@portal/UI/components/VerticalTabs'

import DeviceDataBrowser from '../../DeviceDataBrowser'
import DevicePhotos from './DevicePhotos'

export default function DeviceTab() {
  const { deviceFetch: device } = useConnect('selectDeviceFetch')
  const isSmallScreen = useSmallScreen()

  const [passwordVisibilityState, setPasswordVisibilityState] = useState({
    current: false,
    desired: false,
  })

  const deviceAttrs = device.deviceAttributes
    ? Object.entries(device?.deviceAttributes)
        .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
    : {}

  const manufacturerInfo = device.manufacturerInfo
    ? Object.entries(device.manufacturerInfo)
        .filter((entries) => ['string', 'number'].includes(typeof entries[1]))
        .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
    : {}

  const nestedManufacturerInfo = device.manufacturerInfo
    ? Object.entries(device.manufacturerInfo)
        .filter((entries) => !(entries[0] in manufacturerInfo))
        .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
    : {}

  /**
   * @component
   * @param {Object} props - The props for the component.
   * @param {'current'|'desired'} props.type
   */
  const PasswordStateIcon = useCallback(
    ({ type }) => {
      const Icon = passwordVisibilityState[type]
        ? VisibilityOffRounded
        : VisibilityRounded
      return (
        <Icon
          sx={{ fontSize: 18, cursor: 'pointer', ml: 0.5, color: 'primary.main' }}
          onClick={() => {
            setPasswordVisibilityState((prev) => ({ ...prev, [type]: !prev[type] }))
          }}
        />
      )
    },
    [passwordVisibilityState],
  )

  const getPasswordText = useCallback(
    ({ type, password }) => {
      if (!password) return null
      return passwordVisibilityState[type] ? password : '******'
    },
    [passwordVisibilityState],
  )

  const verticalTabs = [
    {
      label: 'Device Details',
      component: (
        <Box
          display="flex"
          flexDirection="column"
          flexWrap={isSmallScreen ? 'nowrap' : 'wrap'}
          maxHeight={isSmallScreen ? 'unset' : 300}
          pt={isSmallScreen ? 1 : 2}
          gap={isSmallScreen ? 1 : 2}
        >
          <DetailItem label="Main Mac" value={device.mainMac} />
          <DetailItem label="Model" value={device.modelName} />
          <DetailItem
            label="Organization"
            value={device.organizationName}
            linkTo={
              device.organization
                ? organizationUrls.entity.replace(':id', device.organization)
                : null
            }
          />
          <DetailItem
            label="Property"
            value={device.propertyName}
            linkTo={
              device.propertyId
                ? propertyUrls.entity.replace(':id', device.propertyId)
                : null
            }
          />
          <DetailItem
            label="Unit"
            value={device.unitName}
            linkTo={
              device.unitId ? unitUrls.entity.replace(':id', device.unitId) : null
            }
          />
          <DetailItem label="Zone" value={device.zoneName} />
        </Box>
      ),
    },
    {
      label: 'Device Attributes',
      disabled: isEmpty(deviceAttrs),
      component: (
        <Box
          display="flex"
          flexDirection="column"
          pt={isSmallScreen ? 1 : 2}
          gap={isSmallScreen ? 1 : 2}
          flexWrap={isSmallScreen ? 'nowrap' : 'wrap'}
          maxHeight={isSmallScreen ? 'unset' : 450}
        >
          {Object.entries(deviceAttrs).map(([field, value]) => (
            <DetailItem key={field} label={titleize(underscore(field))} value={value} />
          ))}
        </Box>
      ),
    },
    {
      label: 'Device SSID Details',
      component: (
        <Box
          display="flex"
          flexDirection="column"
          flexWrap={isSmallScreen ? 'nowrap' : 'wrap'}
          maxHeight={isSmallScreen ? 'unset' : 300}
          pt={isSmallScreen ? 1 : 2}
          gap={isSmallScreen ? 1 : 2}
        >
          <DetailItem label="Current SSID" value={device.currentWifiSsid} />
          <DetailItem
            label="Current Password"
            valueIcon={
              device.currentWifiPw ? (
                <Box sx={{ px: 2 }}>
                  <PasswordStateIcon type="current" />
                </Box>
              ) : null
            }
            value={getPasswordText({
              type: 'current',
              password: device.currentWifiPw,
            })}
          />
          <DetailItem label="Desired SSID" value={device.desiredWifiSsid} />
          <DetailItem
            label="Desired Password"
            labelIcon={
              device.desiredWifiPw ? <PasswordStateIcon type="desired" /> : null
            }
            value={getPasswordText({ type: 'desired', password: device.desiredWifiPw })}
          />
        </Box>
      ),
    },
    {
      label: 'Installation',
      disabled: false,
      component: <DevicePhotos />,
    },
    {
      label: 'Manufacturer Info',
      disabled: isEmpty(manufacturerInfo),
      component: (
        <Box display="flex" justifyContent="space-between">
          <Box
            display="flex"
            flexDirection="column"
            pt={isSmallScreen ? 1 : 2}
            gap={isSmallScreen ? 1 : 2}
            flexWrap={isSmallScreen ? 'nowrap' : 'wrap'}
            maxHeight={isSmallScreen ? 'unset' : 550}
          >
            {Object.entries(manufacturerInfo).map(([field, value]) => (
              <DetailItem
                key={field}
                label={titleize(underscore(field))}
                value={value}
              />
            ))}
          </Box>
          {Object.entries(nestedManufacturerInfo).map(
            ([label, data]) =>
              !isEmpty(data) && (
                <Box
                  key={label}
                  pt={isSmallScreen ? 1 : 2}
                  gap={isSmallScreen ? 1 : 2}
                  maxHeight={isSmallScreen ? 'unset' : 600}
                  flexWrap={isSmallScreen ? 'nowrap' : 'wrap'}
                >
                  <NestedDetailItem label={titleize(underscore(label))} data={data} />
                </Box>
              ),
          )}
        </Box>
      ),
    },
  ]

  return (
    <>
      <Divider />
      <div>
        {device ? (
          <Box>
            <VerticalTabs tabs={verticalTabs} tabsMinWidth={218} sx={{ pb: 2 }} />
            <Divider sx={{ mb: 3 }} />
            <DeviceDataBrowser device={device} />
          </Box>
        ) : (
          <Typography>Device not found.</Typography>
        )}
      </div>
    </>
  )
}
