import { useCallback } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { RefreshRounded } from '@mui/icons-material'
import { Button, CircularProgress, IconButton, Stack, Tooltip } from '@mui/material'

import { humanize } from 'inflection'
import { DateTime } from 'luxon'

import { parseApiErrors, useSmallScreen } from '@common/utils'
import DetailItem from '@portal/UI/components/DetailItem'

import DetailContainer from './DetailContainer'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} [props.property]
 * @param {string} [props.property.salesforceAccountId]
 * @param {string} [props.property.stripeCustomerId]
 * @param {string} [props.property.billingAccountType]
 * @param {string} [props.property.lastBilledOn]
 * @param {string} [props.property.prevBillingPeriodStart]
 * @param {string} [props.property.prevBillingPeriodEnd]
 * @param {Function} [props.onEdit]
 * @param {number} [props.margin]
 * @param {number} [props.gap]
 */
export default function BillingComponent({
  property = undefined,
  onEdit = undefined,
  margin = 0,
  gap = 0,
}) {
  const isSmallScreen = useSmallScreen()

  const {
    propertySalesforceSyncIsLoading,
    doSyncPropertySalesforce,
    doFetchPropertyActiveContracts,
    doMarkPropertyContractListAsOutdated,
    doFetchPropertyDetails,
    doShowSnackbar,
  } = useConnect(
    'selectPropertySalesforceSyncIsLoading',
    'doSyncPropertySalesforce',
    'doFetchPropertyActiveContracts',
    'doMarkPropertyContractListAsOutdated',
    'doFetchPropertyDetails',
    'doShowSnackbar',
  )

  const formatDateTime = useCallback(
    (dt) => (dt ? DateTime.fromISO(dt).toLocaleString(DateTime.DATETIME_MED) : 'N/A'),
    [],
  )

  const handleSalesforceSync = useCallback(async () => {
    try {
      await doSyncPropertySalesforce(property?.id)
      doShowSnackbar('Salesforce sync success!')

      await doFetchPropertyDetails(property?.id)
      await doFetchPropertyActiveContracts()
      doMarkPropertyContractListAsOutdated()
    } catch (err) {
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [property])

  const SalesforceSyncButton = useCallback(() => {
    if (property?.salesforceAccountId) {
      return propertySalesforceSyncIsLoading ? (
        <CircularProgress size={14} thickness={5} sx={{ m: 1 }} />
      ) : (
        <Tooltip title="Sync Salesforce">
          <IconButton size="small" color="primary" onClick={handleSalesforceSync}>
            <RefreshRounded sx={{ fontSize: 20 }} />
          </IconButton>
        </Tooltip>
      )
    }
    return null
  }, [property, propertySalesforceSyncIsLoading])

  return (
    <Stack direction="row" alignItems="start">
      <DetailContainer margin={margin} gap={gap}>
        <DetailItem
          label="SalesForce Account ID"
          value={property?.salesforceAccountId}
          labelIcon={<SalesforceSyncButton />}
        />
        <DetailItem label="Stripe Customer ID" value={property?.stripeCustomerId} />
        <DetailItem
          label="Billing Type"
          value={
            property?.billingAccountType ? humanize(property.billingAccountType) : null
          }
        />
        <DetailItem
          label="last billed on"
          value={formatDateTime(property?.lastBilledOn)}
        />
        <DetailItem
          label="Prev billing period end"
          value={formatDateTime(property?.prevBillingPeriodEnd)}
        />
        <DetailItem
          label="Prev billing period start"
          value={formatDateTime(property?.prevBillingPeriodStart)}
        />
      </DetailContainer>
      {onEdit && (
        <Button onClick={onEdit} sx={{ mt: 1, fontSize: isSmallScreen ? 12 : 14 }}>
          Edit
        </Button>
      )}
    </Stack>
  )
}
