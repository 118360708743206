/* eslint-disable
  jsx-a11y/click-events-have-key-events,
  jsx-a11y/no-static-element-interactions */

import { useState } from 'react'

import { pathOr } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import Container from '@mui/material/Container'

import { getNavHelper } from 'internal-nav-helper'

import { NewVersionBanner } from '@common/components'
import Snackbar from '@common/components/Snackbar'
import { useSmallScreen } from '@common/utils'
import Sidebar from '@portal/pages/Sidebar'
import { DEFAULT_MARGIN, SIDEBAR_WIDTH } from '@portal/pages/Sidebar/Sidebar.styles'

import ProtectedRoute from './ProtectedRoute'

function PortalApp() {
  const { route, drawerOpen, doUpdateUrl } = useConnect(
    'selectRoute',
    'selectDrawerOpen',
    'doUpdateUrl',
  )

  const isSmallScreen = useSmallScreen()

  const [leftOpen, setLeftDrawerOpen] = useState(!isSmallScreen)

  const handleLeftDrawer = () => setLeftDrawerOpen(!leftOpen)

  const RoutedComponent = route.component || route
  const showSidebar = pathOr(true, ['showSidebar'], route)

  let leftMargin = 0

  if (showSidebar && !isSmallScreen) {
    if (leftOpen) {
      leftMargin = SIDEBAR_WIDTH
    } else {
      leftMargin = DEFAULT_MARGIN
    }
  }

  return (
    <div id="wrapper" onClick={getNavHelper(doUpdateUrl)}>
      <NewVersionBanner marginLeft={leftMargin} />
      {showSidebar && (
        <Sidebar
          handleDrawer={handleLeftDrawer}
          open={isSmallScreen ? drawerOpen : leftOpen}
        />
      )}
      <main style={{ marginLeft: leftMargin }}>
        <Container maxWidth="false" className="mainContainer" disableGutters>
          <ProtectedRoute roles={route.allowedRoles}>
            <RoutedComponent />
          </ProtectedRoute>
          <Snackbar />
        </Container>
      </main>
    </div>
  )
}

export default PortalApp
