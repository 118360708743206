import { mapObjIndexed } from 'ramda'

import { DateTime } from 'luxon'

export const CATEGORY_PM = 'pm_subtracted'
export const CATEGORY_SOUND_LEVELS = 'sound_levels'
export const CATEGORY_MODEL_SCORES = 'model_scores'
export const CATEGORY_PM_RATIOS = 'pm_ratios'
export const CATEGORY_ENVIRONMENT = 'env'
export const CATEGORY_CO2_TVOC = 'co2_tvoc'
export const CATEGORY_VOC_NOX = 'voc_nox'

export const CATEGORY_AUTOMOTIVE_PM = 'pm'
export const CATEGORY_AUTOMOTIVE_GASES = 'gases'
export const CATEGORY_AUTOMOTIVE_INDEXES = 'indexes'
export const CATEGORY_AUTOMOTIVE_ENVIRONMENT = 'environment'
export const CATEGORY_AUTOMOTIVE_NC = 'nc'
export const CATEGORY_AUTOMOTIVE_RAW = 'raw'
export const CATEGORY_AUTOMOTIVE_MOX = 'mox'

export const automotiveCategoryToTypesMap = {
  [CATEGORY_AUTOMOTIVE_PM]: [
    'mc_1p0_mean',
    'mc_2p5_mean',
    'mc_4p0_mean',
    'mc_10p0_mean',
  ],
  [CATEGORY_AUTOMOTIVE_GASES]: ['etoh', 'eco2_mean', 'co2', 'tvoc_mean'],
  [CATEGORY_AUTOMOTIVE_INDEXES]: ['voc_mean', 'nox_mean', 'zmod_iaq', 'zmod_rel_iaq'],
  [CATEGORY_AUTOMOTIVE_ENVIRONMENT]: [
    'temp_mean',
    'hum_mean',
    'pressure',
    // 'altitude',
    'rhtr',
    'log_rcda',
  ],
  [CATEGORY_AUTOMOTIVE_NC]: [
    'nc_0p5_mean',
    'nc_1p0_mean',
    'nc_2p5_mean',
    'nc_4p0_mean',
    'nc_10p0_mean',
  ],
  [CATEGORY_AUTOMOTIVE_RAW]: ['sen_voc_raw_mean', 'sen_nox_raw_mean', 'raw_co2'],
  [CATEGORY_AUTOMOTIVE_MOX]: Array.from(Array(13)).map((_, index) => `mox${index}`),
}

function getRawMc10p0(mc1p0, mc2p5, mc4p0, mc10p0) {
  return mc1p0 !== undefined &&
    mc2p5 !== undefined &&
    mc4p0 !== undefined &&
    mc10p0 !== undefined
    ? mc10p0 + (mc4p0 + (mc2p5 + mc1p0))
    : undefined
}

function processTypes(types, mappedData, length, tz) {
  const graphData = Object.values(mappedData).filter((datum) =>
    types.some((key) => datum.dataType === key),
  )
  return Array.from({ length }).map((_, i) =>
    graphData.reduce((acc, datum) => {
      const { time, value } = datum.data[i]
      return {
        ...acc,
        time: DateTime.fromISO(time, { zone: tz }),
        [datum.dataType]: value,
      }
    }, {}),
  )
}

function processPmSubtracted(mappedData, length, tz) {
  const graphData = Object.values(mappedData).filter((datum) =>
    ['mc_1p0_mean', 'mc_2p5_mean', 'mc_4p0_mean', 'mc_10p0_mean', 'tpm_mean'].some(
      (key) => datum.dataType?.includes(key),
    ),
  )
  return Array.from({ length }).map((_, i) =>
    graphData.reduce((acc, datum) => {
      const { time, value } = datum.data[i]
      return {
        ...acc,
        time: DateTime.fromISO(time, { zone: tz }),
        [datum.dataType]: value,
      }
    }, {}),
  )
}

function processSoundLevels(mappedData, length, tz) {
  const graphData = Object.values(mappedData).filter((datum) =>
    ['dba_mean', 'nrs'].some((key) => datum.dataType?.includes(key)),
  )
  return Array.from({ length }).map((_, i) =>
    graphData.reduce((acc, datum) => {
      const data = datum.data[i]
      if (!data) return null

      const { time, value, highThreshold } = data
      return {
        ...acc,
        time: DateTime.fromISO(time, { zone: tz }),
        [datum.dataType]: value,
        ...(datum.dataType === 'nrs'
          ? { [`${datum.dataType}_threshold`]: highThreshold }
          : {}),
      }
    }, {}),
  )
}

function processPmRatios(mappedData, length, tz) {
  return Array.from({ length }).map((_, i) => {
    const newTypes = ['mc_1p0_ratio', 'mc_2p5_ratio', 'mc_4p0_ratio', 'mc_10p0_ratio']

    const data1p0 = mappedData.mc_1p0_mean?.data.at(i)
    const data2p5 = mappedData.mc_2p5_mean?.data.at(i)
    const data4p0 = mappedData.mc_4p0_mean?.data.at(i)
    const data10p0 = mappedData.mc_10p0_mean?.data.at(i)
    const rawMc10p0 = getRawMc10p0(
      data10p0?.value,
      data2p5?.value,
      data4p0?.value,
      data1p0?.value,
    )

    const dataArr = [data1p0, data2p5, data4p0, data10p0]
    const time = dataArr.find((dt) => dt?.time)?.time

    let result = time ? { time: DateTime.fromISO(time, { zone: tz }) } : {}

    newTypes.forEach((type) => {
      if (type === 'mc_1p0_ratio') {
        result = {
          ...result,
          [type]:
            data1p0 !== undefined && rawMc10p0 > 0 ? data1p0.value / rawMc10p0 : null,
        }
      }
      if (type === 'mc_2p5_ratio') {
        result = {
          ...result,
          [type]:
            data2p5 !== undefined && rawMc10p0 > 0 ? data2p5.value / rawMc10p0 : null,
        }
      }
      if (type === 'mc_4p0_ratio') {
        result = {
          ...result,
          [type]:
            data4p0 !== undefined && rawMc10p0 > 0 ? data4p0.value / rawMc10p0 : null,
        }
      }
      if (type === 'mc_10p0_ratio') {
        result = {
          ...result,
          [type]:
            data10p0 !== undefined && rawMc10p0 > 0 ? data10p0.value / rawMc10p0 : null,
        }
      }
    })
    return mapObjIndexed((v, k) => (k !== 'time' && v !== null ? v * 100 : v), result)
  })
}

function processEnvironment(mappedData, length, tz) {
  return Array.from({ length }).map((_, i) => {
    const newTypes = ['temp', 'rel_hum', 'abs_hum']

    const dataRelHum = mappedData.hum_mean?.data.at(i)
    const dataTemp = mappedData.temp_mean?.data.at(i)

    const dataArr = [dataRelHum, dataTemp]
    const time = dataArr.find((dt) => dt?.time)?.time

    let result = time ? { time: DateTime.fromISO(time, { zone: tz }) } : {}

    newTypes.forEach((type) => {
      if (type === 'temp') {
        result = { ...result, [type]: dataTemp?.value ?? null }
      }
      if (type === 'rel_hum') {
        result = { ...result, [type]: dataRelHum?.value ?? null }
      }
      if (type === 'abs_hum') {
        result = {
          ...result,
          [type]:
            dataRelHum?.value !== null &&
            dataRelHum?.value !== null &&
            dataTemp?.value !== null &&
            dataTemp?.value !== undefined
              ? 216.7 *
                (((dataRelHum.value / 100) *
                  6.112 *
                  Math.exp((17.62 * dataTemp.value) / (243.12 + dataTemp.value))) /
                  (273.15 + dataTemp.value))
              : null,
        }
      }
    })
    return result
  })
}

function processCo2Tvoc(mappedData, length, tz) {
  const graphData = Object.values(mappedData).filter((datum) =>
    ['eco2_mean', 'tvoc_mean'].some((key) => datum.dataType?.includes(key)),
  )
  return Array.from({ length }).map((_, i) =>
    graphData.reduce((acc, datum) => {
      const { time, value } = datum.data[i]
      return {
        ...acc,
        time: DateTime.fromISO(time, { zone: tz }),
        [datum.dataType]: value,
      }
    }, {}),
  )
}

function processVocNox(mappedData, length, tz) {
  const graphData = Object.values(mappedData).filter((datum) =>
    ['voc_mean', 'nox_mean'].some((key) => datum.dataType === key),
  )
  return Array.from({ length }).map((_, i) =>
    graphData.reduce((acc, datum) => {
      const { time, value } = datum.data[i]
      return {
        ...acc,
        time: DateTime.fromISO(time, { zone: tz }),
        [datum.dataType]: value,
      }
    }, {}),
  )
}

function processModelScores(mappedData, length, tz) {
  const graphData = Object.values(mappedData).filter((datum) => datum.tag)
  return Array.from({ length }).map((_, i) =>
    graphData.reduce((acc, datum) => {
      const { time, value } = datum.data[i]
      return {
        ...acc,
        time: DateTime.fromISO(time, { zone: tz }),
        [datum.tag]: typeof value === 'number' ? value * 100 : null,
      }
    }, {}),
  )
}

function processDefault(category, mappedData, length, tz) {
  const graphData = Object.values(mappedData).filter((datum) =>
    datum.key.includes(category),
  )
  return Array.from({ length }).map((_, i) =>
    graphData.reduce((acc, datum) => {
      const { time, value } = datum.data[i] ?? {}
      return {
        ...acc,
        time: time ? DateTime.fromISO(time, { zone: tz }) : null,
        [datum.key]: value,
      }
    }, {}),
  )
}

export default function processEventData(data, category, tz) {
  if (!data) {
    return []
  }
  const mappedData = data.reduce(
    (acc, item) => ({ ...acc, [item.dataType ?? item.tag ?? item.key]: item }),
    {},
  )
  const numPoints = data?.[0]?.data.length

  switch (category) {
    case CATEGORY_PM:
      return processPmSubtracted(mappedData, numPoints, tz)
    case CATEGORY_SOUND_LEVELS:
      return processSoundLevels(mappedData, numPoints, tz)
    case CATEGORY_PM_RATIOS:
      return processPmRatios(mappedData, numPoints, tz)
    case CATEGORY_ENVIRONMENT:
      return processEnvironment(mappedData, numPoints, tz)
    case CATEGORY_CO2_TVOC:
      return processCo2Tvoc(mappedData, numPoints, tz)
    case CATEGORY_MODEL_SCORES:
      return processModelScores(mappedData, numPoints, tz)
    case CATEGORY_VOC_NOX:
      return processVocNox(mappedData, numPoints, tz)
    case CATEGORY_AUTOMOTIVE_PM:
    case CATEGORY_AUTOMOTIVE_GASES:
    case CATEGORY_AUTOMOTIVE_INDEXES:
    case CATEGORY_AUTOMOTIVE_ENVIRONMENT:
    case CATEGORY_AUTOMOTIVE_NC:
    case CATEGORY_AUTOMOTIVE_RAW:
    case CATEGORY_AUTOMOTIVE_MOX:
      return processTypes(
        automotiveCategoryToTypesMap[category],
        mappedData,
        numPoints,
        tz,
      )
    default:
      return processDefault(category, mappedData, numPoints, tz)
  }
}
