/* eslint-disable no-console */
import { isEmpty } from 'ramda'

import { DateTime } from 'luxon'

import { MIGRATIONS } from './migrations'

const LAST_MIGRATION_KEY = 'last_migration_id'

// eslint-disable-next-line import/prefer-default-export
export async function runLocalStorageMigrations() {
  if (isEmpty(MIGRATIONS)) return

  const lastMigrationData = JSON.parse(localStorage.getItem(LAST_MIGRATION_KEY))
  if (
    !lastMigrationData ||
    lastMigrationData.id < MIGRATIONS[MIGRATIONS.length - 1].id
  ) {
    const migrations = MIGRATIONS.filter((obj) =>
      lastMigrationData ? obj.id > lastMigrationData.id : true,
    ).sort((a, b) => a.id - b.id)

    // eslint-disable-next-line no-restricted-syntax
    for await (const migration of migrations) {
      await migration.operation(migration.id)
      localStorage.setItem(
        LAST_MIGRATION_KEY,
        JSON.stringify({ id: migration.id, ts: DateTime.now().toISO() }),
      )
      console.debug(`Local storage migration [id_${migration.id}] applied`)
    }
  } else {
    console.debug(`All local storage migrations applied`)
  }
}
