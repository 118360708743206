import { useCallback, useEffect, useMemo, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { RefreshRounded } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import { DateRangePicker } from '@mui/x-date-pickers-pro'

import { titleize } from 'inflection'
import { DateTime } from 'luxon'

import { ErrorComponent, MobileList, MobileListDefaultCard } from '@common/components'
import { isAbortError, parseApiErrors, useSmallScreen } from '@common/utils'
import List from '@portal/UI/components/List'

import CollapsableData from './CollapsableData'
import TypeFilter from './TypeFilter'

export default function CommunicationsTab() {
  const defaultEnd = DateTime.now()
  const defaultStart = DateTime.now().minus({ years: 1 })

  const formatType = (type) => {
    switch (type) {
      case 'firmware_ota':
        return 'Firmware OTA'
      default:
        return type ? titleize(type) : '--'
    }
  }

  const typeOptions = useMemo(
    () =>
      ['command_bit', 'device_event', 'firmware_ota'].map((key) => ({
        id: key,
        label: formatType(key),
      })),
    [],
  )

  const [typeFilter, setTypeFilter] = useState(typeOptions)
  const [dateRange, setDateRange] = useState([defaultStart, defaultEnd])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)

  const isSmallScreen = useSmallScreen()

  const {
    routeParams: { id: deviceId },
    deviceCommunications,
    deviceCommunicationsIsLoading,
    doDeviceFetchCommunications,
    doShowSnackbar,
  } = useConnect(
    'selectRouteParams',
    'selectDeviceCommunications',
    'selectDeviceCommunicationsIsLoading',
    'doDeviceFetchCommunications',
    'doShowSnackbar',
  )

  const fetchCommunications = useCallback(
    async ({ types } = {}) => {
      try {
        if (dateRange.every((date) => date)) {
          await doDeviceFetchCommunications({
            id: deviceId,
            dateRange,
            types: types ?? typeFilter,
          })
        }
      } catch (err) {
        if (!isAbortError(err)) {
          const parsedError = parseApiErrors(err?.response)
          doShowSnackbar(parsedError, 'error')
        }
      }
    },
    [deviceId, dateRange, typeFilter],
  )

  useEffect(() => {
    fetchCommunications()
  }, [dateRange])

  const data = useMemo(
    () => deviceCommunications?.map((item) => ({ id: Math.random(), ...item })),
    [deviceCommunications],
  )

  const visibleRows = useMemo(
    () => data?.slice((page - 1) * pageSize, page * pageSize),
    [page, data],
  )

  const handlePageChange = async (newPage) => setPage(newPage)

  const handlePageSizeChange = (size) => {
    setPageSize((prevPageSize) => (prevPageSize === size ? pageSize : size))
  }

  const columns = [
    {
      field: 'type',
      headerName: 'Type',
      flex: 1.5,
      valueGetter: (_, row) => formatType(row.type),
      sortable: false,
    },
    {
      field: 'ts',
      headerName: 'Timestamp',
      flex: 1.5,
      valueGetter: (_, row) =>
        row.ts ? DateTime.fromISO(row.ts).toLocaleString(DateTime.DATETIME_MED) : '',
      sortable: false,
    },
    {
      field: 'payload',
      headerName: 'Data',
      renderCell: ({ row }) => <CollapsableData data={row.payload} />,
      flex: 1,
      sortable: false,
    },
  ]

  const RangePicker = useCallback(
    () => (
      <DateRangePicker
        disableMaskedInput
        value={dateRange}
        maxDate={defaultEnd}
        onChange={setDateRange}
        disabled={deviceCommunicationsIsLoading}
        slotProps={{
          fieldSeparator: { children: '-' },
          textField: {
            size: 'small',
            style: { width: !isSmallScreen ? 150 : null },
            required: true,
          },
        }}
      />
    ),
    [dateRange, setDateRange, defaultEnd, isSmallScreen, deviceCommunicationsIsLoading],
  )

  const MobileListItem = useCallback(
    (row) => MobileListDefaultCard({ row, columns }),
    [columns],
  )

  if (!visibleRows && !deviceCommunicationsIsLoading)
    return (
      <ErrorComponent title="Device Communications" callback={fetchCommunications} />
    )

  return isSmallScreen ? (
    <Box display="flex" flexDirection="column" width="100%" gap={2} mt={1}>
      <TypeFilter
        typeFilter={typeFilter}
        setTypeFilter={setTypeFilter}
        options={typeOptions}
        isLoading={deviceCommunicationsIsLoading}
        onSave={(types) => fetchCommunications({ types })}
      />
      <Box display="flex" gap={1}>
        <RangePicker />
        <IconButton
          disabled={deviceCommunicationsIsLoading}
          onClick={fetchCommunications}
        >
          <RefreshRounded />
        </IconButton>
      </Box>

      <MobileList
        dynamicRowHeight
        title="Communications"
        loading={deviceCommunicationsIsLoading}
        showActions={false}
        showAddButton={false}
        itemBuilder={MobileListItem}
        page={page}
        pageChange={handlePageChange}
        pageSize={pageSize}
        pageSizeChange={handlePageSizeChange}
        rows={visibleRows ?? []}
        rowCount={data?.length || 0}
      />
    </Box>
  ) : (
    <Box>
      <Box display="flex" justifyContent="end" mt={1} gap={2}>
        <TypeFilter
          typeFilter={typeFilter}
          setTypeFilter={setTypeFilter}
          options={typeOptions}
          isLoading={deviceCommunicationsIsLoading}
          onSave={(types) => fetchCommunications({ types })}
        />
        <RangePicker />
        <IconButton
          disabled={deviceCommunicationsIsLoading}
          onClick={fetchCommunications}
        >
          <RefreshRounded />
        </IconButton>
      </Box>
      <List
        columnsAutosize
        dynamicRowHeight
        title="Communications"
        loading={deviceCommunicationsIsLoading}
        showActions={false}
        showAddButton={false}
        columns={columns}
        page={page}
        pageChange={handlePageChange}
        pageSize={pageSize}
        pageSizeChange={handlePageSizeChange}
        rows={visibleRows ?? []}
        rowCount={data?.length || 0}
      />
    </Box>
  )
}
