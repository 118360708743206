import { useCallback, useEffect, useState } from 'react'

import { pick } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { Box, Chip, Typography } from '@mui/material'

import { humanize } from 'inflection'
import { DateTime } from 'luxon'

import {
  Breadcrumbs,
  ClickableCell,
  ErrorComponent,
  MobileList,
  MobileListDefaultCard,
  Picker,
} from '@common/components'
import { DynamicSelect, StaticSelect } from '@common/components/Selects'
import { parseApiErrors, useSmallScreen } from '@common/utils'
import { homeUrls } from '@portal/pages/Home'
import propertyUrls from '@portal/pages/Properties/urls'
import { BooleanCell, GridCellExpand } from '@portal/UI/components/cells'
import ConfirmationDialog from '@portal/UI/components/ConfirmationDialog'
import Filter from '@portal/UI/components/Filter'
import List from '@portal/UI/components/List'
import ListPageTitle from '@portal/UI/components/ListPageTitle'
import boolOptions from '@portal/Utils/constants'

import IssueActions from './IssueActions'
import IssueForm from './IssueForm'
import IssuesAuditLog from './IssuesAuditLog'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {boolean} [props.renderAsTab]
 * @param {Object} [props.property]
 */
export default function Issues({ renderAsTab = false }) {
  const isSmallScreen = useSmallScreen()

  const [filterModalOpen, setFilterModalOpen] = useState(false)
  const [issueToUpdate, setIssueToUpdate] = useState(null)
  const [issueToClose, setIssueToClose] = useState(null)

  const {
    doIssueListClearParams,
    doIssueListSetFilter,
    doIssueListSetPage,
    doIssueListSetPageSize,
    doIssueListSetOrdering,
    doIssueListSetSearch,
    doMarkIssueListAsOutdated,
    doIssueClose,
    issueList,
    issueListRaw: { ordering = [] },
    issueListIsLoading,
    issueListApiParams,
    me: { id: currentUserId, isCsmOwner: isCurrentUserCsmOwner },
    systemIssueCategories,
    queryObject,
    doUpdateQuery,
    doShowSnackbar,
  } = useConnect(
    'doIssueListClearParams',
    'doIssueListSetFilter',
    'doIssueListSetPage',
    'doIssueListSetPageSize',
    'doIssueListSetOrdering',
    'doIssueListSetSearch',
    'doMarkIssueListAsOutdated',
    'doIssueClose',
    'selectIssueList',
    'selectIssueListRaw',
    'selectIssueListIsLoading',
    'selectIssueListApiParams',
    'selectMe',
    'selectSystemIssueCategories',
    'selectQueryObject',
    'doUpdateQuery',
    'doShowSnackbar',
  )

  const defaultFilter = {
    isUnresolved: renderAsTab ? undefined : true,
    isSnoozed: false,
    ...(!renderAsTab && isCurrentUserCsmOwner ? { csmOwner: currentUserId } : {}),
  }

  useEffect(() => {
    doIssueListSetFilter(defaultFilter)
  }, [])

  const handleClear = () => {
    doIssueListClearParams()
  }

  const handleCloseIssue = useCallback(async () => {
    if (issueToClose) {
      try {
        await doIssueClose(issueToClose.id)
        setIssueToClose(null)
        doMarkIssueListAsOutdated()
        doShowSnackbar('Issue closed successfully', 'success')
      } catch (err) {
        const parsedError = parseApiErrors(err?.response)
        doShowSnackbar(parsedError, 'error')
      }
    }
  }, [issueToClose])

  const columns = [
    ...(!renderAsTab
      ? [
          {
            field: 'property',
            headerName: 'Property',
            flex: 1,
            renderCell: ({ row }) => {
              if (row.property) {
                return (
                  <ClickableCell
                    label={row.propertyName}
                    url={propertyUrls?.entity.replace(':id', row.property)}
                  />
                )
              }
              return row.propertyName
            },
          },
        ]
      : []),
    {
      field: 'resolved',
      headerName: 'Resolved',
      type: 'boolean',
      flex: 0.5,
      sortable: false,
      renderCell: BooleanCell,
      renderMobile: ({ row }) => (row.resolved ? 'Yes' : 'No'),
    },
    {
      field: 'category',
      headerName: 'Category',
      flex: 1,
      valueFormatter: (value) => humanize(value),
    },
    {
      field: 'start',
      headerName: 'Start Time',
      flex: 0.8,
      valueFormatter: (value) =>
        DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED),
    },
    {
      field: 'end',
      headerName: 'End Time',
      flex: 0.8,
      valueFormatter: (value) =>
        value ? DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED) : 'N/A',
    },
    {
      field: 'snoozedUntil',
      headerName: 'Snoozed Until',
      flex: 1,
      sortable: false,
      valueFormatter: (value) =>
        value ? DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED) : 'N/A',
    },
    {
      field: 'comments',
      headerName: 'Comments',
      flex: 1,
      sortable: false,
      renderCell: GridCellExpand,
      renderMobile: ({ row }) => row.comments,
      maxWidth: 250,
    },
    {
      field: 'extraActions',
      align: 'center',
      headerAlign: 'center',
      headerName: 'Actions',
      flex: 1.6,
      sortable: false,
      minWidth: 200,
      renderCell: ({ row }) => (
        <IssueActions
          row={row}
          onEditPressed={setIssueToUpdate}
          onClosePressed={setIssueToClose}
          onAuditLogPressed={(issue) =>
            doUpdateQuery({ auditLog: issue.id }, { maintainScrollPosition: true })
          }
        />
      ),
    },
  ]

  const MobileItemFooter = useCallback(
    ({ row }) => (
      <IssueActions
        row={row}
        onEditPressed={setIssueToUpdate}
        onClosePressed={setIssueToClose}
        onAuditLogPressed={(issue) =>
          doUpdateQuery({ auditLog: issue.id }, { maintainScrollPosition: true })
        }
      />
    ),
    [],
  )

  const MobileListItem = useCallback(
    (row) =>
      MobileListDefaultCard({
        row,
        columns,
        ignoredFields: ['extraActions'],
        footer: <MobileItemFooter row={row} />,
      }),
    [columns],
  )

  if (!issueList.results) {
    return <ErrorComponent title="Issues" callback={handleClear} />
  }

  const rows = issueList?.results?.map((issue) => ({
    id: issue.id,
    property: issue.property,
    propertyName: issue.propertyName,
    category: issue.category,
    start: issue.start,
    end: issue.end,
    comments: issue.comments,
    snoozedUntil: issue.snoozedUntil,
    resolved: !!issue.end,
  }))

  const getFilterChipsData = () => {
    const now = DateTime.now()

    const isCurrentlyOpenSelected = () => {
      if ('isUnresolved' in issueListApiParams && 'isSnoozed' in issueListApiParams) {
        const unexpectedFilters = Object.keys(issueListApiParams).some((key) =>
          [
            'startAfter',
            'startBefore',
            'endAfter',
            'endBefore',
            'snoozedUntilAfter',
            'snoozedUntilBefore',
          ].includes(key),
        )
        return (
          issueListApiParams.isUnresolved &&
          !issueListApiParams.isSnoozed &&
          !unexpectedFilters
        )
      }
      return false
    }

    const isRecentlyResolvedSelected = () => {
      if (
        'isUnresolved' in issueListApiParams &&
        issueListApiParams?.endAfter &&
        issueListApiParams?.endBefore
      ) {
        const unexpectedFilters = Object.keys(issueListApiParams).some((key) =>
          [
            'startAfter',
            'startBefore',
            'snoozedUntilAfter',
            'snoozedUntilBefore',
          ].includes(key),
        )
        const end =
          typeof issueListApiParams.endBefore === 'string'
            ? DateTime.fromISO(issueListApiParams.endBefore)
            : issueListApiParams.endBefore
        const start =
          typeof issueListApiParams.endAfter === 'string'
            ? DateTime.fromISO(issueListApiParams.endAfter)
            : issueListApiParams.endAfter
        const diff = end.diff(start, 'days')
        const isDesiredDiff = diff.days === 7
        return !issueListApiParams.isUnresolved && isDesiredDiff && !unexpectedFilters
      }
      return false
    }

    const isSnoozedSelected = () => {
      if ('isUnresolved' in issueListApiParams && 'isSnoozed' in issueListApiParams) {
        const unexpectedFilters = Object.keys(issueListApiParams).some((key) =>
          [
            'startAfter',
            'startBefore',
            'endAfter',
            'endBefore',
            'snoozedUntilAfter',
            'snoozedUntilBefore',
          ].includes(key),
        )
        return (
          issueListApiParams.isUnresolved &&
          issueListApiParams.isSnoozed &&
          !unexpectedFilters
        )
      }
      return false
    }

    const freezedParams = ['property', 'csmOwner'].reduce((acc, param) => {
      if (param in issueListApiParams) {
        acc[param] = issueListApiParams[param]
      }
      return acc
    }, {})

    return [
      {
        label: 'Currently Open',
        params: { ...freezedParams, isUnresolved: true, isSnoozed: false },
        selected: isCurrentlyOpenSelected(),
      },
      {
        label: 'Recently Resolved',
        params: {
          ...freezedParams,
          isUnresolved: false,
          endAfter: now.minus({ days: 7 }),
          endBefore: now,
        },
        selected: isRecentlyResolvedSelected(),
      },
      {
        label: 'Snoozed',
        params: {
          ...freezedParams,
          isSnoozed: true,
          isUnresolved: true,
        },
        selected: isSnoozedSelected(),
      },
    ]
  }

  return (
    <>
      <IssueForm
        open={!!issueToUpdate}
        onClose={(success) => {
          setIssueToUpdate(null)
          if (success === true) {
            doMarkIssueListAsOutdated()
          }
        }}
        instance={issueToUpdate}
      />
      <IssuesAuditLog
        open={!!queryObject?.auditLog}
        onClose={() => doUpdateQuery({}, { maintainScrollPosition: true })}
        issueId={queryObject?.auditLog}
      />
      <ConfirmationDialog
        fullWidth={false}
        title="Close Issue"
        message={
          issueToClose ? (
            <Typography>
              Are you sure you want to close{' '}
              <Box fontWeight="bold" display="inline">
                {humanize(issueToClose.category)}
              </Box>{' '}
              issue for{' '}
              <Box fontWeight="bold" display="inline">
                {issueToClose?.propertyName}
              </Box>
              ?
            </Typography>
          ) : (
            'Are you sure you want to close this issue?'
          )
        }
        open={!!issueToClose}
        onConfirm={handleCloseIssue}
        onCancel={() => setIssueToClose(null)}
      />
      <Box m={renderAsTab ? 0 : 3} sx={{ display: 'flex', flexDirection: 'column' }}>
        {!renderAsTab && (
          <>
            <Breadcrumbs
              links={[{ label: 'Home', href: homeUrls.home }, { label: 'Issues' }]}
            />
            <ListPageTitle
              title="Issues"
              onFilterPressed={isSmallScreen ? () => setFilterModalOpen(true) : null}
              mb={2}
            />
          </>
        )}
        <Box display="flex">
          <Filter
            mode={isSmallScreen ? 'modal' : 'drawer'}
            disabled={issueListIsLoading}
            apiParams={issueListApiParams}
            setApiParams={doIssueListSetFilter}
            dialogOpen={filterModalOpen}
            dialogOnClose={() => setFilterModalOpen(false)}
          >
            {!renderAsTab && (
              <>
                <DynamicSelect label="Properties" filterName="property" size="small" />
                <DynamicSelect
                  label="CSM Owner"
                  filterName="csmOwner"
                  endpoint="users"
                  filters={{ isCsmOwner: true }}
                  size="small"
                />
              </>
            )}
            <StaticSelect
              label="Unresolved"
              filterName="isUnresolved"
              options={boolOptions}
            />
            <StaticSelect
              label="Snoozed"
              filterName="isSnoozed"
              options={boolOptions}
            />
            <StaticSelect
              multiple
              label="Categories"
              filterName="category"
              options={systemIssueCategories}
            />
            <Picker
              range
              type="dateTime"
              label="Start at"
              disableMaskedInput
              conditionSeparator=""
              filterName="start"
              lowerCondition="After"
              upperCondition="Before"
              value={pick(['startBefore', 'startAfter'], issueListApiParams)}
            />
            <Picker
              range
              type="dateTime"
              label="End at"
              disableMaskedInput
              conditionSeparator=""
              filterName="end"
              lowerCondition="After"
              upperCondition="Before"
              value={pick(['endBefore', 'endAfter'], issueListApiParams)}
            />
            <Picker
              range
              type="dateTime"
              label="Snoozed at"
              disableMaskedInput
              conditionSeparator=""
              filterName="snoozedUntil"
              lowerCondition="After"
              upperCondition="Before"
              value={pick(
                ['snoozedUntilBefore', 'snoozedUntilAfter'],
                issueListApiParams,
              )}
            />
          </Filter>
          <Box
            ml={isSmallScreen ? 0 : 2}
            mt={isSmallScreen ? 0 : 1}
            flex={1}
            overflow="hidden"
            minHeight="900px"
          >
            <Box display="flex" gap={1}>
              {getFilterChipsData().map((item) => (
                <Chip
                  key={item.label}
                  label={item.label}
                  size="small"
                  onClick={
                    item.selected
                      ? () => doIssueListSetFilter(defaultFilter)
                      : () => doIssueListSetFilter(item.params)
                  }
                  color="primary"
                  variant={item.selected ? 'standard' : 'outlined'}
                />
              ))}
            </Box>
            {isSmallScreen ? (
              <MobileList
                title="Issues"
                loading={issueListIsLoading}
                itemBuilder={MobileListItem}
                page={issueList.current || 1}
                pageChange={doIssueListSetPage}
                pageSize={issueList.pageSize}
                pageSizeChange={doIssueListSetPageSize}
                rows={rows}
                rowCount={issueList.count || 0}
                setSearch={doIssueListSetSearch}
              />
            ) : (
              <List
                columnsAutosize
                title="Issues"
                showAddButton={false}
                showActions={false}
                loading={issueListIsLoading}
                columns={columns}
                page={issueList.current || 1}
                pageChange={doIssueListSetPage}
                pageSize={issueList.pageSize}
                pageSizeChange={doIssueListSetPageSize}
                rows={rows}
                rowCount={issueList.count || 0}
                setSearch={doIssueListSetSearch}
                sortChange={doIssueListSetOrdering}
                currentOrdering={ordering}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}
