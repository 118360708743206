import { useConnect, useReduxBundlerStore } from 'redux-bundler-hook'

import { Box, FormControlLabel, Stack, Typography } from '@mui/material'

import { Field } from 'formik'
import { DateTime } from 'luxon'
import * as Yup from 'yup'

import UserAvatar from '@common/components/Avatar'
import { FormBase, PhoneNumber, Switch, TextField } from '@common/components/Form'
import { StaticSelect } from '@common/components/Selects'
import { getApiFetch, merge, useSmallScreen } from '@common/utils'

export default function Profile({ ...props }) {
  const { me, doFetchMe, system } = useConnect('selectMe', 'doFetchMe', 'selectSystem')

  const isSmallScreen = useSmallScreen()

  const initialValues = merge(
    {
      email: '',
      name: '',
      phoneNumber: '',
      timezone: '',
      portfolioPerformanceReport: false,
      pushEnabled: false,
      textEnabled: false,
      emailEnabled: false,
    },
    me,
  )

  const validationSchema = Yup.object().shape({
    email: Yup.string().min(1).max(254).required(),
    name: Yup.string().max(255).required(),
    phoneNumber: Yup.string().matches(/\+$|\+[1-9]\d{1,14}/, {
      message: 'Please enter a valid phone number',
    }),
    timezone: Yup.string(),
  })

  const store = useReduxBundlerStore()
  const apiFetch = getApiFetch(store)

  const handleSave = async (values) => {
    const payload = values

    if (values.phoneNumber === '+') {
      payload.phoneNumber = ''
    }

    const result = await apiFetch('/me/', payload, { method: 'PUT' })

    if (result && !result.error) {
      await doFetchMe()
    }

    return result
  }

  const getTimezoneLabel = (tz) => {
    const { offset } = DateTime.local().setZone(tz)
    const offsetHours = Math.abs(offset) / 60
    const symbol = offset < 0 ? '-' : '+'

    return `GMT/UTC ${symbol} ${offsetHours}h (${tz})`
  }

  const timezoneOptions = system.timeZones.map((tz) => ({
    id: tz,
    label: getTimezoneLabel(tz),
  }))

  return (
    <Stack
      data-testid="profile_container"
      direction="row"
      spacing={2}
      justifyContent="flex-end"
      mb={isSmallScreen ? 2 : 0}
    >
      {!isSmallScreen && (
        <Box
          data-testid="avatar_container"
          display="flex"
          flex={0.5}
          justifyContent="center"
          sx={{ width: '100%' }}
        >
          <UserAvatar name={me.name} size={192} fontSize={64} />
        </Box>
      )}

      <Stack spacing={3} flex={1}>
        <Typography variant="h6">Your Information</Typography>

        <FormBase
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSave={handleSave}
          successMessage="Your information was successfully updated!"
          {...props}
        >
          <Stack spacing={2}>
            <Field
              data-testid="field-name"
              component={TextField}
              label="Full Name"
              name="name"
              size={isSmallScreen ? 'small' : 'medium'}
            />
            <Field
              disabled
              data-testid="field-email"
              component={TextField}
              label="Email"
              name="email"
              size={isSmallScreen ? 'small' : 'medium'}
            />
            <Field
              data-testid="field-phonenumber"
              component={PhoneNumber}
              label="Phone"
              name="phoneNumber"
              size={isSmallScreen ? 'small' : 'medium'}
            />
            <Field
              disableClearable
              data-testid="timezone"
              component={StaticSelect}
              options={timezoneOptions}
              label="Time Zone"
              name="timezone"
              size={isSmallScreen ? 'small' : 'medium'}
            />
            <Box
              display="flex"
              flexDirection="column"
              border={1}
              borderColor="lightgrey"
              position="relative"
              padding={2}
              borderRadius={1}
            >
              <Typography
                variant="subtitle1"
                component="div"
                style={{
                  position: 'absolute',
                  top: -12,
                  left: 16,
                  backgroundColor: 'white',
                  paddingLeft: 4,
                  paddingRight: 4,
                }}
              >
                User Notifications
              </Typography>
              <FormControlLabel
                control={
                  <Field type="checkbox" component={Switch} name="textEnabled" />
                }
                label="Receive notifications via SMS text"
              />
              <FormControlLabel
                control={
                  <Field type="checkbox" component={Switch} name="emailEnabled" />
                }
                label="Receive notifications via email"
              />
              <FormControlLabel
                control={
                  <Field type="checkbox" component={Switch} name="pushEnabled" />
                }
                label={
                  <>
                    Receive push notifications from the Rest mobile app [
                    <a href="https://play.google.com/store/apps/details?id=io.noiseaware.smokeaware_mobile&hl=en_US">
                      {' '}
                      Android{' '}
                    </a>
                    |
                    <a href="https://apps.apple.com/us/app/rest-by-noiseaware/id6449251426">
                      {' '}
                      iOS{' '}
                    </a>
                    ]
                  </>
                }
              />
            </Box>
            <FormControlLabel
              control={
                <Field
                  type="checkbox"
                  component={Switch}
                  name="portfolioPerformanceReport"
                  sx={{ marginLeft: 1 }}
                />
              }
              label="Receive weekly portfolio performance report"
            />
          </Stack>
        </FormBase>
      </Stack>
    </Stack>
  )
}
