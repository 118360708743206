import { AUTOMOTIVE_LAYOUT_KEY, DEFAULT_LAYOUT_KEY } from '@common/constants'
import { generateColors } from '@common/utils'
import {
  CATEGORY_AUTOMOTIVE_ENVIRONMENT,
  CATEGORY_AUTOMOTIVE_GASES,
  CATEGORY_AUTOMOTIVE_INDEXES,
  CATEGORY_AUTOMOTIVE_MOX,
  CATEGORY_AUTOMOTIVE_NC,
  CATEGORY_AUTOMOTIVE_PM,
  CATEGORY_AUTOMOTIVE_RAW,
  CATEGORY_CO2_TVOC,
  CATEGORY_ENVIRONMENT,
  CATEGORY_MODEL_SCORES,
  CATEGORY_PM,
  CATEGORY_PM_RATIOS,
  CATEGORY_SOUND_LEVELS,
  CATEGORY_VOC_NOX,
} from '@common/utils/grafanaViewDataProcessor'

export const GRAFANA_GREEN = '#63b556'
export const GRAFANA_YELLOW = '#eec30f'
export const GRAFANA_BLUE = '#78a7ff'
export const GRAFANA_ORANGE = '#fc620d'
export const GRAFANA_RED = '#ec304a'
export const GRAFANA_DARK_BLUE = '#4a6fb5'
export const GRAFANA_DARK_GREEN = '#21914a'

export const colorsPool = [
  GRAFANA_GREEN,
  GRAFANA_YELLOW,
  GRAFANA_BLUE,
  GRAFANA_ORANGE,
  GRAFANA_RED,
  ...generateColors(),
]

function generateAutomotiveTypesMeta() {
  const initialData = {
    // PM
    mc_1p0_mean: {
      color: colorsPool[0],
      category: CATEGORY_AUTOMOTIVE_PM,
    },
    mc_2p5_mean: {
      color: colorsPool[1],
      category: CATEGORY_AUTOMOTIVE_PM,
    },
    mc_4p0_mean: {
      color: colorsPool[2],
      category: CATEGORY_AUTOMOTIVE_PM,
    },
    mc_10p0_mean: {
      color: colorsPool[3],
      category: CATEGORY_AUTOMOTIVE_PM,
    },
    // GASES
    etoh: {
      color: colorsPool[0],
      category: CATEGORY_AUTOMOTIVE_GASES,
    },
    eco2_mean: {
      color: colorsPool[1],
      category: CATEGORY_AUTOMOTIVE_GASES,
    },
    co2: {
      color: colorsPool[2],
      category: CATEGORY_AUTOMOTIVE_GASES,
    },
    tvoc_mean: {
      color: colorsPool[3],
      category: CATEGORY_AUTOMOTIVE_GASES,
    },
    // INDEXES
    voc_mean: {
      color: colorsPool[0],
      category: CATEGORY_AUTOMOTIVE_INDEXES,
    },
    nox_mean: {
      color: colorsPool[1],
      category: CATEGORY_AUTOMOTIVE_INDEXES,
    },
    zmod_iaq: {
      color: colorsPool[2],
      category: CATEGORY_AUTOMOTIVE_INDEXES,
    },
    zmod_rel_iaq: {
      color: colorsPool[3],
      category: CATEGORY_AUTOMOTIVE_INDEXES,
    },
    // ENV
    temp_mean: {
      color: colorsPool[0],
      category: CATEGORY_AUTOMOTIVE_ENVIRONMENT,
    },
    hum_mean: {
      color: colorsPool[1],
      category: CATEGORY_AUTOMOTIVE_ENVIRONMENT,
    },
    pressure: {
      color: colorsPool[2],
      category: CATEGORY_AUTOMOTIVE_ENVIRONMENT,
    },
    // altitude: {
    //   color: colorsPool[3],
    //   category: CATEGORY_AUTOMOTIVE_ENVIRONMENT,
    // },
    rhtr: {
      color: colorsPool[4],
      category: CATEGORY_AUTOMOTIVE_ENVIRONMENT,
    },
    log_rcda: {
      color: colorsPool[5],
      category: CATEGORY_AUTOMOTIVE_ENVIRONMENT,
    },
    // NC
    nc_0p5_mean: {
      color: colorsPool[0],
      category: CATEGORY_AUTOMOTIVE_NC,
    },
    nc_1p0_mean: {
      color: colorsPool[1],
      category: CATEGORY_AUTOMOTIVE_NC,
    },
    nc_2p5_mean: {
      color: colorsPool[2],
      category: CATEGORY_AUTOMOTIVE_NC,
    },
    nc_4p0_mean: {
      color: colorsPool[3],
      category: CATEGORY_AUTOMOTIVE_NC,
    },
    nc_10p0_mean: {
      color: colorsPool[4],
      category: CATEGORY_AUTOMOTIVE_NC,
    },
    // RAW
    sen_voc_raw_mean: {
      color: colorsPool[0],
      category: CATEGORY_AUTOMOTIVE_RAW,
    },
    sen_nox_raw_mean: {
      color: colorsPool[1],
      category: CATEGORY_AUTOMOTIVE_RAW,
    },
    raw_co2: {
      color: colorsPool[2],
      category: CATEGORY_AUTOMOTIVE_RAW,
    },
    // MOX
    ...Array.from(Array(13)).reduce(
      (acc, _, index) => ({
        ...acc,
        [`mox${index}`]: {
          color: colorsPool[index],
          category: CATEGORY_AUTOMOTIVE_MOX,
        },
      }),
      {},
    ),
  }

  return Object.entries(initialData).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: { ...value, key, systemName: key, tooltipLabel: key, visible: true },
    }),
    {},
  )
}

function generateDefaultTypesMeta() {
  const initialData = {
    // PM
    mc_1p0_mean: {
      tooltipLabel: 'PM 0.3-1.0',
      color: GRAFANA_GREEN,
      category: CATEGORY_PM,
    },
    mc_2p5_mean: {
      tooltipLabel: 'PM 1.0-2.5',
      color: GRAFANA_YELLOW,
      category: CATEGORY_PM,
    },
    mc_4p0_mean: {
      tooltipLabel: 'PM 2.5-4.0',
      color: GRAFANA_BLUE,
      category: CATEGORY_PM,
    },
    mc_10p0_mean: {
      tooltipLabel: 'PM 4.0-10.0',
      color: GRAFANA_ORANGE,
      category: CATEGORY_PM,
    },
    tpm_mean: {
      tooltipLabel: 'particle size',
      color: GRAFANA_RED,
      category: CATEGORY_PM,
      unit: 'particle size',
    },
    // SOUND LEVELS
    nrs: {
      color: GRAFANA_GREEN,
      category: CATEGORY_SOUND_LEVELS,
      unit: 'NRS',
    },
    dba_mean: {
      color: GRAFANA_YELLOW,
      category: CATEGORY_SOUND_LEVELS,
    },
    // PM RATIOS
    mc_1p0_ratio: {
      tooltipLabel: 'PM 0.3-1.0',
      color: GRAFANA_GREEN,
      category: CATEGORY_PM_RATIOS,
      unit: '%',
      template: '{{ value }} %',
      fillArea: true,
    },
    mc_2p5_ratio: {
      tooltipLabel: 'PM 1.0-2.5',
      color: GRAFANA_YELLOW,
      category: CATEGORY_PM_RATIOS,
      unit: '%',
      template: '{{ value }} %',
      fillArea: true,
    },
    mc_4p0_ratio: {
      tooltipLabel: 'PM 2.5-4.0',
      color: GRAFANA_BLUE,
      category: CATEGORY_PM_RATIOS,
      unit: '%',
      template: '{{ value }} %',
      fillArea: true,
    },
    mc_10p0_ratio: {
      tooltipLabel: 'PM 4.0-10.0',
      color: GRAFANA_ORANGE,
      category: CATEGORY_PM_RATIOS,
      unit: '%',
      template: '{{ value }} %',
      fillArea: true,
    },
    // ENV
    temp: {
      systemName: 'temp_mean',
      color: GRAFANA_YELLOW,
      category: CATEGORY_ENVIRONMENT,
    },
    rel_hum: {
      systemName: 'hum_mean',
      color: GRAFANA_GREEN,
      category: CATEGORY_ENVIRONMENT,
    },
    abs_hum: {
      systemName: '-',
      color: GRAFANA_BLUE,
      category: CATEGORY_ENVIRONMENT,
      unit: 'g/m³',
      template: '{{ value }} g/m³',
    },
    // CO2/TVOC
    tvoc_mean: {
      tooltipLabel: 'tvoc',
      color: GRAFANA_YELLOW,
      category: CATEGORY_CO2_TVOC,
    },
    eco2_mean: {
      tooltipLabel: 'co2',
      color: GRAFANA_GREEN,
      category: CATEGORY_CO2_TVOC,
    },
    // VOC/NOX
    voc_mean: {
      tooltipLabel: 'voc',
      color: GRAFANA_GREEN,
      category: CATEGORY_VOC_NOX,
    },
    nox_mean: {
      tooltipLabel: 'nox',
      color: GRAFANA_YELLOW,
      category: CATEGORY_VOC_NOX,
    },
  }

  return Object.entries(initialData).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: {
        ...value,
        key,
        systemName: value.systemName ?? key,
        tooltipLabel: value.tooltipLabel ?? key,
        visible: true,
      },
    }),
    {},
  )
}

export function getEventTypesMeta(
  systemDataTypes,
  systemSmokeProfiles,
  extraTypes,
  { layout = DEFAULT_LAYOUT_KEY } = {},
) {
  const reversedProfiles = [...systemSmokeProfiles].reverse()
  const profilesMeta = reversedProfiles.reduce(
    (acc, profile, i) => ({
      ...acc,
      [profile.id]: {
        key: profile.id,
        tooltipLabel: `${profile.modelKey.replace(/moneymaker/gi, 'mm')} - ${
          profile.name
        }`,
        color: colorsPool[i % colorsPool.length],
        category: CATEGORY_MODEL_SCORES,
        unit: '%',
        template: '{{ value }} %',
        visible: true,
        scatter: true,
        scatterShowNull: true,
        scatterNullRenderValue: 50,
        scatterNullRenderStyle: { color: 'red', radius: 4 },
      },
    }),
    {},
  )

  const smokeModelsMeta =
    extraTypes?.reduce(
      (acc, data, i) => ({
        ...acc,
        [data.type]: {
          key: data.type,
          tooltipLabel: data.type.replace(/moneymaker/gi, 'mm'),
          color: colorsPool[i % colorsPool.length],
          category: data.category,
          unit: '',
          template: '{{ value }}',
          visible: true,
          scatter: false,
        },
      }),
      {},
    ) ?? {}

  const typesMeta =
    layout === AUTOMOTIVE_LAYOUT_KEY
      ? generateAutomotiveTypesMeta()
      : generateDefaultTypesMeta()
  return Object.fromEntries(
    Object.entries({ ...typesMeta, ...profilesMeta, ...smokeModelsMeta }).map(
      ([k, v]) => {
        const systemDataType = systemDataTypes.find((type) =>
          type.key.startsWith(v.systemName),
        )
        let { template, unit } = v
        if (systemDataType) {
          template ??= systemDataType.template
          unit ??= systemDataType.template.replace('{{ value }}', '').trim()
          if (unit.startsWith('/')) {
            unit = `#${unit}`
          }
        }
        return [k, { ...v, unit: unit ?? '', template: template ?? '{{ value }}' }]
      },
    ),
  )
}
