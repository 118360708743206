import { AssignmentOutlined, CloseRounded, Edit } from '@mui/icons-material'
import { Box, Button, IconButton, Tooltip } from '@mui/material'

import { useSmallScreen } from '@common/utils'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.row
 * @param {Function} props.onEditPressed
 * @param {Function} props.onAuditLogPressed
 * @param {Function} props.onClosePressed
 */
export default function IssueActions({
  row,
  onEditPressed,
  onAuditLogPressed,
  onClosePressed,
}) {
  const isSmallScreen = useSmallScreen()
  const closeButtonColor = row.end ? 'rgba(191, 191, 191, 1)' : 'rgba(209, 101, 101, 1)'

  return (
    <Box flex={1} display="flex" gap={1} justifyContent="center" alignItems="center">
      <Button
        fullWidth={isSmallScreen}
        size="small"
        startIcon={<Edit />}
        variant="outlined"
        onClick={() => onEditPressed(row)}
      >
        Edit
      </Button>
      <Button
        fullWidth={isSmallScreen}
        size="small"
        startIcon={<AssignmentOutlined />}
        variant="outlined"
        onClick={() => onAuditLogPressed(row)}
      >
        Audit Log
      </Button>
      <Tooltip title={row.end ? 'Already resolved' : null}>
        <span>
          <IconButton
            disabled={row.end}
            sx={{
              height: 28,
              outline: `1px solid ${closeButtonColor}; border-radius: 3px;`,
              '& .MuiTouchRipple-root .MuiTouchRipple-child': {
                borderRadius: '3px',
              },
            }}
            color="error"
            size="small"
            onClick={() => onClosePressed(row)}
          >
            <CloseRounded sx={{ fontSize: 14 }} />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  )
}
