import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { path } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import {
  Alert,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material'

import { Field, useFormikContext } from 'formik'
import * as Yup from 'yup'

import { LoadingOverlay } from '@common/components'
import { TextField } from '@common/components/Form'
import { StaticSelect } from '@common/components/Selects'
import { parseApiErrors } from '@common/utils'
import FormikStatePropagator from '@portal/UI/components/FormikStatePropagator'
import { FormDialog } from '@rest/UI/components'

import {
  REPEAT_OFFENCE_SUGGESTION,
  UNBOOKED_ROOM_WARNING,
  VACANT_ROOM_WARNING,
} from './constants'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.chargeType
 * @param {Function} props.handleChargeTypeChange
 * @param {string} props.fee
 */
function ChargeTypeSelection({ chargeType, handleChargeTypeChange, fee }) {
  const formik = useFormikContext()
  return (
    <FormControl>
      <FormLabel id="charge-type-group">
        Select charge amount for smoking event:
      </FormLabel>
      <RadioGroup
        aria-labelledby="charge-type-group"
        name="chargeType"
        value={chargeType}
        onChange={(event) => handleChargeTypeChange(event, formik)}
      >
        <FormControlLabel
          value="default"
          control={<Radio />}
          label={`$${fee} - property policy amount`}
        />
        <FormControlLabel value="other" control={<Radio />} label="Other" />
      </RadioGroup>
    </FormControl>
  )
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {Function} props.onClose
 * @param {Object} [props.instance]
 * @param {string} props.instance.id
 * @param {Object} [props.instance.smokeFeedback]
 * @param {Object} props.property
 * @param {string} props.property.id
 * @param {string[]} [props.property.expandedFlags]
 * @param {string} props.property.name
 * @param {string} [props.property.smokingFee]
 */
export default function PostCharge({ onClose, instance = {}, property }) {
  const {
    doEventFeedbackSave,
    doEventFeedbackCanWaive,
    doEventFeedbackRemoveEvent,
    doShowSnackbar,
    smokeAdjustmentReasons,
  } = useConnect(
    'doEventFeedbackSave',
    'doEventFeedbackCanWaive',
    'doEventFeedbackRemoveEvent',
    'doShowSnackbar',
    'selectSmokeAdjustmentReasons',
  )

  const fieldRef = useRef(null)

  const [formikProps, setFormikProps] = useState({
    values: {},
    setValues: () => {},
    setFieldValue: () => {},
  })
  const [initialDataLoading, setInitialDataLoading] = useState(false)
  const [showRepeatOffenceSuggestion, setShowRepeatOffenceSuggestion] = useState(false)
  const [chargeType, setChargeType] = useState('default')

  const initialValues = {
    chargeAmount: property.smokingFee,
    chargeType: 'default',
    comments: '',
  }

  const repeatOffenceSuggestionAllowed = useMemo(
    () => property?.expandedFlags?.includes('ENABLE_REPEAT_OFFENSE'),
    [property],
  )

  const unbookedRoomSuggestionAllowed = useMemo(
    () => property?.expandedFlags?.includes('ENABLE_USER_REMOVE_EVENT'),
    [property],
  )

  const isRemoveEventFlow =
    unbookedRoomSuggestionAllowed && formikProps.values.adjustmentReason === 'UNBOOKED'

  const fetchEventCanWaive = useCallback(async () => {
    try {
      setInitialDataLoading(true)
      const result = await doEventFeedbackCanWaive(instance.id)
      if (result) {
        setInitialDataLoading(false)
        setShowRepeatOffenceSuggestion(result.canWaive)
        if (result.canWaive) setChargeType('other')
      }
    } catch (err) {
      setInitialDataLoading(false)
      const parsedError = parseApiErrors(err?.response)
      doShowSnackbar(parsedError, 'error')
    }
  }, [instance.id])

  useEffect(() => {
    if (repeatOffenceSuggestionAllowed) {
      fetchEventCanWaive()
    }
  }, [repeatOffenceSuggestionAllowed, fetchEventCanWaive])

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        chargeAmount: Yup.number()
          .typeError('Please enter a valid amount')
          .test(
            'is-decimal',
            'Please enter a valid amount',
            (value) =>
              value === undefined ||
              value === 0 ||
              `${value}`.match(/^[1-9]\d*(\.\d{0,2})?$/),
          )
          .required('Please enter a charge amount'),
        adjustmentReason: Yup.string().when('chargeAmount', {
          is: (val) => Number(val) !== Number(property.smokingFee),
          then: (field) => field.required('Please select a reason for adjustment'),
          otherwise: (field) => field.nullable(),
        }),
        comments: Yup.string().when(['adjustmentReason'], {
          is: (val) => val === 'OTHER',
          then: (field) =>
            field.matches(/^(?=.*\S).{10,}$/, 'Please enter at least 10 characters'),
          otherwise: (field) => field.nullable(),
        }),
      }),
    [property.smokingFee],
  )

  const onFeedbackSave = useCallback(
    async (data) => {
      const adjustmentAmount = Number(data.chargeAmount) - Number(property.smokingFee)
      const result = await doEventFeedbackSave({
        ...data,
        id: path(['smokeFeedback', 'id'], instance),
        property: property.id,
        event: instance.id,
        netChargeAmount: data.chargeAmount,
        chargeAmount: property.smokingFee,
        adjustmentAmount: adjustmentAmount.toFixed(2),
      })
      if (isRemoveEventFlow) {
        await doEventFeedbackRemoveEvent(instance.id)
      }
      if (result.error) throw parseApiErrors(result)
      return result
    },
    [doEventFeedbackSave, instance, isRemoveEventFlow],
  )

  const handleChargeTypeChange = (event, formik) => {
    setChargeType(event.target.value)
    formik.setFieldValue('chargeType', event.target.value)
    if (event.target.value === 'default') {
      formik.setFieldValue('chargeAmount', property.smokingFee)
    } else {
      formik.setFieldValue('chargeAmount', '')
    }
  }

  return (
    <FormDialog
      open
      data-testid="post_charge_dialog"
      onClose={onClose}
      initialValues={initialValues}
      label="Smoking Event Charge"
      validationSchema={validationSchema}
      onSave={onFeedbackSave}
      successMessage="Successfully saved charge information"
      isSaveEnabled={!initialDataLoading}
      saveButtonText={isRemoveEventFlow ? 'Remove Event' : 'Submit'}
      bypassDirtyState
    >
      <>
        <LoadingOverlay loading={initialDataLoading}>
          <Stack spacing={2} mt={0}>
            <ChargeTypeSelection
              chargeType={chargeType}
              handleChargeTypeChange={handleChargeTypeChange}
              fee={property.smokingFee}
            />

            {chargeType === 'other' && (
              <>
                {showRepeatOffenceSuggestion && (
                  <Alert severity="info">{REPEAT_OFFENCE_SUGGESTION}</Alert>
                )}
                <Field
                  component={TextField}
                  name="chargeAmount"
                  label="Charge Amount"
                  InputProps={{
                    inputRef: fieldRef,
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  autoFocus
                />

                <Field
                  component={StaticSelect}
                  name="adjustmentReason"
                  label="Adjustment Reason"
                  options={smokeAdjustmentReasons}
                />
                {isRemoveEventFlow && (
                  <Alert severity="warning">{UNBOOKED_ROOM_WARNING}</Alert>
                )}
                {formikProps.values.adjustmentReason === 'VACANT' && (
                  <Alert severity="warning">{VACANT_ROOM_WARNING}</Alert>
                )}
              </>
            )}
            <Field
              component={TextField}
              name="comments"
              label="Comments"
              InputProps={{ multiline: true }}
            />
          </Stack>
        </LoadingOverlay>

        <FormikStatePropagator propSetter={setFormikProps} />
      </>
    </FormDialog>
  )
}
